import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { MenuItem, Select } from "@material-ui/core";
import { debounce } from "lodash";

import { apiAddress } from "../../config/general";
import AddNew from "./AddNew";
import View from "./View";
import MobileLayoutToggle from "../App/MobileLayoutToggle";

import Sidebar from "../../components/Layout/Sidebar";
import AddActionButtons from "../../components/Buttons/AddActionButtons";

import DateRangeFilter from "../../components/Filters/DateRangeFilter";
import { default as ScrollArea } from "../../components/Layout/ScrollBar";

import { handleDate } from "../../services/utils";
import MainButton from "../../components/Buttons/MainButton";

import { addNewToggle, getInspections, setHistory } from "./actions";
import { setSearch, setActivePark, finishedSearch } from "../App/actions";

const StyledWrap = styled.div`
  && {
    display: flex;
    height: 100%;
    /* height: calc(100vh - 49px); */
    @media (max-width: 425px) {
      display: block;
    }
  }
`;
const StyledMainButton = styled(MainButton)`
  && {
    width: 100%;
    margin-top: 1em;
    font-size: 0.8em;
  }
`;
const Section = styled.div`
  && {
    margin: 0.5em 0;
  }
`;

const Card = styled.div`
  && {
    /* base: */
    background: #ffffff;
    border: 1px solid #e9eff4;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    padding: 1em;
    margin: 1em 0;
    cursor: pointer;

    .photo {
      height: 150px;
      display: none;
      margin: -16px -16px 16px -16px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }

    &:hover {
      /* base: */
      background: rgba(74, 144, 226, 0.75);
      border: 1px solid #e9eff4;
      color: #fff;
      .date,
      .damages,
      .details,
      .report,
      .name {
        color: #fff;
      }
      .photo {
        display: block;
      }
      .parque,
      .element,
      .type {
        color: rgba(255, 255, 255, 0.4);
      }
    }
    &.Danos {
      border-left: 3px solid #eb3d3d;
      &:hover {
        background: #eb3d3d;
      }
    }
    &.Inicial {
      border-left: 3px solid #ebcd3d;
      &:hover {
        background: #ebcd3d;
      }
    }
    &.Final {
      border-left: 3px solid #6fc44c;
      &:hover {
        background: #6fc44c;
      }
    }
    .element {
      text-align: right;
      font-size: 1em;
      display: inline-block;
      color: rgba(74, 144, 226, 0.6);
    }
    .parque {
      font-size: 0.8em;
      text-align: right;
      display: block;
      color: rgba(74, 144, 226, 0.3);
      margin-bottom: 0.5em;
    }
    .type {
      display: inline-block;
      padding: 5px;
      position: absolute;
      right: 0.5em;
      img {
        width: 20px;
        opacity: 0.7;
      }
    }

    .details {
      font-size: 0.8em;
      display: block;
      color: #989898;
    }
    .damages {
      font-size: 0.8em;
      margin-bottom: 0.5em;
      display: inline-block;
      font-weight: bold;
      color: rgba(74, 144, 226, 1);
    }
    .date,
    .name {
      /* 11:00 AM - 11:30 AM: */
      margin-top: 1em;
      opacity: 0.7;
      font-size: 0.7em;
      color: rgba(74, 144, 226, 0.8);
      letter-spacing: -0.32px;
      display: inline-block;
      text-align: right;
    }
    .report {
      margin-top: 0;
      font-size: 1.2em;
      text-align: right;
      display: inline-block;
      font-weight: bold;
      margin-right: 0.2em;
      color: rgba(74, 144, 226, 0.8);
    }
    .name {
      font-weight: bold;
      text-align: left;
    }

    .goToPark {
      background: #fff;
      display: block;
      border-top: 1px solid #e9eff4;
      color: #cfd1db;
      padding: 0.6em 1em;
      font-size: 0.7em;
      margin: 1em -1.4em -1.5em;
      border-radius: 0 0 5px 5px;
      :hover {
        color: #fff;
        background: rgba(74, 144, 226, 0.8);
      }
    }
  }
`;

const CardList = styled.div`
  && {
    .inspection-list {
      height: calc(100vh - 100px);
      padding-right: 18px;
      .scrollbar {
        border-radius: 1em;
      }
    }
  }
`;

const FilterList = styled.div`
  && {
    > div {
      height: calc(100vh - 250px);
      padding-right: 18px;

      @media (max-width: 900px) {
        height: calc(100vh - 300px);
      }
      .scrollbar {
        border-radius: 1em;
      }
    }
  }
`;

const InspectionsFilter = styled(Select)`
  && {
    border: 0;
    font-size: 0.9em;
    color: #a3a1a1;
  }
`;

class Inspections extends Component {
  constructor(props) {
    super(props);

    this.state = {
      previewImageId: null,
      selectedElement: null,
    };

    this.toggleActiveInspection = this.toggleActiveInspection.bind(this);
    this.toggleAddNew = this.toggleAddNew.bind(this);
    this.goToInspectionDetail = this.goToInspectionDetail.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.getInspections = debounce(props.getInspections, 600);
    this.setSearch = debounce(props.setSearch, 600);
    this.closeAddModal = this.closeAddModal.bind(this);
  }

  componentDidMount(prevProps, prevState) {
    this.toggleActiveInspection("general");
    // if (!this.props.match.params.pid) this.props.getInspections();
    this.setSearch({
      element: "edit",
      value: null,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    //if Edit
    if (
      this.props.match.params.id &&
      this.props.edit !== this.props.match.params.id
    ) {
      this.setSearch({
        element: "edit",
        value: this.props.match.params.id,
      });
    }
    if (
      this.props.appStatus === "searching" ||
      this.props.appStatus === "searchUpdated"
    ) {
      // this.getInspections();
      this.props.finishedSearch();
    }
  }

  handleChange(e, value) {
    this.setSearch({
      element: [e.target.name],
      value: e.target.value,
    });
  }

  handleSearchChange(e, value) {
    this.setSearch({
      element: [e.target.name],
      value: e.target.value,
    });
    // this.getInspections();
  }

  setActivePark(park, type) {
    this.props.setActivePark(
      park.properties.numero || this.props.numero,
      park.properties.local || this.props.local,
      park.properties.freguesia || this.props.freguesia,
      park.properties.eid,
      true,
      true
    );

    if (type !== "element" && !this.props.selected) {
      this.setState({ selectedElement: null });
      // this.getInspections();
    } else if (type !== "element") {
      this.setState({ selectedElement: null });
      // this.getInspections();
      // this.toggleAddNew();
    } else {
      this.setState({ selectedElement: park.properties });
      // this.toggleAddNew();
    }
  }

  toggleActiveInspection(element) {
    this.setSearch({ element: "active", value: element });
    this.getInspections();
  }

  preprocessInspections(inspections) {
    // lets filter inspections when element is selected

    return inspections;
  }

  closeAddModal() {
    this.props.addNewToggle();
  }
  toggleAddNew(local, state) {
    let localId = local; //from url

    if (local && local.properties) {
      localId = local.properties.numero;
    } else if (!local && this.props.selected) {
      localId = this.props.selected;
    }
    if (localId !== this.props.selected) this.props.setHistory();
    this.props.addNewToggle(localId, state);
  }

  uniq(a) {
    return Array.from(new Set(a || []));
  }

  renderCard(inspection, i) {
    let selectedPark = [];

    const specialEV = inspection.pid !== inspection.location;

    if (!inspection.pid && inspection.attr.relation) {
      // special case for element selection
      selectedPark = this.props.list.filter((park) => {
        return inspection.attr.relation.includes(park.numero);
      });
    } else if (specialEV) {
      // special case for element selection
      selectedPark = this.props.list.find((park) => {
        if (park.numero === inspection.pid) return true;
        if (park.eid === inspection.pid) return true;
        return false;
      });
    } else {
      selectedPark = this.props.list.find(
        (park) => park.numero === inspection.pid
      );
    }

    let parkName = "";
    const totalParcels =
      inspection.attr &&
      inspection.attr.selected &&
      inspection.attr.selected.length;

    if (selectedPark && selectedPark instanceof Array) {
      parkName = `${this.uniq(selectedPark.map((p) => p.local)).join(
        ", "
      )}, ${this.uniq(selectedPark.map((p) => p.nome)).join(", ")}`;
    } else if (selectedPark) {
      parkName = `${selectedPark.local}, ${selectedPark.nome}`;
    }
    const photo =
      inspection.photos && inspection.photos.length > 0
        ? inspection.photos[0]
        : null;
    return (
      <Card
        className={`animated fadeInUp ${inspection.type}`}
        key={i}
        onMouseOver={() => this.setState({ previewImageId: i })}
      >
        <div
          onClick={() =>
            this.goToInspectionDetail(null, inspection.id, inspection.group)
          }
        >
          {photo && this.state.previewImageId === i && (
            <span
              className={"photo"}
              style={{ backgroundImage: `url(${apiAddress}${photo.path})` }}
            />
          )}

          <span className={"parque"}>{parkName}</span>
          {inspection.attr && inspection.attr.rating && (
            <span className={"report"}>{inspection.attr.rating}</span>
          )}
          {inspection.Damage && (
            <span className={"damages"}>{inspection.Damage.label}</span>
          )}
          <span className={"details"}>{inspection.details}</span>

          <span className={"name"}>{inspection.User.name}, </span>

          <span className={"date"}> {handleDate(inspection.date)}</span>
        </div>
        {selectedPark && selectedPark instanceof Array && (
          <span
            className={"goToPark"}
            onClick={() => {
              selectedPark &&
                this.setActivePark({ properties: selectedPark[0] });
            }}
          >
            <span className={`icon-location-pin icons`} /> Parcelas{" "}
            {selectedPark.map((p) => p.numero).join(", ")}
          </span>
        )}
        {selectedPark && selectedPark.numero && (
          <span
            className={"goToPark"}
            onClick={() => {
              selectedPark && this.setActivePark({ properties: selectedPark });
            }}
          >
            <span className={`icon-location-pin icons`} /> Ver Parcela{" "}
            {specialEV ? selectedPark.numero : selectedPark.numero}{" "}
            {inspection.ElementId && (
              <span className={"element"}>{inspection.ElementId}</span>
            )}
          </span>
        )}
      </Card>
    );
  }

  goToInspectionDetail(event, id, group) {
    //les allways get the history
    return this.props.history.push(`/inspections/${id}`);
    // return this.props.history.push(`/inspections/${!group ? id : group}`);
  }

  render() {
    const columnData = [
      {
        id: "id",
        numeric: false,
        disablePadding: true,
        label: "id",
      },
      { id: "date", numeric: false, disablePadding: false, label: "Data" },
      { id: "type", numeric: false, disablePadding: false, label: "Tipo" },
      { id: "pid", numeric: false, disablePadding: false, label: "Parque" },
      { id: "Damages", numeric: false, disablePadding: false, label: "Danos" },
      {
        id: "details",
        numeric: false,
        disablePadding: false,
        label: "Detalhes",
      },
    ];

    const {
      active,
      startDate,
      endDate,
      edit,
      history,
      addNew,
      map,
      inspections,
      selected,
      userWrite,
      status,
      role,
      freguesia,
      local,
      menuData,
    } = this.props;

    const hasInspection = inspections.length > 0;
    return (
      <StyledWrap>
        <MobileLayoutToggle />
        <Sidebar
          title={menuData.name}
          text={`${freguesia} ${local}<span> ${selected} </span>`}
          icon={menuData.icon}
        >
          {/* {role === 'PI' && (
            <ButtonGroup>
              <GroupedButton
                onClick={() => this.toggleActiveInspection('general')}
                active={active === 'general'}
              >
                <MenuIcon className={`icon-eye icons`} />
                Ocular
              </GroupedButton>
              <GroupedButton
                onClick={() => this.toggleActiveInspection('funcional')}
                active={active === 'funcional'}
              >
                <MenuIcon
                  theme={active === 'funcional'}
                  className={`icon-umbrella icons`}
                />
                Funcional
              </GroupedButton>
            </ButtonGroup>
          )} */}

          <FilterList>
            <ScrollArea
              speed={0.8}
              className="inspection-filter"
              contentClassName="inspection-filter-content"
              horizontal={false}
            >
              <Section>
                <InspectionsFilter
                  fullWidth
                  name={"status"}
                  value={status}
                  onChange={this.handleSearchChange}
                  inputProps={{
                    name: "status",
                    id: "status",
                  }}
                >
                  <MenuItem value="all">
                    <em>Todas</em>
                  </MenuItem>
                  <MenuItem value={0}>{"Aberto"}</MenuItem>
                  <MenuItem value={1}>{"Fechado"}</MenuItem>
                </InspectionsFilter>
              </Section>

              {hasInspection && (
                <CardList>
                  {inspections &&
                    this.preprocessInspections(inspections).map(
                      (inspection, index) => this.renderCard(inspection, index)
                    )}
                </CardList>
              )}
            </ScrollArea>
          </FilterList>
          <DateRangeFilter
            handleSearchChange={this.handleSearchChange}
            endDate={endDate}
            startDate={startDate}
          />
          {/* {((active === 'funcional' || active === 'general') &&
            role === 'PI' &&
            selected.length > 0) ||
            (role === 'EV' && selected.length > 0 && (
              <StyledMainButton onClick={() => this.toggleAddNew()}>
                <MenuIcon className={`icon-camera icons`} /> Adicionar{' '}
                {menuData.name}
              </StyledMainButton>
            ))} */}
        </Sidebar>

        {/* <Content size={hasInspection ? 3 : 2} hidden={map} map={!map}>
          <MapComponent
            geoJson={getGeoJson()}
            elements={getGeoElementJson()}
            onClickMarker={this.setActivePark}
          />
        </Content> */}
        <AddActionButtons />
        {edit && (
          <View
            group={edit}
            history={history}
            callback={this.props.match.params.callback}
          />
        )}
      </StyledWrap>
    );
  }
}

export default connect(
  (state) => {
    return {
      element: state.app.search.element,
      local: state.app.search.local,
      freguesia: state.app.search.freguesia,
      selected: state.app.search.selected,
      status: state.app.search.status,
      appStatus: state.app.status,
      startDate: state.app.search.startDate,
      endDate: state.app.search.endDate,
      edit: state.app.search.edit,
      active: state.app.search.active,
      type: state.app.search.type,
      elementType: state.app.search.elementTypeSelected,
      elmentDisplay: state.app.elmentDisplay,
      addNew: state.inspections.addNew,
      map: state.app.map,
      inspections: state.inspections.list,
      userWrite: state.user.data.write,

      list: state.parklist.list,
      role: state.user.data.role,
      menuData:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/inspections`
        ),
    };
  },
  {
    addNewToggle,
    getInspections,
    setSearch,
    setHistory,
    setActivePark,
    finishedSearch,
  }
)(Inspections);
