import { MenuItem, Select, Tooltip } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  recenterMap,
  setActivePark,
  toggleViewReport,
  updateZoom,
} from "../../containers/App/actions";
import { findParkFromPosition } from "../../containers/ParkList/actions";
import { userLocation } from "../../containers/User/actions";
import LineButton from "../Buttons/LineButton";

const Section = styled.div`
  && {
    margin: 0.2em;
    background: #fff;
    padding: 0.4em;
    display: inline-block;
    border-radius: 5px;
    max-width: 100px;
    box-shadow: 6px 0 9px 0 rgba(130, 158, 171, 0.4);
    > div {
      font-size: 0.8em;
      ::before {
        border-bottom: none;
      }
    }
    :hover {
      background: #4da1ff;
      color: #fff;
      > div {
        color: #fff;
      }
    }
  }
`;
const SearchResults = styled.div`
  && {
    display: inline-block;
    position: fixed;
    width: 200px;
    z-index: 99;
    bottom: 1em;
    right: 1em;
  }
`;
const SearchSection = styled.div`
  && {
    margin: 0.2em;
    font-size: 0.7em;
    cursor: pointer;
    background: #fff;
    padding: 0.8em;
    display: block;
    border-radius: 5px;
    box-shadow: 6px 0 9px 0 rgba(130, 158, 171, 0.4);
    :hover {
      background: #4da1ff;
      color: #fff;
    }
  }
`;
const SectionSearch = styled.div`
  && {
    margin: 0.2em;
    background: #fff;
    padding: 0.7em;
    display: flex;
    border-radius: 5px;
    width: 200px;
    box-shadow: 6px 0 9px 0 rgba(130, 158, 171, 0.4);
    input {
      border: none;
      font-size: 0.9em;
      &:focus-visible {
        border: none;
        outline: none;
      }
    }
    > div {
      font-size: 0.8em;
      ::before {
        border-bottom: none;
      }
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    text-transform: uppercase;
  }
`;

const StyledLineButton = styled(LineButton)`
  && {
    display: block;
    font-size: 0.8em;
    width: 100%;
    text-align: center;
    margin: 0.8em 0;
    line-height: 1;
  }
`;

const SpanBlock = styled.span`
  && {
    font-size: 1em;
    text-align: center;
    color: #989898;
    vertical-align: middle;
    cursor: pointer;
    line-height: 1.8;
    padding: 0 0.3em;
    :hover {
      color: #fff;
      transform: rotate(90deg);
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
  }
`;
class ParcelsFilterMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      openSearch: false,
      searchResults: [],
    };
    this.resetSearch = this.resetSearch.bind(this);
    this.setSearch = this.setSearch.bind(this);
    this.selectedPark = this.selectedPark.bind(this);
    this.setOpenSearch = this.setOpenSearch.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  setOpenSearch(e) {
    this.setState({ openSearch: !this.state.openSearch });
  }
  setSearch(e) {
    const results = this.searchFolha(e.target.value);
    this.setState({ search: e.target.value, searchResults: results });
  }
  resetSearch(full) {
    this.props.handleSearchChange({
      target: {
        name: "selected",
        value: [],
      },
    });
    if (full) {
      setTimeout(
        () =>
          this.props.handleSearchChange({
            target: {
              name: "local",
              value: "",
            },
          }),
        1500
      );
    }
  }
  selectedPark(raw) {
    this.props.setActivePark(
      raw.properties.numero,
      raw.properties.local,
      raw.properties.freguesia,
      null,
      true,
      true
    );
    this.setOpenSearch();
  }
  handleSearchChange(e) {
    const { list, freguesia, local } = this.props;
    if (e.target.name === "selected" && e.target.value.includes("all")) {
      const selectedParks = list.sort().reduce((acc, park, index) => {
        if (park.freguesia === freguesia && local === park.local) {
          acc.push(park.numero);
        }
        return acc;
      }, []);
      e.target.value = selectedParks;
    }
    this.props.handleSearchChange(e);
    this.props.recenterMap();
  }

  sortAlphaNum(a, b) {
    var reA = /[^a-zA-Z]/g;
    var reN = /[^0-9]/g;
    var aA = a.replace(reA, "");
    var bA = b.replace(reA, "");
    if (aA === bA) {
      var aN = parseInt(a.replace(reN, ""), 10);
      var bN = parseInt(b.replace(reN, ""), 10);
      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1;
    }
  }

  findParkFromPosition() {
    const selectedPark = this.props.findParkFromPosition();
    if (selectedPark) {
      const { freguesia, local, numero } = selectedPark.properties;
      this.props.setActivePark(numero, local, freguesia);
    }
    this.props.updateZoom({
      zoom: this.props.zoom.zoom,
      center: [this.props.position.lat, this.props.position.lng],
    });
  }

  renderParcels() {
    const { list, freguesia, local, selected } = this.props;
    const optionsList = [];

    list.reduce((acc, park, index) => {
      if (park.freguesia === freguesia && local === park.local) {
        optionsList.push(
          <StyledMenuItem
            key={index}
            element={park.numero === selected}
            value={park.numero}
          >
            {park.numero}
          </StyledMenuItem>
        );
      }
      return acc;
    }, []);
    return optionsList.sort();
  }

  searchFolha(query) {
    const foundParks = this.props?.geoJson
      ?.filter((park) => {
        const regEx = new RegExp(`^${query.toLowerCase()}$`);
        return (
          // park.properties.local.toLowerCase().match(regEx) ||
          park.properties.numero.toLowerCase().match(regEx)
        );
      })
      ?.map((result) => {
        return {
          raw: result,
          label: `${result.properties.local}, ${result.properties.numero}, ${result.properties.freguesia}, ${result.properties.name}`,
        };
      });
    return foundParks;
  }

  render() {
    const { location, list, handleSearchChange, notitle } = this.props;

    const elementTypesList = [];

    const {
      elementsGeoJson,
      elmentDisplay,
      local,
      freguesia,
      role,
      selected,
      element,
      elementType,
    } = this.props.override || this.props;
    return (
      <div>
        {this.state.openSearch && (
          <SearchResults>
            {this.state.searchResults.slice(0, 9).map((result) => {
              return (
                <SearchSection onClick={() => this.selectedPark(result.raw)}>
                  {result.label}
                </SearchSection>
              );
            })}
            <SectionSearch>
              <input
                type={"text"}
                value={this.state.search}
                onChange={(e) => this.setSearch(e)}
                placeholder={"nome da parcela"}
              />
              <SpanBlock
                onClick={() => this.setOpenSearch()}
                className={"icon-close icons reset"}
              />
            </SectionSearch>
          </SearchResults>
        )}
        <Section>
          <Select
            fullWidth
            name={"freguesia"}
            value={freguesia}
            onChange={this.handleSearchChange}
            inputProps={{
              name: "freguesia",
              id: "freguesia",
            }}
          >
            {this.props.userIsAdmin && (
              <MenuItem key={-1} value={"all"}>
                Todas
              </MenuItem>
            )}
            {Object.keys(location)
              .sort()
              .map((freguesia, index) => (
                <StyledMenuItem key={index} value={freguesia}>
                  {freguesia}
                </StyledMenuItem>
              ))}
          </Select>
        </Section>

        {freguesia && freguesia !== "" && (
          <Section>
            <Select
              fullWidth
              name={"local"}
              value={local}
              onChange={this.handleSearchChange}
              inputProps={{
                name: "local",
                id: "local",
              }}
            >
              <StyledMenuItem key={-1} value={"all"}>
                Todos
              </StyledMenuItem>
              {location[freguesia] &&
                Object.keys(location[freguesia])
                  .sort(this.sortAlphaNum)
                  .map((local, index) => (
                    <StyledMenuItem
                      key={index}
                      selected={local === freguesia}
                      value={local}
                    >
                      {location[freguesia][local].local}
                      {", "}
                      {location[freguesia][local].nome}
                    </StyledMenuItem>
                  ))}
            </Select>
          </Section>
        )}

        {local && local !== "" && (
          <Section>
            <Select
              fullWidth
              name={"selected"}
              multiple
              value={selected || ""}
              onChange={this.handleSearchChange}
              inputProps={{
                name: "selected",
                id: "selected",
              }}
            >
              <StyledMenuItem key={-2} value={""} />
              <StyledMenuItem key={-1} value={"all"}>
                Todos
              </StyledMenuItem>
              {this.renderParcels()}
            </Select>
          </Section>
        )}
        <Section>
          <Tooltip title={`Limpar selecção`}>
            <SpanBlock
              onContextMenu={(e) => {
                e.preventDefault();
                this.resetSearch(true);
              }}
              onClick={(e) => {
                this.resetSearch();
              }}
              className={"icon-close icons reset"}
            />
          </Tooltip>
        </Section>

        <Section>
          <Tooltip title={`Procurar parcela`}>
            <SpanBlock
              onClick={() => this.findParkFromPosition()}
              className={`icon-location-pin icons`}
            />
          </Tooltip>
        </Section>
        <Section>
          <Tooltip title={`Procurar Parcela`}>
            <SpanBlock
              onClick={() => this.setOpenSearch()}
              className={`icon-magnifier icons`}
            />
          </Tooltip>
        </Section>
        {this.props.selected.length === 1 && (
          <Section>
            <Tooltip title={`Ver relatório da visita`}>
              <SpanBlock
                onClick={() => this.props.toggleViewReport()}
                className={`icon-event icons`}
              />
            </Tooltip>
          </Section>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      userIsAdmin: state.user !== null && state.user.data.admin,
      zoom: state.app.zoom,
      list: state.parklist.list,
      role: state.user.data.role,
      position: state.user.position,
      elementsGeoJson: state.app.elementsGeoJson,
      elmentDisplay: state.app.elmentDisplay,
      local: state.app.search.local,
      freguesia: state.app.search.freguesia,
      selected: state.app.search.selected,
      element: state.app.search.element,
      elementType: state.app.search.elementTypeSelected,
      location: state.parklist.location,
      geoJson: state.parklist.geoJson,
    };
  },
  {
    recenterMap,
    userLocation,
    findParkFromPosition,
    toggleViewReport,
    setActivePark,
    updateZoom,
  }
)(ParcelsFilterMap);
