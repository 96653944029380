import * as constants from "./constants";
import axios from "axios";
import { toastr } from "react-redux-toastr";

export function getStats(type, year) {
  return (dispatch) => {
    dispatch({
      type: constants.GETTING,
    });
    axios
      .get(`/api/v1/stats/${type}/${year}`)
      .then(function(response) {
        if (response.data.msg) {
          //lets unpack the data by parsing the JSON
          const data = JSON.parse(response?.data?.msg || "{}");
          dispatch({
            type: constants.RECEIVED,
            data: data,
          });
        }
      })
      .catch(function(response) {
        // toastr.error('Ocorreu um erro a obter as inspecções');
        dispatch({
          type: constants.ERROR,
        });
      });
  };
}
