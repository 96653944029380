export const ADD_NEW_TOGGLE = 'GEOREFERENCE/ADD_NEW_TOGGLE';

export const UPDATE_SEARCH = 'GEOREFERENCE/UPDATE_SEARCH';
export const EDIT_TOGGLE = 'ELEMENTS/EDIT_TOGGLE';
export const VIEW_TOGGLE = 'ELEMENTS/VIEW_TOGGLE';
export const UPDATE_ACTIVE_PARK = 'GEOREFERENCE/UPDATE_PARK';
export const SET_TYPE = 'GEOREFERENCE/SET_TYPE';
export const SET_SUBTYPE = 'GEOREFERENCE/SET_SUBTYPE';
export const SET_DETAILS = 'GEOREFERENCE/SET_DETAILS';
export const SAVING = 'GEOREFERENCE/SAVING';
export const SAVED = 'GEOREFERENCE/SAVED';
export const DELETED = 'GEOREFERENCE/DELETED';
export const ERROR = 'GEOREFERENCE/ERROR';
export const RESET = 'GEOREFERENCE/RESET';
export const ELEMENTS_TYPE_GETTING = 'ELEMENTSTYPE/_REQUEST';
export const ELEMENTS_TYPE_RECEIVED = 'ELEMENTSTYPE/_SUCCESS';
export const VEGETATION_TYPE_GETTING = 'VEGETATIONTYPE/_REQUEST';
export const VEGETATION_TYPE_RECEIVED = 'VEGETATIONTYPE/_SUCCESS';
export const ELEMENTS_ERROR = 'GEOREFERENCE/ELEMENTS_FAILURE';
export const ELEMENTS_GETTING = 'GEOREFERENCE/ELEMENTS_REQUEST';
export const PARK_ERROR = 'GEOREFERENCE/PARK_FAILURE';
export const PARK_GETTING = 'GEOREFERENCE/PARK_REQUEST';
export const PARK_RECEIVED = 'GEOREFERENCE/PARK_SUCCESS';
export const PARK_DONE = 'GEOREFERENCE/PARK_DONE';

export const HORTAS_PARCEL_GETTING = 'GEOREFERENCE/HORTAS_PARCEL_GETTING';
export const HORTAS_PARCEL_RECEIVED = 'GEOREFERENCE/HORTAS_PARCEL_RECEIVED';
export const HORTAS_PARCEL_DONE = 'GEOREFERENCE/HORTAS_PARCEL_DONE';
export const HORTAS_PARCEL_ERROR = 'GEOREFERENCE/HORTAS_PARCEL_ERROR';

export const HISTORY_ERROR = 'GEOREFERENCE/HISTORY_FAILURE';
export const HISTORY_GETTING = 'GEOREFERENCE/HISTORY_REQUEST';
export const HISTORY_RECEIVED = 'GEOREFERENCE/HISTORY_SUCCESS';
export const HISTORY_DONE = 'GEOREFERENCE/HISTORY_DONE';
export const ELEMENTS_RECEIVED = 'ELEMENTS_SUCCESS';
