import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import MobileLayoutToggle from "../App/MobileLayoutToggle";
import ShowHelpChoiceModal from "./ShowHelpChoiceModal";

import AddActionButtons from "../../components/Buttons/AddActionButtons";
import TextField from "../../components/form-wrappers/TextField";
import { default as ScrollArea } from "../../components/Layout/ScrollBar";
import Sidebar from "../../components/Layout/Sidebar";
import SectionTitle from "../../components/Text/SectionTitle";

import {
  setDateSearch,
  setSearch,
  setUserTypeSearch,
  toggleAddInterventions,
} from "../App/actions";
import { getTasks, setVisit } from "./actions";

const StyledWrap = styled.div`
  && {
    display: flex;
    height: 100%;
    /* height: calc(100vh - 49px); */
    @media (max-width: 425px) {
      display: block;
    }
  }
`;

const Section = styled.div`
  && {
    margin: 0.5em 0;
  }
`;
const VisitButton = styled.div`
  && {
    margin: 0.5em 0;
    border: 1px solid #4da1ff;
    background: #4da1ff;
    color: ${(props) =>
      props.selected || props.visited
        ? "#4da1ff !important"
        : "#fff !important"};
    display: inline-block;
    padding: 0.3em 0.6em;
    border-radius: 5px;
    font-size: 0.8em;
    background: ${(props) =>
      props.selected || props.visited ? "#fff" : "#4da1ff"};
    span {
      color: ${(props) =>
        props.selected || props.visited
          ? "#4da1ff !important"
          : "#fff !important"};
    }
  }
`;
const Input = styled(TextField)`
  && {
    width: 48%;
    font-size: 0.9em;
    padding: 0;
    margin: 0 2% 0 0;
    text-align: left;
    textarea {
      height: auto;
    }
    label {
      width: 100%;
    }
    div {
      width: 100%;
    }
  }
`;

const CalendarInput = styled(Input)`
  && {
    width: 100%;
  }
`;
const SectionTitleStyled = styled(SectionTitle)`
  && {
    @media (max-width: 768px) {
      display: none;
    }
  }
`;
const FilterList = styled.div`
  && {
    > div {
      overflow-y: none;
      height: calc(100vh - 250px);
      @media (max-width: 900px) {
        height: calc(100vh - 300px);
      }
      @media (max-width: 768px) {
        height: calc(100vh - 240px);
      }
    }
    .maintenance-filter {
      height: calc(100vh - 250px);
      padding-right: 18px;
      margin: 1em 0;
      @media (max-width: 900px) {
        height: calc(100vh - 300px);
      }
      @media (max-width: 768px) {
        height: calc(100vh - 240px);
      }
      .scrollbar {
        border-radius: 1em;
      }
    }
  }
`;

const OptionWrapElements = styled.div`
  && {
    padding: 1em;
    margin: 0.5em 0;
    display: flex;
    align-items: center;
    justify-content: left;
    border: 1px solid #e9eff4;
    border-radius: 6px;
    cursor: pointer;
    background: ${(props) => {
      if (props.selected) {
        return "#4da1ff";
      }

      if (props.visited) {
        return "#6fc44c";
      }
      if (props.fixed) {
        return "#f29729";
      }
      return "#fff";
    }};
    ${(props) => {
      if (props.fixed) {
        return "color: #fff; span,div{color:#fff }";
      }
    }};
    :hover {
      background: ${(props) =>
        props.selected || props.visited ? "#4da1ff" : "#e9eff4"};
      color: #fff;
      ${(props) => {
        if (props.fixed) {
          return "color: #4da1ff; span,div{color:#4da1ff }";
        }
      }};
    }

    ${(props) =>
      props.selected || props.visited ? "span,div{color:#fff}" : ""};
  }
`;

const SlectPark = styled.span`
  && {
    font-size: 1.6em;
    margin: 0 0.3em 0 0;
    text-align: center;
    color: rgba(74, 144, 226, 0.4);
    vertical-align: middle;
    display: inline-block;
    text-align: left;
    cursor: pointer;
  }
`;

const ParkName = styled.div`
  && {
    font-size: 0.8em;
    color: rgba(74, 144, 226, 0.8);
    font-weight: bold;
    text-align: left;
  }
`;
const ParkLocation = styled.div`
  && {
    font-size: 0.8em;
    color: rgba(74, 144, 226, 0.33);
    text-align: left;
  }
`;

const Inline = styled.div`
  && {
    display: inline-block;
  }
`;
class Tasks extends Component {
  constructor(props) {
    super(props);

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.setVisitToPark = this.setVisitToPark.bind(this);

    this.state = {
      showAddHelp: false,
    };

    // lets set the correct permissions
    props.setUserTypeSearch(props.userType);
    // const currentDate = getCurrentDate();
    // if (currentDate !== props.startDate) {
    //   props.setDateSearch(currentDate);
    //   toastr.success('Data Alterada', 'A data foi alterada para o dia de hoje');
    // }
  }

  componentDidUpdate() {
    // we need to update after saving elements
    // to present parcel in green

    if (
      (this.props.maintenanceStatus === "saving" ||
        this.props.inpectionsStatus === "saving" ||
        this.props.status === "saving") &&
      this.props.status !== "getting"
    ) {
      this.props.getTasks();
    }
  }

  setVisitToPark(park, eid) {
    // const toastrConfirmOptions = {
    //   onOk: () => this.props.setVisit(park, eid),
    //   onCancel: () => console.log("CANCEL: clicked"),
    // };
    // toastr.confirm(
    //   `Quer registar a sua visita à folha ${park}`,
    //   toastrConfirmOptions
    // );
  }

  componentDidMount() {
    if (this.props.status !== "getting") this.props.getTasks();
  }

  handleSearchChange(e, value, nofetch) {
    this.props.setSearch(
      {
        element: e.target ? [e.target.name] : e,
        value: e.target ? e.target.value : value,
      },
      nofetch
    );

    if (e.target && e.target.name === "startDate") this.props.getTasks();
  }

  /**
   * Get all filtered tasks
   */
  getTasks() {
    const { maintenances, inpections, type } = this.props;
    const filteredType =
      type !== "interventions" && type !== "inspections" ? "all" : type;
    switch (filteredType) {
      case "all":
        const i = inpections || [];
        const m = maintenances || [];

        return [
          ...i.filter(
            (inpection) => this.filterTask(inpection) && inpection.status === 0
          ),
          ...m.filter((maintenance) => this.filterTask(maintenance)),
        ].sort(function(a, b) {
          return new Date(b.date) - new Date(a.date);
        });
      case "inspections":
        return inpections
          ? inpections.filter(
              (inpection) =>
                this.filterTask(inpection) && inpection.status === 0
            )
          : [];
      case "interventions":
        return maintenances
          ? maintenances.filter((maintenance) => this.filterTask(maintenance))
          : [];

      default:
        return [];
    }
  }

  renderSelectedParkEV() {
    const {
      geoJson,
      routesList,
      local,
      inpections,
      maintenances,
      startDate,
      selected,
      isRega,
    } = this.props;
    if (!geoJson || !routesList) return null;

    //lets find and push this park
    const parkinRoutes = routesList.reduce((prev, curr) => {
      return [...prev, ...curr.list];
    }, []);

    let scheduled = maintenances.filter((maintenance) => {
      if (isRega) {
        return (
          maintenance?.attr?.team === "rega" &&
          maintenance?.attr?.type === "schedule"
        );
      } else {
        return maintenance?.attr?.type === "schedule";
      }
    });

    // first lets get all unique folhas
    const selectParks = geoJson.reduce((prev, curr) => {
      // lets check if we already added folha
      if (!prev.find((p) => p.properties.local === curr.properties.local)) {
        //lets find and push this park
        const thisPark =
          parkinRoutes.find((r) => r === curr.properties.numero) ||
          scheduled.find((s) => s.pid === curr.properties.numero);

        if (thisPark) {
          prev.push({ ...curr });
        }
      }

      return prev;
    }, []);

    // let wasVisited = inpections.filter(
    //   (inpection) =>
    //     parkinRoutes.find((pid) => pid === inpection.pid) &&
    //     moment(startDate).diff(inpection.date, "hours") < 16
    // );

    // lets check if we have maintenances
    let wasVisited = maintenances.filter(
      (maintenance) =>
        (parkinRoutes.find((pid) => pid === maintenance.pid) &&
          Math.abs(moment(startDate).diff(maintenance.date, "hours")) < 72) ||
        (maintenance?.attr?.type !== "schedule" &&
          selectParks.find((p) => p.properties.numero === maintenance.pid))
    );

    //lets check if any maintenance isHelp
    // const isHelp = maintenances.find((maintenance) => {
    //   const isHelp = maintenance.attr?.isHelp;
    //   //check if this local is in the userAccess
    //   const isSamePark = this.props.userAccess.includes(maintenance.pid);
    //   return isSamePark && isHelp;
    // });
    // console.log(isHelp);

    return (
      <div>
        {selectParks.map((park, index) => {
          const parkRoute = routesList.find((route) =>
            route.list.includes(park.properties.numero)
          );
          // const isSameSelection =
          //   JSON.stringify(selected.sort()) ===
          //   JSON.stringify(parkRoute.list.sort());
          const visitedTag = wasVisited.find(
            (visited) =>
              visited.location === park.properties.local ||
              visited.location === park.pid
          );
          // const fixTag = wasFix.find(
          //   (visited) => visited.location === park.properties.local
          // );

          const scheduleInfo = scheduled.find(
            (s) => s.pid === park.properties.numero
          );

          return (
            <OptionWrapElements
              key={index}
              selected={!scheduleInfo && local === park.properties.local}
              visited={!scheduleInfo && visitedTag}
              fixed={scheduleInfo}
              onClick={() => {
                const selectedElements = geoJson.filter(
                  (p) => p.properties.local === park.properties.local
                );
                // if (local !== park.properties.local) {
                this.handleSearchChange(
                  "freguesia",
                  park.properties.freguesia,
                  true
                );
                this.handleSearchChange("local", park.properties.local, true);
                // this.handleSearchChange("selected", parkRoute.list);
                this.handleSearchChange(
                  "selected",
                  selectedElements.map((p) => p.properties.numero) || []
                );
                // }
              }}
            >
              <SlectPark
                // onClick={() => this.togglePark(park)}
                className={`icon-location-pin icons`}
              />
              <Inline>
                <ParkName>
                  {park.properties.local}, {park.properties.name}
                </ParkName>
                <ParkLocation>{park.properties.freguesia}</ParkLocation>
                <ParkLocation>
                  {parkRoute && parkRoute["Team.label"]}
                </ParkLocation>
                <ParkLocation>{scheduleInfo?.details}</ParkLocation>
                {!visitedTag && (
                  <VisitButton
                    selected={local === park.properties.local}
                    visited={visitedTag}
                    onClick={
                      () => this.props.toggleAddInterventions()
                      // this.setVisitToPark(
                      //   park.properties.local,
                      //   park.properties.numero
                      // )
                    }
                  >
                    <span className={"icon-check icons"} /> Fazer visita
                  </VisitButton>
                )}
              </Inline>
            </OptionWrapElements>
          );
        })}
      </div>
    );
  }

  renderSelectedPark() {
    const {
      geoJson,
      routesList,
      selected,
      inpections,
      maintenances,
      startDate,
    } = this.props;
    if (!geoJson || !routesList) return null;
    return (
      <div>
        {routesList.map((route, index) => {
          return route.list.map((place, index) => {
            const thisPark = geoJson.find(
              (park) => park.properties.numero === place
            );

            if (!thisPark || routesList.length < 1) return null;

            let wasVisited =
              (inpections &&
                inpections.find(
                  (inpection) =>
                    inpection.pid === place &&
                    moment(startDate).diff(inpection.date, "hours") < 16
                )) ||
              null;

            // lets check if we have maintenances
            let wasFix =
              maintenances &&
              maintenances.find(
                (inpection) =>
                  inpection.pid === place &&
                  moment(startDate).diff(inpection.date, "hours") < 16
              );

            const isSelected =
              selected &&
              selected.find((elem) => elem === thisPark.properties.numero);

            return (
              <OptionWrapElements
                key={index}
                selected={isSelected}
                visited={wasVisited}
                fixed={wasFix}
                onClick={() => {
                  this.handleSearchChange(
                    "freguesia",
                    thisPark.properties.freguesia,
                    true
                  );
                  this.handleSearchChange(
                    "selected",
                    isSelected ? [] : [place]
                  );
                }}
              >
                <SlectPark
                  // onClick={() => this.togglePark(thisPark)}
                  className={`icon-location-pin icons`}
                />
                <Inline>
                  <ParkName>{thisPark.properties.name}</ParkName>
                  <ParkLocation>
                    {thisPark.properties.freguesia}, {thisPark.properties.local}
                  </ParkLocation>
                  <ParkLocation>{route["Team.label"]}</ParkLocation>
                </Inline>
              </OptionWrapElements>
            );
          });
        })}
      </div>
    );
  }

  render() {
    const {
      startDate,
      totalParks,
      routesList,
      menuData,
      role,
      maintenances,
    } = this.props;

    return (
      <StyledWrap>
        <MobileLayoutToggle />
        <Sidebar
          title={menuData.name}
          text={` ${totalParks == 0 ? "0" : totalParks}  agendadas`}
          icon={"layers"}
          noanimation
        >
          <FilterList>
            <ScrollArea
              speed={0.8}
              className="maintenance-filter"
              contentClassName="maintenance-filter-content"
              horizontal={false}
            >
              {this.renderSelectedParkEV()}
              <ShowHelpChoiceModal
                toggleAddInterventions={this.props.toggleAddInterventions}
              />
            </ScrollArea>
          </FilterList>
          <Section>
            <SectionTitleStyled>Data</SectionTitleStyled>
            <CalendarInput
              id="startDate"
              name="startDate"
              label=""
              input={{}}
              meta={{ invalid: false }}
              type="date"
              onChange={(e) => this.handleSearchChange(e)}
              defaultValue={startDate}
            />
          </Section>
        </Sidebar>
        <AddActionButtons />
      </StyledWrap>
    );
  }
}

export default connect(
  (state) => {
    return {
      local: state.app.search.local,
      freguesia: state.app.search.freguesia,
      selected: state.app.search.selected,
      geoJson: state.parklist.geoJson,
      type: state.app.search.type,

      maintenanceStatus: state.maintenances.status,
      inpectionsStatus: state.inspections.status,

      maintenances: state.tasks.maintenancesList,
      inpections: state.tasks.inspectionList,

      totalParks: state.tasks.totalParks,
      routesList: state.tasks.routesList,
      startDate: state.app.search.startDate,
      status: state.tasks.status,
      role: state.user.data.role,
      userAccess: state.user.data.parks,
      isRega: state.user.data.rega,
      userTyp1e: state.user,
      userType: state.user.data.type,
      menuInspections:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/inspections`
        ),
      menuInterventions:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/interventions`
        ),
      menuData:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/tasks`
        ),
    };
  },
  {
    getTasks,
    setSearch,
    setUserTypeSearch,
    setDateSearch,
    setVisit,
    toggleAddInterventions,
  }
)(Tasks);
