import { createSelector } from "reselect";

const getElementsGeoJson = (state) => state.app.elementsGeoJson;
const getElementRelations = (state) => state.georeference.elementRelations;
const getSelectedElement = (state) => state.app.search.element;
const getParkGeoJson = (state) => state.parklist.geoJson;
const getSearchProps = (state) => state.app.search;
const getElmentDisplay = (state) => state.app.elmentDisplay;

export const getGeoJson = createSelector(
  [getParkGeoJson, getSearchProps],
  (geoJson, search) => {
    const { freguesia, selected, local, element } = search;
    // const { freguesia, selected, local, role } = this.props;

    if (!geoJson)
      return {
        geoJson: [],
        freguesia: [],
        local: [],
        selected: [],
      };

    const freguesiaList = [];
    const localList = [];
    const selectedList = [];

    const list = geoJson.reduce((acc, park, index) => {
      const parkInSelection = selected.includes(park.properties.numero);
      if (element && element === park.properties.eid) {
        acc.push({
          ...park,
          properties: { ...park.properties, selected: true, color: "#fff" },
        });
        selectedList.push(park);
      } else if (selected && parkInSelection) {
        acc.push({
          ...park,
          properties: { ...park.properties, selected: true, color: "#01f4c1" },
        });
        selectedList.push(park);
      } else if (local && park.properties.local === local) {
        acc.push({
          ...park,
          properties: { ...park.properties, selected: true, color: "#4da1ff" },
        });
        localList.push(park);
      } else {
        if (
          !parkInSelection &&
          ((local && park.properties.local === local) ||
            park.properties.freguesia === freguesia ||
            freguesia === "todas")
        ) {
          acc.push({
            ...park,
            properties: { ...park.properties, area: true },
          });
        } else if (
          !local &&
          !parkInSelection &&
          freguesia &&
          (park.properties.freguesia === freguesia || freguesia === "todas")
        ) {
          acc.push({
            ...park,
            properties: { ...park.properties, area: true, color: "#8400ff" },
          });
          freguesiaList.push(park);
        }
      }

      return acc;
    }, []);

    return {
      geoJson: list,
      freguesia: freguesiaList,
      local: localList,
      selected: selectedList,
    };
  }
);

export const getGeoElementJson = createSelector(
  [getElementsGeoJson, getElmentDisplay, getElementRelations, getSearchProps],
  (geoJson, elmentDisplay, elementRelations, search) => {
    if (!elmentDisplay) return [];

    const { freguesia, selected, local, elementTypeSelected, element } = search;
    // const { freguesia, selected, local, role } = this.props;
    if (!geoJson) return [];
    const resultGeoJson = geoJson.reduce((acc, park, index) => {
      const parkInSelection = selected.includes(park.properties.numero);
      let elementSelection = false;

      if (elementTypeSelected.length === 0) {
        elementSelection = true;
      }

      if (
        elementTypeSelected.length > 0 &&
        park.properties.category &&
        elementTypeSelected.includes(park.properties.category)
      ) {
        elementSelection = true;
      }

      if (
        elementTypeSelected.length > 0 &&
        park.properties.label &&
        elementTypeSelected.includes(park.properties.label)
      ) {
        elementSelection = true;
      }

      const thisId = park.properties.id || park.properties.origin;
      //set element properties

      const addElement = {
        ...park,
        properties:
          thisId === element || thisId === elementTypeSelected
            ? { ...park.properties, selected: true, color: "#ff3c3c" }
            : park.properties,
      };

      acc.push({
        ...addElement,
      });
      if (
        // !parkInSelection &&
        local &&
        park.properties.local === local &&
        elementSelection
      ) {
        acc.push({ ...addElement });
      } else if (
        // !local &&
        // !parkInSelection &&
        freguesia &&
        elementSelection &&
        park.properties.freguesia === freguesia
      ) {
        acc.push({ ...addElement });
      }

      return acc;
    }, []);
    return resultGeoJson;
  }
);
