import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import PropTypes from "prop-types";

import { Paper } from "@material-ui/core";

import TextField from "../../components/form-wrappers/TextField";
import MainButton from "../../components/Buttons/MainButton";
import { REQUIRED_FIELDS, FORM_NAME } from "./constants";
import { login } from "./actions";

/*
 *
 * Component Styles
 */

const LoginImage = styled.div`
  && {
    height: 100%;
    background: url("/images/login.jpg") no-repeat;
    background-size: cover;
  }
`;

const LoginContainer = styled.div`
  && {
    min-width: 320px;
    width: 35%;
    height: 100%;
    min-height: 330px;
    float: right;
    margin: auto;
    @media (max-width: 425px) {
      width: 100%;
      height: 50%;
      min-height: 330px;

      bottom: 0;
      position: absolute;
    }
    @media (max-height: 500px) {
      min-height: 330px;
    }
  }
`;

const StyledPaper = styled(Paper)`
  && {
    padding: 0 2em;
    overflow: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
`;

const StyledLogo = styled.div`
  && {
    text-align: center;
    margin-bottom: 4em;
    @media (max-width: 425px), (max-height: 500px) {
      margin-bottom: 0;
    }
    img {
      width: 70%;
      max-width: 230px;
      @media (max-width: 425px), (max-height: 500px) {
        width: 100px;
      }
    }
  }
`;

const Input = styled(TextField)`
  && {
    padding: 10px 0;
    margin: 0;
    width: 100%;
    text-align: left;
  }
`;

const validate = (values) =>
  REQUIRED_FIELDS.reduce((errors, field) => {
    if (values.hasOwnProperty(field)) return errors;

    errors[field] = "required";
    return errors;
  }, {});

class LoginPage extends Component {
  static propTypes = {
    login: PropTypes.func.isRequired,
    valid: PropTypes.bool.isRequired,
    error: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.setLogin = this.setLogin.bind(this);
  }

  setLogin() {
    this.props.login();
  }

  render() {
    return (
      <LoginImage>
        <LoginContainer>
          <StyledPaper>
            <StyledLogo>
              <img src={`/images/icons/logo.svg`} alt="logo" />
            </StyledLogo>
            <Field component={Input} label="Email" name="email" type="email" />
            <Field
              component={Input}
              label="password"
              name="password"
              type="password"
            />
            <br />
            <MainButton
              onClick={() => this.props.login()}
              color="primary"
              disabled={!this.props.valid}
            >
              Entrar
            </MainButton>
          </StyledPaper>
        </LoginContainer>
      </LoginImage>
    );
  }
}

const formSelector = formValueSelector(FORM_NAME);

const mapStateToProps = (state) => ({
  formValues: formSelector(state, ...REQUIRED_FIELDS),
  error: state.user.error,
});

const mapDispatchToProps = {
  login,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withForm = reduxForm({ form: FORM_NAME, validate });

export default withForm(withConnect(LoginPage));
