import { MenuItem, Select } from "@material-ui/core";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { default as ScrollArea } from "../../components/Layout/ScrollBar";
import Table from "../../components/Table/Table";
import SectionTitle from "../../components/Text/SectionTitle";
import MobileLayoutToggle from "../App/MobileLayoutToggle";
import { Tooltip } from "@material-ui/core";

import ButtonGroup from "../../components/Buttons/ButtonGroup";
import GroupedButton from "../../components/Buttons/GroupedButton";
import DateRangeFilter from "../../components/Filters/DateRangeFilter";
import ParcelsFilter from "../../components/Filters/ParcelsFilter";
import TeamFilter from "../../components/Filters/TeamFilter";

import { default as ViewInspection } from "../Inspections/View";
import { default as ViewMaintenance } from "../Maintenances/View";

import MainButton from "../../components/Buttons/MainButton";
import MenuIcon from "../../components/Buttons/MenuIcon";
import Sidebar from "../../components/Layout/Sidebar";
// import SidebarList from '../../components/Layout/SidebarList';
import Content from "../../components/Layout/Content";
import { setSearch } from "../App/actions";

import { getReports, setActiveType, submitTeamReports } from "./actions";
import { isArray } from "lodash";

const StyledWrap = styled.div`
  display: flex;
  height: 100%;
  /* height: calc(100vh - 49px); */
  @media (max-width: 768px) {
    display: block;
    height: 100%;
    overflow: auto;
  }
`;
const Flag = styled.div`
  && {
    width: 1em;
    height: 3em;
    border-radius: 4px;
    background: ${(props) => (props.warging === "red" ? "#9C27B0" : "#FFC107")};
  }
`;
const TableWrap = styled.div`
  && {
    display: ${(props) => (props.display === "display" ? "block" : "none")};
  }
`;

const StyledMainButton = styled(MainButton)`
  && {
    width: 100%;
    margin-top: 1em;
    font-size: 0.8em;
  }
`;

const FilterList = styled.div`
  && {
    .maintenance-filter {
      height: calc(100vh - 240px);
      padding-right: 18px;
      .scrollbar {
        border-radius: 1em;
      }
      @media (max-width: 900px) {
        height: calc(100vh - 300px);
      }
      @media (max-width: 768px) {
        height: calc(100vh - 207px);
      }
    }
  }
`;

const MaintenanceFilter = styled(Select)`
  && {
    border: 0;
    font-size: 0.9em;
    color: #a3a1a1;
  }
`;

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: props.type || "inspections",
      status: "",
      include: "all",
      team: "",
      editMaintenanceId: false,
      editInspectionId: false,
    };
    this.goToDetail = this.goToDetail.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleTeamChange = this.handleTeamChange.bind(this);
    this.renderCSV = this.renderCSV.bind(this);
    this.handleExcludeView = this.handleExcludeView.bind(this);
    props.getReports(this.state.type);
  }

  componentDidUpdate(preProps) {
    //need this for updating table
    if (this.state.status === "changed") {
      this.setState({
        status: "",
      });
    }
  }

  closeModal() {
    this.setState({
      editInspectionId: null,
      editMaintenanceId: null,
    });
  }

  goToDetail(id) {
    const elementFound = this.props.data.find((element) => {
      return element.id === id;
    });

    if (!elementFound) return false;
    if (elementFound.instanceType === "inspections") {
      this.setState({
        editInspectionId: id,
        editMaintenanceId: null,
      });
    } else if (elementFound.instanceType === "interventions") {
      this.setState({
        editMaintenanceId: id,
        editInspectionId: null,
      });
    }
  }

  getReportsHeader() {
    return [
      {
        id: "id",
        numeric: false,
        disablePadding: true,
        label: "id",
        name: "id",
        options: {
          display: false,
        },
      },
      {
        id: "freguesia",
        numeric: false,
        disablePadding: false,
        label: "Freguesia",
        name: "Freguesia",
      },
      {
        id: "TeamId",
        numeric: false,
        disablePadding: false,
        label: "Equipa",
        name: "Equipa",
      },
      {
        id: "date",
        numeric: false,
        disablePadding: false,
        label: "Data",
        name: "Data",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return moment(value).format("DD/MM/YYYY");
          },
        },
      },
      {
        id: "teams",
        numeric: false,
        disablePadding: false,
        label: "Visualizado por",
        name: "Visualizado por",
      },
    ];
  }
  getLocalReportHeader() {
    const today = moment();
    let outputHeader = [
      {
        label: "Aviso",
        name: "Aviso",
        id: "id",
        options: {
          filter: true,

          customBodyRender: (value, tableMeta, updateValue) => {
            const thisData = this.props.data.find((row) => row.id === value);

            if (
              thisData &&
              (thisData["status"] === "activo" ||
                thisData["status"] === "aberto")
            ) {
              const thisDate = new Date(thisData["date"]);
              const date = moment(thisData["date"]);
              const passeDays = today.diff(date, "days");

              return <Flag warning={passeDays > 89 ? "red" : "yellow"} />;
            }
          },
        },
      },
    ];
    outputHeader.push({
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "id",
      name: "id",
      options: {
        display: false,
      },
    });
    outputHeader.push({
      id: "pid",
      numeric: false,
      disablePadding: false,
      label: "Parcela",
      name: "Parcela",
    });
    outputHeader.push({
      id: "location",
      numeric: false,
      disablePadding: false,
      label: "Folha",
      name: "Folha",
    });

    if (this.state.type === "rega") {
      /***
       * 
       *  regaCategoria: `${splitName[0]}`,
            regaMaterial: `${splitName[1]}`,
            [`regaVal${splitName[2]}`]: `${row.attr.customFields[cf]}`,
       */

      outputHeader.push({
        id: "regaType",
        numeric: false,
        disablePadding: false,
        label: "Tipo de Intervenção",
        name: "Tipo de Intervenção",
      });
      outputHeader.push({
        id: "regaCategoria",
        numeric: false,
        disablePadding: false,
        label: "Categoria",
        name: "Categoria",
      });

      outputHeader.push({
        id: "regaMaterial",
        numeric: false,
        disablePadding: false,
        label: "Material",
        name: "Material",
      });

      outputHeader.push({
        id: "regaValMODELO",
        numeric: false,
        disablePadding: false,
        label: "Modelo",
        name: "Modelo",
      });
      outputHeader.push({
        id: "regaValNº DE ESTAÇÕES",
        numeric: false,
        disablePadding: false,
        label: "Nº DE ESTAÇÕES",
        name: "Nº DE ESTAÇÕES",
      });
      outputHeader.push({
        id: "regaValDIÂMETRO (mm)",
        numeric: false,
        disablePadding: false,
        label: "DIÂMETRO (mm)",
        name: "DIÂMETRO (mm)",
      });

      outputHeader.push({
        id: "regaValCOMPRIMENTO (m)",
        numeric: false,
        disablePadding: false,
        label: "COMPRIMENTO (m)",
        name: "COMPRIMENTO (m)",
      });

      outputHeader.push({
        id: "regaValUNIDADES",
        numeric: false,
        disablePadding: false,
        label: "Unidades",
        name: "Unidades",
      });

      outputHeader.push({
        id: "details",
        numeric: false,
        disablePadding: false,
        label: "Observações",
        name: "Observações",
      });

      outputHeader.push({
        id: "date",
        numeric: false,
        disablePadding: false,
        label: "Data",
        name: "Data",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return moment(value).format("DD/MM/YYYY HH:mm");
          },
        },
      });
    }
    outputHeader.push({
      id: "cost_user",
      numeric: false,
      disablePadding: false,
      label: "Utilizador",
      name: "Utilizador",
    });
    if (this.state.type !== "rega") {
      this.props?.data?.map((row) => {
        Object.keys(row?.attr?.customFields || {}).map((cf) => {
          // if (this.state.type !== "rega" && row?.attr?.type === "rega") {
          //   return null;
          // }
          if (row?.attr?.type === "rega") {
            return null;
          }
          if (
            row.attr.customFields[cf] &&
            outputHeader.findIndex((item) => item.id === `cf_${cf}`) === -1
          ) {
            outputHeader.push({
              id: `cf_${cf}`,
              numeric: false,
              disablePadding: true,
              label: cf,
              name: cf,
            });
          }
        });
      });
      outputHeader = [
        ...outputHeader,
        {
          id: "id",
          numeric: false,
          disablePadding: true,
          label: "id",
          name: "id",
          options: {
            display: false,
          },
        },

        {
          id: "Element.ElementType.label",
          numeric: false,
          disablePadding: false,
          label: "Elemento",
          name: "Elemento",
        },
        {
          id: "Damage.label",
          numeric: false,
          disablePadding: false,
          label: "Fiscalização",
          name: "Fiscalização",
        },

        {
          id: "details",
          numeric: false,
          disablePadding: false,
          label: "Observações",
          name: "Observações",
        },
        {
          id: "rating",
          numeric: false,
          disablePadding: false,
          label: "Avaliação",
          name: "Avaliação",
        },
        {
          id: "status",
          numeric: false,
          disablePadding: false,
          label: "Estado",
          name: "Estado",
        },
        {
          id: "User.name",
          numeric: false,
          disablePadding: false,
          label: "User",
          name: "User",
        },
        // {
        //   id: "userTeam",
        //   numeric: false,
        //   disablePadding: false,
        //   label: "Equipa",
        //   name: "Equipa",
        // },

        {
          id: "isHelp",
          numeric: false,
          disablePadding: false,
          label: "Ajuda",
          name: "Ajuda",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return value === "true" ? "Sim" : "Não";
            },
          },
        },
        {
          id: "teamsAccess",
          numeric: false,
          disablePadding: false,
          label: "permissões",
          name: "permissões",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return isArray(value) && value?.join(", ");
            },
          },
        },
        {
          id: "date",
          numeric: false,
          disablePadding: false,
          label: "Data",
          name: "Data",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return moment(value).format("DD/MM/YYYY HH:mm");
            },
          },
        },
      ];
    }

    return outputHeader;
  }

  getParksReportHeader() {
    let outputHeader = [
      {
        name: "Stats",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            // console.log(value, tableMeta, updateValue);
          },
        },
      },
    ];

    if (this.state.type === "readings") {
      outputHeader.push(
        {
          id: "monthReading",
          numeric: true,
          disablePadding: false,
          label: "Consumo",
          name: "Consumo",
        },
        {
          id: "reading",
          numeric: true,
          disablePadding: false,
          label: "Leitura",
          name: "Leitura",
        }
      );
    } else if (
      this.state.type === "inspections" ||
      this.state.type === "interventions"
    ) {
      this.props?.data?.map((row) => {
        Object.keys(row.attr.customFields).map((cf) => {
          if (row?.attr?.type === "rega") return null;
          if (
            row.attr.customFields[cf] &&
            outputHeader.findIndex((item) => item.id === `cf_${cf}`) === -1
          ) {
            outputHeader.push({
              id: `cf_${cf}`,
              numeric: false,
              disablePadding: true,
              label: cf,
              name: cf,
            });
          }
        });
      });
      outputHeader.push(
        {
          id: "cost_user",
          numeric: false,
          disablePadding: true,
          label: "User",
          name: "User",
        },
        {
          id: "cost_name",
          numeric: false,
          disablePadding: true,
          label: "Compra",
          name: "Compra",
        }
      );
    }

    outputHeader = [
      ...outputHeader,
      {
        id: "id",
        numeric: false,
        disablePadding: true,
        label: "id",
        name: "id",
        options: {
          display: false,
        },
      },

      {
        id: "location",
        numeric: false,
        disablePadding: false,
        label: "Folha",
        name: "Folha",
      },
      {
        id: "pid",
        numeric: false,
        disablePadding: false,
        label: "Parcela",
        name: "Parcela",
      },

      {
        id: "Damage.label",
        numeric: false,
        disablePadding: false,
        label: "Fiscalização",
        name: "Fiscalização",
      },

      {
        id: "details",
        numeric: false,
        disablePadding: false,
        label: "Observações",
        name: "Observações",
      },
      {
        id: "rating",
        numeric: false,
        disablePadding: false,
        label: "Avaliação",
        name: "Avaliação",
      },
      {
        id: "status",
        numeric: false,
        disablePadding: false,
        label: "Estado",
        name: "Estado",
      },
      {
        id: "User.name",
        numeric: false,
        disablePadding: false,
        label: "User",
        name: "User",
      },
      {
        id: "User.Team.label",
        numeric: false,
        disablePadding: false,
        label: "Equipa",
        name: "Equipa",
      },
      {
        id: "date",
        numeric: false,
        disablePadding: false,
        label: "Data",
        name: "Data",
      },
    ];

    return outputHeader;
  }

  handleTypeChange(type) {
    let fetchType = type;
    // if (this.state.type === type || !type || type === "") {
    //   type = "all";
    // }

    if (type === "readings") {
      fetchType = "inspections";
    }
    if (type === "rega") {
      fetchType = "inspections";
    }
    // console.log(this.props.appStatus);
    // if (this.props.appStatus !== "getting") {
    //   return false;
    // }

    this.props.getReports(fetchType);
    this.setState({
      status: "changed",
      type,
    });
    this.props.setActiveType(type);
  }

  handleExcludeView(e) {
    this.setState({
      include: e.target.value,
    });
  }

  handleTeamChange(e) {
    this.setState({
      team: e.target.value === this.state.team ? null : e.target.value,
    });
  }

  handleSearchChange(e) {
    this.props.setSearch({
      element: [e.target.name],
      value: e.target.value,
    });

    this.props.getReports(this.state.type);
  }

  preProcessData() {
    const {
      elementTypeSelected,
      elementSelected,
      selected,
      data,
      dataReports,
      type,
    } = this.props;

    let processedData = type === "reports" ? dataReports : data;
    // dataReports;
    processedData = processedData.filter((row) => {
      let include = true;

      if (this.state.type === "rega") {
        if (row?.attr?.type !== "rega") return null;
      } else {
        if (row?.attr?.type === "rega") return null;
      }

      /**
       * only show readings
       */
      if (
        (row["Damage.label"] === "Leitura" ||
          row["Intervention.label"] === "Leitura") &&
        this.state.type !== "readings"
      ) {
        include = false;
      } else if (
        row["Damage.label"] &&
        row["Damage.label"] !== "Leitura" &&
        this.state.type === "readings"
      ) {
        include = false;
      } else if (
        row["Intervention.label"] &&
        row["Intervention.label"] !== "Leitura" &&
        this.state.type === "readings"
      ) {
        include = false;
      } else if (
        (row["Damage.label"] === null || row["Intervention.label"] === null) &&
        this.state.type === "readings"
      ) {
        include = false;
      }

      if (selected && row.pid) {
        let thisId = row.pid;
        if (row.pid.indexOf(",") > -1) {
          thisId = row.pid.split(",");
          selected.map((element) => {
            if (!thisId.includes(element)) {
              include = false;
            }
          });
        } else {
          selected.map((element) => {
            if (thisId !== element) {
              include = false;
            }
          });
        }
      }
      // check if element is in selection
      if (
        (elementSelected && !row["Element.id"]) ||
        (row["Element.id"] &&
          elementSelected &&
          elementSelected !== row["Element.id"])
      ) {
        include = false;
      }

      // check if team os in selection
      if (this.state.team && this.state.team !== row["User.Team.id"]) {
        include = false;
      }

      row["status"] = row["status"] === "activo" ? "aberto" : "fechado";

      // for external providers
      if (
        this.state.include !== "all" &&
        (this.state.type !== "readings" || this.state.type !== "reports")
      ) {
        /// everything is excluded by default so lets check if we have a true value to not diplae
        // 0 = internals or flase
        // 1 = external or excluded
        if (this.state.include !== 1 && row["include"]) {
          include = false;
        } else if (row["include"] && this.state.include === 1) {
          include = false;
        }
      }
      return include;
    });

    // custom fields rating transformation
    let processedDataOutput = [];

    processedData.map((row) => {
      let newRowElements = {};
      row.teams =
        row.teams &&
        row.teams.map((t) => {
          const thisTeam = this.props.teams.find((team) => team.id === t);
          if (thisTeam) {
            processedDataOutput.push(thisTeam.label);
            return row;
          }
        });
      if (row.TeamId) {
        const thisTeam = this.props.teams.find(
          (team) => team.id === row.TeamId
        );
        if (thisTeam) {
          row.TeamId = thisTeam.label;
        }
      }

      if (row.attr && row.attr.relation) {
        row.pid = row.attr.relation.join(", ");
      }
      if (row.attr && row.attr.rating) {
        // console.log('adding rating', row.attr.rating);
        processedDataOutput.push({ ...row, rating: row.attr.rating });
        return row;
      }

      if (row?.attr?.cost_user) {
        row["cost_user"] = row?.attr?.cost_user;
      }
      if (
        row.attr &&
        (row.attr.cost_name || row.attr.cost_amount || row.attr.cost_value)
      ) {
        newRowElements[`cost_user`] = row.attr.cost_user;
        newRowElements[`cost_name`] = row.attr.cost_name;
        // newRowElements[`cost_amount`] = row.attr.cost_amount;
        // newRowElements[`cost_value`] = row.attr.cost_value;
      }

      if (this.state.type === "rega") {
        let groupObjectByKey = [];
        Object.keys(row.attr.customFields).map((cf) => {
          const splitName = cf.split("->");
          if (splitName.length < 3) return null;

          //lets see if we added object
          let foundObjt = false;
          groupObjectByKey.map((item) => {
            if (
              item.key === (splitName[3] || "undefined") &&
              item.regaCategoria === `${splitName[0]}` &&
              item.regaMaterial === `${splitName[1]}`
            ) {
              foundObjt = true;
              item[`regaVal${splitName[2]}`] = `${row.attr.customFields[cf]}`;
            }
          });
          if (!foundObjt) {
            groupObjectByKey.push({
              ...row,
              regaCategoria: `${splitName[0]}`,
              regaMaterial: `${splitName[1]}`,
              regaType: row.attr.customFields.regaType,
              key: `${splitName[3]}`,
              [`regaVal${splitName[2]}`]: `${row.attr.customFields[cf]}`,
            });
          }
        });
        processedDataOutput = [...processedDataOutput, ...groupObjectByKey];
        return row;
      }

      if (row.attr && row.attr.customFields) {
        Object.keys(row.attr.customFields).map((cf) => {
          newRowElements[`cf_${cf}`] = row.attr.customFields[cf];
        });
        processedDataOutput.push({ ...row, ...newRowElements });
        return row;
      }
      processedDataOutput.push(row);
      return row;
    });
    return processedDataOutput;
  }

  download(content, fileName, mimeType) {
    var a = document.createElement("a");
    mimeType = mimeType || "application/octet-stream";

    if (navigator.msSaveBlob) {
      // IE10
      navigator.msSaveBlob(
        new Blob([content], {
          type: mimeType,
        }),
        fileName
      );
    } else if (URL && "download" in a) {
      //html5 A[download]
      a.href = URL.createObjectURL(
        new Blob([content], {
          type: mimeType,
        })
      );
      a.setAttribute("download", fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    // else if (typeof  !== 'undefined') {
    //   .href =
    //     'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
    // }
  }

  renderCSV() {
    let columnHeaderData;
    if (this.props.selected.length > 0) {
      columnHeaderData = this.getParksReportHeader();
    } else {
      columnHeaderData = this.getLocalReportHeader();
    }
    const data = this.preProcessData();
    let header = columnHeaderData.map((col) => col.label);
    let outputCsv = [];

    data.map((row) => {
      let line = [];
      columnHeaderData.map((col) => {
        return line.push(row[col.id]);
      });
      return outputCsv.push(line.join(";"));
    });

    const str = header.join(";") + "\r\n" + outputCsv.join("\r\n");

    // var uri = 'data:application/csv;charset=UTF-8,' + encodeURI(str);
    // window.open(uri);

    this.download(str, "ReportExportData.csv", "text/csv;encoding:utf-8");
  }

  render() {
    const {
      list,
      menuData,
      status,
      role,
      endDate,
      startDate,
      menuInterventions,
      menuInspections,
      selected,
    } = this.props;

    let columnData = [];

    if (this.state.type === "reports") {
      columnData = this.getReportsHeader();
    } else if (selected.length > 0) {
      columnData = this.getParksReportHeader();
    } else {
      columnData = this.getLocalReportHeader();
    }
    return (
      <StyledWrap>
        <MobileLayoutToggle />
        <Sidebar
          title={menuData.name}
          text={`gerir ${menuData.name}`}
          icon={"graph"}
          noanimation
        >
          <FilterList>
            <ScrollArea
              speed={0.8}
              className="maintenance-filter"
              contentClassName="maintenance-filter-content"
              horizontal={false}
            >
              <ButtonGroup>
                <GroupedButton
                  width={"20%"}
                  onClick={() => this.handleTypeChange("inspections")}
                  active={this.state.type === "inspections"}
                >
                  <Tooltip title={`Fizcalizações / Gerar Relatórios`}>
                    <MenuIcon
                      theme={this.state.type === "inspections"}
                      className={`icon-${menuInspections.icon} icons`}
                    />
                  </Tooltip>
                  {/* <GroupedButtonBlockText>
                    {menuInspections.name}
                  </GroupedButtonBlockText> */}
                </GroupedButton>
                <GroupedButton
                  width={"20%"}
                  onClick={() => this.handleTypeChange("interventions")}
                  active={this.state.type === "interventions"}
                >
                  <Tooltip title={`Visitas`}>
                    <MenuIcon
                      theme={this.state.type === "interventions"}
                      className={`icon-${menuInterventions.icon} icons`}
                    />
                  </Tooltip>
                  {/* <GroupedButtonBlockText>
                    {menuInterventions.name}
                  </GroupedButtonBlockText> */}
                </GroupedButton>
                <GroupedButton
                  width={"20%"}
                  onClick={() => this.handleTypeChange("rega")}
                  active={this.state.type === "rega"}
                >
                  <Tooltip title={`Rega`}>
                    <MenuIcon
                      theme={this.state.type === "rega"}
                      className={`icon-drop icons`}
                    />
                  </Tooltip>
                  {/* <GroupedButtonBlockText>
                    {menuInterventions.name}
                  </GroupedButtonBlockText> */}
                </GroupedButton>

                <GroupedButton
                  width={"20%"}
                  onClick={() => this.handleTypeChange("readings")}
                  active={this.state.type === "readings"}
                >
                  <Tooltip title={`Leituras`}>
                    <MenuIcon
                      theme={this.state.type === "readings"}
                      className={`icon-speedometer icons`}
                    />
                  </Tooltip>
                  {/* <GroupedButtonBlockText>Leituras</GroupedButtonBlockText> */}
                </GroupedButton>
                <GroupedButton
                  width={"20%"}
                  onClick={() => this.handleTypeChange("reports")}
                  active={this.state.type === "reports"}
                >
                  <Tooltip title={`Relatorios`}>
                    <MenuIcon
                      theme={this.state.type === "reports"}
                      className={`icon-eye icons`}
                    />
                  </Tooltip>
                  {/* <GroupedButtonBlockText>Leituras</GroupedButtonBlockText> */}
                </GroupedButton>
              </ButtonGroup>

              {/* <SectionTitle>Parcelas</SectionTitle> */}
              {this.state.type !== "reports" && (
                <div>
                  <ParcelsFilter handleSearchChange={this.handleSearchChange} />
                  <SectionTitle>Equipa</SectionTitle>
                  <TeamFilter
                    noMulti={true}
                    selected={this.state.team}
                    handleChange={this.handleTeamChange}
                  />
                </div>
              )}
              {this.state.type === "inspections" && (
                <div>
                  <SectionTitle>Tipo de {menuInspections.name}</SectionTitle>
                  <div>
                    <MaintenanceFilter
                      fullWidth
                      name={"include"}
                      value={this.state.include}
                      onChange={this.handleExcludeView}
                      inputProps={{
                        name: "include",
                        id: "include",
                      }}
                    >
                      <MenuItem value="all">
                        <em>Tipo Inspeção</em>
                      </MenuItem>
                      <MenuItem value={0}>Externas</MenuItem>
                      <MenuItem value={1}>Internas</MenuItem>
                    </MaintenanceFilter>
                  </div>
                </div>
              )}
              <DateRangeFilter
                handleSearchChange={this.handleSearchChange}
                endDate={endDate}
                startDate={startDate}
              />
            </ScrollArea>
          </FilterList>
          {this.state.type === "inspections" &&
            this.props.freguesia &&
            this.state.team && (
              <StyledMainButton
                onClick={() =>
                  this.props.submitTeamReports(
                    this.props.freguesia,
                    this.state.team
                  )
                }
              >
                <MenuIcon className={`icon-speech icons`} /> Submeter Relatorio{" "}
                {moment(startDate).format("MMM")}
                {/* {this.state.team ? "Equipa" : "Mensal"} */}
              </StyledMainButton>
            )}
          {/* <StyledMainButton onClick={() => this.renderCSV()}>
            <MenuIcon className={`icon-doc icons`} /> Export data
          </StyledMainButton> */}
        </Sidebar>
        <Content size={2} color={"white"} map={true}>
          <TableWrap
            display={
              !this.state.editInspectionId && !this.state.editMaintenanceId
                ? "display"
                : "hide"
            }
          >
            {list && (
              <Table
                orderBy={"id"}
                data={this.preProcessData()}
                click={this.goToDetail}
                header={columnData}
              />
            )}
          </TableWrap>
        </Content>
        {this.state.editMaintenanceId && (
          <ViewMaintenance
            onClose={this.closeModal}
            group={this.state.editMaintenanceId}
          />
        )}
        {this.state.editInspectionId && (
          <ViewInspection
            onClose={this.closeModal}
            group={this.state.editInspectionId}
          />
        )}
      </StyledWrap>
    );
  }
}

export default connect(
  (state) => {
    return {
      element: state.app.search.element,
      elementTypeSelected: state.app.search.elementTypeSelected,
      teams: state.user.teams,
      type: state.reports.type,
      appStatus: state.reports.status,
      startDate: state.app.search.startDate,
      selected: state.app.search.selected,
      elementSelected: state.app.search.element,
      freguesia: state.app.search.freguesia,
      endDate: state.app.search.endDate,
      role: state.user.data.role,
      list: state.parklist.list,
      data: state.reports.data,
      dataReports: state.reports.dataReports,
      menuData:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/reports`
        ),
      menuInspections:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/inspections`
        ),
      menuInterventions:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/interventions`
        ),
    };
  },
  { getReports, setSearch, submitTeamReports, setActiveType }
)(Reports);
