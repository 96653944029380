import React, { useEffect, useState } from "react";
import { Modal, Select, Input } from "@material-ui/core";
import axios from "axios";
import styled from "styled-components";
import { toastr } from "react-redux-toastr";
import { default as AddNewMaintenance } from "../Maintenances/AddNew";
import { store } from "../../store/configure";

/**page styles */
const OptionWrapElements = styled.div`
  && {
    padding: 1em;
    margin: 0.5em 0;
    display: flex;
    align-items: center;
    justify-content: left;
    border: 1px solid #e9eff4;
    border-radius: 6px;
    cursor: pointer;
    background: ${(props) => {
      if (props.selected) {
        return "#4da1ff";
      }

      if (props.visited) {
        return "#6fc44c";
      }
      if (props.fixed) {
        return "#f29729";
      }
      return "#fff";
    }};
    :hover {
      background: ${(props) =>
        props.selected || props.visited ? "#4da1ff" : "#e9eff4"};
      color: #fff;
    }

    ${(props) =>
      props.selected || props.visited ? "span,div{color:#fff}" : ""};
  }
`;

const VisitButton = styled.div`
  && {
    margin: 0.5em 0;
    width: 100%;
    text-align: center;
    padding: 1em 0;
    border: 1px solid #4da1ff;
    background: #4da1ff;
    color: ${(props) =>
      props.selected || props.visited
        ? "#4da1ff !important"
        : "#fff !important"};
    display: inline-block;
    border-radius: 5px;
    font-size: 0.8em;
    background: ${(props) =>
      props.selected || props.visited ? "#fff" : "#4da1ff"};
    span {
      color: ${(props) =>
        props.selected || props.visited
          ? "#4da1ff !important"
          : "#fff !important"};
    }
    cursor: pointer;
  }
`;

const ModalInner = styled.div`
  && {
    border: 1px solid #e9eff4;
    box-shadow: rgb(0 0 0 / 12%) 0px 3.5px 5.5px;
    border-radius: 6px;
    padding: 2em;
    margin: 0.8em 0;
    overflow: auto;
    cursor: default;
    position: relative;
    color: #000;
    width: 50%;
    padding: 1em 2em;
    max-width: 300px;
    background: white;
    max-height: 95vh;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .flexwrap {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .left {
        width: 65%;
      }
      .right {
        width: 25%;
      }
    }
    @media (max-width: 768px) {
      width: 80%;
    }
  }
`;
const ParkName = styled.div`
  && {
    font-size: 0.8em;
    color: rgba(74, 144, 226, 0.8);
    font-weight: bold;
    text-align: left;
  }
`;
const ParkLocation = styled.div`
  && {
    font-size: 0.8em;
    color: rgba(74, 144, 226, 0.33);
    text-align: left;
  }
`;

const ShowHelpChoiceModal = ({ toggleAddInterventions }) => {
  const [toggleAddHelpModal, setToggleAddHelpModal] = useState(false);
  const [folhas, setFolhas] = useState([]);
  const [parks, setParks] = useState([]);
  const [selectionPark, setSelectionPark] = useState();
  // const [toggleAddInterventions, setToggleAddInterventions] = useState();

  useEffect(() => {
    if (toggleAddHelpModal === true && parks.length === 0) getAllParks();
    return () => {};
  }, [toggleAddHelpModal]);

  /**
   * Get all parks
   * to display out of map permissions help
   */

  const getAllParks = async () => {
    try {
      const response = await axios.get(`api/v1/parks/getAllNoPerm`);
      if (!response.data.msg || response.data.msg.length === 0)
        toastr.error("Erro", "Não existem parques para ajudar");

      const folhas = response.data.msg
        .map((p) => p.local)
        .filter((p) => {
          // check if p starts with ESC, CR or C followed by a number
          const regex = new RegExp(/^(ESC|CR|C)\d+$/);
          return !regex.test(p);
        });
      setParks(response.data.msg);
      setFolhas([...new Set(folhas)]);
    } catch (error) {
      toastr.error("Erro", "Erro ao obter parques");
    }
  };

  //   /**
  //    * Set training data
  //    */
  //   const setTraining = async () => {
  //     try {
  //       await axios.post(
  //         `${apiAddress}api/v1/training${
  //           showFormationModal && showFormationModal !== true
  //             ? `/${showFormationModal}`
  //             : ""
  //         }`,
  //         trainingDetails
  //       );
  //       toastr.success("Sucesso", "Formação adicionada com sucesso");
  //       setShowFormationModal(false);
  //       getTrainings();
  //     } catch (error) {
  //       toastr.error("Erro", "Erro ao adicionar formação");
  //     }
  //   };

  //   /**
  //    * Send email to user
  //    */
  //   const sendInvitationEmail = async () => {
  //     try {
  //       if (!data.id) throw new Error("no id found");
  //       await axios.get(`${apiAddress}api/v1/training/sendemail/${data.id}`);
  //       toastr.success("Sucesso", "Convite para formação enviado com sucesso");
  //       setShowFormationModal(false);
  //       getTrainings();
  //     } catch (error) {
  //       toastr.error("Erro", "Erro ao enviar email formação");
  //     }
  //   };

  /**
   * render content
   */
  return (
    <div>
      <OptionWrapElements
        selected={true}
        onClick={
          () => {
            setToggleAddHelpModal(true);
          }
          // this.setVisitToPark(
          //   park.properties.local,
          //   park.properties.numero
          // )
        }
      >
        <ParkName>
          <div
            className="icons icon-check"
            style={{ fontSize: "2em", marginRight: ".5em" }}
          />
        </ParkName>
        <div>
          <ParkName>Ajudar outra equipa</ParkName>
          <ParkLocation>Marcar visita a folha de outra equipa</ParkLocation>
        </div>
      </OptionWrapElements>

      <Modal
        open={toggleAddHelpModal}
        onClose={() => setToggleAddHelpModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalInner>
          <h3 style={{ color: "#4da1ff" }}>Escolha uma Folha</h3>
          <p>
            Antes de inserir a nova visita tem de selecionar a folha correta,
            pode escrever o nome ou selecionar da lista
          </p>
          {/* <input
            type="text"
            list="folhas"
            style={{ display: "block", fontSize: "1.3em", padding: ".3em" }}
          /> */}
          <select
            id="folhas"
            style={{
              display: "block",
              width: "100%",
              fontSize: "1.3em",
              padding: ".3em",
            }}
            onChange={(e) => {
              setSelectionPark(e.target.value);
            }}
          >
            {folhas &&
              folhas.sort().map((park) => (
                <option
                  style={{
                    fontSize: "1.2em",
                    padding: ".8em",
                  }}
                >
                  {park}
                </option>
              ))}
          </select>
          {selectionPark && (
            <VisitButton
              onClick={() => {
                toggleAddInterventions(
                  parks.find((park) => park.local === selectionPark),
                  true
                );
                setToggleAddHelpModal(false);
              }}
              // onClick={
              //   // () => this.props.toggleAddInterventions()
              //   // this.setVisitToPark(
              //   //   park.properties.local,
              //   //   park.properties.numero
              //   // )
              // }
            >
              <span className={"icon-check icons"} /> Fazer visita
            </VisitButton>
          )}
        </ModalInner>
      </Modal>
    </div>
  );
};

export default ShowHelpChoiceModal;
