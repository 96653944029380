import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Row, Col } from "react-flexbox-grid";
import { Tooltip } from "@material-ui/core";

import SectionTitle from "../../components/Text/SectionTitle";
import MainButton from "../../components/Buttons/MainButton";
import MenuIcon from "../../components/Buttons/MenuIcon";
import ViewReport from "../Reports/ViewReport";
import FullScreenModal from "../../components/Layout/FullScreenModal";
import PageTitle from "../../components/Text/PageTitle";
import ContentWrap from "../../components/Layout/ContentWrap";
import { default as InspectionsEdit } from "../Inspections/Edit";
import Edit from "./Edit";
import AddComment from "./steps/AddComment";

import {
  getMaintenancesHistory,
  addNewToggle,
  setHistory,
  setMaintenanceDone,
} from "./actions";
import { setSearch } from "../App/actions";

const OptionWrap = styled(ContentWrap)`
  && {
    padding: 2em;
    text-align: center;
  }
`;
const Section = styled.div`
  && {
    margin: 2em auto;
    max-width: 1400px;
  }
`;
const FinishButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    width: 100%;
    margin-top: 1em;
    background: #6fc44c;
    border: 1px solid #6fc44c;
    @media print {
      display: none;
    }
  }
`;

const Close = styled.span`
  && {
    font-size: 2.5em;
    margin: 0 0.5em 0 0;
    text-align: center;
    color: #989898;
    vertical-align: middle;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 11;
    cursor: pointer;
    :hover {
      color: #4da1ff;
      transform: rotate(90deg);
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
    @media print {
      display: none;
    }
    @media (max-width: 425px) {
      font-size: 2em;
    }
  }
`;
const PrintButton = styled.span`
  && {
    font-size: 1.3em;
    margin: 0 0.5em 0 0;
    text-align: center;
    color: #989898;
    vertical-align: middle;
    position: absolute;
    right: 77px;
    top: 20px;
    z-index: 11;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid;
    padding: 0.4em;
    :hover {
      color: #4da1ff;
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
    @media print {
      display: none;
    }
    @media (max-width: 425px) {
      font-size: 1em;
      right: 77px;
      margin: 0;
    }
  }
`;
const DailyReportButton = styled.span`
  && {
    font-size: 1.3em;
    margin: 0 0.5em 0 0;
    text-align: center;
    color: #989898;
    vertical-align: middle;
    position: absolute;
    right: 126px;
    top: 20px;
    z-index: 11;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid;
    padding: 0.4em;
    :hover {
      color: #4da1ff;
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
    @media print {
      display: none;
    }
    @media (max-width: 425px) {
      font-size: 1em;
      right: 117px;
      margin: 0;
    }
  }
`;

const LocationTitle = styled.span`
  && {
    font-size: 0.8em;
    display: block;
  }
`;
class View extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      group: props.group,
      viewDailyReport: null,
    };
    this.AddNewMaintenance = this.AddNewMaintenance.bind(this);
    this.generatePDF = this.generatePDF.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.viewEventReport = this.viewEventReport.bind(this);

    this.closeViewDailyReport = this.closeViewDailyReport.bind(this);
    // props.setSearch({
    //   element: "edit",
    //   value: this.state.group,
    // });
    props.getMaintenancesHistory(this.state.group);
  }

  closeModal() {
    if (typeof this.props.onClose === "function") return this.props.onClose();
    this.props.setSearch({
      element: "edit",
      value: null,
    });

    this.props.history.push(`/${this.props.callback || "interventions"}`);
  }

  AddNewMaintenance() {
    this.props.setHistory([this.state.group]);
    this.props.addNewToggle();
  }

  handleFinishMaintenance() {
    this.props.setMaintenanceDone(this.state.group);
  }

  generatePDF() {
    window && window.print();
  }

  closeViewDailyReport() {
    this.setState({ viewDailyReport: null });
  }

  viewEventReport(park) {
    const data = this.props.historyData;
    let date = null;
    if (data && data.length > 0) {
      date = data[0].date;
    }
    this.setState({ viewDailyReport: { ...park, date } });
  }
  render() {
    const data = this.props.historyData;
    let selectedPID = "";
    let isApproved = false;

    if (data && data.length > 0) {
      selectedPID = data[0].Inspection ? data[0].Inspection.pid : data[0].pid;
      isApproved = data[0].status === 1;
    }

    const selectedPark = this.props.parks.find(
      (park) => park.numero === selectedPID
    );
    return (
      <div>
        <FullScreenModal>
          <Tooltip title="Fechar">
            <Close
              onClick={() => this.closeModal()}
              className={`icon-close icons`}
            />
          </Tooltip>
          <Tooltip title="Imprimir">
            <PrintButton
              onClick={() => this.generatePDF()}
              className={`icon-printer icons`}
            />
          </Tooltip>
          <Tooltip title="Relatório da Visita">
            <DailyReportButton
              onClick={() => this.viewEventReport(selectedPark)}
              className={`icon-event icons`}
            />
          </Tooltip>
          <Section>
            {data && (
              <div>
                <PageTitle>
                  {selectedPark?.nome ||
                    `${data[0]?.location} - ${data[0]?.area}`}
                  <LocationTitle>
                    {selectedPark && selectedPark.freguesia
                      ? `${selectedPark && selectedPark.freguesia} ,`
                      : ``}
                    {selectedPark && selectedPark.local
                      ? `${selectedPark && selectedPark.local} ,`
                      : ``}
                    {selectedPark && selectedPark.numero
                      ? `${selectedPark && selectedPark.numero}`
                      : ""}
                  </LocationTitle>
                </PageTitle>
                {data.map((maintenance, index) => {
                  if (maintenance.Inspection) {
                    return (
                      <div key={index}>
                        <InspectionsEdit data={maintenance.Inspection} />
                        <Edit data={maintenance} closeModal={this.closeModal} />
                      </div>
                    );
                  }
                  return (
                    <Edit
                      key={index}
                      data={maintenance}
                      closeModal={this.closeModal}
                    />
                  );
                })}
              </div>
            )}
            {!data && (
              <div>
                <PageTitle>Upss...</PageTitle>
                <Row>
                  <Col xs={12} md={9}>
                    <SectionTitle>Detalhes</SectionTitle>
                    <OptionWrap>
                      Não conseguimos encontrar os detalhes para esta
                      manutenção.
                    </OptionWrap>
                  </Col>
                </Row>
              </div>
            )}
          </Section>
        </FullScreenModal>
        {this.state.viewDailyReport && (
          <ViewReport
            args={this.state.viewDailyReport}
            onClose={this.closeViewDailyReport}
          />
        )}
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      type: state.maintenances.type,
      activeStep: state.maintenances.activeStep,
      historyData: state.maintenances.reportHistory,
      status: state.maintenances.status,
      parks: state.parklist.list,
      role: state.user.data.role,
      isAdmin: state.user.data.admin,
    };
  },
  {
    getMaintenancesHistory,
    setMaintenanceDone,
    addNewToggle,
    setHistory,
    setSearch,
  }
)(View);
