import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import Table from "../../components/Table/Table";
import PageTitle from "../../components/Text/PageTitle";
import { default as ScrollArea } from "../../components/Layout/ScrollBar";

import MobileLayoutToggle from "../App/MobileLayoutToggle";
import ButtonGroup from "../../components/Buttons/ButtonGroup";
import GroupedButton from "../../components/Buttons/GroupedButton";
import Sidebar from "../../components/Layout/Sidebar";
import MenuIcon from "../../components/Buttons/MenuIcon";
import MainButton from "../../components/Buttons/MainButton";
// import SidebarList from '../../components/Layout/SidebarList';
import Content from "../../components/Layout/Content";
import { getUniqArrayElements } from "../../services/utils";
import AddUser from "./Modals/AddUser";
import AddTeam from "./Modals/AddTeam";
import {
  getUsers,
  getTeams,
  resetUser,
  setUserInitData,
  setTeamInitData,
} from "./actions";
const FilterList = styled.div`
  && {
    .maintenance-filter {
      height: calc(100vh - 240px);
      padding-right: 18px;
      .scrollbar {
        border-radius: 1em;
      }
      @media (max-width: 900px) {
        height: calc(100vh - 300px);
      }
      @media (max-width: 768px) {
        height: calc(100vh - 207px);
      }
    }
  }
`;
const HeaderWrap = styled.div`
  && {
    display: flex;
    justify-content: space-between;
  }
`;

const StyledWrap = styled.div`
  display: flex;
  height: 100%;
  /* height: calc(100vh - 49px); */
  @media (max-width: 768px) {
    display: block;
    height: 100%;
    overflow: auto;
  }
`;

const ButtonWrapper = styled.div`
  && {
    text-align: left;
    display: block;
    font-size: 0.8em;
    margin-top: 0.9em;
    button {
      margin-right: 0.5em;
      :hover {
        a {
          color: #4da1ff;
        }
      }
    }

    a {
      text-decoration: none;
      color: #fff;
      :hover {
        color: #4da1ff;
      }
    }
  }
`;

const AddNew = styled(MainButton)`
  && {
    padding: 0 1em 0;
    line-height: 1;
    font-size: 0.8em;
    margin-left: 0.8em;
    margin-top: 1.9em;
    display: block;
    span {
      display: inline-block;
      color: #fff;
      :hover {
        color: #4da1ff;
      }
    }
  }
`;

const PageTitleStyled = styled.div`
  && {
    h2 {
      margin-bottom: 1em;
      div {
        margin-bottom: 0;
      }
    }
  }
`;
const TableWrap = styled.div`
  && {
    overflow-x: auto;
    overflow-y: visible;
    > div {
      padding-top: 0;
    }
  }
`;

const StyledMainButton = styled(MainButton)`
  && {
    margin-top: 1em;
    font-size: 0.8em;
  }
`;
class Users extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      view: "users",
      edit: props.id || "",
      addNewUser: false,
      addNewTeam: false,
    };
    this.toggleAddNew = this.toggleAddNew.bind(this);
    this.goToDetails = this.goToDetails.bind(this);
    this.closeModal = this.closeModal.bind(this);
    props.getUsers();
    props.getTeams();
  }

  closeModal() {
    this.setState({
      addNewUser: null,
      addNewTeam: null,
    });
    this.props.resetUser();
  }
  processedData(data) {
    return data.map((row) => {
      return { ...row, equipa: row.Team ? row.Team.label : "" };
    });
  }

  toggleAddNew() {
    if (this.state.view === "users") {
      this.setState({
        addNewUser: !this.state.addNewUser,
        addNewTeam: false,
      });
      this.props.setUserInitData();
    } else {
      this.setState({
        addNewTeam: !this.state.addNewTeam,
        addNewUser: false,
      });
      this.props.setTeamInitData();
    }
  }

  goToDetails(id) {
    if (this.state.view === "users") {
      this.setState({
        edit: id,
        addNewUser: true,
        addNewTeam: false,
      });

      this.props.setUserInitData(id);
    } else {
      this.setState({
        edit: id,
        addNewTeam: true,
        addNewUser: false,
      });

      this.props.setTeamInitData(id);
    }

    // return this.props.history.push(`/teams/${id}`);
  }

  render() {
    const { list, teams } = this.props;
    const { addNewUser, addNewTeam } = this.state;

    let data = [];
    let columnData = [];

    if (this.state.view === "users") {
      data = list;
      columnData = [
        {
          id: "id",
          name: "id",
          numeric: false,
          disablePadding: true,
          label: "id",
        },
        {
          id: "name",
          name: "name",
          numeric: false,
          disablePadding: false,
          label: "Nome",
        },
        {
          id: "email",
          name: "email",
          numeric: false,
          disablePadding: false,
          label: "Email",
        },
        {
          id: "equipa",
          name: "Equipa",
          numeric: false,
          disablePadding: false,
          label: "Equipa",
        },
        {
          id: "role",
          name: "role",
          numeric: false,
          disablePadding: false,
          label: "Permissao",
        },

        {
          id: "status",
          name: "status",
          numeric: false,
          disablePadding: false,
          label: "Status",
        },
        {
          id: "createdAt",
          name: "createdAt",
          numeric: false,
          disablePadding: false,
          label: "Data",
        },
      ];
    } else {
      let uniqueTeams = [];
      // lets filter out duplicates
      teams.map((elem) => {
        if (!uniqueTeams.find((newA) => elem.id === newA.id)) {
          const teamUsers = list.filter((user) => user.TeamId === elem.id);
          uniqueTeams.push({ ...elem, users: teamUsers.length });
        }
      });

      data = teams;
      columnData = [
        {
          id: "id",
          name: "id",
          numeric: false,
          disablePadding: true,
          label: "id",
        },
        {
          id: "label",
          name: "label",
          numeric: false,
          disablePadding: false,
          label: "Nome",
        },
        {
          id: "type",
          name: "type",
          numeric: false,
          disablePadding: false,
          label: "Tipo",
        },
        {
          id: "users",
          name: "users",
          numeric: false,
          disablePadding: false,
          label: "Utilizadores",
        },

        {
          id: "status",
          name: "status",
          numeric: false,
          disablePadding: false,
          label: "Status",
        },
        {
          id: "createdAt",
          name: "createdAt",
          numeric: false,
          disablePadding: false,
          label: "Data",
        },
      ];
    }

    return (
      <StyledWrap>
        <MobileLayoutToggle />
        <Sidebar
          title={this.state.view === "users" ? "Utilizadores" : "Equipas"}
          text={`gerir ${
            this.state.view === "users" ? "utilizadores" : "equipas"
          }`}
          icon={"user"}
          noanimation
        >
          <FilterList>
            <ScrollArea
              speed={0.8}
              className="maintenance-filter"
              contentClassName="maintenance-filter-content"
              horizontal={false}
            >
              <ButtonGroup>
                <GroupedButton
                  width={"50%"}
                  onClick={() => {
                    this.setState({ view: "users" });
                    this.props.getUsers();
                  }}
                  active={this.state.view === "users" ? "true" : "false"}
                >
                  <MenuIcon className={`icon-user icons`} /> Utilizadores
                </GroupedButton>
                <GroupedButton
                  width={"50%"}
                  active={this.state.view === "teams" ? "true" : "false"}
                  onClick={() => {
                    this.setState({ view: "teams" });
                    this.props.getTeams();
                  }}
                >
                  <MenuIcon className={`icon-people icons`} /> Equipas
                </GroupedButton>
              </ButtonGroup>

              <AddNew onClick={() => this.toggleAddNew()}>
                <MenuIcon className={`icon-plus icons`} /> Adicionar{" "}
                {this.state.view === "users" ? "Utilizadores" : "Equipas"}
              </AddNew>
            </ScrollArea>
          </FilterList>
        </Sidebar>

        <Content size={2} color={"white"} map={true}>
          <TableWrap>
            {list && (
              <Table
                orderBy={"id"}
                data={this.processedData(data)}
                click={this.goToDetails}
                header={columnData}
              />
            )}
          </TableWrap>
        </Content>
        {addNewUser && (
          <AddUser edit={this.props.edit} closeModal={this.closeModal} />
        )}
        {addNewTeam && (
          <AddTeam edit={this.props.edit} closeModal={this.closeModal} />
        )}
      </StyledWrap>
    );
  }
}

export default connect(
  (state) => {
    return {
      list: state.user.list,
      teams: state.user.teams,
      id: state.user.id,
    };
  },
  { getUsers, getTeams, resetUser, setUserInitData, setTeamInitData }
)(Users);
