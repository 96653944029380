import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
// import ChipInput from 'material-ui-chip-input';
import { Row, Col } from "react-flexbox-grid";
import DropzoneComponent from "react-dropzone-component";
import Rating from "react-rating";
import { FormControlLabel, Switch, MenuItem, Select } from "@material-ui/core";
import { toastr } from "react-redux-toastr";
import SistemaDeRegaCF from "./SistemaDeRegaCF";
import DefaultCF from "./DefaultCF";

import MainButton from "../../../components/Buttons/MainButton";
import ContentWrap from "../../../components/Layout/ContentWrap";
import TextField from "../../../components/form-wrappers/TextField";
import SectionTitle from "../../../components/Text/SectionTitle";
import MenuIcon from "../../../components/Buttons/MenuIcon";
import AutoComplete from "../../../components/form-wrappers/AutoComplete";
import { apiAddress } from "../../../config/general";

import { setDetails, setInspectionEV, getDamages, setPhotos } from "../actions";
import { getHortasParcel } from "../../Georeference/actions";

const NextButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    width: 100%;
  }
`;

const HalfRate = styled.div`
  && {
    display: inline-block;
    width: 30%;
    margin-right: 2%;
    @media (max-width: 900px) {
      width: 45%;
    }
    @media (max-width: 425px) {
      width: 100%;
    }
  }
`;

const Toggle = styled(Switch)`
  && {
    .MuiSwitch-bar-143,
    .jss143 {
      color: #4da1ff;
      background: #4da1ff;
    }
    .MuiSwitch-colorPrimary-140.MuiSwitch-checked-139,
    .jss139 {
      color: #4da1ff;
    }
  }
`;
const OptionWrap = styled(ContentWrap)`
  && {
    padding: 2em;
    text-align: left;
  }
`;

const Input = styled(TextField)`
  && {
    width: 100%;
    padding: 1em 0;
    margin: 0;
    text-align: left;
    textarea {
      height: auto;
    }
  }
`;

const Calendar = styled(Input)`
  && {
    margin: 0;
  }
`;
const Contacts = styled.div`
  && {
    div {
      word-break: break-word;
      margin: 1em 0;
      color: #4da1ff;
      font-size: 0.9em;
      strong {
        color: rgba(0, 0, 0, 0.87);
        font-weight: normal;
        display: block;
      }
    }
  }
`;

const Section = styled.div`
  && {
    margin-bottom: 10% !important;
    margin: 2em 0;
    max-width: 1200px;
  }
`;
const RatingWrap = styled.div`
  && {
    margin: 2em 0 0;
    text-align: left;
    label {
      display: block;
      color: rgba(0, 0, 0, 0.6);
      font-size: 0.8em;
      margin-bottom: 0.5em;
    }
    /* > span {
      zoom: 0.8;
    } */
  }
`;

const Files = styled(DropzoneComponent)`
  && {
    background-color: rgba(74, 144, 226, 0.1);
    border: 2px dashed rgba(74, 144, 226, 1);
    color: #4990e2;
    padding: 1em 1em 2em 1em;
    margin: 1em 0 1.5em;
    .dz-message {
      margin: 0;
    }
    .dz-preview.dz-image-preview {
      background: transparent;
    }
    .filepicker-file-icon {
      color: #4990e2;
      &::after {
        background-color: #4990e2;
      }
      &::before {
        border: solid 2px #4990e2;
      }
    }
    .dz-image {
      img {
        width: 120px;
        height: 120px;
      }
    }
  }
`;

class DetailsSpecific extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: this.getCurrentDate(),
      selectedHorta: "",
      reading:
        props.reading && props.details.reading ? props.details.reading : "",
      details:
        props.details && props.details.details ? props.details.details : "",
      damages:
        props.details && props.details.damages ? props.details.damages : [],
      type: "general",
      attr: {
        include: true,
        customFields: [],
        type: props.isRega ? "rega" : "inspection",
      },
      photos: props.photos || [],
    };
    // if (
    //   props.selectedParkId !== props.details.pid &&
    //   props.photos &&
    //   props.photos.length > 0
    // )
    //   toastr.error(
    //     'Informação incorrecta',
    //     'Atenção a informação guardada não corresponde a este parque, carregue em reset para começar de novo'
    //   );

    // For a full list of possible configurations,
    // please consult http://www.dropzonejs.com/#configuration
    this.djsConfig = {
      acceptedFiles: "image/*, application/*",
      addRemoveLinks: true,
      resizeWidth: 1100,
      resizeHeight: 1100,
      resizeQuality: 0.6,
      headers: { Authorization: "Bearer " + props.token },
      params: {
        fid: props.selectedParkId, //should be the park id
      },
    };

    this.componentConfig = {
      iconFiletypes: ["docs", "images"],
      showFiletypeIcon: true,
      postUrl: `${apiAddress}api/v1/files`,
    };

    this.callback = this.callback.bind(this);
    this.removedfile = this.removedfile.bind(this);

    this.handleAttrCustomFieldsRating = this.handleAttrCustomFieldsRating.bind(
      this
    );
    this.handleChange = this.handleChange.bind(this);
    this.handleAttrChange = this.handleAttrChange.bind(this);
    this.handleChangeDamages = this.handleChangeDamages.bind(this);
    this.handleSaveInspection = this.handleSaveInspection.bind(this);
    this.preloadImages = this.preloadImages.bind(this);
    this.handleAttrRating = this.handleAttrRating.bind(this);
    this.inspectionCF = {
      EV: [
        { name: "Geral", comment: "" },
        { name: "Limpeza", comment: "" },
        { name: "Relvado/Prado", comment: "" },
        { name: "Herbáceas", comment: "" },
        { name: "Arbustos", comment: "" },
        { name: "Árvores", comment: "" },
        { name: "Canteiro", comment: "" },
        // { name: "Mobiliário Urbano", comment: "" },
        // { name: "Pavimento", comment: "" },
        // { name: "Sistema de Rega", comment: "" },
      ],
    };
    props.getDamages();
  }

  componentDidMount() {}

  fetchExtraParcelData() {
    //now we need to fetch contact data
    try {
      const selectedPark = this.props.list.find(
        (park) => park.numero === this.props.selected[0]
      );
      if (!this.props.selectedElement.properties.label) throw "no label";

      let number = parseInt(
        this.props.selectedElement.properties.label.match(/\d+/g)
      );
      if (!number) throw "no number";

      number = number < 10 ? `0${number}` : number;

      if (!selectedPark || !selectedPark["número_ant"]) throw "no name";
      this.setState({
        selectedHorta: `${selectedPark["número_ant"]}.${number}`,
      });
      this.props.getHortasParcel(`${selectedPark["número_ant"]}.${number}`);
    } catch (error) {
      console.log(error);
    }
  }

  // Simple callbacks work too, of course
  callback(file) {
    const fileData = JSON.parse(file.xhr.response);
    const photos = [
      ...this.state.photos,
      {
        path: fileData.msg.details.path,
        name: fileData.msg.details.originalname,
        size: fileData.msg.details.size,
        id: fileData.msg.id,
      },
    ];
    this.props.setPhotos(photos);
    this.setState({ photos: photos });
  }

  // Simple callbacks work too, of course
  removedfile(file) {
    //need to delete images
    // this.setState({ photos: [...this.state.photos, file.upload] });
    const updatedPhotos = this.state.photos.filter(
      (photo) => photo.name !== file.upload.filename
    );
    this.setState({
      photos: updatedPhotos,
    });
    this.props.setPhotos(updatedPhotos);
  }

  getCurrentDate() {
    const now = new Date();
    const addZero = (i) => (i < 10 ? "0" + i : i);
    return `${now.getFullYear()}-${addZero(now.getMonth() + 1)}-${addZero(
      now.getDate()
    )}T${addZero(now.getHours())}:${addZero(now.getMinutes())}`;
  }

  getDamagesList() {
    return this.props.damagesList || [];
  }

  handleChange(e, value) {
    this.setState({ [e.target.name]: e.target.value });
    this.props.setDetails(this.state);
  }

  handleAttrChange(e, value) {
    let valueFinal = e.target.value;
    // for toggle
    if (e.target.name === "include") {
      valueFinal = !this.state.attr[e.target.name];
    }
    this.setState({
      attr: { ...this.state.attr, [e.target.name]: valueFinal },
    });
    this.props.setDetails(this.state);
  }

  handleAttrCustomFieldsRating(value, index) {
    this.setState({
      attr: {
        ...this.state.attr,
        customFields: {
          ...this.state.attr.customFields,
          [index]: value,
        },
      },
    });
  }
  handleAttrRating(value) {
    const suggestion = this.props.damagesList.find(
      (e) => e.label === "Avaliar"
    );
    this.setState({
      attr: { ...this.state.attr, rating: value },
      damages: { suggestion },
    });
    // this.props.setDetails(this.state);
  }

  handleChangeDamages(value) {
    this.setState({
      leitura: this.props.damagesList.find(
        (damage) =>
          damage.id === value.target.value && damage.label === "Leitura"
      ),
      damages: value.target ? { id: value.target.value } : value,
    });
  }

  handleSaveInspection() {
    if (this.inspectionCF[this.props.role]) {
      let valid = true;
      this.inspectionCF[this.props.role].map((field, index) => {
        if (field.required == 1 && !this.state.attr.customFields[index]) {
          valid = false;
        }
      });
      if (!valid) {
        return toastr.error(
          "Tem de completar toda a fiscalização antes de salvar"
        );
      }
    }
    this.props.setDetails(this.state);
    this.props.setInspectionEV();
  }

  preloadImages(dropzone) {
    (this.state.photos || []).forEach(function(i) {
      var name = "";
      var file = { url: i.path, name: name };
      var imageUrl = `${apiAddress}${i.path}`;

      dropzone.createThumbnailFromUrl(file, imageUrl);
      dropzone.emit("addedfile", file);
      dropzone.emit("thumbnail", file, imageUrl);
      dropzone.emit("complete", file);
    });
  }

  render() {
    const config = this.componentConfig;
    const djsConfig = this.djsConfig;

    const self = this;

    /// not bound to this is moved out of render
    const eventHandlers = {
      init: (dropzone) => {
        dropzone.autoDiscover = false;
        self.preloadImages(dropzone);
      },
      removedfile: self.removedfile,
      error: (e) => {
        console.log(e);
      },
      success: self.callback,
    };

    return (
      <Section>
        <Row>
          <Col xs={12} md={9}>
            {!this.props.isRega && (
              <DefaultCF
                handleAttrCustomFieldsRating={this.handleAttrCustomFieldsRating}
                interventionType={this.props.interventionType}
                attr={this.state.attr}
              />
            )}
            {this.props.isRega && (
              <SistemaDeRegaCF
                handleAttrCustomFieldsRating={this.handleAttrCustomFieldsRating}
                interventionType={this.props.isRega === "rega"}
                attr={this.state.attr}
              />
            )}
            {!this.props.isRega &&
              this.inspectionCF[this.props.role] &&
              this.inspectionCF[this.props.role].length > 0 && (
                <div>
                  <SectionTitle>Avaliação geral</SectionTitle>
                  <OptionWrap>
                    {this.inspectionCF[this.props.role].map((cf, index) => (
                      <HalfRate key={index}>
                        <RatingWrap>
                          <label>{cf.name}</label>
                          <Rating
                            onChange={(rate) =>
                              this.handleAttrCustomFieldsRating(rate, cf.name)
                            }
                            initialRating={
                              this.state.attr.customFields
                                ? this.state.attr.customFields[cf.name]
                                : 0
                            }
                            stop={3}
                            fractions={2}
                          />
                          <label
                            className={"comment"}
                            style={{ fontWeight: "bold" }}
                          >
                            {cf.comment}
                          </label>
                        </RatingWrap>
                      </HalfRate>
                    ))}
                  </OptionWrap>
                </div>
              )}
            <SectionTitle>Detalhes Gerais</SectionTitle>
            <OptionWrap>
              {(!this.state.damages.suggestion ||
                (this.state.damages.suggestion &&
                  this.state.damages.suggestion.label !== "Avaliar")) && (
                <Select
                  fullWidth
                  name={"damages"}
                  value={this.state.damages.id || ""}
                  onChange={this.handleChangeDamages}
                  inputProps={{
                    name: "damages",
                    id: "damages",
                  }}
                >
                  {this.getDamagesList().map((damage, index) => {
                    return (
                      <MenuItem key={index} value={damage.id}>
                        {damage.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
              {this.state.leitura && (
                <Input
                  label="Total"
                  onChange={(e) => this.handleAttrChange(e)}
                  name="reading"
                  input={{}}
                  type="number"
                  meta={{ invalid: false }}
                  defaultValue={this.state.attr.reading || ""}
                />
              )}

              <Input
                multiline
                label="Descrição"
                onChange={(e) => this.handleChange(e)}
                name="details"
                input={{}}
                type="multiline"
                meta={{ invalid: false }}
                defaultValue={this.state.details}
                rows="8"
              />
            </OptionWrap>
          </Col>

          <Col xs={12} md={3}>
            <SectionTitle>Opções</SectionTitle>
            <OptionWrap>
              <Input
                multiline
                label="Utilizador"
                onChange={(e) => this.handleAttrChange(e)}
                name="cost_user"
                input={{}}
                type="text"
                meta={{ invalid: false }}
                defaultValue={
                  this.state.attr && this.state.attr.cost_user
                    ? this.state.attr.cost_user
                    : ""
                }
              />
              <Calendar
                id="date"
                name="date"
                input={{}}
                label=""
                meta={{ invalid: false }}
                type="datetime-local"
                onChange={(e) => this.handleChange(e)}
                defaultValue={this.state.date}
              />
              {this.props.isAdmin && (
                <FormControlLabel
                  control={
                    <Toggle
                      onChange={this.handleAttrChange}
                      value="true"
                      checked={this.state.attr.include}
                      label="Report"
                      // disabled={!data.edit}
                      name="include"
                      color="primary"
                      meta={{ invalid: false }}
                    />
                  }
                  label="Report"
                />
              )}
              {this.props.status !== "saving" && (
                <NextButton
                  color="primary"
                  onClick={() => this.handleSaveInspection()}
                >
                  Salvar
                  <MenuIcon className={`icon-check icons`} />
                </NextButton>
              )}
            </OptionWrap>

            {this.props.role === "HC" && (
              <div>
                <SectionTitle>Contactos</SectionTitle>
                <OptionWrap>
                  {this.props.selectedParcelHorta[this.state.selectedHorta] &&
                    this.props.selectedParcelHorta[
                      this.state.selectedHorta
                    ].map((contact, i) => {
                      return (
                        <Contacts key={i}>
                          <div>
                            <strong>Nome</strong> {contact.Contacto}
                          </div>
                          <div>
                            <strong>Email</strong> {contact.Email}
                          </div>
                          <div>
                            <strong>Telefone</strong> {contact.Telefone}
                          </div>
                          <div>
                            <strong>Morada</strong> {contact.Morada}
                          </div>
                          <div>
                            <strong>ID PHC</strong> {contact.Horta}
                          </div>
                        </Contacts>
                      );
                    })}
                </OptionWrap>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={9}>
            <SectionTitle>Fotos</SectionTitle>
            <Files
              config={config}
              eventHandlers={eventHandlers}
              djsConfig={djsConfig}
            />
          </Col>
        </Row>
      </Section>
    );
  }
}

export default connect(
  (state) => {
    return {
      type: state.inspections.type,
      status: state.inspections.status,
      details: state.inspections.details,
      selected: state.app.search.selected,
      photos: state.inspections.photos,
      token: state.user.data.token,
      damagesList: state.inspections.damages,
      isAdmin: state.user.data.admin,
      role: state.user.data.role,
      selected: state.app.search.selected,
      list: state.parklist.list,
      selectedParcelHorta: state.georeference.selectedParcelHorta,
      elementsGeoJson: state.app.elementsGeoJson,
    };
  },
  {
    setDetails,
    setInspectionEV,
    getDamages,
    setPhotos,
    getHortasParcel,
  }
)(DetailsSpecific);
