import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { reducer as form } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import dashboard from '../containers/Dashboard/Reducer';
import user from '../containers/User/Reducer';
import app from '../containers/App/Reducer';
import inspections from '../containers/Inspections/Reducer';
import maintenances from '../containers/Maintenances/Reducer';
import calendar from '../containers/Calendar/Reducer';
import parklist from '../containers/ParkList/Reducer';
import tasks from '../containers/Tasks/Reducer';
import georeference from '../containers/Georeference/Reducer';
import reports from '../containers/Reports/Reducer';

const rootReducer = combineReducers({
  loadingBar: loadingBarReducer,
  user,
  form,
  app,
  dashboard,
  inspections,
  maintenances,
  calendar,
  parklist,
  tasks,
  georeference,
  toastr: toastrReducer,
  reports,
});

export default rootReducer;
