import React, { Component } from "react";
import PropTypes from "prop-types";

import { InputLabel, Input, FormControl } from "@material-ui/core";
import styled from "styled-components";
const FormWrapper = styled.div`
  && {
    label {
    }
    > div {
      textarea {
      }
      label[data-shrink="true"] {
        color: #4da1ff;
      }
      > div {
        &:after {
          border-bottom: 2px solid #4da1ff !important;
        }
        &:hover {
          &:before {
            border-bottom: 2px solid #4da1ff !important;
          }
        }
      }
    }
  }
`;

export default class TextFieldForm extends Component {
  static propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: "",
  };

  render() {
    const { label, className, input, meta, ...custom } = this.props;

    return (
      <FormWrapper>
        <FormControl className={className}>
          <InputLabel
            className={className}
            error={meta?.invalid || false}
            htmlFor={label}
          >
            {label}
          </InputLabel>
          <Input
            className={className}
            id={label}
            error={meta?.invalid || false}
            {...input}
            {...custom}
          />
        </FormControl>
      </FormWrapper>
    );
  }
}
