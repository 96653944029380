import React from "react";
import styled from "styled-components";
import MainButton from "./MainButton";
const LineButtonStyle = styled(MainButton)`
  && {
    color: #4da1ff;
    text-transform: capitalize;
    background: transparent;
    border: 1px solid #4da1ff;
    display: inline-block;
    margin: 0.2em;
    text-align: right;
    ${(props) =>
      props.active ? `background: #4da1ff;span,i{color:white;}` : ``}

    .icons {
      color: #4da1ff;
    }
  }
`;
class LineButton extends React.Component {
  render() {
    return (
      <LineButtonStyle {...this.props}>{this.props.children}</LineButtonStyle>
    );
  }
}

export default LineButton;
