import React from 'react';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';
import { connect } from 'react-redux';

const StyledPaper = styled(Paper)`
  && {
    background: #ffffff;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.06);
    height: 100%;
    border: none;
    z-index: 2;
    width: 320px;
    position: fixed;
    border-radius: 0;
    @media (max-width: 768px) {
      position: relative;
      width: 100%;
      display: none;
      display: ${props => (props.activeview ? 'block' : 'none')};
    }
    @media (max-width: 900px) {
      height: calc(100vh - 65px);
    }
    @media (max-width: 425px) {
      height: calc(100vh - 100px);
    }
    @media print {
      display: none;
    }
  }
`;

const ContentWrap = styled.div`
  && {
    padding: 2em 1em 0 2em;
    @media (max-width: 425px) {
      padding: 1em 1em 0 2em;
      height: 90%;
    }
  }
`;

const TitleWrap = styled.div`
  && {
    display: flex;
    margin-bottom: 2em;
    @media (max-width: 425px) {
      margin-bottom: 0;
    }
  }
`;

const Icon = styled.div`
  && {
    width: 22%;
    max-width: 69px;
    margin-right: 8%;
    background: #4da1ff;
    box-shadow: 1px 2px 17px 0 rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    @media (max-width: 425px) {
      display: none;
    }
  }
`;

const Label = styled.div`
  && {
    width: 70%;
  }
`;

const Title = styled.h3`
  && {
    font-size: 1.2em;
    margin: 0;
    padding: 0;
    font-weight: lighter;
    color: #4a4a4a;
    letter-spacing: 0;
  }
`;

const SubTitle = styled.div`
  && {
    font-size: 0.9em;
    margin-top: 0.5em;
    color: #989898;
    font-weight: lighter;
    line-height: 1;
    word-break: break-all;
    span {
      display: block;
      opacity: 0.8;
      font-size: 0.8em;
      margin-top: 0.5em;
    }
  }
`;

const MenuIcon = styled.i`
  && {
    width: 100%;
    display: block;
    font-size: 1.3em;
    text-align: center;
    color: #fff;
    padding: 0.8em 0;
  }
`;

class Sidebar extends React.Component {
  render() {
    const { title, text, icon } = this.props;
    return (
      <StyledPaper
        className={this.props.noanimation ? '' : 'animated fadeInLeft'}
        activeview={
          this.props.activeMobileView === 'filter' ? 'true' : undefined
        }
      >
        <ContentWrap>
          <TitleWrap>
            {icon && (
              <Icon>
                <MenuIcon className={`icon-${icon} icons`} />
              </Icon>
            )}
            <Label>
              <Title>{title}</Title>
              <SubTitle
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            </Label>
          </TitleWrap>

          {this.props.children}
        </ContentWrap>
      </StyledPaper>
    );
  }
}

export default connect(
  state => {
    return {
      activeMobileView: state.app.activeMobileView,
    };
  },
  {}
)(Sidebar);
