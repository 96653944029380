export const USER_LOGGING_IN = 'USER_LOGGING_IN';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const USER_LOGGED_IN_ERROR = 'USER_LOGGED_IN_ERROR';
export const USER_POSITION = 'USER_POSITION';
export const DONE_REFRESHING_TOKEN_SUCCESS = 'DONE_REFRESHING_TOKEN_SUCCESS';
export const REFRESHING_TOKEN_REQUEST = 'REFRESHING_TOKEN_REQUEST';
export const TOKEN_UPDATED = 'TOKEN_UPDATED';
export const FORM_NAME = 'login';
export const CENTERED = 'map/CENTERED';
export const CENTER = 'map/CENTER';
export const REQUIRED_FIELDS = ['email', 'password'];

export const UPDATE_SEARCH = 'UPDATE_USERS_SEARCH';
export const GETTING = 'USERS_GETTING';
export const GETTING_TEAMS = 'TEAMS_GETTING';
export const RECEIVED = 'USERS_RECEIVED';
export const RECEIVED_TEAMS = 'TEAMS_RECEIVED';
export const RECEIVED_USER = 'RECEIVED_USER';
export const DONE = 'DONE';

export const SET_USER_INIT_DATA = 'SET_USER_INIT_DATA';
export const SET_TEAM_INIT_DATA = 'SET_TEAM_INIT_DATA';

export const ERROR = 'USERS_ERROR';
export const ERROR_TEAMS = 'TEAMS_ERROR';
export const UPDATED = 'USERS_UPDATED';
export const SAVING = 'USERS_SAVING';
export const SAVED = 'USERS_SAVED';
export const RESETUSER = 'RESETUSER';
export const SET_DETAILS = 'USER_SET_DETAILS';
