import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
// import ChipInput from 'material-ui-chip-input';
import { Row, Col } from "react-flexbox-grid";
import DropzoneComponent from "react-dropzone-component";

import MainButton from "../../../components/Buttons/MainButton";
import ContentWrap from "../../../components/Layout/ContentWrap";
import TextField from "../../../components/form-wrappers/TextField";
import SectionTitle from "../../../components/Text/SectionTitle";
import MenuIcon from "../../../components/Buttons/MenuIcon";
import { apiAddress } from "../../../config/general";

import { setDetails, setComment, getInterventions } from "../actions";

const PrintWrap = styled.div`
  && {
    @media print {
      display: none;
    }
  }
`;
const NextButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    width: 100%;
  }
`;

const FinishButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    width: 100%;
    margin-top: 1em;
    background: #6fc44c;
    border: 1px solid #6fc44c;
  }
`;

const OptionWrap = styled(ContentWrap)`
  && {
    padding: 2em;
    text-align: center;
  }
`;

const Input = styled(TextField)`
  && {
    width: 100%;
    padding: 10px 0;
    margin: 1em 0;
    text-align: left;
    textarea {
      height: auto;
    }
  }
`;

const Files = styled(DropzoneComponent)`
  && {
    background-color: rgba(74, 144, 226, 0.1);
    border: 2px dashed rgba(74, 144, 226, 1);
    color: #4990e2;
    padding: 1em 1em 2em 1em;
    margin: 1em 0 1.5em;
    .dz-message {
      margin: 0;
    }
    .dz-preview.dz-image-preview {
      background: transparent;
    }
    .filepicker-file-icon {
      color: #4990e2;
      &::after {
        background-color: #4990e2;
      }
      &::before {
        border: solid 2px #4990e2;
      }
    }
  }
`;

class AddComment extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      date: this.getCurrentDate(),
      details: "",
      type: "comentario",
      interventions: [],
      files: props.files || [],
      group: props.group || [],
    };

    // For a full list of possible configurations,
    // please consult http://www.dropzonejs.com/#configuration
    this.djsConfig = {
      acceptedFiles: "image/*, application/*",
      addRemoveLinks: true,
      resizeWidth: 1100,
      resizeHeight: 1100,
      resizeQuality: 0.6,
      headers: { Authorization: "Bearer " + props.token },
      params: {
        fid: props.selectedPark, //should be the park id
      },
    };

    this.componentConfig = {
      iconFiletypes: ["docs", "images"],
      showFiletypeIcon: true,
      postUrl: `${apiAddress}api/v1/files`,
    };

    this.callback = this.callback.bind(this);
    this.removedfile = this.removedfile.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeIntervention = this.handleChangeIntervention.bind(this);
    this.handleSaveMaintenance = this.handleSaveMaintenance.bind(this);
    this.handleFinishMaintenance = this.handleFinishMaintenance.bind(this);

    props.getInterventions();
  }

  // Simple callbacks work too, of course
  callback(file) {
    const fileData = JSON.parse(file.xhr.response);
    const files = [
      ...this.state.files,
      {
        path: fileData.msg.details.path,
        name: fileData.msg.details.originalname,
        size: fileData.msg.details.size,
        id: fileData.msg.id,
      },
    ];
    this.setState({ files: files });
  }

  // Simple callbacks work too, of course
  removedfile(file) {
    //need to delete images
    //need to delete images
    const updatedFiles = this.state.files.filter(
      (photo) => photo.name !== file.upload.filename
    );

    this.setState({
      files: updatedFiles,
    });
  }

  getCurrentDate() {
    const now = new Date();
    const addZero = (i) => (i < 10 ? "0" + i : i);
    return `${now.getFullYear()}-${addZero(now.getMonth() + 1)}-${addZero(
      now.getDate()
    )}T${addZero(now.getHours())}:${addZero(now.getMinutes())}`;
  }

  getInterventionsList() {
    return this.props.interventionsList || [];
  }

  handleChange(e, value) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeIntervention(value) {
    this.setState({ interventions: value });
  }

  handleFinishMaintenance() {
    this.props.setDetails({
      ...this.state,
      status: this.props.isAdmin ? 1 : 0,
    });
    this.props.setComment(this.props.selectedParkId); //this should not be necessary remove when using the correct table
    this.setState({
      date: this.getCurrentDate(),
      details: "",
      interventions: [],
      files: [],
    });
  }

  handleSaveMaintenance() {
    this.props.setDetails(this.state);
    this.props.setComment(this.props.selectedParkId); //this should not be necessary remove when using the correct table
    this.setState({
      date: this.getCurrentDate(),
      details: "",
      interventions: [],
      files: [],
    });
  }

  render() {
    const config = this.componentConfig;
    const djsConfig = this.djsConfig;

    // For a list of all possible events (there are many), see README.md!
    const eventHandlers = {
      removedfile: this.removedfile,
      success: this.callback,
    };
    if (
      this.props.userWrite !== "all" &&
      this.props.userWrite !== "interventions"
    ) {
      return null;
    }

    return (
      <PrintWrap>
        <Row>
          <Col xs={12} md={9}>
            <SectionTitle>Adicionar Comentario</SectionTitle>
            <OptionWrap>
              <Input
                multiline
                label="Descrição"
                onChange={(e) => this.handleChange(e)}
                name="details"
                input={{}}
                type="multiline"
                meta={{ invalid: false }}
                rows="8"
              />
            </OptionWrap>
          </Col>

          <Col xs={12} md={3}>
            <SectionTitle>Opções</SectionTitle>
            <OptionWrap>
              <Files
                config={config}
                eventHandlers={eventHandlers}
                djsConfig={djsConfig}
              />
              {this.props.status !== "saving" && (
                <div>
                  <NextButton
                    color="primary"
                    onClick={() => this.handleSaveMaintenance()}
                  >
                    Comentar
                    <MenuIcon className={`icon-check icons`} />
                  </NextButton>
                  {/* {this.props.isAdmin && (
                    <FinishButton
                      onClick={() => this.handleFinishMaintenance()}
                    >
                      Agendar
                      <MenuIcon className={`icon-like icons`} />
                    </FinishButton>
                  )} */}
                  {!this.props.isAdmin && (
                    <FinishButton
                      onClick={() => this.handleFinishMaintenance()}
                    >
                      Submeter
                      <MenuIcon className={`icon-like icons`} />
                    </FinishButton>
                  )}
                </div>
              )}
            </OptionWrap>
          </Col>
        </Row>
      </PrintWrap>
    );
  }
}

export default connect(
  (state) => {
    return {
      type: state.maintenances.type,
      status: state.maintenances.status,
      photos: state.maintenances.photos,
      interventionsList: state.maintenances.interventions,
      token: state.user.data.token,
      isAdmin: state.user.data.admin,
      userWrite: state.user.data.write,
    };
  },
  { setDetails, setComment, getInterventions }
)(AddComment);
