import * as constants from "./constants";
import axios from "axios";
import { store } from "../../store/configure";
import { toastr } from "react-redux-toastr";

export function setActiveType(type) {
  return {
    type: constants.SET_TYPE,
    data: type,
  };
}

export function getParkDayReport(pid, date) {
  return (dispatch) => {
    dispatch({
      type: constants.GETTING_PARK_REPORT,
    });

    const search = store.getState().app.search;

    let parks = "all";
    // if (role === 'PI') what = search.active || 'all';
    if (search.selected.length > 0) parks = search.selected || "all";

    const url = [
      `api/v1/reports/dayreport`,
      encodeURI("all"),
      encodeURI(pid || parks),
      encodeURI(date || search.startDate),
      encodeURI(date || search.endDate),
    ];

    axios
      .get(url.join("/"))
      .then(function(response) {
        if (response.data.msg && response.data.msg.data) {
          dispatch({
            type: constants.RECEIVED_PARK_REPORT,
            data: response.data.msg.data,
          });
        } else {
          // toastr.error('Ocorreu um erro a obter as inspecções');

          dispatch({
            type: constants.ERROR_PARK_REPORT,
          });
        }
      })
      .catch(function(response) {
        // toastr.error('Ocorreu um erro a obter as inspecções');

        dispatch({
          type: constants.ERROR_PARK_REPORT,
        });
      });
  };
}

export function getMonthReport(local, date) {
  return (dispatch) => {
    dispatch({
      type: constants.GET_MONTH_REPORT,
    });

    axios
      .get(`api/v1/reports/freguesia/${encodeURI(local)}/${encodeURI(date)}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.RECEIVED_MONTH_REPORT,
            data: response.data.msg,
          });
        } else {
          // toastr.error('Ocorreu um erro a obter as inspecções');

          dispatch({
            type: constants.ERROR_MONTH_REPORT,
          });
        }
      })
      .catch(function(response) {
        // toastr.error('Ocorreu um erro a obter as inspecções');

        dispatch({
          type: constants.ERROR_MONTH_REPORT,
        });
      });
  };
}

export function submitTeamReports(freguesia, team) {
  return (dispatch) => {
    dispatch({
      type: constants.SAVING_REPORT,
    });

    const search = store.getState().app.search;
    // const month = new Date(search.startDate).getMonth() + 1;
    const payload = {
      date: encodeURI(search.startDate),
      freguesia: encodeURI(search.freguesia || freguesia),
      team: encodeURI(team),
    };

    axios
      .post(`api/v1/reports`, payload)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.SAVED_REPORT,
            data: response.data.msg,
          });
          toastr.success(
            `Relatorio para ${search.freguesia} enviado com sucesso`
          );
        } else {
          toastr.error(
            "Ocorreu um erro a salvar o relatorio: " + response.data.nok
          );

          dispatch({
            type: constants.ERROR_REPORT,
          });
        }
      })
      .catch(function(err) {
        console.log(err);
        toastr.error("Ocorreu um erro a salvar o relatorio");

        dispatch({
          type: constants.ERROR_REPORT,
        });
      });
  };
}
export function getFreguesiasReports() {
  return (dispatch) => {
    dispatch({
      type: constants.GETTING,
    });

    const parks = store.getState().parklist.location;
    const parkFreguesias = Object.keys(parks);

    axios
      .get(`api/v1/reports`)
      .then(function(response) {
        if (response.data.msg) {
          const payload = response.data.msg || [];

          console.log(payload);
          dispatch({
            type: constants.RECEIVED_FREGUESIAS_REPORT,
            data: payload.filter((data) =>
              parkFreguesias.includes(data.freguesia)
            ),
          });
        } else {
          toastr.error("Ocorreu um erro a obter os relatorios");

          dispatch({
            type: constants.ERROR,
          });
        }
      })
      .catch(function(response) {
        toastr.error("Ocorreu um erro a obter os relatorios");

        dispatch({
          type: constants.ERROR,
        });
      });
  };
}
export function getReports(type, team) {
  return (dispatch) => {
    dispatch({
      type: constants.GETTING,
    });

    const search = store.getState().app.search;

    let fetchType = type;
    if (type === "readings") {
      fetchType = "inspections";
    }
    if (type === "rega") {
      fetchType = "inspections";
    }
    let parks = "all";
    // if (role === 'PI') what = search.active || 'all';
    if (search.selected.length > 0) parks = search.selected || "all";
    const url = [
      `api/v1/reports`,
      encodeURI(team || "all"),
      encodeURI(fetchType || "all"),
      encodeURI(search.freguesia || "all"),
      encodeURI(search.local || "all"),
      encodeURI("all"),
      encodeURI(search.startDate),
      encodeURI(search.endDate),
      encodeURI(search.status),
    ];

    axios
      .get(url.join("/"))
      .then(function(response) {
        if (response.data.msg && response.data.msg.data) {
          dispatch({
            type: constants.RECEIVED,
            data: { list: response.data.msg.data, type },
          });
        } else {
          // toastr.error('Ocorreu um erro a obter as inspecções');

          dispatch({
            type: constants.ERROR,
          });
        }
      })
      .catch(function(response) {
        // toastr.error('Ocorreu um erro a obter as inspecções');

        dispatch({
          type: constants.ERROR,
        });
      });
  };
}
