import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Row, Col } from "react-flexbox-grid";
import { Tooltip } from "@material-ui/core";

import SectionTitle from "../../components/Text/SectionTitle";
import MainButton from "../../components/Buttons/MainButton";
import MenuIcon from "../../components/Buttons/MenuIcon";
import ViewReport from "../Reports/ViewReport";

import FullScreenModal from "../../components/Layout/FullScreenModal";
import PageTitle from "../../components/Text/PageTitle";
import ContentWrap from "../../components/Layout/ContentWrap";
import Edit from "./Edit";
import AddComment from "./steps/AddComment";

import {
  getInspectionsHistory,
  getInspectionsGroup,
  getInspection,
  addNewToggle,
  setHistory,
  setInspectionDone,
  getInspections,
} from "./actions";
import { setSearch } from "../App/actions";

const OptionWrap = styled(ContentWrap)`
  && {
    padding: 2em;
    text-align: center;
  }
`;
const Section = styled.div`
  && {
    margin: 2em auto;
    max-width: 1400px;
  }
`;

const FinishButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    width: 100%;
    margin-top: 1em;
    background: #6fc44c;
    border: 1px solid #6fc44c;
    @media print {
      display: none;
    }
  }
`;

const Close = styled.span`
  && {
    font-size: 2.5em;
    margin: 0 0.5em 0 0;
    text-align: center;
    color: #989898;
    vertical-align: middle;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 11;
    cursor: pointer;
    :hover {
      color: #4da1ff;
      transform: rotate(90deg);
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
    @media print {
      display: none;
    }
    @media (max-width: 425px) {
      font-size: 2em;
    }
  }
`;
const PrintButton = styled.span`
  && {
    font-size: 1.3em;
    margin: 0 0.5em 0 0;
    text-align: center;
    color: #989898;
    vertical-align: middle;
    position: absolute;
    right: 77px;
    top: 20px;
    z-index: 11;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid;
    padding: 0.4em;
    :hover {
      color: #4da1ff;
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
    @media print {
      display: none;
    }
    @media (max-width: 425px) {
      font-size: 1em;
      right: 76px;
      margin: 0;
    }
  }
`;

const DailyReportButton = styled.span`
  && {
    font-size: 1.3em;
    margin: 0 0.5em 0 0;
    text-align: center;
    color: #989898;
    vertical-align: middle;
    position: absolute;
    right: 126px;
    top: 20px;
    z-index: 11;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid;
    padding: 0.4em;
    :hover {
      color: #4da1ff;
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
    @media print {
      display: none;
    }
    @media (max-width: 425px) {
      font-size: 1em;
      right: 117px;
      margin: 0;
    }
  }
`;

const LocationTitle = styled.span`
  && {
    font-size: 0.8em;
    display: block;
  }
`;

class View extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      group: props.group,
      viewDailyReport: null,
    };
    this.addNewInscption = this.addNewInscption.bind(this);
    this.generatePDF = this.generatePDF.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleFinishInspection = this.handleFinishInspection.bind(this);
    this.viewEventReport = this.viewEventReport.bind(this);
    this.closeViewDailyReport = this.closeViewDailyReport.bind(this);

    props.getInspectionsGroup(this.state.group);
  }

  closeModal() {
    if (this.props.status === "saved")
      this.props.getInspections(this.state.group);
    if (typeof this.props.onClose === "function") return this.props.onClose();
    this.props.setSearch({
      element: "edit",
      value: null,
    });
    this.props.history.push(`/${this.props.callback || "inspections"}`);
  }

  addNewInscption(group, pid = "") {
    this.props.setHistory(group, pid);
    this.props.addNewToggle();
  }

  handleFinishInspection() {
    this.props.setInspectionDone(this.state.group);
  }

  generatePDF() {
    // this.setState({ print: true });
    window && window.print();
  }

  closeViewDailyReport() {
    this.setState({ viewDailyReport: null });
  }

  viewEventReport(park) {
    const data = this.props.historyData;
    let date = null;
    if (data && data.length > 0) {
      date = data[0].date;
    }
    this.setState({ viewDailyReport: { ...park, date } });
  }

  uniq(a) {
    return Array.from(new Set(a || []));
  }

  render() {
    const { parks, local, role, historyData, historyGroup } = this.props;

    let data = historyData.length === 0 ? historyGroup : historyData;
    let selected = data.length > 0 ? data[0].pid : "";
    if (data.length === 0) return null;

    const isApproved = data && data.length > 0 && data[0].status === 1;
    if (!data[0].pid && data[0].attr && data[0].attr.relation) {
      selected = data[0].attr.relation;
    }

    let selectedPark = parks.filter((park) => {
      if (selected instanceof Array) {
        return selected.includes(park.numero);
      } else {
        return selected === park.numero;
      }
    });

    let parkName = `${this.uniq(selectedPark.map((p) => p.local)).join(
      ", "
    )}, ${this.uniq(selectedPark.map((p) => p.nome)).join(", ")}`;

    const thisElementType = this.props.elementsGeoJson.find((eleGJ) => {
      return (
        eleGJ.properties.id === data[0].ElementId ||
        (eleGJ.properties.origin &&
          eleGJ.properties.origin === data[0].ElementId)
      );
    });

    return (
      <div>
        <FullScreenModal>
          <Tooltip title="Fechar">
            <Close
              onClick={() => this.closeModal()}
              className={`icon-close icons`}
            />
          </Tooltip>
          <Tooltip title="Imprimir">
            <PrintButton
              onClick={() => this.generatePDF()}
              className={`icon-printer icons`}
            />
          </Tooltip>
          <Tooltip title="Relatório da Visita">
            <DailyReportButton
              onClick={() => this.viewEventReport(selectedPark)}
              className={`icon-event icons`}
            />
          </Tooltip>
          <Section>
            {data && (
              <div>
                <PageTitle>
                  {parkName}
                  <LocationTitle>
                    {selected &&
                      selected instanceof Array &&
                      selected.join(", ")}
                    {thisElementType &&
                      thisElementType.properties.label &&
                      `${
                        thisElementType.properties.category
                          ? thisElementType.properties.category + ","
                          : ""
                      } ${thisElementType.properties.label}`}
                    {selectedPark && selectedPark.freguesia
                      ? `${selectedPark && selectedPark.freguesia} ,`
                      : ""}
                    {selectedPark && selectedPark.local
                      ? `${selectedPark && selectedPark.local} ,`
                      : ""}
                    {selectedPark && selectedPark.numero
                      ? `${selectedPark && selectedPark.numero}`
                      : ""}

                    {/* {thisElementType &&
                      thisElementType.properties.label &&
                      `${
                        thisElementType.properties.category
                          ? thisElementType.properties.category + ','
                          : ''
                      } ${thisElementType.properties.label}`} */}
                  </LocationTitle>
                </PageTitle>

                {data.map((inspection, index) => (
                  <Edit
                    key={index}
                    data={inspection}
                    closeModal={this.closeModal}
                  />
                ))}
                {this.props.status !== "saving" && (
                  <AddComment
                    selectedParkId={selectedPark && selectedPark.numero} //Temporari untill we use the correct table
                    group={this.state.group}
                    isApproved={isApproved}
                  />
                )}
                {this.props.isAdmin && (
                  <FinishButton onClick={() => this.handleFinishInspection()}>
                    {!isApproved ? "Fechar" : "Reabrir"}

                    <MenuIcon
                      className={`icon-${!isApproved ? "like" : "close"} icons`}
                    />
                  </FinishButton>
                )}
              </div>
            )}
            {!data && (
              <div>
                <PageTitle>Upss...</PageTitle>
                <Row>
                  <Col xs={12} md={9}>
                    <SectionTitle>Detalhes</SectionTitle>
                    <OptionWrap>
                      Não conseguimos encontrar os detalhes para esta inspeção.
                    </OptionWrap>
                  </Col>
                </Row>
              </div>
            )}
          </Section>
        </FullScreenModal>
        {this.state.viewDailyReport && (
          <ViewReport
            args={this.state.viewDailyReport}
            onClose={this.closeViewDailyReport}
          />
        )}
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      type: state.inspections.type,
      activeStep: state.inspections.activeStep,
      historyData: state.inspections.reportHistory,
      historyGroup: state.inspections.historyGroup,
      status: state.inspections.status,
      parks: state.parklist.list,
      isAdmin: state.user.data.admin,
      role: state.user.data.role,
      elementsGeoJson: state.app.elementsGeoJson,
    };
  },
  {
    getInspectionsHistory,
    getInspectionsGroup,
    getInspection,
    addNewToggle,
    setHistory,
    setSearch,
    setInspectionDone,
    getInspections,
  }
)(View);
