import React from "react";
import { connect } from "react-redux";

import ProfileMenu from "../../containers/User/ProfileMenu";

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.search = this.search.bind(this);
    this.state = { isPanelOpened: false };
  }

  search() {
    this.setState({ isPanelOpened: !this.state.isPanelOpened });
  }

  render() {
    return <ProfileMenu />;
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Header);
