import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Row, Col } from "react-flexbox-grid";
import Lightbox from "react-image-lightbox";
import { apiAddress } from "../../config/general";
import moment from "moment";
import DropzoneComponent from "react-dropzone-component";
import Rating from "react-rating";
import { FormControlLabel, Switch } from "@material-ui/core";
import { toastr } from "react-redux-toastr";

import MainButton from "../../components/Buttons/MainButton";
import LineButton from "../../components/Buttons/LineButton";
import ContentWrap from "../../components/Layout/ContentWrap";
import TextField from "../../components/form-wrappers/TextField";
import SectionTitle from "../../components/Text/SectionTitle";
import MenuIcon from "../../components/Buttons/MenuIcon";
import AutoComplete from "../../components/form-wrappers/AutoComplete";
import SistemaDeRegaCF from "./steps/SistemaDeRegaCF";
import DefaultCF from "./steps/DefaultCF";

import { getHortasParcel } from "../Georeference/actions";

import {
  updateInspection,
  getInspections,
  getDamages,
  setMaintenance,
  deleteInspection,
} from "./actions";

import PageTitle from "../../components/Text/PageTitle";

const OptionWrap = styled(ContentWrap)`
  && {
    padding: 2em;
    text-align: center;
  }
`;
const SideOptionWrap = styled(OptionWrap)`
  && {
    padding: 1em;
    @media screen and (max-width: 1183px) and (min-width: 766px) {
      font-size: 0.7em;
    }
    text-align: left;
    button {
      margin: 0 0 0.5em 0;
    }
  }
`;

const Contacts = styled.div`
  && {
    div {
      word-break: break-word;
      margin: 1em 0;
      color: #4da1ff;
      font-size: 1em;
      strong {
        color: rgba(0, 0, 0, 0.87);
        font-weight: normal;
        display: block;
      }
    }
  }
`;
const Toggle = styled(Switch)`
  && {
    .MuiSwitch-bar-192,
    .jss192 {
      color: #4da1ff;
      background: #4da1ff;
    }
    .MuiSwitch-iconChecked-194,
    .jss194 {
      color: #4da1ff;
    }
  }
`;
const RatingWrap = styled.div`
  && {
    margin: 2em 0 0;
    text-align: left;
    label {
      display: block;
      color: rgba(0, 0, 0, 0.6);
      font-size: 0.8em;
      margin-bottom: 0.5em;
    }
    > span {
      > span > span {
        margin: 0 0.2em 0 0;
        > span {
          background-color: #4da1ff !important;
        }
      }
    }
  }
`;
const Files = styled(DropzoneComponent)`
  && {
    background-color: rgba(74, 144, 226, 0.1);
    border: 2px dashed rgba(74, 144, 226, 1);
    color: #4990e2;
    padding: 1em 1em 2em 1em;
    margin: 1em 0 1.5em;
    .dz-message {
      margin: 0;
    }
    .dz-preview.dz-image-preview {
      background: transparent;
    }
    .filepicker-file-icon {
      color: #4990e2;
      &::after {
        background-color: #4990e2;
      }
      &::before {
        border: solid 2px #4990e2;
      }
    }
    .dz-image {
      img {
        width: 120px;
        height: 120px;
      }
    }
  }
`;
const Input = styled(TextField)`
  && {
    width: 100%;
    padding: 10px 0;
    margin: 1em 0;
    text-align: left;
    color: #000;
    label {
      color: #919191;
      margin: 0;
    }

    textarea {
      height: auto;
    }

    input:disabled,
    textarea:disabled {
      color: #4da1ff;
    }
    div {
      margin: 0;
    }
    @media print {
      margin: 0.5em 0;
    }
  }
`;
const ImageContainer = styled.div`
  && {
    text-align: left;
  }
`;

const ElementType = styled.span`
  && {
    font-weight: bold;
    font-size: 1.5em;
  }
`;

const Image = styled.div`
  && {
    width: 200px;
    height: 130px;
    margin: 0 0.5em 0.5em 0;
    display: inline-block;
    border-radius: 0.5em;
    background-size: cover;
    cursor: pointer;
  }
`;

const Calendar = styled(Input)`
  && {
    margin: 0;
  }
`;

const SaveButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    margin: 1em 0;
    display: inline-block;
    text-align: right;
  }
`;

const InspectionWrap = styled.div`
  && {
    margin: 4em 0;
    .col-md-3 {
      @media print {
        display: none;
      }
    }
  }
`;

const HalfRate = styled.div`
  && {
    display: inline-block;
    width: 30%;
    margin-right: 2%;
    @media (max-width: 900px) {
      width: 45%;
    }
    @media (max-width: 425px) {
      width: 100%;
    }
  }
`;

const DisableButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    margin-top: 1em;
    background: #df5757;
    border-color: #f44336;
    &:hover {
      color: #df5757;
      .icons {
        color: #df5757;
      }
    }
  }
`;
const ImageWrapper = styled.span`
  && {
    position: relative;
  }
`;
const RemoveItem = styled.span`
  && {
    font-size: 2em;
    margin: 0 0.5em 0 0;
    text-align: center;
    background: #fff;
    position: absolute;
    z-index: 11;
    cursor: pointer;
    right: 6px;
    margin-top: 6px;
    border-radius: 1em;

    color: #f44336;
    vertical-align: middle;
    cursor: pointer;
    :hover {
      color: #4da1ff;
      transform: rotate(90deg);
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
    @media print {
      display: none;
    }
    @media (max-width: 425px) {
      font-size: 2em;
    }
  }
`;
class Edit extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      edit: false,
      selectedHorta: "",
      damages: [],
      photoIndex: 0,
      isOpen: false,
      hiddenPhotos: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.callback = this.callback.bind(this);
    this.removedfile = this.removedfile.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.delete = this.delete.bind(this);
    this.handleChangeAttribute = this.handleChangeAttribute.bind(this);
    this.handleChangeDamages = this.handleChangeDamages.bind(this);
    this.handleAttrChange = this.handleAttrChange.bind(this);
    this.serverURL = apiAddress;
    this.handleAttrRating = this.handleAttrRating.bind(this);
    this.handleAttrCustomFieldsRating = this.handleAttrCustomFieldsRating.bind(
      this
    );
    // const t = this;
    // setTimeout(() => {
    //   t.setState({ hiddenPhotos: false });
    // }, 0);
    this.djsConfig = {
      acceptedFiles: "image/*, application/*",
      addRemoveLinks: true,
      resizeWidth: 1100,
      resizeHeight: 1100,
      resizeQuality: 0.6,
      headers: { Authorization: "Bearer " + props.token },
      params: {
        fid: props.selectedParkId, //should be the park id
      },
    };

    this.componentConfig = {
      iconFiletypes: ["docs", "images"],
      showFiletypeIcon: true,
      postUrl: `${apiAddress}api/v1/files`,
    };

    this.inspectionCF = {
      EV: [
        { name: "Geral", comment: "" },
        { name: "Limpeza", comment: "" },
        { name: "Relvado/Prado", comment: "" },
        { name: "Herbáceas", comment: "" },
        { name: "Arbustos", comment: "" },
        { name: "Árvores", comment: "" },
        { name: "Canteiro", comment: "" },
        // { name: "Mobiliário Urbano", comment: "" },
        // { name: "Pavimento", comment: "" },
        // { name: "Sistema de Rega", comment: "" },
      ],
    };
  }

  componentDidMount() {
    if (this.props.role === "HC") this.fetchExtraParcelData();
  }

  fetchExtraParcelData() {
    //now we need to fetch contact data
    try {
      const selectedPark = this.props.list.find(
        (park) => park.numero === this.props.data.pid
      );
      if (
        !this.props.data.Element ||
        !this.props.data.Element.ElementType ||
        !this.props.data.Element.ElementType.label
      )
        throw "no label";

      let number = parseInt(
        this.props.data.Element.ElementType.label.match(/\d+/g)
      );
      if (!number) throw "no number";

      number = number < 10 ? `0${number}` : number;

      if (!selectedPark || !selectedPark["número_ant"]) throw "no name";
      this.setState({
        selectedHorta: `${selectedPark["número_ant"]}.${number}`,
      });
      this.props.getHortasParcel(`${selectedPark["número_ant"]}.${number}`);
    } catch (error) {
      console.log(error);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.status === "edited") {
      this.props.getInspections();
    }
    if (this.props.status === "saved" || this.props.status === "deleted") {
      this.props.closeModal();
    }
  }

  getDate(date) {
    const now = new Date(date);
    const addZero = (i) => (i < 10 ? "0" + i : i);
    return `${now.getFullYear()}-${addZero(now.getMonth() + 1)}-${addZero(
      now.getDate()
    )}T${addZero(now.getHours())}:${addZero(now.getMinutes())}`;
  }

  handleChangeAttribute(e, value) {
    this.setState({
      data: {
        ...this.state.data,
        attr: {
          ...this.state.data.attr,
          [e.target.name]: !this.state.data.attr[e.target.name],
        },
      },
    });
  }

  handleAttrCustomFieldsRating(value, index) {
    this.setState({
      data: {
        ...this.state.data,
        attr: {
          ...this.state.data.attr,
          customFields: {
            ...this.state.data.attr.customFields,
            [index]: value,
          },
        },
      },
    });
  }

  toggleEdit() {
    if (!this.state.edit) this.props.getDamages();
    this.setState({ edit: !this.state.edit });
  }

  removedfile(e) {
    let foundId = null;
    this.state.data.photos.map((files, index) => {
      if (files.name === e.upload.filename) {
        foundId = index;
      }
    });
    if (foundId) this.deleteImage(foundId);
  }

  deleteImage(photoIndex) {
    this.setState({
      data: {
        ...this.state.data,
        photos: this.state.data.photos.filter((photo, i) => i !== photoIndex),
      },
    });
  }

  // Simple callbacks work too, of course
  callback(file) {
    const fileData = JSON.parse(file.xhr.response);
    const photos = [
      ...this.state.data.photos,
      {
        path: fileData.msg.details.path,
        name: fileData.msg.details.originalname,
        size: fileData.msg.details.size,
        id: fileData.msg.id,
      },
    ];
    this.setState({
      data: {
        ...this.state.data,
        photos,
      },
    });
  }

  delete() {
    const toastrConfirmOptions = {
      onOk: () => this.props.deleteInspection({ ...this.state.data }),
      onCancel: () => console.log("CANCEL: clicked"),
    };
    toastr.confirm(
      "Tem a certeza? Esta acção não pode ser revertida",
      toastrConfirmOptions
    );
  }

  handleChange(e, value) {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  }
  handleAttrChange(e, value) {
    let valueFinal = e.target.value;
    // for toggle
    if (e.target.name === "include") {
      valueFinal = !this.state.data.attr[e.target.name];
    }

    this.setState({
      data: {
        ...this.state.data,
        attr: { ...this.state.data.attr, [e.target.name]: valueFinal },
      },
    });
  }
  handleAttrRating(value) {
    this.setState({
      data: {
        ...this.state.data,
        attr: { ...this.state.data.attr, rating: value },
      },
    });
  }
  handleChangeDamages(value) {
    this.setState({ damages: value });
  }

  handleSaveInspection() {
    this.toggleEdit();
    this.props.updateInspection({
      ...this.state.data,
      damages: this.state.damages,
    });
  }
  closeModal() {
    this.props.history.push(`/inspections`);
  }

  renderPhotos(photos) {
    const config = this.componentConfig;
    const djsConfig = this.djsConfig;

    const self = this;

    /// not bound to this is moved out of render
    const eventHandlers = {
      init: (dropzone) => {
        dropzone.autoDiscover = false;
      },
      removedfile: self.removedfile,
      error: (e) => {
        console.log(e);
      },
      success: self.callback,
    };

    return (
      <div>
        {photos && photos.length > 0 && (
          <div>
            <SectionTitle>Fotos</SectionTitle>
            <ImageContainer>
              {photos &&
                photos.map((photo, index) => {
                  return (
                    <ImageWrapper key={index}>
                      <Image
                        onClick={() =>
                          this.setState({
                            isOpen: true,
                            photoIndex: index,
                          })
                        }
                        style={{
                          backgroundImage: `url('${this.serverURL}${photo.path}')`,
                        }}
                      />

                      {this.state.edit && (
                        <RemoveItem
                          onClick={() => this.deleteImage(index)}
                          className={`icon-close icons`}
                        />
                      )}
                    </ImageWrapper>
                  );
                })}
            </ImageContainer>
          </div>
        )}

        {this.state.edit && (
          <Files
            config={config}
            eventHandlers={eventHandlers}
            djsConfig={djsConfig}
          />
        )}
      </div>
    );
  }

  render() {
    const { photoIndex, isOpen, data } = this.state;
    const images = data.photos;
    let color;
    switch (data.type) {
      case "Danos":
        color = "#eb3d3d";
        break;
      case "Inicial":
        color = "#ebcd3d";
        break;
      default:
        color = "#6fc44c";
        break;
    }
    const customFields = data.attr ? data.attr.customFields : {};
    const rega = data?.attr?.type === "rega";
    console.log("rega", data?.attr);
    return (
      <InspectionWrap name={data.id}>
        {data && (
          <div>
            <Row>
              <Col xs={12} md={9}>
                <SectionTitle color={color}>
                  <i className={`icon-camera icons`} />{" "}
                  {data.Damage ? data.Damage.label : data.type}{" "}
                  {data.Element && data.Element.ElementType && (
                    <ElementType>
                      {data.Element.ElementType.category
                        ? `${data.Element.ElementType.category} ,`
                        : ""}

                      {data.Element.ElementType.label
                        ? `${data.Element.ElementType.label} ,`
                        : ""}
                    </ElementType>
                  )}
                  <strong>
                    {data.User && (
                      <span>
                        {data.User.name},{" "}
                        {moment(data.date).format("YYYY-MM-DD HH:mm:ss")}
                      </span>
                    )}
                  </strong>
                </SectionTitle>
                {rega && (
                  <SistemaDeRegaCF
                    handleAttrCustomFieldsRating={
                      this.handleAttrCustomFieldsRating
                    }
                    readonly={!this.state.edit}
                    interventionType={this.props.interventionType}
                    attr={data?.attr}
                  />
                )}
                {!rega && (
                  <DefaultCF
                    handleAttrCustomFieldsRating={
                      this.handleAttrCustomFieldsRating
                    }
                    readonly={!this.state.edit}
                    interventionType={data?.attr?.type}
                    attr={data?.attr}
                  />
                )}

                {!rega &&
                  customFields &&
                  Object.keys(customFields).length > 0 &&
                  this.inspectionCF[this.props.role] &&
                  this.inspectionCF[this.props.role].length > 0 && (
                    <div>
                      <SectionTitle>Avaliação geral</SectionTitle>
                      <OptionWrap>
                        {this.inspectionCF[this.props.role].map((cf, index) => (
                          <HalfRate key={index}>
                            <RatingWrap>
                              <label>{cf.name}</label>

                              <Rating
                                onChange={(rate) =>
                                  this.handleAttrCustomFieldsRating(
                                    rate,
                                    cf.name
                                  )
                                }
                                readonly={!this.state.edit}
                                initialRating={customFields[cf.name] || 0}
                                stop={3}
                                fractions={2}
                              />

                              <label
                                className={"comment"}
                                style={{ fontWeight: "bold" }}
                              >
                                {cf.comment}
                              </label>
                            </RatingWrap>
                          </HalfRate>
                        ))}
                      </OptionWrap>
                    </div>
                  )}

                {/* {!rega && customFields && Object.keys(customFields).length > 0 && (
                  <div>
                    <SectionTitle>Avaliações Gerais</SectionTitle>
                    <OptionWrap>
                      {Object.keys(customFields).map((cf, index) => (
                        <HalfRate key={index}>
                          <RatingWrap>
                            <label>{cf}</label>
                            <Rating
                              onChange={(rate) =>
                                this.handleAttrCustomFieldsRating(rate, cf)
                              }
                              readonly={!this.state.edit}
                              initialRating={customFields[cf] || 0}
                              stop={3}
                              fractions={2}
                            />
                            <label className={"comment"}>
                              {customFields[cf].comment}
                            </label>
                          </RatingWrap>
                        </HalfRate>
                      ))}
                    </OptionWrap>
                  </div>
                )} */}
                <OptionWrap>
                  {data.attr && !data.attr.rating && (
                    <AutoComplete
                      updateState={this.handleChangeDamages}
                      options={this.props.damagesList}
                      defaultValue={data.Damage}
                      placeholder={
                        "Pesquise ou adicione um novo tipo de manutenção"
                      }
                      disabled={!this.state.edit}
                    />
                  )}
                  {data.attr && data.attr.reading && (
                    <Input
                      label="Total"
                      onChange={(e) => this.handleAttrChange(e)}
                      name="reading"
                      input={{}}
                      defaultValue={data.attr.reading}
                      meta={{ invalid: false }}
                      disabled={!this.state.edit}
                    />
                  )}

                  {this.props.role !== "PI" && data.attr && data.attr.rating && (
                    <RatingWrap>
                      <label>Avaliação Geral</label>
                      <Rating
                        onChange={(rate) => this.handleAttrRating(rate)}
                        initialRating={data.attr.rating || 0}
                        stop={this.props.role !== "HC" ? 5 : 3}
                        readonly={!this.state.edit}
                      />
                    </RatingWrap>
                  )}

                  <Input
                    multiline
                    label="Descrição"
                    onChange={(e) => this.handleChange(e)}
                    name="details"
                    input={{}}
                    defaultValue={data.details}
                    type="multiline"
                    meta={{ invalid: false }}
                    rows="8"
                    disabled={!this.state.edit}
                  />
                </OptionWrap>

                {(data.attr.cost_name ||
                  data.attr.cost_amount ||
                  data.attr.cost_value) && (
                  <div>
                    <SectionTitle>
                      <span>&nbsp;</span>
                      Custos
                    </SectionTitle>
                    <SideOptionWrap>
                      <Row>
                        <Col xs={12} md={8}>
                          <Input
                            multiline
                            label="Datalhe de custos"
                            onChange={(e) => this.handleAttrChange(e)}
                            name="cost_name"
                            input={{}}
                            disabled={!this.state.edit}
                            type="multiline"
                            rows="4"
                            meta={{ invalid: false }}
                            defaultValue={
                              data.attr && data.attr.cost_name
                                ? data.attr.cost_name
                                : ""
                            }
                          />
                        </Col>

                        {/* <Col xs={12} md={4}>
                          <Input
                            multiline
                            label="Quantidade"
                            onChange={(e) => this.handleAttrChange(e)}
                            name="cost_amount"
                            input={{}}
                            disabled={!this.state.edit}
                            type="text"
                            meta={{ invalid: false }}
                            defaultValue={
                              data.attr && data.attr.cost_amount
                                ? data.attr.cost_amount
                                : ""
                            }
                          />
                        </Col>

                        <Col xs={12} md={4}>
                          <Input
                            multiline
                            label="Custo"
                            onChange={(e) => this.handleAttrChange(e)}
                            name="cost_value"
                            input={{}}
                            disabled={!this.state.edit}
                            type="text"
                            meta={{ invalid: false }}
                            defaultValue={
                              data.attr && data.attr.cost_value
                                ? data.attr.cost_value
                                : ""
                            }
                          />
                        </Col> */}
                      </Row>
                    </SideOptionWrap>
                  </div>
                )}
              </Col>

              <Col xs={12} md={3}>
                <SectionTitle>
                  <span>&nbsp;</span>
                  Opções
                </SectionTitle>
                <SideOptionWrap>
                  <Input
                    multiline
                    label="Utilizador"
                    onChange={(e) => this.handleAttrChange(e)}
                    name="cost_user"
                    input={{}}
                    disabled={!this.state.edit}
                    type="text"
                    meta={{ invalid: false }}
                    defaultValue={
                      data.attr && data.attr.cost_user
                        ? data.attr.cost_user
                        : ""
                    }
                  />
                  <Calendar
                    id="date"
                    name="date"
                    input={{}}
                    label=""
                    meta={{ invalid: false }}
                    defaultValue={this.getDate(data.date)}
                    type="datetime-local"
                    onChange={(e) => this.handleChange(e)}
                    // defaultValue={this.state.date}
                    disabled={!this.state.edit}
                  />

                  {this.props.isAdmin && data.type !== "comentario" && (
                    <FormControlLabel
                      control={
                        <Toggle
                          onChange={this.handleAttrChange}
                          value="true"
                          checked={data.attr && data.attr.include}
                          label="Report"
                          disabled={!this.state.edit}
                          name="include"
                          color="primary"
                          meta={{ invalid: false }}
                        />
                      }
                      label="Report"
                    />
                  )}
                  {this.props.status !== "saving" && (
                    <div>
                      {this.state.edit ? (
                        <div style={{ textAlign: "right" }}>
                          <SaveButton
                            color="primary"
                            onClick={() => this.handleSaveInspection()}
                          >
                            Salvar
                            <MenuIcon className={`icon-check icons`} />
                          </SaveButton>
                        </div>
                      ) : (
                        <div style={{ textAlign: "right" }}>
                          {this.props.isAdmin &&
                            (this.props.userWrite === "all" ||
                              this.props.userWrite === "inspections") && (
                              <LineButton
                                color="primary"
                                onClick={() => this.toggleEdit()}
                              >
                                Editar
                                <MenuIcon className={`icon-check icons`} />
                              </LineButton>
                            )}

                          {(this.props.userWrite === "all" ||
                            this.props.userWrite === "inspections") &&
                            this.props.isAdmin && (
                              <DisableButton onClick={() => this.delete()}>
                                <MenuIcon className={`icon-close icons`} />{" "}
                                Apagar
                              </DisableButton>
                            )}
                        </div>
                      )}
                    </div>
                  )}
                </SideOptionWrap>

                {this.props.role === "HC" &&
                  this.props.selectedParcelHorta[this.state.selectedHorta] && (
                    <div>
                      <SectionTitle>Contactos</SectionTitle>
                      <SideOptionWrap>
                        {this.props.selectedParcelHorta[
                          this.state.selectedHorta
                        ].map((contact, i) => (
                          <Contacts key={i}>
                            <div>
                              <strong>Nome</strong> {contact.Contacto}
                            </div>
                            <div>
                              <strong>Email</strong> {contact.Email}
                            </div>
                            <div>
                              <strong>Telefone</strong> {contact.Telefone}
                            </div>
                            <div>
                              <strong>Morada</strong> {contact.Morada}
                            </div>
                            <div>
                              <strong>ID PHC</strong> {contact.Horta}
                            </div>
                          </Contacts>
                        ))}
                      </SideOptionWrap>
                    </div>
                  )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                {!this.state.hiddenPhotos && this.renderPhotos(data.photos)}
              </Col>
            </Row>
          </div>
        )}
        {!data && (
          <div>
            <PageTitle>Upss...</PageTitle>
            <Row>
              <Col xs={12} md={9}>
                <SectionTitle>Detalhes</SectionTitle>
                <OptionWrap>
                  Não conseguimos encontrar os detalhes para esta inspeção.
                </OptionWrap>
              </Col>
            </Row>
          </div>
        )}
        {isOpen && (
          <Lightbox
            mainSrc={`${this.serverURL}${images[photoIndex].path}`}
            nextSrc={`${this.serverURL}${
              images[(photoIndex + 1) % images.length].path
            }`}
            prevSrc={`${this.serverURL}${
              images[(photoIndex + images.length - 1) % images.length].path
            }`}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </InspectionWrap>
    );
  }
}

export default connect(
  (state) => {
    return {
      type: state.inspections.type,

      activeStep: state.inspections.activeStep,
      status: state.inspections.status,
      damagesList: state.inspections.damages,
      isAdmin: state.user.data.admin,
      role: state.user.data.role,
      userWrite: state.user.data.write,
      token: state.user.data.token,
      selected: state.app.search.selected,
      list: state.parklist.list,
      selectedParcelHorta: state.georeference.selectedParcelHorta,
      elementsGeoJson: state.app.elementsGeoJson,
    };
  },
  {
    updateInspection,
    getInspections,
    getDamages,
    setMaintenance,
    deleteInspection,
    getHortasParcel,
  }
)(Edit);
