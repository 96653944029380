import React from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import { connect } from 'react-redux';

const StyledPaper = styled.div`
  && {
    border: none;
    height: 100vh;
    /* height: calc(100vh - 49px); */
    width: calc(100% - ${props => props.size});
    animation-delay: ${props => props.delay};
    margin-left: ${props => props.size};
    &.hidden {
      visibility: hidden;
      z-index: 0;
      width: 0;
    }
    @media (max-width: 768px) {
      width: 100%;
      margin-left: 0;
    }

    @media print {
      width: 100%;
      margin-left: 0;
    }
  }
`;
const Padder = styled.div`
  && {
    padding: 5%;
    height: 100vh;
    /* height: calc(100vh - 49px); */
    &.white {
      background: #fff;
      overflow-x: hidden;
    }
    &.map {
      padding: 0;
    }
  }
`;
class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      browserwidth: window.innerWidth,
    };
    this.updateBrowserWith = debounce(this.updateBrowserWith.bind(this), 600);
    window.addEventListener('resize', () => this.updateBrowserWith(), true);
  }

  updateBrowserWith() {
    const newBrowserWidth = window.innerWidth;
    if (newBrowserWidth !== this.state.browserwidth)
      this.setState({
        browserwidth: window.innerWidth,
      });
  }

  render() {
    let size = '0';
    let delay = '0s';
    switch (this.props.size) {
      case 3:
        size = '580px';
        delay = '1s';
        break;
      case 2:
        size = '320px';
        delay = '.5s';
        break;
      default:
        size = '0';
        delay = '0s';
        break;
    }
    if (
      this.state.browserwidth < 426 &&
      this.props.activeMobileView !== 'map' &&
      !this.props.dashboard
    ) {
      return <div />;
    }

    return (
      <StyledPaper
        size={size}
        delay={delay}
        className={`animated fadeIn ${
          this.props.hidden && !this.props.dashboard ? 'hidden' : ''
        }`}
      >
        <Padder
          className={`${this.props.map ? 'map' : ''} ${this.props.color}`}
        >
          {this.props.children}
        </Padder>
      </StyledPaper>
    );
  }
}

export default connect(
  state => {
    return {
      activeMobileView: state.app.activeMobileView,
    };
  },
  {}
)(Content);
