import moment from "moment";
import React from "react";
import DropzoneComponent from "react-dropzone-component";
import { Col, Row } from "react-flexbox-grid";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Lightbox from "react-image-lightbox";
import Rating from "react-rating";
import { toastr } from "react-redux-toastr";

import DefaultCF from "./steps/DefaultCF";
import SistemaDeRegaCF from "./steps/SistemaDeRegaCF";
import LineButton from "../../components/Buttons/LineButton";
import MainButton from "../../components/Buttons/MainButton";
import MenuIcon from "../../components/Buttons/MenuIcon";
import AutoComplete from "../../components/form-wrappers/AutoComplete";
import TextField from "../../components/form-wrappers/TextField";
import ContentWrap from "../../components/Layout/ContentWrap";
import SectionTitle from "../../components/Text/SectionTitle";
import { apiAddress } from "../../config/general";
import {
  deleteMaintenance,
  getInterventions,
  getMaintenances,
  updateMaintenance,
} from "./actions";

import PageTitle from "../../components/Text/PageTitle";

const OptionWrap = styled(ContentWrap)`
  && {
    padding: 2em;
    text-align: left;
  }
`;

const SideOptionWrap = styled(OptionWrap)`
  && {
    padding: 1em;
    @media screen and (max-width: 1183px) and (min-width: 766px) {
      font-size: 0.7em;
    }
    text-align: left;
    button {
      margin: 0 0 0.5em 0;
    }
  }
`;
const Files = styled(DropzoneComponent)`
  && {
    background-color: rgba(74, 144, 226, 0.1);
    border: 2px dashed rgba(74, 144, 226, 1);
    color: #4990e2;
    padding: 1em 1em 2em 1em;
    margin: 1em 0 1.5em;
    .dz-message {
      margin: 0;
    }
    .dz-preview.dz-image-preview {
      background: transparent;
    }
    .filepicker-file-icon {
      color: #4990e2;
      &::after {
        background-color: #4990e2;
      }
      &::before {
        border: solid 2px #4990e2;
      }
    }
    .dz-image {
      img {
        width: 120px;
        height: 120px;
      }
    }
  }
`;
const Input = styled(TextField)`
  && {
    width: 100%;
    padding: 10px 0;
    margin: 1em 0;
    text-align: left;
    color: #000;
    label {
      color: #919191;
    }

    textarea {
      height: auto;
    }
    input:disabled,
    textarea:disabled {
      color: #4da1ff;
    }

    @media print {
      margin: 0.5em 0;
    }
  }
`;
const ImageContainer = styled.div`
  && {
    text-align: left;
  }
`;
const Image = styled.div`
  && {
    width: 200px;
    height: 130px;
    margin: 0 0.5em 0.5em 0;
    display: inline-block;
    border-radius: 0.5em;
    background-size: cover;
    cursor: pointer;
  }
`;

const SaveButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    margin: 1em 0;
    display: inline-block;
    text-align: right;
  }
`;

const MaintenanceWrap = styled.div`
  && {
    margin: 4em 0;
    .col-md-3 {
      @media print {
        display: none;
      }
    }
  }
`;

const FileContainer = styled.div`
  && {
    display: inline-block;
    margin: 0 1em 1em 0;
    width: 80px;
    text-align: center;
    a {
      text-align: center;
      text-decoration: none;
      color: #4da1ff;
      font-size: 0.8em;
      .filepicker-file-icon {
        color: #4990e2;
        &::after {
          background-color: #4990e2;
        }
        &::before {
          border: solid 2px #4990e2;
        }
      }
    }
  }
`;

const ElementType = styled.span`
  && {
    font-weight: bold;
    font-size: 1.5em;
  }
`;

const Calendar = styled(Input)`
  && {
    margin: 0;
    text-align: left;
    display: inline-block;
    width: 50%;
    & > div {
      width: 100%;
    }
  }
`;

const DisableButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    margin-top: 1em;
    background: #df5757;
    border-color: #f44336;
    &:hover {
      color: #df5757;
      .icons {
        color: #df5757;
      }
    }
  }
`;
const ImageWrapper = styled.span`
  && {
    position: relative;
  }
`;
const RemoveItem = styled.span`
  && {
    font-size: 2em;
    margin: 0 0.5em 0 0;
    text-align: center;
    background: #fff;
    position: absolute;
    z-index: 11;
    cursor: pointer;
    right: 6px;
    margin-top: 6px;
    border-radius: 1em;

    color: #f44336;
    vertical-align: middle;
    cursor: pointer;
    :hover {
      color: #4da1ff;
      transform: rotate(90deg);
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
    @media print {
      display: none;
    }
    @media (max-width: 425px) {
      font-size: 2em;
    }
  }
`;
const RatingWrap = styled.div`
  && {
    margin: 2em 0 0;
    text-align: left;
    label {
      display: block;
      color: rgba(0, 0, 0, 0.6);
      font-size: 0.8em;
      margin-bottom: 0.5em;
    }
    > span {
      > span > span {
        margin: 0 0.2em 0 0;
        > span {
          background-color: #4da1ff !important;
        }
      }
    }
  }
`;

const HalfRate = styled.div`
  && {
    display: inline-block;
    width: 30%;
    margin-right: 2%;
    @media (max-width: 900px) {
      width: 45%;
    }
    @media (max-width: 425px) {
      width: 100%;
    }
  }
`;

class Edit extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      edit: false,
      damages: [],
      photoIndex: 0,
      isOpen: false,
      hiddenPhotos: false,
      type: props.data?.attr.type,
    };

    this.deleteImage = this.deleteImage.bind(this);
    this.callback = this.callback.bind(this);
    this.removedfile = this.removedfile.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.delete = this.delete.bind(this);
    this.handleChangeInterventions = this.handleChangeInterventions.bind(this);

    this.handleAttrCustomFieldsRating =
      this.handleAttrCustomFieldsRating.bind(this);

    this.serverURL = apiAddress;

    // this.inspectionCF = {
    //   EV: [
    //     { name: "Geral", comment: "" },
    //     { name: "Limpeza", comment: "" },
    //     { name: "Relvado/Prado", comment: "" },
    //     { name: "Herbáceas", comment: "" },
    //     { name: "Arbustos", comment: "" },
    //     { name: "Árvores", comment: "" },
    //     { name: "Mobiliário Urbano", comment: "" },
    //     { name: "Pavimento", comment: "" },
    //     { name: "Sistema de Rega", comment: "" },
    //   ],
    // };

    // const t = this;
    // setTimeout(() => {
    //   t.setState({ hiddenPhotos: false });
    // }, 0);
    this.djsConfig = {
      acceptedFiles: "image/*, application/*",
      addRemoveLinks: true,
      resizeWidth: 1100,
      resizeHeight: 1100,
      resizeQuality: 0.6,
      headers: { Authorization: "Bearer " + props.token },
      params: {
        fid: props.selectedParkId, //should be the park id
      },
    };

    this.componentConfig = {
      iconFiletypes: ["docs", "images"],
      showFiletypeIcon: true,
      postUrl: `${apiAddress}api/v1/files`,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.status === "edited" || this.props.status === "deleted") {
      this.props.getMaintenances();
      this.props.closeModal();
    }
  }

  getDate(date) {
    const now = new Date(date);
    const addZero = (i) => (i < 10 ? "0" + i : i);
    return `${now.getFullYear()}-${addZero(now.getMonth() + 1)}-${addZero(
      now.getDate()
    )}T${addZero(now.getHours())}:${addZero(now.getMinutes())}`;
  }

  handleChangeAttribute(e, value) {
    this.setState({
      data: {
        ...this.state.data,
        attr: {
          ...this.state.data.attr,
          [e.target.name]: !this.state.data.attr[e.target.name],
        },
      },
    });
  }

  toggleEdit() {
    if (!this.state.edit) this.props.getInterventions();
    this.setState({ edit: !this.state.edit });
  }

  delete() {
    const toastrConfirmOptions = {
      onOk: () => this.props.deleteMaintenance({ ...this.state.data }),
      onCancel: () => console.log("CANCEL: clicked"),
    };
    toastr.confirm(
      "Tem a certeza? Esta acção não pode ser revertida",
      toastrConfirmOptions
    );
  }

  removedfile(e) {
    let foundId = null;
    this.state.data.files.map((files, index) => {
      if (files.name === e.upload.filename) {
        foundId = index;
      }
    });
    if (foundId) this.deleteImage(foundId);
  }

  deleteImage(filesIndex) {
    this.setState({
      data: {
        ...this.state.data,
        files: this.state.data.files.filter((files, i) => i !== filesIndex),
      },
    });
  }
  // Simple callbacks work too, of course
  callback(file) {
    const fileData = JSON.parse(file.xhr.response);
    const files = [
      ...this.state.data.files,
      {
        path: fileData.msg.details.path,
        name: fileData.msg.details.originalname,
        size: fileData.msg.details.size,
        id: fileData.msg.id,
      },
    ];
    this.setState({
      data: {
        ...this.state.data,
        files,
      },
    });
  }

  handleChange(e, value) {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleChangeInterventions(value) {
    this.setState({ intervention: value });
  }

  handleSaveMaintenance() {
    this.toggleEdit();
    this.props.updateMaintenance({
      ...this.state.data,
      intervention: this.state.intervention,
    });
  }
  closeModal() {
    this.props.history.push(`/interventions`);
  }

  handleAttrCustomFieldsRating(value, index) {
    this.setState({
      data: {
        ...this.state.data,
        attr: {
          ...this.state.data.attr,
          customFields: {
            ...this.state.data.attr.customFields,
            [index]: value,
          },
        },
      },
    });
  }

  renderPhotos(data) {
    const config = this.componentConfig;
    const djsConfig = this.djsConfig;

    const self = this;

    /// not bound to this is moved out of render
    const eventHandlers = {
      init: (dropzone) => {
        dropzone.autoDiscover = false;
      },
      removedfile: self.removedfile,
      error: (e) => {
        console.log(e);
      },
      success: self.callback,
    };

    if (data.files) {
      return (
        <div>
          {data.files && data.files.length > 0 && (
            <div>
              {" "}
              <SectionTitle>Imagen Inicial e Final</SectionTitle>
              <ImageContainer>
                {data.files.map((file, index) => {
                  const sliptName = file.name.split(".");
                  const fileExtension = sliptName[sliptName.length - 1];
                  if (
                    fileExtension === "gif" ||
                    fileExtension === "jpg" ||
                    fileExtension === "png"
                  ) {
                    return (
                      <ImageWrapper key={index}>
                        <Image
                          onClick={() =>
                            this.setState({
                              isOpen: true,
                              photoIndex: index,
                            })
                          }
                          style={{
                            backgroundImage: `url('${this.serverURL}${file.path}')`,
                          }}
                        />

                        {this.state.edit && (
                          <RemoveItem
                            onClick={() => this.deleteImage(index)}
                            className={`icon-close icons`}
                          />
                        )}
                      </ImageWrapper>
                    );
                  } else {
                    return (
                      <ImageWrapper key={index}>
                        <FileContainer>
                          <Link
                            to={`${this.serverURL}${file.path}`}
                            target={"_blank"}
                          >
                            <span
                              className={"filepicker-file-icon"}
                              data-filetype={`${fileExtension}`}
                            />
                            {file.name}
                          </Link>
                        </FileContainer>

                        {this.state.edit && (
                          <RemoveItem
                            onClick={() => this.deleteImage(index)}
                            className={`icon-close icons`}
                          />
                        )}
                      </ImageWrapper>
                    );
                  }
                })}
              </ImageContainer>
            </div>
          )}
          {this.state.edit && (
            <Files
              config={config}
              eventHandlers={eventHandlers}
              djsConfig={djsConfig}
            />
          )}
        </div>
      );
    }
  }

  render() {
    const { photoIndex, isOpen, data } = this.state;
    const images = data.files;

    let color;
    switch (data.type) {
      case "Danos":
        color = "#eb3d3d";
        break;
      case "Inicial":
        color = "#ebcd3d";
        break;
      default:
        color = "#6fc44c";
        break;
    }
    // const customFieldsValues = data.attr ? data.attr.customFields : {};
    const scheduled = data?.attr?.type === "schedule";
    const rega = data?.attr?.type === "rega";

    // const customFields = this.inspectionCF["EV"];

    return (
      <MaintenanceWrap name={data.id}>
        {data && (
          <div>
            <Row>
              <Col xs={12} md={9}>
                <SectionTitle color={color}>
                  <i className={`icon-wrench icons`} /> {data.type}
                  {data.Element && data.Element.ElementType && (
                    <ElementType>
                      {data.Element.ElementType.category},
                      {data.Element.ElementType.label}
                    </ElementType>
                  )}
                  <strong>
                    {data.User && (
                      <span>
                        {data.User.name},{" "}
                        {moment(data.date).format("YYYY-MM-DD HH:mm:ss")}
                      </span>
                    )}
                  </strong>
                </SectionTitle>
                {!scheduled && !rega && (
                  <DefaultCF
                    handleAttrCustomFieldsRating={
                      this.handleAttrCustomFieldsRating
                    }
                    readonly={!this.state.edit}
                    interventionType={data?.attr?.type}
                    attr={data?.attr}
                  />
                )}
                {rega && (
                  <SistemaDeRegaCF
                    handleAttrCustomFieldsRating={
                      this.handleAttrCustomFieldsRating
                    }
                    readonly={!this.state.edit}
                    interventionType={this.props.interventionType}
                    attr={data?.attr}
                  />
                )}
                <OptionWrap>
                  {data.endDate && (
                    <Calendar
                      id="date"
                      name="date"
                      input={{}}
                      label=""
                      meta={{ invalid: false }}
                      defaultValue={this.getDate(data.date)}
                      type="datetime-local"
                      onChange={(e) => this.handleChange(e)}
                      disabled={!this.state.edit}
                    />
                  )}
                  {data.endDate && (
                    <Calendar
                      id="endDate"
                      name="endDate"
                      input={{}}
                      label=""
                      meta={{ invalid: false }}
                      defaultValue={this.getDate(data.endDate)}
                      type="datetime-local"
                      onChange={(e) => this.handleChange(e)}
                      disabled={!this.state.edit}
                    />
                  )}
                  {data.Intervention && data.Intervention.label && (
                    <AutoComplete
                      updateState={this.handleChangeInterventions}
                      options={this.props.interventionList}
                      defaultValue={data.Intervention}
                      placeholder={"Pesquise ou adicione um novo dano"}
                      disabled={!this.state.edit}
                    />
                  )}
                  {data.attr && data.attr.reading && (
                    <Input
                      label="Total"
                      onChange={(e) => this.handleAttrChange(e)}
                      name="reading"
                      input={{}}
                      defaultValue={data.attr.reading}
                      meta={{ invalid: false }}
                      disabled={!this.state.edit}
                    />
                  )}

                  {data.details && (
                    <Input
                      multiline
                      label="Descrição"
                      onChange={(e) => this.handleChange(e)}
                      name="details"
                      input={{}}
                      defaultValue={data.details}
                      type="multiline"
                      meta={{ invalid: false }}
                      rows="8"
                      disabled={!this.state.edit}
                    />
                  )}
                </OptionWrap>
              </Col>

              <Col xs={12} md={3}>
                <SectionTitle>
                  <span>&nbsp;</span>
                  Opções
                </SectionTitle>
                <SideOptionWrap>
                  {!data.endDate && (
                    <Calendar
                      id="date"
                      name="date"
                      input={{}}
                      label=""
                      meta={{ invalid: false }}
                      defaultValue={this.getDate(data.date)}
                      type="datetime-local"
                      onChange={(e) => this.handleChange(e)}
                      disabled={!this.state.edit}
                    />
                  )}
                  {this.props.status !== "saving" && (
                    <div>
                      {this.state.edit ? (
                        <div style={{ textAlign: "right" }}>
                          <SaveButton
                            color="primary"
                            onClick={() => this.handleSaveMaintenance()}
                          >
                            Salvar
                            <MenuIcon className={`icon-check icons`} />
                          </SaveButton>
                        </div>
                      ) : (
                        (this.props.userWrite === "all" ||
                          this.props.userWrite === "interventions") && (
                          <div style={{ textAlign: "right" }}>
                            <LineButton
                              color="primary"
                              onClick={() => this.toggleEdit()}
                            >
                              Editar
                              <MenuIcon className={`icon-check icons`} />
                            </LineButton>
                            {this.props.isAdmin &&
                              (this.props.userWrite === "all" ||
                                this.props.userWrite === "interventions") && (
                                <DisableButton onClick={() => this.delete()}>
                                  <MenuIcon className={`icon-close icons`} />{" "}
                                  Apagar
                                </DisableButton>
                              )}
                          </div>
                        )
                      )}
                    </div>
                  )}
                </SideOptionWrap>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                {!this.state.hiddenPhotos && this.renderPhotos(data)}
              </Col>
            </Row>
          </div>
        )}
        {!data && (
          <div>
            <PageTitle>Upss...</PageTitle>
            <Row>
              <Col xs={12} md={9}>
                <SectionTitle>Detalhes</SectionTitle>
                <OptionWrap>
                  Não conseguimos encontrar os detalhes para esta Manutenção.
                </OptionWrap>
              </Col>
            </Row>
          </div>
        )}
        {isOpen && (
          <Lightbox
            mainSrc={`${this.serverURL}${images[photoIndex].path}`}
            nextSrc={`${this.serverURL}${
              images[(photoIndex + 1) % images.length].path
            }`}
            prevSrc={`${this.serverURL}${
              images[(photoIndex + images.length - 1) % images.length].path
            }`}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </MaintenanceWrap>
    );
  }
}

export default connect(
  (state) => {
    return {
      isAdmin: state.user.data.admin,
      type: state.maintenances.type,
      activeStep: state.maintenances.activeStep,
      status: state.maintenances.status,
      interventionList: state.maintenances.interventions,
      userWrite: state.user.data.write,
      token: state.user.data.token,
    };
  },
  { deleteMaintenance, updateMaintenance, getMaintenances, getInterventions }
)(Edit);
