import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Rating from "react-rating";
import ContentWrap from "../../../components/Layout/ContentWrap";
import SectionTitle from "../../../components/Text/SectionTitle";
import TextField from "../../../components/form-wrappers/TextField";

const HalfRate = styled.div`
  && {
    display: inline-block;
    width: 30%;
    margin-right: 2%;
    @media (max-width: 900px) {
      width: 45%;
    }
    @media (max-width: 425px) {
      width: 100%;
    }
  }
`;
const RatingWrap = styled.div`
  && {
    margin: 2em 0 0;
    text-align: left;
    label {
      display: block;
      color: rgba(0, 0, 0, 0.6);
      font-size: 0.8em;
      margin-bottom: 0.5em;
    }
    /* > span {
      zoom: 0.8;
    } */
  }
`;

const OptionWrap = styled(ContentWrap)`
  && {
    padding: 2em;
    margin-bottom: 1em;
    text-align: center;
    .optionsTitle {
      cursor: pointer;
      display: flex;
      width: 100%;
      font-weight: 600;
      text-align: left;
      font-size: 1.2em;
      color: #4da1ff;
      justify-content: space-between;
    }
    .options {
      display: none;
    }
    .optionLine {
      display: flex;
      text-align: left;
      padding: 0.8em 0;
      align-items: center;
      margin: 0em 0;
      border-bottom: 1px solid #4da1ff40;
      flex-wrap: wrap;
      &:last-child {
        border-bottom: none;
      }
      justify-content: space-between;
    }
    .optionName {
      padding: 0.8em 0;
      width: 30%;
    }
    .optionValues {
      display: flex;
      min-width: 70%;
      align-items: center;
      justify-content: flex-end;
      .option {
        margin: 0 0.5em 0 0;
        font-size: 0.8em;
      }
      label,
      input {
        font-size: 1em;
      }
    }
  }
`;

const SistemaDeRegaCF = (props) => {
  const [opened, setOpened] = useState([]);

  const inspectionCFMaterial = [
    {
      name: "GOTA-A-GOTA",
      options: [
        {
          name: "Tubo gota-a-gota",

          options: ["DIÂMETRO (mm)", "COMPRIMENTO (m)"],
        },
        {
          name: "Tubo cego",

          options: ["DIÂMETRO (mm)", "COMPRIMENTO (m)"],
        },
        {
          name: "Fim de ciclo",

          options: ["UNIDADES"],
        },
        {
          name: "Braçadeira",

          options: ["UNIDADES"],
        },
        {
          name: "Estaca de fixação",

          options: ["UNIDADES"],
        },
      ],
    },
    {
      name: "UNIÕES",
      options: [
        { name: "Joelho", options: ["DIÂMETRO (mm)", "UNIDADES"] },
        { name: "Tê", options: ["DIÂMETRO (mm)", "UNIDADES"] },
        { name: "Racord", options: ["DIÂMETRO (mm)", "UNIDADES"] },
        { name: "Uniões", options: ["DIÂMETRO (mm)", "UNIDADES"] },
        { name: "Gromet", options: ["DIÂMETRO (mm)", "UNIDADES"] },
        { name: "Cachimbo", options: ["DIÂMETRO (mm)", "UNIDADES"] },
      ],
    },
    {
      name: "REGA POR ASPERSÃO",
      options: [
        { name: "Pulverizador fixo", options: ["UNIDADES"] },
        {
          name: "Pulverizador rotativo",
          options: ["UNIDADES"],
        },
        { name: "Bicos", options: ["UNIDADES"] },
        { name: "Cápsulas", options: ["UNIDADES"] },
        { name: "Aspersor 3500", options: ["UNIDADES"] },
        { name: "Aspersor 5000", options: ["UNIDADES"] },
      ],
    },
    {
      name: "CONTADORES",
      options: [
        {
          name: "Torneira",
          options: ["DIÂMETRO (mm)", "UNIDADES"],
        },
        { name: "O ring", options: ["DIÂMETRO (mm)", "UNIDADES"] },
      ],
    },
    {
      name: "TUBAGENS",
      options: [
        {
          name: "Tubo",
          options: ["DIÂMETRO / POL.", "COMPRIMENTO", "UNIDADES"],
        },
        {
          name: "Tubo cego",
          options: ["DIÂMETRO / POL.", "COMPRIMENTO", "UNIDADES"],
        },
        { name: "Uniões", options: ["DIÂMETRO / POL.", "UNIDADES"] },
      ],
    },
    {
      name: "VÁLVULAS",
      options: [
        {
          name: "Torneira de segurança",
          options: ["DIÂMETRO (mm)", "UNIDADES"],
        },
        {
          name: "Tomada de carga",
          options: ["DIÂMETRO (mm)", "UNIDADES"],
        },
        {
          name: "Filtro de água",
          options: ["DIÂMETRO (mm)", "UNIDADES"],
        },
        {
          name: "Eletroválvulas",
          options: ["MODELO", "Nº DE ESTAÇÕES", "DIAÂMETRO (mm)", "UNIDADES"],
        },
        {
          name: "Válvula solenoide",
          options: ["MODELO", "Nº DE ESTAÇÕES", "DIAÂMETRO (mm)", "UNIDADES"],
        },
      ],
    },
    {
      name: "PROGRAMADORES",
      options: [
        {
          name: "Programador novo",
          options: ["MODELO", "N. DE ESTAÇÕES", "UNIDADES"],
        },
        {
          name: "Programador reutilizado",
          options: ["MODELO", "N. DE ESTAÇÕES", "UNIDADES"],
        },
        {
          name: "Pilhas",
          options: ["UNIDADES"],
        },
        {
          name: "Programadores roubados (se aplicável)",
          options: ["UNIDADES"],
        },
      ],
    },
    {
      name: "GERAL",
      options: [
        {
          name: "Caixas",
          options: ["MODELO", "UNIDADES"],
        },
        {
          name: "Mangueira",
          options: ["DIÂMETRO (mm)", "COMPRIMENTO (m)"],
        },
        {
          name: "Sure Quick",
          options: ["DIÂMETRO (mm)", "UNIDADES"],
        },

        {
          name: "Torneira de jardim",
          options: ["DIÂMETRO (mm)", "UNIDADES"],
        },
        {
          name: "Tampões",
          options: ["DIÂMETRO (mm)", "UNIDADES"],
        },
        {
          name: "Casquilhos",
          options: ["DIÂMETRO (mm)", "UNIDADES"],
        },
        {
          name: "Tubo",
          options: ["DIÂMETRO (mm)", "COMPRIMENTO (m)"],
        },
      ],
    },
  ];
  useEffect(() => {
    const newOpened = [];
    //lets set the opened array for every custom field with values
    Object.keys(props?.attr?.customFields || {})?.forEach((cf) => {
      const valueHir = cf.split("->");
      const mainDif = valueHir[0];
      //get the index of the main custom field
      const mainIndex = inspectionCFMaterial.findIndex(
        (m) => m.name === mainDif
      );
      newOpened.push(mainIndex);
      //let set the array state
    });
    setOpened([...new Set(newOpened)]);

    return () => {};
  }, []);

  return (
    <div>
      <SectionTitle>Material</SectionTitle>
      {inspectionCFMaterial.map((cf, index) => {
        const isOpen = opened.includes(index);
        return (
          <OptionWrap key={index}>
            <label
              className="optionsTitle"
              onClick={() =>
                isOpen
                  ? setOpened(opened.filter((o) => o !== index))
                  : setOpened([...opened, index])
              }
            >
              {cf.name}{" "}
              <i
                className={`icons ${
                  isOpen ? "icon-arrow-up" : "icon-arrow-down"
                }`}
              />
            </label>
            <div
              className="options"
              style={{ display: isOpen ? "block" : "none" }}
            >
              {cf.options.map((row, b) => {
                return (
                  <div key={b} className="optionLine">
                    <div className="optionName">{row.name}</div>
                    <div className="optionValues">
                      {row.options.map((option, c) => {
                        const name = `${cf.name}->${row.name}->${option}`;
                        return (
                          <div key={c} className="option">
                            <TextField
                              label={option}
                              onChange={(e) => {
                                props.handleAttrCustomFieldsRating(
                                  e.target.value,
                                  name
                                );
                              }}
                              disabled={props.readonly}
                              name={name}
                              input={{}}
                              style={{
                                color:
                                  props?.attr?.customFields &&
                                  props?.attr?.customFields[name]
                                    ? "#4da1ff"
                                    : "black",
                              }}
                              value={
                                props?.attr?.customFields
                                  ? props?.attr?.customFields[name]
                                  : ""
                              }
                              type="text"
                              meta={{ invalid: false }}
                            />
                            {/* <label>{option}</label> */}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </OptionWrap>
        );
      })}
    </div>
  );
};

export default SistemaDeRegaCF;
