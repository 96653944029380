import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
// import ChipInput from 'material-ui-chip-input';
import { Row, Col } from "react-flexbox-grid";
import DropzoneComponent from "react-dropzone-component";

import Lightbox from "react-image-lightbox";

import MainButton from "../../../components/Buttons/MainButton";
import ContentWrap from "../../../components/Layout/ContentWrap";
import TextField from "../../../components/form-wrappers/TextField";
import SectionTitle from "../../../components/Text/SectionTitle";
import MenuIcon from "../../../components/Buttons/MenuIcon";
import { apiAddress } from "../../../config/general";

import { setDetails, setElement, setParkByNumber } from "../actions";

const NextButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    width: 100%;
  }
`;

const OptionWrap = styled(ContentWrap)`
  && {
    padding: 2em;
    text-align: left;
  }
`;

const InlineBlock = styled.div`
  && {
    display: inline-block;
    width: 44%;
    margin-right: 2%;
  }
`;
const ImageContainer = styled.div`
  && {
    text-align: left;
  }
`;

const Image = styled.div`
  && {
    width: 200px;
    height: 130px;
    margin: 0 0.5em 0.5em 0;
    display: inline-block;
    border-radius: 0.5em;
    background-size: cover;
    cursor: pointer;
  }
`;

const Input = styled(TextField)`
  && {
    width: 100%;
    padding: 10px 0;
    margin: 0em 0;
    display: inline-block;
    margin-right: 5%;
    text-align: left;
    textarea {
      height: auto;
    }
    input {
      width: 100%;
    }
    div {
      width: 100%;
    }

    input:disabled,
    textarea:disabled {
      color: #4da1ff;
    }
  }
`;

const InputFull = styled(TextField)`
  && {
    width: 100%;
    padding: 10px 0;
    margin: 0em 0;
    display: block;
    text-align: left;
    textarea {
      height: auto;
      width: 100%;
    }
    div {
      width: 100%;
    }
    input:disabled,
    textarea:disabled {
      color: #4da1ff;
    }
  }
`;

const Files = styled(DropzoneComponent)`
  && {
    background-color: rgba(74, 144, 226, 0.1);
    border: 2px dashed rgba(74, 144, 226, 1);
    color: #4990e2;
    padding: 1em 1em 2em 1em;
    margin: 1em 0 1.5em;
    .dz-message {
      margin: 0;
    }
    .dz-preview.dz-image-preview {
      background: transparent;
    }
    .filepicker-file-icon {
      color: #4990e2;
      &::after {
        background-color: #4990e2;
      }
      &::before {
        border: solid 2px #4990e2;
      }
    }
  }
`;

class EditParkDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    const data = props.data || {};

    const about = {
      designacao: data.designacao || "",
      freguesia: data.freguesia || "",
      função: data.função || "",
      local: data.local || "",
      localidade: data.localidade || "",
      morada: data.morada || "",
      nome: data.nome || "",
      numero: data.numero || "",
      observaçõe: data.observaçõe || "",
    };

    const responsible = {
      assistênci: data.assistênci || "",
      gestor: data.gestor || "",
      prestador: data.prestador || "",
    };

    const maintenance = {
      actuação: data.actuação || "",
      area_emac: data.area_emac || "",
      designacao: data.designacao || "",
      inicio_prestacao: data.inicio_prestacao || "",
      rega: data.rega || "",
      tipo: data.tipo || "",
    };
    this.state = {
      about: props.data.about || about,
      responsible: props.data.responsible || responsible,
      maintenance: props.data.maintenance || maintenance,
      isOpen: null,
      pid: props.data.numero || props.selected,
      elementId: null,
      photoIndex: 0,
      implementationDate: this.getDate(),
      files: props.files || props.data.files || [],
    };

    this.serverURL = apiAddress;
    // For a full list of possible configurations,
    // please consult http://www.dropzonejs.com/#configuration
    this.djsConfig = {
      acceptedFiles: "image/*, application/*",
      addRemoveLinks: true,
      resizeWidth: 1100,
      resizeHeight: 1100,
      resizeQuality: 0.6,
      headers: { Authorization: "Bearer " + props.token },
      params: {
        fid: this.state.pid, //should be the park id
      },
    };

    this.componentConfig = {
      iconFiletypes: ["docs", "images"],
      showFiletypeIcon: true,
      postUrl: `${apiAddress}api/v1/files`,
    };

    this.callback = this.callback.bind(this);
    this.removedfile = this.removedfile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSaveElement = this.handleSaveElement.bind(this);
  }

  componentDidUpdate() {
    if (this.props.status === "done") {
    }
  }

  getDate(date) {
    const now = date ? new Date(date) : new Date();
    const addZero = (i) => (i < 10 ? "0" + i : i);
    return `${now.getFullYear()}-${addZero(now.getMonth() + 1)}-${addZero(
      now.getDate()
    )}T${addZero(now.getHours())}:${addZero(now.getMinutes())}`;
  }

  // Simple callbacks work too, of course
  callback(file) {
    const fileData = JSON.parse(file.xhr.response);
    const files = [
      ...this.state.files,
      {
        path: fileData.msg.details.path,
        name: fileData.msg.details.originalname,
        size: fileData.msg.details.size,
        id: fileData.msg.id,
      },
    ];
    this.setState({ files: files });
  }

  // Simple callbacks work too, of course
  removedfile(file) {
    //need to delete images
    console.log(file);
    // this.setState({ photos: [...this.state.photos, file.upload] });
  }

  handleChange(e, category) {
    if (category) {
      this.setState({
        [category]: {
          ...this.state[category],
          [e.target.name]: e.target.value,
        },
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  handleSaveElement() {
    const details = {
      responsible: this.state.responsible,
      about: this.state.about,
      maintenance: this.state.maintenance,
      files: this.state.files,
    };

    this.props.setDetails(details);
    this.props.setParkByNumber(this.props.selected[0] || null);
  }

  renderPhotos(photos) {
    return (
      <div>
        <SectionTitle>Fotos</SectionTitle>
        <ImageContainer>
          {photos &&
            photos.map((photo, index) => (
              <Image
                onClick={() =>
                  this.setState({
                    isOpen: true,
                    photoIndex: index,
                  })
                }
                key={index}
                style={{
                  backgroundImage: `url('${this.serverURL}${photo.path}')`,
                }}
              />
            ))}
        </ImageContainer>
      </div>
    );
  }

  render() {
    const config = this.componentConfig;
    const djsConfig = this.djsConfig;
    const { photoIndex, isOpen } = this.state;
    const images = this.state.files;

    // For a list of all possible events (there are many), see README.md!
    const eventHandlers = {
      removedfile: this.removedfile,
      success: this.callback,
    };

    return (
      <div>
        {images && images.length > 0 && (
          <Row>
            <Col xs={12} md={12}>
              {this.renderPhotos(images)}
            </Col>
          </Row>
        )}

        <Row>
          <Col xs={12} md={9}>
            <SectionTitle>Informação sobre o Parque</SectionTitle>
            <OptionWrap>
              {this.state.about &&
                Object.keys(this.state.about).map((ele, index) => {
                  if (ele === "observaçõe") {
                    return (
                      <InputFull
                        key={index}
                        multiline
                        label="Observações"
                        onChange={(e) => this.handleChange(e, "about")}
                        defaultValue={this.state.about[ele]}
                        name="observaçõe"
                        input={{}}
                        type="multiline"
                        meta={{ invalid: false }}
                        rows="8"
                        disabled={this.props.view}
                      />
                    );
                  }
                  return (
                    <InlineBlock key={index}>
                      <Input
                        label={ele}
                        onChange={(e) => this.handleChange(e, "about")}
                        defaultValue={this.state.about[ele]}
                        name={ele}
                        input={{}}
                        meta={{ invalid: false }}
                        type="text"
                        disabled={this.props.view}
                      />
                    </InlineBlock>
                  );
                })}
            </OptionWrap>
          </Col>

          <Col xs={12} md={3}>
            <SectionTitle>Responsável</SectionTitle>
            <OptionWrap>
              {this.state.responsible &&
                Object.keys(this.state.responsible).map((ele, index) => {
                  return (
                    <InputFull
                      key={index}
                      label={ele}
                      onChange={(e) => this.handleChange(e, "responsible")}
                      defaultValue={this.state.responsible[ele]}
                      name={ele}
                      input={{}}
                      meta={{ invalid: false }}
                      type="text"
                      disabled={this.props.view}
                    />
                  );
                })}
            </OptionWrap>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <SectionTitle>Manutenção</SectionTitle>
            <OptionWrap>
              {this.state.maintenance &&
                Object.keys(this.state.maintenance).map((ele, index) => {
                  return (
                    <InlineBlock key={index}>
                      <Input
                        label={ele}
                        onChange={(e) => this.handleChange(e, "maintenance")}
                        defaultValue={this.state.maintenance[ele]}
                        name={ele}
                        input={{}}
                        meta={{ invalid: false }}
                        type="text"
                        disabled={this.props.view}
                      />
                    </InlineBlock>
                  );
                })}
            </OptionWrap>
          </Col>
          {!this.props.view && (
            <Col xs={12} md={12}>
              <SectionTitle>Opções</SectionTitle>
              <OptionWrap>
                <Files
                  config={config}
                  eventHandlers={eventHandlers}
                  djsConfig={djsConfig}
                />
                {this.props.status !== "getting" &&
                  this.props.status !== "saving" && (
                    <NextButton
                      color="primary"
                      onClick={() => this.handleSaveElement()}
                    >
                      Submeter
                      <MenuIcon className={`icon-check icons`} />
                    </NextButton>
                  )}
              </OptionWrap>
            </Col>
          )}
        </Row>
        {isOpen && (
          <Lightbox
            mainSrc={`${this.serverURL}${images[photoIndex].path}`}
            nextSrc={`${this.serverURL}${
              images[(photoIndex + 1) % images.length].path
            }`}
            prevSrc={`${this.serverURL}${
              images[(photoIndex + images.length - 1) % images.length].path
            }`}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      park: state.georeference.park,
      selected: state.app.search.selected,
      token: state.user.data.token,
      status: state.georeference.status,
      isAdmin: state.user.data.admin,
      elementTypesList: state.georeference.elementTypes,
      vegetationTypes: state.georeference.vegetationTypes,
    };
  },
  { setDetails, setElement, setParkByNumber }
)(EditParkDetails);
