import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import Dayz from "dayz";
import moment from "moment";

import MobileLayoutToggle from "../App/MobileLayoutToggle";

import SectionTitle from "../../components/Text/SectionTitle";
import Sidebar from "../../components/Layout/Sidebar";
import ButtonGroup from "../../components/Buttons/ButtonGroup";
import GroupedButton from "../../components/Buttons/GroupedButton";
import MenuIcon from "../../components/Buttons/MenuIcon";
// import SidebarList from '../../components/Layout/SidebarList';
import Content from "../../components/Layout/Content";
import MainButton from "../../components/Buttons/MainButton";

import TextField from "../../components/form-wrappers/TextField";
import LocationFilter from "../../components/Filters/LocationFilter";
import ParcelsFilter from "../../components/Filters/ParcelsFilter";
import TeamFilter from "../../components/Filters/TeamFilter";

import "dayz/dist/dayz.css";
import { getEvents, setRotasDetails, editRouteById } from "./actions";

import { default as MaintenancesView } from "../Maintenances/View";
import { default as InspectionsView } from "../Inspections/View";
import EditRoute from "./EditRoute";
import { setSearch } from "../App/actions";

const StyledWrap = styled.div`
  display: flex;
  height: 100%;
  /* height: calc(100vh - 49px); */
  @media (max-width: 768px) {
    display: block;
  }
`;

const Section = styled.div`
  && {
    margin: 0.5em 0;
  }
`;

const StyledMainButton = styled(MainButton)`
  && {
    width: 100%;
    margin-top: 1em;
    font-size: 0.8em;
  }
`;

const Input = styled(TextField)`
  && {
    width: 100%;
    padding: 0;
    margin: 0 2% 0 0;
    text-align: left;
    textarea {
      height: auto;
    }
    label {
      width: 100%;
    }
    div {
      width: 100%;
    }
  }
`;
const CalendarInput = styled(Input)`
  && {
  }
`;
const FilterList = styled.div`
  && {
    .inspection-filter {
      height: calc(100vh - 210px);
      padding-right: 18px;
      .scrollbar {
        border-radius: 1em;
      }
    }
  }
`;
const CalendarWrap = styled.div`
  && {
    height: 100%;
    border: none;
    .day-label {
      font-size: 0.7em;
      color: #989898;
    }
    .hour {
      font-size: 0.7em;
      color: #989898;
    }
    .days {
      border-top: 1px solid #efefef;
      border-left: none !important;
      .event {
        background: #4da1ff;
        font-size: 0.7em;
        border-radius: 1em;
        padding: 5px;
        height: auto !important;
        cursor: pointer;
      }
      .color-2 {
        .evbody {
          background: #4da1ff;
          color: #fff;
        }
      }
      .color-1 {
        background: #faa43a;
        .evbody {
          background: #faa43a;
          color: #fff;
        }
      }
      .color-3 {
        background: #6fc44c;
        .evbody {
          background: #6fc44c;
          color: #fff;
        }
      }
    }
    .day {
      border: 4px solid #f4f8f9;
      background: #fff;
      border-radius: 10px;
      .label {
        color: #4da1ff;
        font-size: 0.9em;
      }
      .event.span-1 .evbody > div {
        white-space: normal !important;
      }
    }
    .outside {
      background-color: transparent !important;
      .label {
        color: #989898;
      }
    }
  }
`;
const StyledCalendar = styled(Dayz)`
  && {
  }
`;
class Calendar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      edit: "",
      calendarType: "month",
      viewMaintenances: false,
      viewInspections: false,
    };
    this.setCloseDetailView = this.setCloseDetailView.bind(this);
    this.openEventDetails = this.openEventDetails.bind(this);
    this.toggleActiveView = this.toggleActiveView.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.toggleEditRoute = this.toggleEditRoute.bind(this);
    props.getEvents();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.status === "saved") this.props.getEvents();
  }

  handleSearchChange(e, value) {
    this.props.setSearch({
      element: [e.target.name],
      value: e.target.value,
    });
    this.props.getEvents();
  }

  openEventDetails(e, data) {
    if (data.attributes.type === "rota") {
      this.toggleEditRoute(data.attributes.id);
    } else {
      this.setState({
        edit: data.attributes.id,
        viewMaintenances: data.attributes.type === "maintenances",
        viewInspections: data.attributes.type === "inspection",
      });
    }
  }

  openEventCreation(e, data) {}

  toggleActiveView(view) {
    this.setState({
      calendarType: view,
    });
  }

  toggleEditRoute(id) {
    this.props.editRouteById(id);
  }

  setCloseDetailView() {
    this.setState({
      edit: "",
      viewMaintenances: false,
      viewInspections: false,
    });
  }

  render() {
    const { role, startDate, list, userTeam, team, menuData } = this.props;
    const { calendarType } = this.state;
    const eventList = list ? new Dayz.EventsCollection(list) : null;

    return (
      <StyledWrap>
        <MobileLayoutToggle />
        <Sidebar
          title={menuData.name}
          text={`Pesquisa por parcela`}
          icon={"clock"}
        >
          {/* <ButtonGroup>
            <GroupedButton
              width={'33%'}
              onClick={() => this.toggleActiveView('month')}
              active={calendarType === 'month'}
            >
              <MenuIcon
                theme={calendarType === 'month'}
                className={`icon-calendar icons`}
              />
            </GroupedButton>
            <GroupedButton
              width={'33%'}
              onClick={() => this.toggleActiveView('week')}
              active={calendarType === 'week'}
            >
              <MenuIcon
                theme={calendarType === 'week'}
                className={`icon-event icons`}
              />
            </GroupedButton>
            <GroupedButton
              width={'33%'}
              onClick={() => this.toggleActiveView('day')}
              active={calendarType === 'day'}
            >
              <MenuIcon
                theme={calendarType === 'day'}
                className={`icon-clock icons`}
              />
            </GroupedButton>
          </ButtonGroup> */}
          {/* <SectionTitle>Parcelas</SectionTitle> */}

          <FilterList>
            <ParcelsFilter handleSearchChange={this.handleSearchChange} />

            {(!userTeam || userTeam.type === "internal") && (
              <div>
                <SectionTitle>Equipa</SectionTitle>
                <TeamFilter
                  noMulti={true}
                  selected={team}
                  handleChange={this.handleSearchChange}
                />
              </div>
            )}
            <Section>
              <SectionTitle>Data</SectionTitle>
              <CalendarInput
                id="startDate"
                name="startDate"
                label=""
                input={{}}
                meta={{ invalid: false }}
                type="date"
                onChange={(e) => this.handleSearchChange(e)}
                defaultValue={startDate}
              />
            </Section>
          </FilterList>

          {this.props.userIsAdmin && (
            <StyledMainButton onClick={() => this.toggleEditRoute()}>
              <MenuIcon className={`icon-compas}s icons`} /> Adicionar Rota
            </StyledMainButton>
          )}
        </Sidebar>
        {(!this.props.editRoute || !this.props.editRoute.open) && (
          <Content size={2} map={true}>
            <CalendarWrap>
              {eventList && (
                <StyledCalendar
                  display={calendarType}
                  onDayClick={this.openEventCreation}
                  onEventClick={this.openEventDetails}
                  date={moment(startDate)}
                  events={eventList}
                />
              )}
            </CalendarWrap>
          </Content>
        )}
        {this.props.editRoute && this.props.editRoute.open && (
          <EditRoute onClose={this.toggleEditRoute} />
        )}

        {this.state.viewMaintenances && (
          <MaintenancesView
            group={this.state.edit}
            onClose={this.setCloseDetailView}
          />
        )}
        {this.state.viewInspections && (
          <InspectionsView
            group={this.state.edit}
            onClose={this.setCloseDetailView}
          />
        )}
      </StyledWrap>
    );
  }
}

export default connect(
  (state) => {
    return {
      list: state.calendar.list,
      startDate: state.app.search.startDate,
      local: state.app.search.local,
      elementType: state.app.search.elementTypeSelected,
      team: state.app.search.team,
      status: state.calendar.status,
      freguesia: state.app.search.freguesia,
      selected: state.app.search.selected,
      editRoute: state.calendar.route,
      isAdmin: state.user.data.admin,
      role: state.user.data.role,
      userIsAdmin: state.user !== null && state.user.data.admin,
      menuData: state.app.menus[state.user.data.role].find(
        (menu) => menu.path === `/calendar`
      ),
      userTeam: state.user.data.team ? state.user.data.team : null,
    };
  },
  { getEvents, setSearch, setRotasDetails, editRouteById }
)(Calendar);
