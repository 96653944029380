import * as constants from "./constants";
import axios from "axios";
import { store } from "../../store/configure";
import { toastr } from "react-redux-toastr";
import moment from "moment";

export function getTasks() {
  return (dispatch) => {
    getTasksList(dispatch);
  };
}

export function setVisit(pid, eidElement) {
  return async (dispatch) => {
    dispatch({
      type: constants.SAVING,
    });

    try {
      const search = store.getState().app.search;

      //lets get the correct element id, from tasks or from own menu
      let eid = null;
      if (search.element && typeof search.element !== "object") {
        eid = search.element;
      }

      // let create data set
      const setData = {
        type: "visita",
        selected: eidElement || search.selected,
        details: { date: new Date(), endDate: new Date() },
        area: search.freguesia,
        attr: { eid: eid || eidElement },
        pid: eidElement || pid,
        location: pid,
      };

      // let set post data
      let url = `/api/v1/maintenances`;

      const response = await axios.post(url, setData);

      if (response.data.msg) {
        dispatch({
          type: constants.SAVED,
          data: response.data.msg,
        });
        toastr.success("Concluido", "Visita Salva com sucesso");
      }
    } catch (error) {
      toastr.error("Verifique os dados introduzidos");
      console.log(error);

      dispatch({
        type: constants.ERROR,
      });
    }
  };
}

export function getTasksList(dispatch) {
  dispatch({
    type: constants.GETTING,
  });
  const search = store.getState().app.search;
  /*
 `/api/v1/events/tasks/${encodeURI(
          search.freguesia || 'all'
        )}/${encodeURI(search.local || 'all')}/${encodeURI(
          search.selected || 'all'
        )}/${encodeURI(
          moment(search.startDate).format('YYYY-MM-DD')
        )}/${encodeURI(moment(search.startDate).format('YYYY-MM-DD'))}/`
        */
  axios
    .get(
      `/api/v1/events/tasks/all/all/all/${encodeURI(
        moment(search.startDate)
          .subtract(2, "days") // for when team adds more than accountet
          .format("YYYY-MM-DD")
      )}/${encodeURI(
        moment(search.startDate)
          .add(2, "days") // for when team adds more than accountet
          .format("YYYY-MM-DD")
      )}/`
    )
    .then(function(response) {
      if (response.data.msg) {
        dispatch({
          type: constants.RECEIVED,
          data: response.data.msg,
        });
      }
    })
    .catch(function(response) {
      toastr.error("Ocorreu um erro a obter os eventos");

      dispatch({
        type: constants.ERROR,
      });
      console.log(response);
    });
}
