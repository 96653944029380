import * as constants from "./constants";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { store } from "../../store/configure";
import { getInspectionsList } from "../Inspections/actions";
import { getMaintenancesList } from "../Maintenances/actions";
import { getTasksList } from "../Tasks/actions";

let getDataActiveParkTimeout;
export function setUserTypeSearch(type) {
  return {
    type: constants.SET_USER_TYPE,
    data: type,
  };
}

export function setMapInteraction(type) {
  return {
    type: constants.SET_MAP_INTERACTION,
    data: type,
  };
}

export function finishedSearch() {
  return {
    type: constants.FINISHEDSEARCH,
  };
}

export function recenterMap() {
  return {
    type: constants.RECENTER,
  };
}

export function recenterEnd() {
  return {
    type: constants.RECENTEREND,
  };
}

export function toggleMap(open) {
  return {
    type: constants.TOGLE_MAP,
    data: open,
  };
}
export function toggleTrackUser() {
  return {
    type: constants.TOGGLE_TRACK_USER,
  };
}

export function toggleElements(open) {
  return {
    type: constants.TOGLE_ELEMENTS,
    data: open,
  };
}

export function toggleAddInterventions(open, addHelp = false) {
  return {
    type: constants.TOGLE_ADD_INTERVENTIONS,
    data: { open, addHelp },
  };
}

export function toggleViewReport(open) {
  return {
    type: constants.TOGLE_VIEW_REPORT,
    data: open,
  };
}

export function toggleAddInspection(open) {
  return {
    type: constants.TOGLE_ADD_INSPECTIONS,
    data: open,
  };
}
export function toggleMobileView(open) {
  return {
    type: constants.TOGLE_MOBILE_VIEW,
    data: open,
  };
}

export function updateZoom(zoom) {
  return {
    type: constants.SET_ZOOM,
    data: zoom,
  };
}

export function getElements(type, pid) {
  return (dispatch) => {
    getElementsPure(dispatch, type, pid);
  };
}

export function getElementsPure(dispatch, type, pid) {
  if (!type || !pid) {
    const search = store.getState().app.search;
    if (search.selected && search.selected.length > 1) {
      type = "selected";
      pid = search.selected;
    } else if (search.local) {
      type = "local";
      pid = search.local;
    } else {
      return null;
    }
  }

  dispatch({
    type: constants.ELEMENTS_GETTING,
  });
  axios
    .get(`/api/v1/elements/${type}/${pid}`)
    .then(function (response) {
      if (response.data.msg) {
        dispatch({
          type: constants.ELEMENTS_RECEIVED,
          data: response.data.msg,
        });
      }
    })
    .finally(() => {
      dispatch({
        type: constants.ELEMENTS_RECEIVED_END,
      });
    })

    .catch(function (response) {
      toastr.error("Ocorreu um erro a obter as elementos");
      dispatch({
        type: constants.ELEMENTS_ERROR,
      });
    });
}

export function setDateSearch(type, initial) {
  return (dispatch) => {
    dispatch({
      type: constants.SET_SEARCH_DATE,
      data: type,
    });

    getInspectionsList(dispatch);
    getMaintenancesList(dispatch);
    if (initial) getTasksList(dispatch);
  };
}

export function setSearch(seachElement, nofetch) {
  return (dispatch) => {
    dispatch({
      type: constants.UPDATE_SEARCH,
      data: seachElement,
    });
    if (!nofetch && seachElement.element !== "edit") {
      getInspectionsList(dispatch, seachElement);
      getMaintenancesList(dispatch, seachElement);
      getElementsPure(dispatch);
      // searchElements(dispatch, seachElement);
    }
  };
}

// export function searchElements(dispatch, seachElement) {
//   const role = store.getState().user.data.role;
//   const list = store.getState().parklist.list;
//   const search = store.getState().app.search;
//   //another HACK to display elements for EV team
//   seachElement.element = 'selected';
//   console.log(seachElement);
//   console.log(search);

//   const parkList = list
//     .filter(park => {
//       return search.selected.includes(park.numero);
//     })
//     .map(p => p.eid)
//     .join(',');

//   console.log(parkList);

//   let url = `/api/v1/elements/selected/${search.local}`;

//   dispatch({
//     type: constants.ELEMENTS_GETTING,
//   });
//   if (url !== '') {
//     axios
//       .get(url)
//       .then(function(response) {
//         console.log(response);
//         if (response.data.msg) {
//           dispatch({
//             type: constants.ELEMENTS_RECEIVED,
//             data: response.data.msg,
//           });
//         }
//       })
//       .catch(function(response) {
//         // toastr.error('Ocorreu um erro a obter as elementos');
//         dispatch({
//           type: constants.ELEMENTS_ERROR,
//         });
//       });
//   }
// }
/*** used for debounde get data from park selec */
export async function getDataActivePark(dispatch) {
  try {
    const response = await Promise.all([
      getInspectionsList(dispatch),
      getMaintenancesList(dispatch),
      getElementsPure(dispatch),
    ]);

    dispatch({
      type: constants.ELEMENTS_RECEIVED,
      data: response[2].data.msg,
    });
  } catch (e) {
    dispatch({
      type: constants.ELEMENTS_ERROR,
    });
  }
}
export function setActivePark(
  parkId,
  local,
  freguesia,
  element,
  unic,
  center,
  elementSelect
) {
  return async (dispatch) => {
    dispatch({
      type: constants.UPDATE_ACTIVE_PARK,
      data: { parkId, local, freguesia, element, unic, center, elementSelect },
    });
    // clearTimeout(getDataActiveParkTimeout);

    getDataActivePark(dispatch);
  };
}

export function setUniqueActivePark(parkId, local, freguesia, element, unic) {
  return async (dispatch) => {
    dispatch({
      type: constants.UPDATE_UNIQUE_ACTIVE_PARK,
      data: { parkId, local, freguesia, element, unic },
    });
    try {
      getElementsPure(dispatch);
    } catch (e) {
      dispatch({
        type: constants.ELEMENTS_ERROR,
      });
    }
  };
}
