import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Row, Col } from "react-flexbox-grid";
import { MenuItem, Select } from "@material-ui/core";
import moment from "moment";
import TeamFilter from "../../components/Filters/TeamFilter";
import DateRangeFilter from "../../components/Filters/DateRangeFilter";

import FullScreenModal from "../../components/Layout/FullScreenModal";
import MainButton from "../../components/Buttons/MainButton";
import MapComponent from "../../components/Map/Map";
import ContentWrap from "../../components/Layout/ContentWrap";
import SectionTitle from "../../components/Text/SectionTitle";
import MenuIcon from "../../components/Buttons/MenuIcon";
import LocationFilter from "../../components/Filters/LocationFilter";
import ParcelsFilter from "../../components/Filters/ParcelsFilter";
import { default as ScrollArea } from "../../components/Layout/ScrollBar";
import { toastr } from "react-redux-toastr";

// import { setActiveStep, getMaintenances } from './actions';
import {
  setParkInRoute,
  setRotasDetails,
  setRoute,
  duplicateRoute,
  delRoute,
} from "./actions";

const PageTitleSub = styled.h2`
  && {
    font-size: 38px;
    margin-top: 2em;
    color: #4a4a4a;
    font-weight: normal;
    letter-spacing: -1.09px;
  }
`;

const StyledMainButton = styled(MainButton)`
  && {
    width: 100%;
    margin-top: 1em;
    font-size: 0.8em;
  }
`;
const FilterList = styled.div`
  && {
    .maintenance-filter {
      height: 250px;
      padding-right: 18px;
      .scrollbar {
        border-radius: 1em;
      }
    }
  }
`;
const OptionWrap = styled(ContentWrap)`
  && {
    padding: 1em;
    margin: 0.5em 0;
    align: center;
  }
`;
const ContentPadder = styled.div`
  && {
    padding: 1em;
  }
`;

const OptionWrapElements = styled(ContentWrap)`
  && {
    padding: 1em;
    margin: 0.5em 0;
    display: flex;
    align-items: center;
    justify-content: left;
  }
`;

const Close = styled.span`
  && {
    font-size: 2.5em;
    margin: 0 0.5em 0 0;
    text-align: center;
    color: #989898;
    vertical-align: middle;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 11;
    cursor: pointer;
    :hover {
      color: #4da1ff;
      transform: rotate(90deg);
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
  }
`;

const RemovePark = styled.span`
  && {
    font-size: 1.5em;
    margin: 0 0.5em;
    text-align: center;
    color: #989898;
    vertical-align: middle;
    display: inline-block;
    text-align: left;
    cursor: pointer;
    :hover {
      color: #4da1ff;
      transform: rotate(90deg);
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
  }
`;

const ParkName = styled.div`
  && {
    font-size: 0.8em;
    color: #464f58;
    font-weight: bold;
    text-align: left;
  }
`;
const ParkLocation = styled.div`
  && {
    font-size: 0.8em;
    color: #464f58;
    text-align: left;
  }
`;

const ParkSelect = styled.div`
  && {
    font-size: 0.8em;
    color: #464f58;
    text-align: center;
  }
`;

const MapContainer = styled.div`
  && {
    height: 100%;
    @media (max-width: 768px) {
      height: 64vh;
    }
    /* height: calc(100vh - 300px); */
  }
`;

const TitleWrap = styled.div`
  && {
    .reset {
      display: none;
    }
  }
`;

const Inline = styled.div`
  && {
    display: inline-block;
  }
`;

const RepetitionFilter = styled(Select)`
  && {
    border: 0;
    font-size: 0.9em;
  }
`;

const DisableButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    margin-top: 1em;
    background: #df5757;
    width: 100%;
    border-color: #f44336;
    &:hover {
      color: #df5757;
      .icons {
        color: #df5757;
      }
    }
  }
`;

class EditRoute extends React.Component {
  constructor(props) {
    super(props);

    this.delete = this.delete.bind(this);
    this.togglePark = this.togglePark.bind(this);
    this.setDetails = this.setDetails.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);

    this.state = {
      local: "",
      freguesia: "",
      selected: "",
    };
  }

  componentDidMount() {
    const { team, routeTeam } = this.props;
    // if the user has a team then we will set it to his own team
    if (team && team !== routeTeam)
      this.props.setRotasDetails({
        element: "team",
        value: team,
      });
  }

  getGeoJson() {
    const { geoJson, route } = this.props;

    if (!geoJson) return [];

    return geoJson.reduce((acc, park, index) => {
      if (this.state.freguesia !== "") {
        if (park.properties.freguesia === this.state.freguesia) {
          acc.push({
            ...park,
            properties: route.find((rout) => rout === park.properties.numero)
              ? { ...park.properties, color: "#01f4c1" }
              : park.properties,
          });
        }
      } else {
        acc.push({
          ...park,
          properties: route.find((rout) => rout === park.properties.numero)
            ? { ...park.properties, color: "#01f4c1" }
            : park.properties,
        });
      }
      return acc;
    }, []);
  }

  validateInput(name, value) {
    switch (name) {
      case "startDate":
        if (moment(value).isBefore(this.props.endDate)) {
          return value;
        }
        toastr.error("Data invalida, esta data nao será salva");
        return false;
      case "endDate":
        if (moment(value).isAfter(this.props.startDate)) {
          return value;
        }
        toastr.error("Data invalida, esta data nao será salva");
        return false;
      default:
        return value;
    }
  }
  setDetails(e, value) {
    const validChangeData = this.validateInput(e.target.name, e.target.value);
    if (validChangeData) {
      this.props.setRotasDetails({
        element: [e.target.name],
        value: validChangeData,
      });
    }
  }

  prepareParkToSet(park) {
    const { geoJson, role } = this.props;
    let totalParks = park.properties ? park.properties.numero : park;
    const ParkLocal = park.properties ? park.properties.local : park;
    if (role === "EV") {
      totalParks = geoJson.reduce((prev, curr) => {
        if (curr.properties.local === ParkLocal)
          prev = [...prev, curr.properties.numero];
        return prev;
      }, []);
    }
    this.props.setParkInRoute(totalParks);
  }

  togglePark(park) {
    this.prepareParkToSet(park);
  }

  setRoute(park) {
    this.prepareParkToSet(park);
  }

  delRoute() {
    this.prepareParkToSet();
  }

  delete() {
    const toastrConfirmOptions = {
      onOk: () => this.props.delRoute(),
      onCancel: () => console.log("CANCEL: clicked"),
    };
    toastr.confirm(
      "Tem a certeza? Esta acção não pode ser revertida",
      toastrConfirmOptions
    );
  }

  handleSearchChange(e) {
    const { role } = this.props;
    if (e.target.name === "local" && e.target.value) {
      this.prepareParkToSet(e.target.value);
    } else {
      this.setState({
        selected: "",
      });
    }

    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  renderSelectedParkEV() {
    const { geoJson, route } = this.props;
    if (!geoJson) return null;
    // first lets get all unique folhas
    const selectParks = geoJson.reduce((prev, curr) => {
      // lets check if we already added folha
      if (!prev.find((p) => p.properties.local === curr.properties.local)) {
        //lets find and push this park
        const thisPark = route.find((r) => r === curr.properties.numero);
        if (thisPark) {
          prev.push(curr);
        }
      }

      return prev;
    }, []);

    //handle no results
    if (selectParks.lenght < 1) {
      return (
        <OptionWrap>
          <ParkSelect>Nenhum local selecionado</ParkSelect>
        </OptionWrap>
      );
    }

    // render results
    return (
      <div>
        {selectParks.map((place, index) => {
          return (
            <OptionWrapElements key={index}>
              <RemovePark
                onClick={() => this.togglePark(place)}
                className={`icon-close icons`}
              />
              <Inline>
                <ParkName>
                  {place.properties.local}, {place.properties.name}
                </ParkName>
                <ParkLocation>{place.properties.freguesia}</ParkLocation>
              </Inline>
            </OptionWrapElements>
          );
        })}
      </div>
    );
  }

  renderSelectedPark() {
    const { geoJson } = this.props;
    return (
      <div>
        {this.props.route.map((place, index) => {
          const thisPark = geoJson.find(
            (park) => park.properties.numero === place
          );

          if (!thisPark || this.props.route.length < 1)
            return (
              <OptionWrap key={index}>
                <ParkSelect>Nenhum local selecionado</ParkSelect>
              </OptionWrap>
            );
          return (
            <OptionWrapElements key={index}>
              <RemovePark
                onClick={() => this.togglePark(thisPark)}
                className={`icon-close icons`}
              />
              <Inline>
                <ParkName>{thisPark.properties.name}</ParkName>
                <ParkLocation>
                  {thisPark.properties.freguesia}, {thisPark.properties.local}
                </ParkLocation>
              </Inline>
            </OptionWrapElements>
          );
        })}
      </div>
    );
  }

  render() {
    const {
      onClose,
      location,
      routeTeam,
      startDate,
      endDate,
      frequency,
      role,
      user,
      team,
    } = this.props;
    const { local, freguesia, selected } = this.state;
    const isOwnRoute =
      (user.teams && user.teams.find((t) => t === routeTeam)) ||
      team === routeTeam;
    return (
      <FullScreenModal nopadding>
        <Close onClick={() => onClose()} className={`icon-close icons`} />

        <Row>
          <Col xs={12} md={8}>
            <MapContainer>
              <MapComponent
                geoJson={{ geoJson: this.getGeoJson() }}
                onClickMarker={this.togglePark}
              />
            </MapContainer>
          </Col>
          <Col xs={12} md={4}>
            <ContentPadder>
              <TitleWrap>
                <PageTitleSub>Adicionar Rota</PageTitleSub>
                <ParcelsFilter
                  handleSearchChange={this.handleSearchChange}
                  override={{ local, location, freguesia, selected }}
                />
              </TitleWrap>
              <SectionTitle>
                <strong>{this.props.route.length}</strong> seleccionados{" "}
              </SectionTitle>

              <FilterList>
                <ScrollArea
                  speed={0.8}
                  className="maintenance-filter"
                  contentClassName="maintenance-filter-content"
                  horizontal={false}
                >
                  {this.renderSelectedParkEV()}
                </ScrollArea>
              </FilterList>

              {(!team || (team.type && team.type === "internal")) && (
                <div>
                  <SectionTitle>Selecionar Equipa</SectionTitle>
                  <OptionWrap>
                    <TeamFilter
                      selected={routeTeam}
                      noMulti={true}
                      handleChange={this.setDetails}
                    />
                  </OptionWrap>
                </div>
              )}
              <SectionTitle>Data</SectionTitle>

              <OptionWrap>
                <DateRangeFilter
                  noTitle={true}
                  handleSearchChange={this.setDetails}
                  endDate={endDate}
                  startDate={startDate}
                />
                <RepetitionFilter
                  fullWidth
                  name={"frequency"}
                  value={frequency}
                  onChange={this.setDetails}
                  inputProps={{
                    name: "frequency",
                    id: "frequency",
                  }}
                >
                  <MenuItem value="">
                    <em>Frequencia</em>
                  </MenuItem>
                  <MenuItem value={"daily"}>Diariamente</MenuItem>
                  <MenuItem value={"2d"}>Cada 2 dias</MenuItem>
                  <MenuItem value={"3d"}>Cada 3 dias</MenuItem>
                  <MenuItem value={"4d"}>Cada 4 dias</MenuItem>
                  <MenuItem value={"weekly"}>Semanalmente</MenuItem>
                  <MenuItem value={"fortnight"}>Quinzenalmente</MenuItem>
                  <MenuItem value={"3s"}>Cada 3 Semanas</MenuItem>
                  <MenuItem value={"monthly"}>Mensalmente</MenuItem>
                </RepetitionFilter>
              </OptionWrap>

              {(this.props.isAdmin || isOwnRoute) && (
                <StyledMainButton onClick={() => this.props.setRoute()}>
                  <MenuIcon className={`icon-pin icons`} /> Salvar Rota
                </StyledMainButton>
              )}
              {(this.props.isAdmin || isOwnRoute) && this.props.routeId && (
                <StyledMainButton onClick={() => this.props.duplicateRoute()}>
                  <MenuIcon className={`icon-plus icons`} /> Duplicar Rota
                </StyledMainButton>
              )}
              {(this.props.isAdmin || isOwnRoute) && this.props.routeId && (
                <DisableButton onClick={() => this.delete()}>
                  <MenuIcon className={`icon-close icons`} /> Apagar Rota
                </DisableButton>
              )}
            </ContentPadder>
          </Col>
        </Row>
      </FullScreenModal>
    );
  }
}

export default connect(
  (state) => {
    return {
      activeStep: state.maintenances.activeStep,
      geoJson: state.parklist.geoJson,
      route: state.calendar.route.list,
      routeId: state.calendar.route.id,
      routeTeam: state.calendar.route.team,
      startDate: state.calendar.route.startDate,
      elementType: state.app.search.elementTypeSelected,
      endDate: state.calendar.route.endDate,
      frequency: state.calendar.route.frequency,
      location: state.parklist.location,
      role: state.user.data.role,
      isAdmin: state.user !== null && state.user.data.admin,
      user: state.user.data,
      team: state.user.data.team ? state.user.data.team : null,
    };
  },
  { setParkInRoute, setRotasDetails, setRoute, duplicateRoute, delRoute }
)(EditRoute);
