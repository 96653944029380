import * as constants from './constants';
import axios from 'axios';
import { store } from '../../store/configure';
import { toastr } from 'react-redux-toastr';

export function addNewToggle(id, state) {
  return {
    type: constants.ADD_NEW_TOGGLE,
    data: { id, state },
  };
}

export function setActiveStep(step) {
  return {
    type: constants.SET_ACTIVE_STEP,
    data: step,
  };
}

export function reset() {
  return {
    type: constants.RESET,
  };
}

export function setDetails(details) {
  return {
    type: constants.SET_DETAILS,
    data: details,
  };
}

export function setType(type) {
  return {
    type: constants.SET_TYPE,
    data: type,
  };
}

export function setPhotos(photos) {
  return {
    type: constants.SET_PHOTOS,
    data: photos,
  };
}

export function setHistory(history = []) {
  return {
    type: constants.UPDATED_HISTORY,
    data: history,
  };
}

export function updateInspection(state) {
  return dispatch => {
    dispatch({
      type: constants.SAVING,
    });
    const data = state;
    const id = data.id;

    axios
      .put(`/api/v1/inspections/${id}`, data)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.UPDATED,
            data: response.data.msg,
          });
          toastr.success('Login', 'Inspeção Salva com sucesso');
        }
      })
      .catch(function(response) {
        toastr.error('Verifique os dados introduzidos');

        dispatch({
          type: constants.ERROR,
        });
        console.log(response);
      });
  };
}

export function setInspectionEV() {
  return async dispatch => {
    dispatch({
      type: constants.SAVING,
    });

    try {
      const data = store.getState().inspections;
      const search = store.getState().app.search;

      //lets get the correct element id, from tasks or from own menu
      let eid = null;
      if (search.element && typeof search.element !== 'object') {
        eid = search.element;
      }

      let park = [];

      if (search.selected.length < 2) {
        park = search.selected[0];
      } else {
        park = null;
        data.details = {
          ...data.details,
          attr: {
            ...data.details.attr,
            relation: search.selected,
          },
        };
      }

      // let create data set
      const setData = {
        type: data.details.type || 'general',
        details: data.details,
        selected: park,
        history: data.history,
        eid,
        location: search.local,
        area: search.freguesia,
      };

      // let set post data
      let url = `/api/v1/inspections`;

      const response = await axios.post(url, setData);

      if (response.data.msg) {
        dispatch({
          type: constants.SAVED,
          data: response.data.msg,
        });
        toastr.success('Sucesso', ' Informação salva');
      }
    } catch (error) {
      toastr.error('Verifique os dados introduzidos');
      console.log(error);

      dispatch({
        type: constants.ERROR,
      });
    }
  };
}

export function setComment(pid) {
  return async dispatch => {
    dispatch({
      type: constants.SAVING,
    });

    try {
      const data = store.getState().inspections;
      const search = store.getState().app.search;
      const parklistData = store.getState().parklist; //temporary untill model is created backend

      //lets get the correct element id, from tasks or from own menu
      let eid = null;
      if (search.element && typeof search.element !== 'object') {
        eid = search.element;
      }
      // let create data set
      const setData = {
        type: 'comentario',
        selected: pid,
        pid: pid,
        history: [data.details.group],
        details: { ...data.details, status: 0 },
        eid,
      };

      // let set post data
      let url = `/api/v1/inspections`;

      const response = await axios.post(url, setData);

      if (response.data.msg) {
        dispatch({
          type: constants.SAVED,
          data: response.data.msg,
        });
        toastr.success('Concluido', 'Comentario Salvo com sucesso');
      }
    } catch (error) {
      toastr.error('Verifique os dados introduzidos');
      console.log(error);

      dispatch({
        type: constants.ERROR,
      });
    }
  };
}

export function deleteInspection(state) {
  const data = state;
  const id = data.id;
  return dispatch => {
    axios
      .delete(`/api/v1/inspections/${id}`)
      .then(function(response) {
        toastr.success('Eleminar', 'foi Eleminada com sucesso');
        dispatch({
          type: constants.SAVED,
        });
      })
      .catch(function(response) {
        toastr.error('Verifique os dados introduzidos');

        dispatch({
          type: constants.ERROR,
        });
      });
  };
}

export function setInspectionDone(id) {
  return dispatch => {
    axios
      .get(`/api/v1/inspections/approve/${id}`)
      .then(function(response) {
        toastr.success('Inspecção', 'foi Aprovada com sucesso');
        dispatch({
          type: constants.APPROVED_INSPECTION_GROUP,
        });
      })
      .catch(function(response) {
        toastr.error('Verifique os dados introduzidos');

        dispatch({
          type: constants.ERROR,
        });
      });
  };
}

export function setMaintenance(id) {
  return dispatch => {
    dispatch({
      type: constants.SAVING,
    });

    axios
      .get(`/api/v1/maintenances/setfrominspection/${id}`)
      .then(function(response) {
        toastr.success('Manutenção', 'foi criada com sucesso');
      })
      .catch(function(response) {
        toastr.error('Verifique os dados introduzidos');

        dispatch({
          type: constants.ERROR,
        });
      });
  };
}

export function getInspection(id) {
  return dispatch => {
    dispatch({
      type: constants.HISTORY_GETTING,
    });
    axios
      .get(`/api/v1/inspections/history/${id}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.HISTORY_RECEIVED,
            data: [response.data.msg],
          });
        }
      })
      .catch(function(response) {
        // toastr.error('Ocorreu um erro a obter as inspecções');

        dispatch({
          type: constants.HISTORY_ERROR,
        });
        console.log(response);
      });
  };
}

export function getInspectionsGroup(id) {
  return dispatch => {
    dispatch({
      type: constants.GROUP_GETTING,
    });
    axios
      .get(`/api/v1/inspections/group/${id}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.GROUP_RECEIVED,
            data: response.data.msg,
          });
        }
      })
      .catch(function(response) {
        // toastr.error('Ocorreu um erro a obter as inspecções');

        dispatch({
          type: constants.GROUP_ERROR,
        });
        console.log(response);
      });
  };
}

export function getInspectionsHistory(id) {
  return dispatch => {
    dispatch({
      type: constants.HISTORY_GETTING,
    });
    axios
      .get(`/api/v1/inspections/history/${id}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.HISTORY_RECEIVED,
            data: response.data.msg,
          });
        }
      })
      .catch(function(response) {
        // toastr.error('Ocorreu um erro a obter as inspecções');

        dispatch({
          type: constants.HISTORY_ERROR,
        });
        console.log(response);
      });
  };
}

export function getDamages() {
  return dispatch => {
    dispatch({
      type: constants.DAMAGES_GETTING,
    });
    axios
      .get(`/api/v1/damages`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.DAMAGES_RECEIVED,
            data: response.data.msg,
          });
        }
      })
      .catch(function(response) {
        toastr.error('Ocorreu um erro a obter lista de danos');
        console.log(response);
      });
  };
}

export function getInspections() {
  return dispatch => {
    dispatch({
      type: constants.GETTING,
    });
    getInspectionsList(dispatch);
  };
}

export function getInspectionsList(dispatch) {
  dispatch({
    type: constants.GETTING,
  });
  const search = store.getState().app.search;
  const role = store.getState().user.data.role;

  let what = 'general';
  let parks = search.selected.length > 0 ? search.selected : 'all';
  let local = search.local || 'all';
  axios
    .get(
      `/api/v1/inspections/${encodeURI(what)}/${encodeURI(
        search.freguesia || 'all'
      )}/${encodeURI(local)}/${encodeURI(parks)}/${encodeURI(
        search.startDate
      )}/${encodeURI(search.endDate)}/${encodeURI(search.status)}`
    )
    .then(function(response) {
      if (response.data.msg) {
        dispatch({
          type: constants.RECEIVED,
          data: response.data.msg,
        });
      } else {
        dispatch({
          type: constants.RECEIVED,
          data: [],
        });
      }
    })
    .catch(function(response) {
      // toastr.error('Ocorreu um erro a obter as inspecções');

      dispatch({
        type: constants.ERROR,
      });
      console.log(response);
    });
}
