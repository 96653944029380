import { GETTING, RECEIVED, ERROR } from './constants';

import { getCurrentDate } from '../../services/utils';

const status = {
  edit: 'edit',
  error: 'error',
  saving: 'saving',
  edited: 'edited',
  getting: 'getting',
  received: 'received',
};

let initialState = {
  status: '',
  data: [],
  reportData: [],
};

const isLocalStorage = typeof localStorage !== 'undefined';
//lets load user data for reload
if (isLocalStorage && localStorage.dashboard) {
  initialState = JSON.parse(localStorage.dashboard);
  initialState.search = {
    ...initialState.search,
    startDate: getCurrentDate(true),
    endDate: getCurrentDate(),
  };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GETTING:
      return {
        ...state,
        status: status.getting,
      };
    case RECEIVED:
      return {
        ...state,
        status: status.received,
        data: action.data,
      };

    case ERROR:
      return { ...state, status: status.error };

    default:
      return state;
  }
};
