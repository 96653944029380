import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Row, Col } from "react-flexbox-grid";
import { FormControlLabel, Select, Switch, MenuItem } from "@material-ui/core";

import SectionTitle from "../../../components/Text/SectionTitle";
import FullScreenModal from "../../../components/Layout/FullScreenModal";
import PageTitle from "../../../components/Text/PageTitle";
import MainButton from "../../../components/Buttons/MainButton";
import TextField from "../../../components/form-wrappers/TextField";
import MenuIcon from "../../../components/Buttons/MenuIcon";
import ContentWrap from "../../../components/Layout/ContentWrap";
import AutoComplete from "../../../components/form-wrappers/AutoComplete";
import ButtonGroup from "../../../components/Buttons/ButtonGroup";
import GroupedButton from "../../../components/Buttons/GroupedButton";

import ParkSelectList from "./ParkSelectList";

// import DetailsSpecific from './.steps/DetailsSpecific';
// import ThankYou from './steps/ThankYou';

import {
  setUser,
  getUsers,
  getUser,
  setDetails,
  resetUser,
  toggleUserStatus,
} from "../actions";
// import { setHistory } from './actions';

const Section = styled.div`
  && {
    margin: 2em 0;
    max-width: 1200px;
  }
`;
const OptionWrap = styled(ContentWrap)`
  && {
    padding: 2em;
    text-align: left;
  }
`;

const SubTitle = styled.div`
  && {
    margin: 1em 0 0.5em 0;
    font-size: 0.8em;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    text-transform: uppercase;
  }
`;

const Input = styled(TextField)`
  && {
    width: 100%;
    padding: 10px 0;
    margin: 0;
    text-align: left;
    textarea {
      height: auto;
    }
  }
`;
const NextButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    margin-top: 1em;
    width: 100%;
  }
`;

const DisableButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    margin-top: 1em;
    background: #df5757;
    width: 100%;
    border-color: #f44336;
    &:hover {
      color: #df5757;
      .icons {
        color: #df5757;
      }
    }
  }
`;

const EnableButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    margin-top: 1em;
    background: #6ab053;
    width: 100%;
    border-color: #6ab053;
    &:hover {
      color: #6ab053;
      .icons {
        color: #6ab053;
      }
    }
  }
`;

const Close = styled.span`
  && {
    font-size: 2.5em;
    margin: 0 0.5em 0 0;
    text-align: center;
    color: #989898;
    vertical-align: middle;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 11;
    cursor: pointer;
    :hover {
      color: #4da1ff;
      transform: rotate(90deg);
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
  }
`;
const Toggle = styled(Switch)`
  && {
    .MuiSwitch-bar-192,
    .jss192 {
      color: #4da1ff;
      background: #4da1ff;
    }
    .MuiSwitch-iconChecked-194,
    .jss194 {
      color: #4da1ff;
    }
  }
`;
const Controlers = styled.div`
  && {
    margin-top: 3%;
  }
`;

const GroupedButtonBlockText = styled.span`
  && {
    display: block;
    font-size: 0.8em !important;
    margin-top: 0.4em;
  }
`;

class AddNewUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: props.edit || "",
      admin: false,
      external: false,
      hiddenAutocomplete: true,
    };

    this.handleSaveUser = this.handleSaveUser.bind(this);
    this.handleToggleUser = this.handleToggleUser.bind(this);
    this.handleChangeTeam = this.handleChangeTeam.bind(this);
    this.handleTeamsChange = this.handleTeamsChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectedParksChange = this.handleSelectedParksChange.bind(this);
    this.handleDashboard = this.handleDashboard.bind(this);
    this.handleRega = this.handleRega.bind(this);

    // if (this.state.edit !== '') {
    //   props.getUser(props.edit);
    // }
    const t = this;
    setTimeout(() => {
      t.setState({ hiddenAutocomplete: false });
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    if (this.props.status === "saved") {
      this.props.getUsers();
      this.props.closeModal();
    }
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.status == 'fetched' && nextProps.data) {
  //     this.setState({
  //       name: nextProps.data.name || '',
  //       emailUser: nextProps.data.email || '',
  //       permissions: nextProps.data.permissions || '',
  //       team: nextProps.data.Team ? nextProps.data.Team : '',
  //       admin: nextProps.data.internal || false,
  //     });
  //   }
  // }

  handleSaveUser() {
    this.props.setUser();
  }

  handleToggleUser() {
    this.props.toggleUserStatus();
  }

  handleChangeTeam(value) {
    this.props.setDetails({
      element: "Team",
      value,
    });
  }

  handleTeamsChange(value) {
    this.props.setDetails({
      element: "attr",
      value: {
        ...this.props.data.attr,
        teams: value.target.value,
      },
    });
  }

  handleChangeType(value) {
    this.props.setDetails({
      element: "type",
      value,
    });
  }

  handleChangeWrite(value) {
    this.props.setDetails({
      element: "write",
      value,
    });
  }

  handleSelectedParksChange(parks) {
    this.props.setDetails({
      element: ["parks"],
      value: parks,
    });
  }

  handleRega(e) {
    this.props.setDetails({
      element: "attr",
      value: {
        ...this.props.data.attr,
        rega: !this.props.data?.attr?.rega,
      },
    });
  }

  handleDashboard(e) {
    this.props.setDetails({
      element: "attr",
      value: {
        ...this.props.data.attr,
        dashboard: !this.props.data?.attr?.dashboard,
      },
    });
  }

  handleChange(e) {
    let valueChanged = e.target.value;
    switch (e.target.name) {
      case "admin":
        valueChanged = !this.props.data.admin;

        break;

      default:
        break;
    }

    this.props.setDetails({
      element: [e.target.name],
      value: valueChanged,
    });
  }

  render() {
    const {
      closeModal,
      menuInspections,
      menuInterventions,
      role,
      data,
      teams,
    } = this.props;
    return (
      <FullScreenModal>
        <Close onClick={() => closeModal()} className={`icon-close icons`} />
        <Section>
          <PageTitle>Utilizador</PageTitle>

          <Row>
            <Col md={12}>
              <Row>
                <Col xs={12} sm={8} md={8} lg={8} className={"m-b-15"}>
                  <SectionTitle>Dados de Utilizador</SectionTitle>
                  <OptionWrap>
                    <Input
                      label="Nome de Utilizador"
                      onChange={(e) => this.handleChange(e)}
                      name="name"
                      input={{}}
                      value={data && data.name}
                      meta={{ invalid: false }}
                    />
                    <Input
                      label="Email"
                      onChange={(e) => this.handleChange(e)}
                      name="email"
                      autoComplete={"off"}
                      value={data && data.email}
                      input={{}}
                      meta={{ invalid: false }}
                    />
                    <Input
                      label="Password (mais de 6 characters)"
                      onChange={(e) => this.handleChange(e)}
                      name="password"
                      type={"password"}
                      autoComplete={"off"}
                      value={data && data.password}
                      input={{}}
                      meta={{ invalid: false }}
                    />
                    <Controlers>
                      {!this.state.hiddenAutocomplete && this.props.teams && (
                        <AutoComplete
                          updateState={this.handleChangeTeam}
                          defaultValue={data && data.Team}
                          options={this.props.teams}
                          placeholder={
                            "Pesquise ou adicione uma equipa ou empresa (Permissões de acesso)"
                          }
                        />
                      )}
                    </Controlers>
                  </OptionWrap>
                </Col>

                <Col xs={12} sm={4} md={4} lg={4} className={"m-b-15"}>
                  <SectionTitle>Permissões</SectionTitle>
                  <OptionWrap>
                    <FormControlLabel
                      control={
                        <Toggle
                          onChange={this.handleChange}
                          value="true"
                          checked={data && data.admin}
                          label="Admin"
                          // disabled={!data.edit}
                          name="admin"
                          color="primary"
                          meta={{ invalid: false }}
                        />
                      }
                      label="Administrador"
                    />
                    <FormControlLabel
                      control={
                        <Toggle
                          onChange={this.handleDashboard}
                          value="true"
                          checked={data && data.attr && data.attr.dashboard}
                          label="Dashboard"
                          // disabled={!data.edit}
                          name="Dashboard"
                          color="primary"
                          meta={{ invalid: false }}
                        />
                      }
                      label="Dashboard"
                    />
                    <FormControlLabel
                      control={
                        <Toggle
                          onChange={this.handleRega}
                          value="true"
                          checked={data && data.attr && data.attr.rega}
                          label="Rega"
                          // disabled={!data.edit}
                          name="Rega"
                          color="primary"
                          meta={{ invalid: false }}
                        />
                      }
                      label="Sistemas de Rega"
                    />
                    {role === "global" && (
                      <Select
                        fullWidth
                        name={"permissions"}
                        value={data && data.permissions}
                        onChange={this.handleChange}
                        inputProps={{
                          name: "permissions",
                          id: "permissions",
                        }}
                      >
                        <MenuItem value="" />
                        <MenuItem value={"PI"}>Parque Infantis</MenuItem>
                        <MenuItem value={"EV"}>Espaços Verdes</MenuItem>
                        <MenuItem value={"HC"}>Hortas</MenuItem>
                      </Select>
                    )}
                    <SubTitle>Leitura</SubTitle>
                    <ButtonGroup>
                      <GroupedButton
                        width={"33%"}
                        onClick={() => this.handleChangeType("all")}
                        active={data && data.type === "all"}
                      >
                        <MenuIcon
                          theme={data && data.type === "all"}
                          className={`icon-layers icons`}
                        />{" "}
                        <GroupedButtonBlockText>Todas</GroupedButtonBlockText>
                      </GroupedButton>
                      <GroupedButton
                        width={"33%"}
                        onClick={() => this.handleChangeType("interventions")}
                        active={data && data.type === "interventions"}
                      >
                        <MenuIcon
                          theme={data && data.type === "interventions"}
                          className={`icon-wrench icons`}
                        />
                        <GroupedButtonBlockText>
                          {menuInterventions.name}
                        </GroupedButtonBlockText>
                      </GroupedButton>
                      <GroupedButton
                        width={"33%"}
                        onClick={() => this.handleChangeType("inspections")}
                        active={data && data.type === "inspections"}
                      >
                        <MenuIcon
                          theme={data && data.type === "inspections"}
                          className={`icon-camera icons`}
                        />
                        <GroupedButtonBlockText>
                          {menuInspections.name}
                        </GroupedButtonBlockText>
                      </GroupedButton>
                    </ButtonGroup>

                    <SubTitle>Escrita</SubTitle>
                    <ButtonGroup>
                      <GroupedButton
                        width={"33%"}
                        onClick={() => this.handleChangeWrite("all")}
                        active={data && data.write === "all"}
                      >
                        <MenuIcon
                          theme={data && data.write === "all"}
                          className={`icon-layers icons`}
                        />{" "}
                        <GroupedButtonBlockText>Todas</GroupedButtonBlockText>
                      </GroupedButton>
                      <GroupedButton
                        width={"33%"}
                        onClick={() => this.handleChangeWrite("interventions")}
                        active={data && data.write === "interventions"}
                      >
                        <MenuIcon
                          theme={data && data.write === "interventions"}
                          className={`icon-wrench icons`}
                        />
                        <GroupedButtonBlockText>
                          {menuInterventions.name}
                        </GroupedButtonBlockText>
                      </GroupedButton>
                      <GroupedButton
                        width={"33%"}
                        onClick={() => this.handleChangeWrite("inspections")}
                        active={data && data.write === "inspections"}
                      >
                        <MenuIcon
                          theme={data && data.write === "inspections"}
                          className={`icon-camera icons`}
                        />
                        <GroupedButtonBlockText>
                          {menuInspections.name}
                        </GroupedButtonBlockText>
                      </GroupedButton>
                    </ButtonGroup>

                    <SubTitle>Sub Equipas</SubTitle>

                    <Select
                      fullWidth
                      name={"Teams"}
                      value={
                        data.attr && data.attr.teams ? data.attr.teams : []
                      }
                      multiple
                      onChange={this.handleTeamsChange}
                      inputProps={{
                        name: "teams",
                        id: "teams",
                      }}
                    >
                      {/* <MenuItem key={-1} value={''} /> */}
                      {teams.sort().map((team, index) => (
                        <StyledMenuItem key={index} value={team.id}>
                          {team.label}
                        </StyledMenuItem>
                      ))}
                    </Select>
                    {/* <small>(acessos apenas da equipa principal)</small> */}
                    <NextButton
                      color="primary"
                      onClick={() => this.handleSaveUser()}
                    >
                      Salvar
                      <MenuIcon className={`icon-check icons`} />
                    </NextButton>
                    {data.status === 1 && (
                      <DisableButton
                        color="primary"
                        onClick={() => this.handleToggleUser()}
                      >
                        Desactivar
                        <MenuIcon className={`icon-close icons`} />
                      </DisableButton>
                    )}
                    {data.status === 0 && (
                      <EnableButton
                        color="primary"
                        onClick={() => this.handleToggleUser()}
                      >
                        Activar
                        <MenuIcon className={`icon-check icons`} />
                      </EnableButton>
                    )}
                  </OptionWrap>
                </Col>
              </Row>
            </Col>
          </Row>
        </Section>
        <ParkSelectList
          handleSelectedParksChange={this.handleSelectedParksChange}
          selectedParks={data && data.access}
        />
      </FullScreenModal>
    );
  }
}

export default connect(
  (state) => {
    return {
      teams: state.user.teams,
      status: state.user.status,
      data: state.user.editData,
      role: state.user.data.role,
      menuInspections:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/inspections`
        ),
      menuInterventions:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/interventions`
        ),
    };
  },
  { setUser, getUsers, getUser, setDetails, resetUser, toggleUserStatus }
)(AddNewUser);
