import {
  INITIAL_LOADING_SUCCESS,
  TOGLE_MAP,
  SEARCHING,
  RECENTER,
  FINISHEDSEARCH,
  RECENTEREND,
  TOGLE_MOBILE_VIEW,
  TOGLE_ADD_INTERVENTIONS,
  TOGLE_VIEW_REPORT,
  TOGLE_VIEW_REPORT_MANAGER,
  TOGLE_ADD_INSPECTIONS,
  ELEMENTS_RECEIVED,
  ELEMENTS_RECEIVED_END,
  ELEMENTS_GETTING,
  ELEMENTS_ERROR,
  UPDATE_SEARCH,
  UPDATE_ACTIVE_PARK,
  UPDATE_UNIQUE_ACTIVE_PARK,
  TOGLE_ELEMENTS,
  SET_ZOOM,
  SET_SEARCH_DATE,
  SET_MAP_INTERACTION,
  TOGGLE_TRACK_USER,
  SET_USER_TYPE,
} from "./constants";

import { menus } from "../../data";
import { getCurrentDate } from "../../services/utils";
import { store } from "../../store/configure";

function mobileAndTabletcheck() {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
}

const status = {
  edit: "edit",
  error: "error",
  saving: "saving",
  edited: "edited",
  getting: "getting",
  searchUpdated: "searchUpdated",
};

let initialState = {
  map: false,
  mapType: "default",
  activeMobileView: "list", // filter || map || list
  mapInteraction: "view",
  isLoaded: false,
  trackUserPosition: true, //needed due to bug when edit elements
  recenterMap: false,
  elmentDisplay: true,
  menus,
  isMobile: mobileAndTabletcheck(),
  elementsGeoJson: [],
  zoom: { zoom: 14, center: [38.719637, -9.395666] },
  addInspection: false,
  addIntervention: false,
  addHelp: false,
  viewDayReport: false,
  viewDayReportManager: false,
  search: {
    latestSelection: { type: "", selection: "" },
    local: "",
    freguesia: "",
    selected: [],
    elementTypeSelected: [],
    element: "",
    startDate: getCurrentDate(true),
    endDate: getCurrentDate(),
    type: "all",
    status: "all",
    active: "",
    team: "",
  },
};

const isLocalStorage = typeof localStorage !== "undefined";
let newState = {};

//lets load user data for reload
if (isLocalStorage && localStorage.app) {
  initialState = JSON.parse(localStorage.app);
  initialState.search = {
    ...initialState.search,
    startDate: getCurrentDate(true),
    endDate: getCurrentDate(),
  };
}
function presistData(type, data) {
  try {
    if (isLocalStorage) localStorage[type] = JSON.stringify(data);
  } catch (error) {
    console.log(error);
  }
  return data;
}
export default (state = initialState, action) => {
  switch (action.type) {
    case TOGLE_MAP:
      return { ...state, map: action.data };
    case TOGGLE_TRACK_USER:
      return { ...state, trackUserPosition: !state.trackUserPosition };
    case TOGLE_ELEMENTS:
      return { ...state, elmentDisplay: !state.elmentDisplay };

    case TOGLE_ADD_INTERVENTIONS:
      if (action.data?.open && typeof action.data?.open !== Boolean) {
        return {
          ...state,
          addIntervention: action.data.open,
          addHelp: action.data.addHelp,
        };
      }
      return {
        ...state,
        addIntervention: !state.addIntervention,
        addHelp: false,
      };
    case TOGLE_VIEW_REPORT:
      return { ...state, viewDayReport: !state.viewDayReport };
    case TOGLE_VIEW_REPORT_MANAGER:
      return { ...state, viewDayReportManager: !state.viewDayReportManager };

    case TOGLE_ADD_INSPECTIONS:
      return { ...state, addInspection: !state.addInspection };

    case TOGLE_MOBILE_VIEW:
      return { ...state, activeMobileView: action.data };
    case INITIAL_LOADING_SUCCESS:
      return { ...state, isLoaded: true };
    case UPDATE_SEARCH:
      newState = {
        ...state,
        status: "",
        search: {
          ...state.search,
          latestSelection: { type: "", selection: "" },
          [action.data.element]: action.data.value,
        },
      };
      //reset search
      if (
        action.data.element[0] === "freguesia" ||
        action.data.element === "freguesia"
      ) {
        newState = {
          ...state,
          status: "",
          search: {
            ...state.search,
            latestSelection: {
              type: "freguesia",
              selection: action.data.value,
            },

            [action.data.element]: action.data.value,
            // selected:
            //   action.data.value !== state.search.freguesia
            //     ? ''
            //     : state.search.selected,
            selected: action.data.value === "" ? [] : state.search.selected,
            element: action.data.value === "" ? null : state.search.element,
            local:
              action.data.value !== state.search.freguesia
                ? ""
                : state.search.local,
          },
        };
        //reset search
      } else if (
        action.data.element[0] === "local" ||
        action.data.element === "local"
      ) {
        newState = {
          ...state,
          status: "",
          search: {
            ...state.search,
            latestSelection: {
              type: "local",
              selection: action.data.value,
            },
            [action.data.element]: action.data.value,
            local: action.data.value,
            element: null,
            elementTypeSelected: [],
          },
        };
      } else if (
        action.data.element[0] === "selected" ||
        action.data.element === "selected"
      ) {
        newState = {
          ...state,
          status: "",
          search: {
            ...state.search,
            latestSelection: {
              type: "selected",
              selection: action.data.value,
            },
            selected: action.data.value.includes("") ? [] : action.data.value,
            element: null,
            elementTypeSelected: [],
          },
        };
      } else if (action.data.element[0] === "elementType") {
        newState = {
          ...state,
          status: "",
          search: {
            ...state.search,
            latestSelection: {
              type: "elementType",
              selection: action.data.value,
            },
            element: action.data.value,
          },
        };
      }

      // Lets check if date are different to update accordingly
      if (
        action.data.element[0] === "endDate" ||
        action.data.element === "endDate"
      ) {
        const startDate = new Date(state.search.startDate);
        if (new Date(action.data.value) < startDate) {
          newState.search.startDate = action.data.value;
        }
      }

      if (
        action.data.element[0] === "startDate" ||
        action.data.element === "startDate"
      ) {
        const endDate = new Date(state.search.endDate);
        if (new Date(action.data.value) > endDate) {
          newState.search.endDate = action.data.value;
        }
      }

      return presistData("app", newState);

    case UPDATE_UNIQUE_ACTIVE_PARK:
      let parkListUniq = [...state.search.selected];
      // for individual elements
      newState = {
        ...state,
        status: status.searchUpdated,
        search: {
          ...state.search,
          freguesia: action.data.freguesia,
          local: action.data.local,
          selected: [action.data.parkId], //action.data.parkId,
          element:
            !action.data.element || action.data.element === -1
              ? null
              : action.data.element,
        },
      };
      return presistData("app", newState);
    case UPDATE_ACTIVE_PARK:
      let parkList = [...state.search.selected];
      // for individual elements
      const foundPark = parkList.indexOf(action.data.parkId);
      if (action.data.unic) {
        parkList = [action.data.parkId];
      } else if (action.data.element) {
        parkList = state.search.selected;
      } else if (foundPark !== -1 && !action.data.elementSelect) {
        // remove elements
        parkList.splice(foundPark, 1);
      } else if (foundPark === -1 && !action.data.elementSelect) {
        // add elements
        parkList = [...parkList, action.data.parkId];
      }

      newState = {
        ...state,
        status: status.searchUpdated,
        search: {
          ...state.search,
          freguesia: action.data.freguesia,
          local: action.data.local,
          selected: parkList, //action.data.parkId,
          element:
            action.data.element === state.search.element
              ? null
              : action.data.element,
        },
      };

      if (action.data.center) {
        newState.search.latestSelection = {
          type: "selected",
          selection: action.data.parkId,
        };
      }
      return presistData("app", newState);
    case SET_SEARCH_DATE:
      return { ...state, search: { ...state.search, startDate: action.data } };

    case SET_USER_TYPE:
      return { ...state, search: { ...state.search, type: action.data } };

    case SET_ZOOM:
      newState = { ...state, zoom: action.data };
      return presistData("app", newState);
    case ELEMENTS_GETTING:
      return { ...state, status: "elements_getting" };

    case FINISHEDSEARCH:
      return { ...state, status: "" };
    case SEARCHING:
      return { ...state, status: "searching" };
    case ELEMENTS_RECEIVED_END:
      return { ...state, status: "" };
    case ELEMENTS_RECEIVED:
      newState = {
        ...state,

        // elements: action.data,
        elementsGeoJson: action.data.reduce((prev, curr) => {
          let geometry = "";
          if (curr.geojson) {
            geometry = JSON.parse(curr.geojson);
          } else if (curr.lat && curr.lon) {
            geometry = JSON.parse(
              `{"type":"Point","coordinates":[${curr.lon},${curr.lat}]}`
            );
          }
          let element = {
            type: "Feature",
            properties: {
              typeId: curr.ElementTypeId,
              implementationDate: curr.implementationDate,
              local: curr.local,
              files: curr.files,
              observations: curr.observations,
              label: curr["ElementTypes.label"],
              category: curr["ElementTypes.category"],
              numero: curr.pid,
              attr: curr.attr,
              type: "element",
              id: curr.id,
              origin: curr.origin,
            },
            geometry,
          };

          if (curr["VegetationType.category"]) {
            element.vegetation = {
              id: curr["VegetationType.Id"],
              category: curr["VegetationType.category"],
              cientificName: curr["VegetationType.cientificName"],
              family: curr["VegetationType.family"],
              normalName: curr["VegetationType.normalName"],
            };
          }
          return [...(prev || []), element];
        }, []),
        status: "elements_received",
      };

      return presistData("app", newState);

    case SET_MAP_INTERACTION:
      return { ...state, mapInteraction: action.data };
    case ELEMENTS_ERROR:
      return { ...state, status: status.error };
    case RECENTER:
      return { ...state, recenterMap: true };
    case RECENTEREND:
      return { ...state, recenterMap: false };

    default:
      return state;
  }
};
