import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Tooltip } from "@material-ui/core";

import {
  toggleAddInspection,
  toggleAddInterventions,
} from "../../containers/App/actions";

const ActionsContent = styled.div`
  && {
    position: fixed;
    right: 1em;
    bottom: 4.5em;
    z-index: 9;

    @media (max-width: 900px) {
      bottom: 9.5em;
      right: 0.5em;
      z-index: 1;
    }
    @media (max-width: 455px) {
      bottom: 6.5em;
    }
  }
`;
const ActionWrapper = styled.div`
  && {
    margin: 0.3em 0.2em;
    background: #4da1ff;
    padding: 0.7em;
    display: block;
    border-radius: 5px;
    max-width: 100px;
    box-shadow: 6px 0 9px 0 rgba(130, 158, 171, 0.4);
    color: #fff;
    cursor: pointer;
    > div {
      font-size: 0.8em;
      ::before {
        border-bottom: none;
      }
    }
    &:hover {
      color: #4da1ff;
      background: #fff;
    }
  }
`;

class AddActionButtons extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { userType, userWrite, isAdmin, userRega } = this.props;
    console.log(userRega);
    return (
      <ActionsContent>
        {(userWrite === "all" || userWrite === "inspections") &&
          this.props.local &&
          this.props.local.length > 0 &&
          this.props.local[0] !== null && (
            <Tooltip title={`Adicionar ${this.props.menuInspections.name}`}>
              <ActionWrapper
                onClick={() => this.props.toggleAddInspection()}
                className={`icon-${
                  !userRega ? this.props.menuInspections.icon : "drop"
                } icons`}
              />
            </Tooltip>
          )}
        {(userWrite === "all" || userWrite === "interventions") &&
          this.props.local &&
          this.props.local.length > 0 &&
          this.props.local[0] !== null && (
            <Tooltip title={`Adicionar ${this.props.menuInterventions.name}`}>
              <ActionWrapper
                onClick={() => this.props.toggleAddInterventions()}
                className={`icon-${this.props.menuInterventions.icon} icons`}
              />
            </Tooltip>
          )}
        {(userWrite === "all" || userWrite === "interventions") &&
          isAdmin &&
          this.props.local &&
          this.props.local.length > 0 &&
          this.props.local[0] !== null && (
            <Tooltip title={`Agendar ${this.props.menuInterventions.name}`}>
              <ActionWrapper
                onClick={() =>
                  this.props.toggleAddInterventions({ type: "schedule" })
                }
                className={`icon-clock icons`}
              />
            </Tooltip>
          )}
      </ActionsContent>
    );
  }
}

export default connect(
  (state) => {
    return {
      role: state.user.data.role,
      isAdmin: state.user.data.admin,
      userWrite: state.user.data.write,
      userType: state.user.data.type,
      local: state.app.search.local,
      selected: state.app.search.selected,
      userRega: state.user.data.rega,

      menuInspections:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/inspections`
        ),
      menuInterventions:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/interventions`
        ),
    };
  },
  { toggleAddInspection, toggleAddInterventions }
)(AddActionButtons);
