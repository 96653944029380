import React, { Component } from 'react';
import './L.Mask';
import Leaflet from 'leaflet';
import cascais from './cascaisLimites.json';
import { LeafletConsumer } from 'react-leaflet';

export default class MaskLayer extends Component {
  constructor(props, context) {
    super(props);

    this.state = {
      overlay: false,
    };
  }

  componentDidMount() {}

  addOverlay(map) {
    if (map && !this.state.overlay) {
      // cascais.features.map(feature => {
      //   feature.geometry.coordinates.map(coordinates => {
      //     coordinates.map((coordinate, i) => {
      //       latLngs.push(
      //         new Leaflet.LatLng(coordinates[i][1], coordinates[i][0])
      //       );
      //     });
      //   });
      // });
      let coordinates = cascais.features[0].geometry.coordinates;
      let latLngs = [];
      coordinates.map((coo, i) => {
        latLngs.push(new Leaflet.LatLng(coo[1], coo[0]));
      });
      // new Leaflet.Mask(latLngs);
      let overlay = Leaflet.mask(latLngs);
      overlay.addTo(map);
      this.setState({ overlay });
    }
  }

  render() {
    return (
      <LeafletConsumer>
        {context => this.addOverlay(context.map)}
      </LeafletConsumer>
    );
  }
}
