import React from "react";
import { toastr } from "react-redux-toastr";
import MainButton from "./components/Buttons/MainButton";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

export default function PWAUpdate(props) {
  serviceWorkerRegistration.register({
    onSuccess: () =>
      setTimeout(() => toastr.info("Aplicação SGEV instalada com sucesso"), 0),
    onUpdate: (reg) => {
      setTimeout(
        () =>
          toastr.message("Nova versão SGEV disponível", {
            // this option will give you a func 'remove' as props
            status: "warning",
            component: (
              <span>
                Atualize agora a versão que tem instalada para ter acesso a
                todos os novos desenvolvimentos. <br />
                <br />
                <div style={{ textAlign: "right" }}>
                  <MainButton
                    onClick={() => {
                      if ("serviceWorker" in navigator) {
                        navigator.serviceWorker
                          .getRegistration()
                          .then(function(reg) {
                            if (reg) {
                              reg.unregister().then(function() {
                                window.location.reload(true);
                              });
                            } else {
                              window.location.reload(true);
                            }
                          });
                      }
                    }}
                  >
                    Atualizar
                  </MainButton>
                </div>
              </span>
            ),
          }),
        0
      );
    },
  });

  return props.children;
}
