import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import FullScreenModal from "../../../components/Layout/FullScreenModal";
import PageTitle from "../../../components/Text/PageTitle";
import MainButton from "../../../components/Buttons/MainButton";
import EditParkDetails from "./EditParkDetails";
import Table from "../../../components/Table/Table";
import MenuIcon from "../../../components/Buttons/MenuIcon";

// import { setActiveStep, getMaintenances, addNewToggle } from './actions';
import {
  resetElement,
  getElementsTypes,
  getVegetationTypes,
  getParkById,
  getHistoryByParkId,
} from "../actions";

const Section = styled.div`
  && {
    margin: 2em 0;
    max-width: 1200px;

    h2 {
      margin: 0;
    }
  }
`;

const ButtonWrapper = styled.div`
  && {
    text-align: left;
    display: block;
    font-size: 0.8em;
    margin-top: 0.9em;
    margin-bottom: 2.3em;
    button {
      margin-right: 0.5em;
      :hover {
        a {
          color: #4da1ff;
        }
      }
    }

    a {
      text-decoration: none;
      color: #fff;
      :hover {
        color: #4da1ff;
      }
    }
  }
`;

const Close = styled.span`
  && {
    font-size: 2.5em;
    margin: 0 0.5em 0 0;
    text-align: center;
    color: #989898;
    vertical-align: middle;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 11;
    cursor: pointer;
    :hover {
      color: #4da1ff;
      transform: rotate(90deg);
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
  }
`;

class EditElement extends Component {
  constructor(props) {
    super(props);
    this.close = this.close.bind(this);
    this.state = {
      view: "info",
      activeHistoryId: null,
      selectedParks: props.selected,
    };
    if (props.selected.length === 1) props.getParkById(props.selected[0]);
    this.getHistory = this.getHistory.bind(this);
    this.handleDataChange = this.handleDataChange.bind(this);
  }

  componentDidUpdate() {
    if (this.props.status === "deleted") {
      this.close();
    }
  }

  close() {
    this.props.closeModal();
  }

  handleDataChange(e, id) {
    this.setState({ view: "info" });
    this.setState({ activeHistoryId: id });
  }

  getViewData() {
    if (this.state.activeHistoryId)
      return this.props.history.find(
        (item) => item.id === this.state.activeHistoryId
      );
    return this.props.park;
  }

  renderParkName() {
    const { selectedParks } = this.state;

    let parkName = "";
    if (selectedParks.length > 1) {
      parkName = `${selectedParks} Parcelas Seleccionadas`;
    } else if (selectedParks.length > 0) {
      parkName = `${selectedParks[0].nome || selectedParks[0] || ""} ${
        this.state.selectedParks.local || ""
      }`;
    } else {
      parkName = "Nenhum local  Seleccionado";
    }

    return parkName;
  }

  getHistory() {
    this.setState({ view: "history" });
    this.props.getHistoryByParkId(this.props.selected[0] || null);
  }

  render() {
    const columnHeader = [
      {
        id: "id",
        numeric: false,
        disablePadding: true,
        label: "id",
      },
      { id: "date", numeric: false, disablePadding: false, label: "Data" },
      {
        id: "user",
        numeric: false,
        disablePadding: false,
        label: "Utilizador",
      },
      { id: "team", numeric: false, disablePadding: false, label: "Equipa" },
      // { id: 'status', numeric: false, disablePadding: false, label: 'Estado' },
    ];
    let columnData = [];
    if (this.props.history) {
      columnData = this.props.history.reduce((prev, curr) => {
        // curr.UserId
        // curr.createdAt
        const itemData = {
          id: curr.id,
          user: curr.User.name,
          team: curr.Team && curr.Team.name,
          date: curr.createdAt,
          status: curr.status,
        };
        return [...prev, itemData];
      }, []);
    }

    if (this.props.selected.length > 1 || this.props.selected.length === 0) {
      return (
        <FullScreenModal>
          <Close onClick={() => this.close()} className={`icon-close icons`} />
          <Section>
            <PageTitle>{this.renderParkName()}</PageTitle>
            <br />
            Para editar ou adicionar novos elementos, apenas pode ter uma
            parcela selecionada.
          </Section>
        </FullScreenModal>
      );
    }

    return (
      <FullScreenModal>
        <Close onClick={() => this.close()} className={`icon-close icons`} />
        <Section>
          <PageTitle>{this.renderParkName()}</PageTitle>
          <ButtonWrapper>
            <MainButton
              active={this.state.view === "info" ? "true" : "false"}
              onClick={() => this.setState({ view: "info" })}
            >
              <MenuIcon className={`icon-notebook icons`} /> Informação
            </MainButton>
            <MainButton
              active={this.state.view === "history" ? "true" : "false"}
              onClick={() => this.getHistory()}
            >
              <MenuIcon className={`icon-calendar icons`} /> Histórico
            </MainButton>
            <MainButton
              active={this.state.view === "reports" ? "true" : "false"}
            >
              <MenuIcon className={`icon-graph icons`} />{" "}
              <Link to={"reports"}>Relatórios</Link>
            </MainButton>
          </ButtonWrapper>
          {this.state.view === "info" &&
            this.props.park &&
            this.props.status === "" && (
              <EditParkDetails
                data={this.getViewData()}
                view={this.props.view}
              />
            )}

          {this.state.view === "history" && this.props.status === "" && (
            <Table
              orderBy={"id"}
              data={columnData}
              click={this.handleDataChange}
              header={columnHeader}
            />
          )}
        </Section>
      </FullScreenModal>
    );
  }
}

export default connect(
  (state) => {
    return {
      park: state.georeference.park,
      history: state.georeference.history,
      role: state.user.data.role,
      selected: state.app.search.selected,
      status: state.georeference.status,
      list: state.parklist.list,
    };
  },
  {
    resetElement,
    getElementsTypes,
    getVegetationTypes,
    getParkById,
    getHistoryByParkId,
  }
)(EditElement);
