import React from "react";
import styled from "styled-components";
import { MenuItem, Select, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import LineButton from "../Buttons/LineButton";
import {
  recenterMap,
  toggleViewReport,
  setActivePark,
  updateZoom,
} from "../../containers/App/actions";
import SectionTitle from "../Text/SectionTitle";
import { findParkFromPosition } from "../../containers/ParkList/actions";
import { userLocation } from "../../containers/User/actions";

const Section = styled.div`
  && {
    margin: 0.2em;
    background: #fff;
    padding: 0.4em;
    display: inline-block;
    border-radius: 5px;
    max-width: 100px;
    box-shadow: 6px 0 9px 0 rgba(130, 158, 171, 0.4);
    color: #fff;
    > div {
      font-size: 0.8em;
      ::before {
        border-bottom: none;
      }
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    text-transform: uppercase;
  }
`;

const StyledLineButton = styled(LineButton)`
  && {
    display: block;
    font-size: 0.8em;
    width: 100%;
    text-align: center;
    margin: 0.8em 0;
    line-height: 1;
  }
`;

const SpanBlock = styled.span`
  && {
    font-size: 1em;
    text-align: center;
    color: #989898;
    vertical-align: middle;
    cursor: pointer;
    line-height: 1.8;
    padding: 0 0.3em;
    :hover {
      color: #4da1ff;
      transform: rotate(90deg);
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
  }
`;
class LocationFilterMap extends React.Component {
  constructor(props) {
    super(props);
    this.resetSearch = this.resetSearch.bind(this);
    this.findParkFromPosition = this.findParkFromPosition.bind(this);
  }

  resetSearch() {
    this.props.handleSearchChange({
      target: {
        name: "freguesia",
        value: "",
      },
    });
  }

  handleSearchChange(e) {
    this.props.handleSearchChange(e);
    this.props.recenterMap();
  }

  sortAlphaNum(array) {
    var reA = /[^a-zA-Z]/g;
    var reN = /[^0-9]/g;
    if (typeof array !== "array") return array;
    return array.sort((a, b) => {
      var aA = a.replace(reA, "");
      var bA = b.replace(reA, "");
      if (aA === bA) {
        var aN = parseInt(a.replace(reN, ""), 10);
        var bN = parseInt(b.replace(reN, ""), 10);
        return aN === bN ? 0 : aN > bN ? 1 : -1;
      } else {
        return aA > bA ? 1 : -1;
      }
    });
  }

  findParkFromPosition() {
    // this.props.userLocation();
    const selectedPark = this.props.findParkFromPosition();
    if (selectedPark) {
      const { freguesia, local, numero } = selectedPark.properties;
      this.props.setActivePark(numero, local, freguesia);
    }
    this.props.updateZoom({
      zoom: this.props.zoom.zoom,
      center: [this.props.position.lat, this.props.position.lng],
    });
  }

  render() {
    const {
      freguesia,
      location,
      selected,
      list,
      handleSearchChange,
      elmentDisplay,
      elementType,
      elementsGeoJson,
      notitle,
      elementSelected,
    } = this.props;

    let elementTypesList = [];

    return (
      <div>
        <Section>
          <Select
            fullWidth
            name={"freguesia"}
            value={freguesia}
            onChange={handleSearchChange}
            inputProps={{
              name: "freguesia",
              id: "freguesia",
            }}
          >
            {/* <StyledMenuItem key={-1} value={''} /> */}
            {Object.keys(location)
              .sort()
              .map((freguesia, index) => (
                <StyledMenuItem key={index} value={freguesia}>
                  {freguesia}
                </StyledMenuItem>
              ))}
          </Select>
        </Section>
        {freguesia !== "" && (
          <Section>
            <Select
              fullWidth
              name={"selected"}
              multiple
              value={selected || []}
              onChange={handleSearchChange}
              inputProps={{
                name: "selected",
                id: "selected",
              }}
            >
              <StyledMenuItem key={-1} value={""}>
                Todos
              </StyledMenuItem>{" "}
              {list.sort(this.sortAlphaNum).map((selectedPark, index) =>
                selectedPark.freguesia === freguesia ? (
                  <StyledMenuItem
                    key={index}
                    selected={selectedPark.numero === selected}
                    value={selectedPark.numero}
                  >
                    {selectedPark.nome}
                  </StyledMenuItem>
                ) : null
              )}
            </Select>
          </Section>
        )}
        {selected &&
          elmentDisplay &&
          selected !== "" &&
          selected.length > 0 &&
          elementsGeoJson &&
          elementsGeoJson.length > 0 && (
            <Section>
              <Select
                fullWidth
                name={"elementType"}
                value={elementSelected || []}
                onChange={handleSearchChange}
                inputProps={{
                  name: "elementType",
                  id: "elementType",
                }}
              >
                <StyledMenuItem key={-1} value={""}>
                  Todos
                </StyledMenuItem>
                {elementsGeoJson.reduce((acc, element, index) => {
                  const elementTypeSelected =
                    element.properties.label || element.properties.category;

                  if (
                    elementTypeSelected &&
                    !elementTypesList.includes(elementTypeSelected) // lets make sure their unique
                  ) {
                    elementTypesList.push(elementTypeSelected);
                    acc.push(
                      <StyledMenuItem key={index} value={element.properties.id}>
                        {elementTypeSelected}
                      </StyledMenuItem>
                    );
                  }
                  return acc;
                }, [])}
              </Select>
            </Section>
          )}
        <Section>
          <Tooltip title={`Limpar selecção`}>
            <SpanBlock
              onClick={() => this.resetSearch()}
              className={"icon-close icons reset"}
            />
          </Tooltip>
        </Section>
        <Section>
          <Tooltip title={`Localizar Elemento`}>
            <SpanBlock
              onClick={() => this.findParkFromPosition()}
              className={`icon-compass icons`}
            />
          </Tooltip>
        </Section>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      zoom: state.app.zoom,
      list: state.parklist.list,
      position: state.user.position,
      elementsGeoJson: state.app.elementsGeoJson,
      elmentDisplay: state.app.elmentDisplay,
      local: state.app.search.local,
      freguesia: state.app.search.freguesia,
      selected: state.app.search.selected,
      location: state.parklist.location,
      elementType: state.app.search.elementTypeSelected,
      elementSelected: state.app.search.element,
    };
  },
  {
    recenterMap,
    userLocation,
    toggleViewReport,
    findParkFromPosition,
    toggleViewReport,
    setActivePark,
    updateZoom,
  }
)(LocationFilterMap);
