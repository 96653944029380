import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Switch } from "react-router-dom";

import { logout } from "./actions";

class LogoutPage extends Component {
  constructor(props) {
    super(props);

    props.logout();
    // browserHistory.push('/login');
  }

  render() {
    return this.props.user === null ? (
      <Switch>
        <Redirect push to="/login" />
      </Switch>
    ) : null;
  }
}

export default connect(
  (state) => {
    return {
      user: state.user.data,
    };
  },
  {
    logout,
  }
)(LogoutPage);
