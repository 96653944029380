import {
  GETTING,
  RECEIVED,
  ERROR,
  GETTING_PARK_REPORT,
  RECEIVED_PARK_REPORT,
  ERROR_PARK_REPORT,
  SAVING_REPORT,
  RECEIVED_FREGUESIAS_REPORT,
  SAVED_REPORT,
  ERROR_REPORT,
  GET_MONTH_REPORT,
  RECEIVED_MONTH_REPORT,
  ERROR_MONTH_REPORT,
  SET_TYPE,
} from "./constants";

import { getCurrentDate } from "../../services/utils";

const status = {
  edit: "edit",
  error: "error",
  saving: "saving",
  saved: "saved",
  edited: "edited",
  getting: "getting",
  received: "received",
};

let initialState = {
  status: "",
  data: [],
  dataReports: [],
  type: "inspections",
  freguesiasReport: null,
  monthReportData: null,
  monthStatus: null,
  reportData: [],
};

const isLocalStorage = typeof localStorage !== "undefined";
let newState = {};
//lets load user data for reload
if (isLocalStorage && localStorage.reports) {
  initialState = JSON.parse(localStorage.reports);
  initialState.search = {
    ...initialState.search,
    startDate: getCurrentDate(true),
    endDate: getCurrentDate(),
  };
}
function presistData(type, data) {
  try {
    // if (isLocalStorage) localStorage[type] = JSON.stringify(data);
  } catch (error) {
    console.log(error);
  }
  return data;
}
export default (state = initialState, action) => {
  switch (action.type) {
    case GETTING:
      return {
        ...state,
        status: status.getting,
      };
    case RECEIVED:
      if (action.data.type === "reports") {
        return {
          ...state,
          status: status.received,
          dataReports: action.data.list,
        };
      } else {
        return {
          ...state,
          data: action.data.list,
          status: status.received,
        };
      }

    case SET_TYPE:
      newState = {
        ...state,
        type: action.data,
      };
      return presistData("reports", newState);

    case GET_MONTH_REPORT:
      return {
        ...state,
        monthStatus: status.getting,
      };

    case RECEIVED_MONTH_REPORT:
      return {
        ...state,
        monthStatus: status.received,
        monthReportData: action.data,
      };
    case ERROR_MONTH_REPORT:
      return {
        ...state,
        monthStatus: status.error,
      };
    case SAVING_REPORT:
      return {
        ...state,
        status: status.saving,
      };
    case SAVED_REPORT:
      return {
        ...state,
        status: status.saved,
      };
    case ERROR_REPORT:
      return {
        ...state,
        status: status.error,
      };
    case GETTING_PARK_REPORT:
      return {
        ...state,
        status: status.getting,
      };
    case ERROR:
      return { ...state, status: status.error };

    case RECEIVED_PARK_REPORT:
      return {
        ...state,
        status: status.received,
        reportData: action.data,
      };
    case ERROR_PARK_REPORT:
      return { ...state, status: status.error };
    case RECEIVED_FREGUESIAS_REPORT:
      return { ...state, freguesiasReport: action.data };

    default:
      return state;
  }
};
