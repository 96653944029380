import React from 'react';
import styled from 'styled-components';

const StyledButtonGroup = styled.div`
  && {
    border-radius: 4px;
    background: #f4f4f4;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.03);
    border-radius: 3px;
    padding: 3px;
    color: #000;

    ${props =>
      props.expanded &&
      `
        width:23%;
        position:absolute;
        right:2em;
      `};
  }
`;

class ButtonGroup extends React.Component {
  render() {
    return (
      <StyledButtonGroup
        {...this.props}
        expanded={this.props.expanded ? 'true' : undefined}
      >
        {this.props.children}
      </StyledButtonGroup>
    );
  }
}

export default ButtonGroup;
