import React from 'react';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';

const StyledPaper = styled(Paper)`
  && {
    background: #ffffff;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.06);
    border-radius: 6px;
  }
`;

class ContentWrap extends React.Component {
  render() {
    return <StyledPaper {...this.props}>{this.props.children}</StyledPaper>;
  }
}

export default ContentWrap;
