import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Row, Col } from "react-flexbox-grid";

import ContentWrap from "../../../components/Layout/ContentWrap";
import SectionTitle from "../../../components/Text/SectionTitle";
import LocationFilter from "../../../components/Filters/LocationFilter";
import ParcelsFilter from "../../../components/Filters/ParcelsFilter";
import { default as ScrollArea } from "../../../components/Layout/ScrollBar";

const FilterList = styled.div`
  && {
    .maintenance-filter {
      height: 250px;
      padding-right: 18px;
      .scrollbar {
        border-radius: 1em;
      }
    }
  }
`;
const OptionWrap = styled(ContentWrap)`
  && {
    padding: 1em;
    margin: 0.5em 0;
    align: center;
  }
`;

const OptionWrapElements = styled(ContentWrap)`
  && {
    padding: 1em;
    margin: 0.5em 0;
    display: flex;
    align-items: center;
    justify-content: left;
  }
`;

const RemovePark = styled.span`
  && {
    font-size: 1.5em;
    margin: 0 0.5em;
    text-align: center;
    color: #989898;
    vertical-align: middle;
    display: inline-block;
    text-align: left;
    cursor: pointer;
    :hover {
      color: #4da1ff;
      transform: rotate(90deg);
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
  }
`;

const ParkName = styled.div`
  && {
    font-size: 0.8em;
    color: #464f58;
    font-weight: bold;
    text-align: left;
  }
`;
const ParkLocation = styled.div`
  && {
    font-size: 0.8em;
    color: #464f58;
    text-align: left;
  }
`;

const ParkSelect = styled.div`
  && {
    font-size: 0.8em;
    color: #464f58;
    text-align: center;
  }
`;

const Inline = styled.div`
  && {
    display: inline-block;
  }
`;

class ParkSelectList extends Component {
  constructor(props) {
    super(props);

    this.togglePark = this.togglePark.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);

    this.state = {
      local: "",
      parks: props.selectedParks || [],
      freguesia: "",
      selected: "",
    };
  }

  prepareParkToSet(park) {
    const { geoJson } = this.props;
    let totalParks = park.properties ? park.properties.numero : park;
    let newPlaceList = [...this.state.parks];
    let foundPark = [];

    if (park === "" && this.state.freguesia !== "") {
      // let find all parks in local
      let allparks = geoJson.filter(
        (value) => value.properties.freguesia === this.state.freguesia
      );

      // let find all unique parks in local

      const allparksUnique = [
        ...new Set(allparks.map((item) => item.properties.local)),
      ];

      // lets find if we have any elements in the state
      foundPark = newPlaceList.filter(
        (value) => -1 !== allparksUnique.indexOf(value)
      );

      if (foundPark.length > 0) {
        // remove elements
        newPlaceList = newPlaceList.filter((value) => {
          return -1 === foundPark.indexOf(value);
        });
      } else {
        newPlaceList = [...newPlaceList, ...allparksUnique];
      }
    } else if (typeof totalParks === "object") {
      // for individual arrays
      foundPark = newPlaceList.filter(
        (value) => -1 !== totalParks.indexOf(value)
      );

      if (foundPark.length > 0) {
        // remove elements
        newPlaceList = newPlaceList.filter((value) => {
          return -1 === totalParks.indexOf(value);
        });
      } else {
        newPlaceList = [...newPlaceList, ...totalParks];
      }
    } else {
      // for individual elements
      foundPark = newPlaceList.indexOf(totalParks);

      if (foundPark !== -1) {
        // remove elements
        newPlaceList.splice(foundPark, 1);
      } else {
        // add elements
        newPlaceList = [...newPlaceList, totalParks];
      }
    }
    this.setState({ parks: newPlaceList });
    this.props.handleSelectedParksChange(newPlaceList);
  }

  togglePark(park) {
    this.prepareParkToSet(park);
  }

  handleSearchChange(e) {
    const { role } = this.props;
    if (e.target.name === "local") {
      this.prepareParkToSet(e.target.value);
    } else {
      this.setState({
        selected: "",
      });
    }

    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  renderSelectedParkEV() {
    //handle no results
    if (this.state.parks && this.state.parks.lenght < 1) {
      return (
        <OptionWrap>
          <ParkSelect>Nenhuma folha selecionado</ParkSelect>
        </OptionWrap>
      );
    }

    // render results
    return (
      <div>
        {this.state.parks &&
          this.state.parks.map((place, index) => {
            return (
              <OptionWrapElements key={index}>
                <RemovePark
                  onClick={() => this.togglePark(place)}
                  className={`icon-close icons`}
                />
                <Inline>
                  <ParkName>{place}</ParkName>
                  {/* <ParkLocation>{place.properties.freguesia}</ParkLocation> */}
                </Inline>
              </OptionWrapElements>
            );
          })}
      </div>
    );
  }

  renderSelectedPark() {
    const { geoJson } = this.props;
    return (
      <div>
        {this.state.parks &&
          this.state.parks.map((place, index) => {
            const thisPark = geoJson.find(
              (park) => park.properties.numero === place
            );

            if (!thisPark || this.props.route.length < 1)
              return (
                <OptionWrap key={index}>
                  <ParkSelect>Nenhum parque selecionado</ParkSelect>
                </OptionWrap>
              );
            return (
              <OptionWrapElements key={index}>
                <RemovePark
                  onClick={() => this.togglePark(thisPark)}
                  className={`icon-close icons`}
                />
                <Inline>
                  <ParkName>{thisPark.properties.name}</ParkName>
                  <ParkLocation>
                    {thisPark.properties.freguesia}, {thisPark.properties.local}
                  </ParkLocation>
                </Inline>
              </OptionWrapElements>
            );
          })}
      </div>
    );
  }

  render() {
    const { location, role } = this.props;
    const { local, freguesia, selected } = this.state;
    return (
      <Row>
        <Col md={12}>
          <Row>
            <Col xs={12} sm={8} md={8} lg={8} className={"m-b-15"}>
              {this.state.parks && (
                <SectionTitle>
                  <strong>
                    {this.state.parks.length === 0
                      ? "Todos os"
                      : this.state.parks.length}
                  </strong>{" "}
                  Parcelas seleccionadas
                </SectionTitle>
              )}
              <FilterList>
                <ScrollArea
                  speed={0.8}
                  className="maintenance-filter"
                  contentClassName="maintenance-filter-content"
                  horizontal={false}
                >
                  {this.renderSelectedParkEV()}
                </ScrollArea>
              </FilterList>
            </Col>
            <Col xs={12} sm={4} md={4} lg={4} className={"m-b-15"}>
              <SectionTitle>{"Folhas"}</SectionTitle>
              <OptionWrap>
                {
                  <ParcelsFilter
                    handleSearchChange={this.handleSearchChange}
                    override={{ local, location, freguesia, selected }}
                  />
                }
              </OptionWrap>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default connect(
  (state) => {
    return {
      geoJson: state.parklist.geoJson,
      // route: state.calendar.route.list,
      location: state.parklist.location,
      role: state.user.data.role,
    };
  },
  // { setParkInRoute, setRotasDetails, setRoute }
  {}
)(ParkSelectList);
