import Leaflet from 'leaflet';
// credits: https://github.com/turban/Leaflet.Mask
Leaflet.Mask = Leaflet.Polygon.extend({
  options: {
    stroke: true,
    color: '#000',
    fillOpacity: 0.6,
    clickable: false,

    outerBounds: new Leaflet.LatLngBounds([-90, -360], [90, 360]),
  },

  initialize: function(latLngs, options) {
    var outerBoundsLatLngs = [
      this.options.outerBounds.getSouthWest(),
      this.options.outerBounds.getNorthWest(),
      this.options.outerBounds.getNorthEast(),
      this.options.outerBounds.getSouthEast(),
    ];
    Leaflet.Polygon.prototype.initialize.call(
      this,
      [outerBoundsLatLngs, latLngs],
      options
    );
  },
});
Leaflet.mask = function(latLngs, options) {
  return new Leaflet.Mask(latLngs, options);
};
