import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { Row, Col } from "react-flexbox-grid";
import MainButton from "../../../components/Buttons/MainButton";
import OptionTitle from "../../../components/Text/OptionTitle";
import MenuIcon from "../../../components/Buttons/MenuIcon";

import { reset, addNewToggle } from "../actions";

const OptionIcon = styled.div`
  && {
    background: url(${require("../../../images/IconOptionFundo.png")}) no-repeat
      center center;
    background-size: contain;
    padding: 1em;
    margin: 1em 0 0;
    img {
      height: 100%;
    }
  }
`;
const Button = styled(MainButton)`
  && {
    margin: 0.5em;
  }
`;
const CenterWrap = styled.div`
  && {
    text-align: center;
    width: 100%;
  }
`;

const Check = styled.span`
  && {
    font-size: 6em;
    margin: 1em;
    text-align: center;
    color: #989898;
    vertical-align: middle;
  }
`;

const Text = styled.p`
  && {
    font-size: 0.8em;
    color: #919191;
    line-height: 1.6em;
    padding: 0 0 1.2em;
  }
`;

const BackButton = styled(MainButton)`
  && {
    color: #4da1ff;
    text-transform: capitalize;
    background: transparent;
    border: 1px solid #4da1ff;
    margin: 0.5em;
    .icons {
      color: #4da1ff;
    }
  }
`;

class DetailsStart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.finish = this.finish.bind(this);
  }

  finish() {
    this.props.reset();
    this.props.close();
  }

  render() {
    const { savedData } = this.props;
    if (!savedData) return null;
    return (
      <div>
        <Row>
          <Col xs={0} md={3} />

          <Col xs={12} md={6}>
            <Row>
              <CenterWrap>
                <OptionIcon>
                  <Check className={`icon-check icons`} />
                </OptionIcon>
                <OptionTitle>Salvo</OptionTitle>

                <Text>
                  <strong>
                    {savedData.files && savedData.files.length} Fotos
                  </strong>
                  <br />
                  <br />
                  escolha uma das seguintes opções
                </Text>
              </CenterWrap>
            </Row>
            <Row>
              <CenterWrap>
                <Button onClick={() => this.props.reset()}>
                  <MenuIcon className={`icon-camera icons`} />
                  Criar uma nova
                </Button>
              </CenterWrap>
            </Row>

            <Row>
              <CenterWrap>
                <BackButton onClick={() => this.finish()}>
                  <MenuIcon className={`icon-close icons`} />
                  Terminar
                </BackButton>
              </CenterWrap>
            </Row>
          </Col>
          <Col xs={0} md={3} />
        </Row>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      savedData: state.maintenances.savedData,
      interventionsMenuData:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/interventions`
        ),
    };
  },
  { reset, addNewToggle }
)(DetailsStart);
