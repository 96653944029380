import { injectGlobal } from "styled-components";

injectGlobal`
  html{
    touch-action: manipulation;
    
  }
  body {
    /* background: #F4F8F9; */
    background: #f8fcfe;
    overscroll-behavior-y: contain;
    @media print {
      background: #fff;
    }
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  }

  article,
  aside,
  footer,
  header,
  nav,
  section {
    display: block;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
  }

    @media print
    {
        * {-webkit-print-color-adjust:exact;}
    }
    @media screen and (max-width: 770px) {
    .row > div{
      margin-bottom:1em;
    }
  }
  .redux-toastr{
    .rrr-title{
      
    }
      font-size:.8em;

  }
  .leaflet-top.leaflet-right{
    top: 3em;
  }
`;
