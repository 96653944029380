import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";

import FullScreenModal from "../../components/Layout/FullScreenModal";
import PageTitle from "../../components/Text/PageTitle";

import DetailsSpecific from "./steps/DetailsSpecific";
import ThankYou from "./steps/ThankYou";

import { setActiveStep, getMaintenances, addNewToggle } from "./actions";
import { setHistory, setDetails } from "./actions";

const Section = styled.div`
  && {
    margin: 2em 0;
    max-width: 1200px;
  }
`;

const Close = styled.span`
  && {
    font-size: 2.5em;
    margin: 0 0.5em 0 0;
    text-align: center;
    color: #989898;
    vertical-align: middle;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 11;
    cursor: pointer;
    :hover {
      color: #4da1ff;
      transform: rotate(90deg);
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
  }
`;

class AddNew extends Component {
  constructor(props) {
    super(props);
    this.close = this.close.bind(this);
  }

  close() {
    this.props.closeModal();
    if (this.props.status === "saved") {
      this.props.getMaintenances();
    }
    this.props.setDetails({});
  }

  uniq(a) {
    return Array.from(new Set(a || []));
  }

  render() {
    const {
      closeModal,
      addIntervention,
      status,
      list,
      selected,
      element,
      role,
      local,
      elementsGeoJson,
      user,
    } = this.props;

    let selectedParks = list.filter(
      (park) => selected.includes(park.numero) || local === park.numero
    );
    let interventionType = "";

    if (
      addIntervention &&
      typeof addIntervention === "object" &&
      addIntervention.type !== "schedule"
    ) {
      selectedParks = [addIntervention];
    }

    if (
      addIntervention &&
      typeof addIntervention === "object" &&
      addIntervention.type
    ) {
      interventionType = addIntervention.type;
    }

    if (user.rega && addIntervention.type !== "schedule") {
      interventionType = "rega";
    }

    //override for tasks for deploy
    // if (selectedPid) this.props.addNewToggle(selectedPid, false);
    let parkName = "";
    let locationData = "";
    let pid = "";

    if (selectedParks.length === 0) {
      toastr.error("nenhuma parcela selecionada");

      return null;
    }
    // if (selected.length > 1) {
    //   parkName = `${selected.length} Parcelas Seleccionados`;
    //   locationData = `${selected}`;
    // } else if (selected.length > 0) {
    //   parkName = `${`${selectedParks[0].nome} ,` || ""}`;
    //   locationData = `${`${selectedParks[0].freguesia} ,` ||
    //     ""}  ${selectedParks[0].numero || ""}`;
    // }

    if (selected.length > 1 && interventionType === "rega") {
      parkName = selectedParks[0].nome;
      locationData = `${this.uniq(selectedParks.map((p) => p.local)).join(
        ", "
      )}, ${selected}`;

      pid = selectedParks[0].numero;
    } else if (selectedParks && selectedParks.length > 0) {
      parkName = selectedParks[0].nome;
      locationData =
        selectedParks[0].freguesia + " , " + selectedParks[0].local;
      pid = selectedParks[0].numero;
    }
    const thisElementType = elementsGeoJson.find((eleGJ) => {
      return (
        eleGJ.properties.id === element ||
        (eleGJ.properties.origin && eleGJ.properties.origin === element)
      );
    });

    return (
      <FullScreenModal>
        <Close onClick={() => this.close()} className={`icon-close icons`} />
        <Section>
          <PageTitle>
            <i className={`icon-${this.props.menuInterventions.icon} icons`} />{" "}
            {parkName}
            <span>{locationData}</span>
          </PageTitle>
          {status !== "saved" && (
            <DetailsSpecific
              selectedParkId={pid}
              interventionType={interventionType} //new intervention types
              selectedOtherPark={
                addIntervention &&
                typeof addIntervention === "object" &&
                interventionType !== "schedule"
                  ? addIntervention
                  : false
              }
              selectedElement={thisElementType}
            />
          )}
          {status === "saved" && <ThankYou close={this.close} />}
        </Section>
      </FullScreenModal>
    );
  }
}

export default connect(
  (state) => {
    return {
      type: state.maintenances.type,
      addIntervention: state.app.addIntervention,
      activeStep: state.maintenances.activeStep,
      status: state.maintenances.status,
      list: state.parklist.list,
      role: state.user.data.role,
      user: state.user.data,
      selected: state.app.search.selected,
      local: state.app.search.local,
      element: state.app.search.element,
      elementsGeoJson: state.app.elementsGeoJson,
      menuInterventions:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/interventions`
        ),
    };
  },
  { setActiveStep, addNewToggle, getMaintenances, setHistory, setDetails }
)(AddNew);
