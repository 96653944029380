import * as constants from "./constants";
import axios from "axios";
import { store } from "../../store/configure";
import { toastr } from "react-redux-toastr";

export function addNewToggle(id, state) {
  return {
    type: constants.ADD_NEW_TOGGLE,
    data: { id, state },
  };
}

export function setActiveStep(step) {
  return {
    type: constants.SET_ACTIVE_STEP,
    data: step,
  };
}

export function reset() {
  return {
    type: constants.RESET,
  };
}

export function setDetails(details) {
  return {
    type: constants.SET_DETAILS,
    data: details,
  };
}

export function setType(type) {
  return {
    type: constants.SET_TYPE,
    data: type,
  };
}

export function setPhotos(photos) {
  return {
    type: constants.SET_PHOTOS,
    data: photos,
  };
}

export function setHistory(history = []) {
  return {
    type: constants.UPDATED_HISTORY,
    data: history,
  };
}

export function updateMaintenance(state) {
  return (dispatch) => {
    dispatch({
      type: constants.SAVING,
    });
    const data = state;
    const id = data.id;

    axios
      .put(`/api/v1/maintenances/${id}`, data)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.UPDATED,
            data: response.data.msg,
          });
          toastr.success("Manutenção", "Salva com sucesso");
        }
      })
      .catch(function(response) {
        toastr.error("Verifique os dados introduzidos");

        dispatch({
          type: constants.ERROR,
        });
        console.log(response);
      });
  };
}

export function deleteMaintenance(state) {
  return (dispatch) => {
    dispatch({
      type: constants.DELETE,
    });
    const data = state;
    const id = data.id;
    axios
      .delete(`/api/v1/maintenances/${id}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.DELETED,
          });
          toastr.success("Eleminar", "Eliminada com sucesso");
        }
      })
      .catch(function(response) {
        toastr.error("Verifique os dados introduzidos");

        dispatch({
          type: constants.ERROR,
        });
        console.log(response);
      });
  };
}

export function setMaintenance(pid) {
  return async (dispatch) => {
    dispatch({
      type: constants.SAVING,
    });

    try {
      const data = store.getState().maintenances;
      const search = store.getState().app.search;
      const parklistData = store.getState().parklist; //temporary untill model is created backend

      //lets get the correct element id, from tasks or from own menu
      let eid = null;
      if (search.element && typeof search.element !== "object") {
        eid = search.element;
      }

      // let create data set
      const setData = parklistData.list.reduce((prev, curr) => {
        if (search.selected.includes(curr.numero) || pid === curr.numero) {
          prev.push({
            type: data.details.type || search.active || "preventiva",
            selected: search.selected,
            pid: curr.numero,
            details: { ...data.details, status: 0 },
            eid,
            location: curr.local,
            area: curr.freguesia,
          });
        }
        return prev;
      }, []);

      // let set post data
      let url = `/api/v1/maintenances`;
      let sendData = setData && setData[0];

      if (setData.length > 1) {
        url += `/batch`;
        sendData = setData;
      } else {
        sendData.selected = sendData.selected[0];
      }

      const response = await axios.post(url, sendData);

      if (response.data.msg) {
        dispatch({
          type: constants.SAVED,
          data: response.data.msg,
        });
        toastr.success("Concluido", "Inspeção Salva com sucesso");
      }
    } catch (error) {
      toastr.error("Verifique os dados introduzidos");
      console.log(error);

      dispatch({
        type: constants.ERROR,
      });
    }
  };
}

export function setMaintenanceEV(selectedOtherPark, type = "visita") {
  return async (dispatch) => {
    dispatch({
      type: constants.SAVING,
    });

    try {
      const data = store.getState().maintenances;
      const search = store.getState().app.search;

      //lets get the correct element id, from tasks or from own menu
      let eid = null;
      if (search.element && typeof search.element !== "object") {
        eid = search.element;
      }
      let park = [];
      // if (search.selected.length < 2) {
      //   park = search.selected[0];
      // } else {
      park = null;

      // }

      // let create data set
      let setData = {
        type: "visita",
        details: data.details,
        selected: park,
        history: data.history,
        attr: {
          ...data.details.attr,
          eid: eid,
          relation: search.selected,
          customFieldsType: type,
        },
        eid,
        pid: type === "rega" ? search.selected : search.selected[0],
        location: search.local,
        area: search.freguesia,
      };

      if (selectedOtherPark) {
        setData.pid = selectedOtherPark.numero;
        setData.location = selectedOtherPark.local;
        setData.area = selectedOtherPark.freguesia;
      }

      // let set post data
      let url = `/api/v1/maintenances`;

      const response = await axios.post(url, setData);

      if (response.data.msg) {
        dispatch({
          type: constants.SAVED,
          data: response.data.msg,
        });
        toastr.success("Concluido", "Inspeção Salva com sucesso");
      }
    } catch (error) {
      toastr.error("Verifique os dados introduzidos");
      console.log(error);

      dispatch({
        type: constants.ERROR,
      });
    }
  };
}

export function setComment(pid) {
  return async (dispatch) => {
    dispatch({
      type: constants.SAVING,
    });

    try {
      const data = store.getState().maintenances;
      const search = store.getState().app.search;
      const parklistData = store.getState().parklist; //temporary untill model is created backend

      //lets get the correct element id, from tasks or from own menu
      let eid = null;
      if (search.element && typeof search.element !== "object") {
        eid = search.element;
      }

      // let create data set
      const setData = {
        type: "comentario",
        selected: search.selected,
        pid: pid,
        details: { ...data.details, status: 0 },
        eid,
      };

      // let set post data
      let url = `/api/v1/maintenances`;

      const response = await axios.post(url, setData);

      if (response.data.msg) {
        dispatch({
          type: constants.SAVED,
          data: response.data.msg,
        });
        toastr.success("Concluido", "Comentario Salvo com sucesso");
      }
    } catch (error) {
      toastr.error("Verifique os dados introduzidos");
      console.log(error);

      dispatch({
        type: constants.ERROR,
      });
    }
  };
}

export function getMaintenancesHistory(id) {
  return (dispatch) => {
    dispatch({
      type: constants.HISTORY_GETTING,
    });
    axios
      .get(`/api/v1/maintenances/history/${id}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.HISTORY_RECEIVED,
            data: response.data.msg,
          });
        }
      })
      .catch(function(response) {
        dispatch({
          type: constants.HISTORY_ERROR,
        });
        console.log(response);
      });
  };
}
export function setMaintenanceDone(id) {
  return (dispatch) => {
    axios
      .get(`/api/v1/maintenances/approve/${id}`)
      .then(function(response) {
        toastr.success("Inspecção", "foi Aprovada com sucesso");
        dispatch({
          type: constants.APPROVED_MAINTENANCE_GROUP,
        });
      })
      .catch(function(response) {
        toastr.error("Verifique os dados introduzidos");

        dispatch({
          type: constants.ERROR,
        });
      });
  };
}
export function getInterventions() {
  return (dispatch) => {
    dispatch({
      type: constants.INTERVENTIONS_GETTING,
    });
    axios
      .get(`/api/v1/interventions`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.INTERVENTIONS_RECEIVED,
            data: response.data.msg,
          });
        }
      })
      .catch(function(response) {
        toastr.error("Ocorreu um erro a obter lista de danos");
        console.log(response);
      });
  };
}

export function getMaintenances() {
  return (dispatch) => {
    dispatch({
      type: constants.GETTING,
    });

    getMaintenancesList(dispatch);
  };
}

export function getMaintenancesList(dispatch) {
  const search = store.getState().app.search;
  const role = store.getState().user.data.role;

  let what = "visita";
  let parks = search.selected.length > 0 ? search.selected : "all";
  let local = search.local || "all";
  let status = "all";

  axios
    .get(
      `/api/v1/maintenances/${encodeURI(what)}/${encodeURI(
        search.freguesia || "all"
      )}/${encodeURI(local)}/${encodeURI(parks)}/${encodeURI(
        search.startDate
      )}/${encodeURI(search.endDate)}/${encodeURI(status)}`
    )
    .then(function(response) {
      if (response.data.msg) {
        dispatch({
          type: constants.RECEIVED,
          data: response.data.msg,
        });
      } else {
        dispatch({
          type: constants.RECEIVED,
          data: [],
        });
      }
    })
    .catch(function(response) {
      // toastr.error('Ocorreu um erro a obter as inspecções');

      dispatch({
        type: constants.ERROR,
      });
    });
}
