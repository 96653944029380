import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

import { handleDate } from "../../services/utils";
const getMuiTheme = () =>
  createMuiTheme({
    typography: {
      useNextVariants: true,
    },
    overrides: {
      MuiTableBody: {
        root: {
          backgroundColor: "#ffffff",
        },
      },
      MUIDataTable: {
        root: {
          backgroundColor: "#FF000",
          maxHeight: "100%",
          height: "80vh",
        },
        paper: {
          boxShadow: "none",
          background: "transparent",
        },
        responsiveScroll: {
          maxHeight: "none!important",
          background: "#fff",
          borderRadius: "4px",
          height: "calc( 100vh - 160px )",
          boxShadow:
            "0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15)",
        },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          zIndex: 9,
        },
      },
    },
  });

const RootWrapper = styled.div`
  && {
    width: 100%;
    padding-top: 20px;
    @media (max-width: 425px) {
      margin: 0 0 27% 0;
    }
  }
`;

const TableWrapper = styled.div`
  && {
    th {
      z-index: 8 !important;
    }
  }
`;

class EnhancedTable extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      order: "asc",
      orderBy: props.orderBy,
      selected: [],
      page: 0,
      rowsPerPage: 10,
    };
  }

  handleRequestSort = (event, property) => {
    // const orderBy = property;
    // let order = 'desc';
    // if (this.state.orderBy === property && this.state.order === 'desc') {
    //   order = 'asc';
    // }
    // const data =
    //   order === 'desc'
    //     ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
    //     : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
    // this.setState({ data, order, orderBy });
  };

  handleClick = (id) => {
    this.props.click && this.props.click(id);
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  filterData = (header, data) => {
    let outputData = [];
    data.map((row) => {
      let thisData = [];
      header.map((col) => {
        thisData.push(row[col.id] || "");
      });
      outputData.push(thisData);
    });
    return outputData;
  };
  render() {
    const { classes, data, header } = this.props;
    const { order, orderBy, selected, rowsPerPage, page } = this.state;

    if (!data)
      return (
        <RootWrapper>
          <TableWrapper>Não existe conteudo</TableWrapper>
        </RootWrapper>
      );

    const thisData = this.filterData(header, data);

    const options = {
      filter: true,
      // count: thisData.length || 0,
      isRowSelectable: false,
      responsive: "scroll",
      tableBodyHeight: "calc( 100vh - 100px )",
      fixedHeader: true,
      fixedSelectColumn: true,
      selectableRows: "none",
      downloadOptions: {
        filename: "report.csv",
        separator: ";",
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        },
      },
      // onDownload: (buildHead, buildBody) => buildHead(header) + buildBody(data),
      onDownload: (buildHead, buildBody, columns, data) => {
        const processedDataCSV =
          (this.props.preprocessExport && this.props.preprocessExport(data)) ||
          data;
        return "\uFEFF" + buildHead(columns) + buildBody(processedDataCSV);
      },
      onRowClick: (rowData, rowMeta) => {
        this.handleClick(this.props.data[rowMeta.dataIndex].id);
      },
      rowsPerPage: 10,
      rowsPerPageOptions: [10, 25, 50, 250, 1000, 5000],
      // customRowRender: (rowdata, dataIndex, rowIndex) => {
      //   console.log(rowdata, dataIndex);
      // },
    };

    return (
      <RootWrapper>
        <TableWrapper>
          <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title={""}
              data={thisData}
              columns={header}
              options={options}
            />
          </MuiThemeProvider>
          {/* <Table>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              header={header}
            />
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n, i) => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, n.id, n.group)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                      {header.map((column, index) => {
                        switch (column.id) {
                          case 'id':
                            return null;
                          case 'date':
                            return (
                              <TableCell key={index} padding="none">
                                {handleDate(n[column.id])}
                              </TableCell>
                            );
                          case 'Damages':
                            return (
                              <TableCell key={index} padding="none">
                                {n[column.id] && n[column.id].label}
                              </TableCell>
                            );
                          case 'Team':
                            return (
                              <TableCell key={index} padding="none">
                                {n[column.id] && n[column.id].label}
                              </TableCell>
                            );
                          case 'Intervention':
                            return (
                              <TableCell key={index} padding="none">
                                {n[column.id] && n[column.id].label}
                              </TableCell>
                            );
                          default:
                            return (
                              <TableCell key={index} padding="none">
                                {typeof n[column.id] !== 'object'
                                  ? n[column.id]
                                  : n[column.id]}
                              </TableCell>
                            );
                        }
                      })}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={6}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  rowsPerPageOptions={[10, 25, 50, 100, 250]}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table> */}
        </TableWrapper>
      </RootWrapper>
    );
  }
}

export default EnhancedTable;
