import { GETTING, RECEIVED } from './constants';
import { USER_LOGGED_OUT } from '../User/constants';
import { store } from '../../store/configure';
const status = {
  edit: 'edit',
  set: 'set',
  error: 'error',
  saving: 'saving',
  edited: 'edited',
  getting: 'getting',
};

let initialState = {
  status: '',
  list: [],
  location: {},
  local: [],
};

const isLocalStorage = typeof localStorage !== 'undefined';
let newState = {};

function presistData(type, data) {
  try {
    if (isLocalStorage) localStorage[type] = JSON.stringify(data);
  } catch (error) {
    console.log(error);
  }
  return data;
}

//lets load user data for reload
if (isLocalStorage && localStorage.parklist) {
  initialState = JSON.parse(localStorage.parklist);
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GETTING:
      return { ...state, status: status.getting };
    case RECEIVED:
      let locationList = {};
      const user = store.getState().user;

      action.data.map(park => {
        //first lets check if we have the values
        if (park.freguesia && park.local)
          //lets use objects... to avoid clenaing the array after
          locationList[park.freguesia] = {
            ...locationList[park.freguesia],
            [park.local]: { local: park.local, nome: park.nome },
          };
        return true;
      });

      // let parkList = action.data;
      // if (user.data.role === 'EV') {
      //   parkList = action.data.map(park => {
      //     return {
      //       ...park,
      //       eid: park.numero,
      //       numero: park.local,
      //     };
      //   });
      // }

      newState = {
        ...state,
        status: status.set,
        list: action.data,
        role: user.data.role,
        location: locationList,
        geoJson: action.data.map(park => {
          // const latArray = park.latitude.trim().split(/[^\d\w\-\.]+/);
          // const lngArray = park.longitude.trim().split(/[^\d\w\-\.]+/);

          return {
            type: 'Feature',
            properties: {
              name: park.nome,
              freguesia: park.freguesia,
              local: park.local,
              id: park.gid,
              eid: '',
              numero: park.numero,
              numero_ant: park.número_ant,
              show_on_map: true,
              type: 'park',
              // lat: Number(
              //   `${latArray[0]}.${latArray[1]}${latArray[2].split('.')[0]}`
              // ),
              // lng: Number(
              //   `${lngArray[0]}.${lngArray[1]}${lngArray[2].split('.')[0]}`
              // ),
            },
            geometry: park.geojson ? JSON.parse(park.geojson) : '',
          };
        }),
      };
      return presistData('parklist', newState);
    // return newState;
    case USER_LOGGED_OUT:
      return initialState;
    default:
      return state;
  }
};
