import { GETTING, RECEIVED, SAVED, SAVING } from './constants';
import { USER_LOGGED_OUT } from '../User/constants';
import { store } from '../../store/configure';

import { getCurrentDate } from '../../services/utils';

const status = {
  edit: 'edit',
  saved: 'saved',
  error: 'error',
  saving: 'saving',
  edited: 'edited',
  getting: 'getting',
};

let initialState = {
  list: [],
  pid: null,
  elementsGeoJson: [],
  status: '',
  search: {
    local: '',
    freguesia: '',
    totalParks: 0,
    selected: '',
    startDate: getCurrentDate(),
  },
};

const isLocalStorage = typeof localStorage !== 'undefined';
let newState = {};
//lets load user data for reload
if (isLocalStorage && localStorage.tasks) {
  initialState = JSON.parse(localStorage.tasks);
  initialState.search = {
    ...initialState.search,
    endDate: getCurrentDate(),
    startDate: getCurrentDate(),
  };
}
function presistData(type, data) {
  try {
    if (isLocalStorage) localStorage[type] = JSON.stringify(data);
  } catch (error) {
    console.log(error);
  }
  return data;
}

function selectedTotalParks(routesList) {
  //TODO
  const role = store.getState().user.data.role;
  const geoJson = store.getState().parklist.geoJson;
  if (!geoJson) return 0;
  let totalParks = 0;
  routesList.map(route => {
    let thisParks = geoJson.reduce((prev, curr) => {
      // lets check if we already added folha
      if (!prev.find(p => p.properties.local === curr.properties.local)) {
        //lets find and push this park
        const thisPark = route.list.find(r => r === curr.properties.numero);
        if (thisPark) {
          prev.push(curr);
        }
      }

      return prev;
    }, []);
    totalParks += thisParks.map(park => park.properties.local).length;
  });
  return totalParks;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVED:
      newState = {
        ...state,
        status: status.set,
        maintenancesList: action.data.maintenancesList,
        inspectionList: action.data.inspectionList,
        routesList: action.data.routesList,
        totalParks: selectedTotalParks(action.data.routesList),
      };
      return presistData('tasks', newState);
    case GETTING:
      return {
        ...state,
        status: status.getting,
      };
    case SAVED:
      newState = {
        ...state,
        status: status.saved,
      };
      return presistData('tasks', newState);
    case SAVING:
      newState = {
        ...state,
        status: status.saving,
      };
      return presistData('tasks', newState);
    case SAVED:
      newState = {
        ...state,
        status: status.saved,
      };
      return presistData('tasks', newState);
    case USER_LOGGED_OUT:
      return initialState;
    default:
      return state;
  }
};
