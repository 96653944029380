import React from "react";
import styled from "styled-components";

const Title = styled.h2`
  && {
    font-size: 2em;
    color: #4a4a4a;
    font-weight: normal;
    margin: 0 0 1.5em 0;
    letter-spacing: -1.09px;
    @media print {
      font-size: 1.6em;
    }
    span {
      font-size: 0.7em;
      display: block;
      color: #9d9d9d;
    }
  }
`;

const Bar = styled.div`
  && {
    height: 4px;
    width: 52px;
    background: ${(props) => props.color || "#4da1ff"};
    border-radius: 2px;
    margin: 0.3em 0 1em 0;
  }
`;

class PageTitle extends React.Component {
  render() {
    return (
      <Title>
        {this.props.children}
        <Bar color={this.props.color} />
      </Title>
    );
  }
}

export default PageTitle;
