import * as constants from './constants';
import axios from 'axios';
import { store } from '../../store/configure';
import { toastr } from 'react-redux-toastr';
import { getElementsPure } from '../App/actions';

export function addNewToggle(type, geoJson) {
  return {
    type: constants.ADD_NEW_TOGGLE,
    data: { type, geoJson },
  };
}

export function editToggle(element) {
  return {
    type: constants.EDIT_TOGGLE,
    data: { element },
  };
}

export function viewToggle(element) {
  return {
    type: constants.VIEW_TOGGLE,
    data: { element },
  };
}

export function receivedElements() {
  return {
    type: constants.ELEMENTS_RECEIVED,
  };
}

export function setParkByNumber(pid) {
  return dispatch => {
    dispatch({
      type: constants.PARK_GETTING,
    });
    const data = store.getState().georeference;
    const updateData = data.newItem.details;
    axios
      .put(`/api/v1/parks/${pid}`, updateData)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.PARK_RECEIVED,
            data: response.data.msg,
          });
        }
      })
      .finally(() => {
        dispatch({
          type: constants.PARK_DONE,
        });
        toastr.success('Parcela', 'Actualizado com sucesso');
      })
      .catch(function(response) {
        toastr.error('Ocorreu um erro a obter as elementos');
        dispatch({
          type: constants.PARK_ERROR,
        });
      });
  };
}
export function getHistoryByElementId(id) {
  return dispatch => {
    dispatch({
      type: constants.HISTORY_GETTING,
    });

    axios
      .get(`/api/v1/elements/history/${id}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.HISTORY_RECEIVED,
            data: response.data.msg,
          });
        }
      })
      .finally(() => {
        dispatch({
          type: constants.HISTORY_DONE,
        });
        toastr.success('Historico', 'Actualizado com sucesso');
      })
      .catch(function(response) {
        toastr.error('Ocorreu um erro a obter as historico');
        dispatch({
          type: constants.HISTORY_ERROR,
        });
      });
  };
}
export function getHistoryByParkId(pid) {
  return dispatch => {
    dispatch({
      type: constants.HISTORY_GETTING,
    });

    axios
      .get(`/api/v1/parks/history/${pid}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.HISTORY_RECEIVED,
            data: response.data.msg,
          });
        }
      })
      .finally(() => {
        dispatch({
          type: constants.HISTORY_DONE,
        });
        toastr.success('Historico', 'Actualizado com sucesso');
      })
      .catch(function(response) {
        toastr.error('Ocorreu um erro a obter as historico');
        dispatch({
          type: constants.HISTORY_ERROR,
        });
      });
  };
}

export function getParkById(pid) {
  return dispatch => {
    dispatch({
      type: constants.PARK_GETTING,
    });
    const user = store.getState().user;
    let dataType = 'espacosverdes';

    axios
      .get(`/api/v1/${dataType}/${pid}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.PARK_RECEIVED,
            data: response.data.msg,
          });
        }
      })
      .finally(() => {
        dispatch({
          type: constants.PARK_DONE,
        });
      })
      .catch(function(response) {
        toastr.error('Ocorreu um erro a obter as elementos');
        dispatch({
          type: constants.PARK_ERROR,
        });
      });
  };
}

export function getHortasParcel(pid) {
  return dispatch => {
    dispatch({
      type: constants.HORTAS_PARCEL_GETTING,
    });

    axios
      .get(`/api/v1/hortas/parcel/${pid}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.HORTAS_PARCEL_RECEIVED,
            data: response.data.msg,
          });
        }
      })
      .finally(() => {
        dispatch({
          type: constants.HORTAS_PARCEL_DONE,
        });
      })
      .catch(function(response) {
        toastr.error('Ocorreu um erro a obter as elementos');
        dispatch({
          type: constants.HORTAS_PARCEL_ERROR,
        });
      });
  };
}

export function resetElement() {
  return {
    type: constants.RESET,
  };
}

export function setDetails(details) {
  return {
    type: constants.SET_DETAILS,
    data: details,
  };
}

export function getElementsTypes() {
  return dispatch => {
    dispatch({
      type: constants.ELEMENTS_TYPE_GETTING,
    });
    axios
      .get(`/api/v1/elementstypes`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.ELEMENTS_TYPE_RECEIVED,
            data: response.data.msg,
          });
        }
      })
      .catch(function(response) {
        toastr.error('Ocorreu um erro a obter lista de danos');
        console.log(response);
      });
  };
}

export function getVegetationTypes() {
  return dispatch => {
    dispatch({
      type: constants.VEGETATION_TYPE_GETTING,
    });
    axios
      .get(`/api/v1/vegetationtype`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.VEGETATION_TYPE_RECEIVED,
            data: response.data.msg,
          });
        }
      })
      .catch(function(response) {
        toastr.error('Ocorreu um erro a obter lista de danos');
        console.log(response);
      });
  };
}

export function updateElement() {
  return dispatch => {
    dispatch({
      type: constants.SAVING,
    });

    const data = store.getState().georeference;
    const search = store.getState().app.search;
    let elementId = '';

    if (!search.selected[0]) {
      toastr.error('Tem de selecionar uma parcela');

      dispatch({
        type: constants.ERROR,
      });
    }
    let elementData = {
      ...data.newItem,
      pid: search.selected[0] || null,
      local: search.local,
    };

    if (data.element) {
      elementId = data.element.properties.id;
      elementData.geoJson = data.element.geometry;
    }

    axios
      .put(`/api/v1/elements/${elementId}`, elementData)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.SAVED,
            data: response.data.msg,
          });
          toastr.success('Elemento', 'Actualizado com sucesso');
        }
      })
      .catch(function(response) {
        toastr.error('Verifique os dados introduzidos');

        dispatch({
          type: constants.ERROR,
        });
        console.log(response);
      })
      .finally(() => {
        getElementsPure(dispatch, 'selected', search.selected[0]);
      });
  };
}

export function updateGeojsonElement(id, geoJson) {
  return dispatch => {
    dispatch({
      type: constants.SAVING,
    });
    const search = store.getState().app.search;

    if (!search.selected[0]) {
      toastr.error('Tem de selecionar uma parcela');

      dispatch({
        type: constants.ERROR,
      });
    }

    axios
      .put(`/api/v1/elements/geojson/${id}`, { geometry: geoJson })
      .then(function(response) {
        if (response.data.msg) {
          // dispatch({
          //   type: constants.SAVED,
          //   data: response.data.msg,
          // });
          toastr.success('Elemento', 'Actualizado com sucesso');
        }
      })
      .catch(function(response) {
        toastr.error('Verifique os dados introduzidos');

        dispatch({
          type: constants.ERROR,
        });
        console.log(response);
      })
      .finally(() => {
        getElementsPure(dispatch, 'selected', search.selected[0]);
      });
  };
}

export function delElement() {
  return dispatch => {
    const data = store.getState().georeference;
    const search = store.getState().app.search;

    if (!search.selected[0]) {
      toastr.error('Tem de selecionar uma parcela');

      dispatch({
        type: constants.ERROR,
      });
    }
    let elementId = '';

    if (data.element) {
      elementId = data.element.properties.id;
    } else {
      return null;
    }

    axios
      .delete(`/api/v1/elements/${elementId}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.DELETED,
            data: response.data.msg,
          });
          toastr.success('Elemento', 'Elemento eleminado');
        }
      })
      .catch(function(response) {
        toastr.error('Verifique os dados introduzidos');

        dispatch({
          type: constants.ERROR,
        });
        console.log(response);
      })
      .finally(() => {
        getElementsPure(dispatch, 'selected', search.selected[0]);
      });
  };
}

export function setElement() {
  return dispatch => {
    dispatch({
      type: constants.SAVING,
    });
    const data = store.getState().georeference;
    const search = store.getState().app.search;

    if (!search.selected[0]) {
      toastr.error('Tem de selecionar uma parcela');

      dispatch({
        type: constants.ERROR,
      });
    }
    axios
      .post(`/api/v1/elements/`, {
        ...data.newItem,
        pid: search.selected[0] || null,
        local: search.local,
      })
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.SAVED,
            data: response.data.msg,
          });
          toastr.success('Elemento', 'Salvo com sucesso');
        }
      })
      .catch(function(response) {
        toastr.error('Verifique os dados introduzidos');

        dispatch({
          type: constants.ERROR,
        });
        console.log(response);
      })
      .finally(() => {
        getElementsPure(dispatch, 'selected', search.selected[0]);
      });
  };
}
