import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Content from "../Content";
import { Route, Switch } from "react-router-dom";
import LoadingBar from "react-redux-loading-bar";
import MapComponent from "../../components/Map/Map";
import { debounce } from "lodash";

import Dashboard from "../Dashboard/DashboardPage";
import Inspections from "../Inspections";
import Georeference from "../Georeference";
import Reports from "../Reports";
import ViewReportManager from "../Reports/ViewReportManager";
import Calendar from "../Calendar";
import Users from "../User";
import Tasks from "../Tasks";

import LocationFilterMap from "../../components/Filters/LocationFilterMap";
import ParcelsFilterMap from "../../components/Filters/ParcelsFilterMap";
import { setSearch, setActivePark, setDateSearch } from "./actions";
import { getCurrentDate } from "../../services/utils";

import NavigationPanel from "../../components/Layout/NavigationPanel";
import Header from "../../components/Layout/Header";
import Maintenances from "../Maintenances";
import { getTaskGeoJsonEV } from "../ParkList/actions";

import { getGeoJson, getGeoElementJson } from "../ParkList/Selector";

import { default as AddNewInspection } from "../Inspections/AddNew";
import { default as AddNewMaintenance } from "../Maintenances/AddNew";
import ViewReport from "../Reports/ViewReport";

import {
  toggleAddInspection,
  toggleAddInterventions,
  toggleViewReport,
} from "./actions";

import { getAllGreenSpaces } from "../../containers/ParkList/actions";

import { userIsAdmin } from "../../auth";

const FullContainer = styled(Content)`
  && {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
    height: 100vh;
    background-size: cover;
  }
`;

const FiltersContent = styled.div`
  && {
    position: fixed;
    right: 1em;
    bottom: 1em;
    z-index: 9;
    @media (max-width: 900px) {
      bottom: 6.5em;
      right: 0.5em;
      z-index: 1;
    }
    @media (max-width: 455px) {
      bottom: auto;
      right: auto;
      top: 0.5em;
      left: 3.5em;
      z-index: 1;
      width: 70%;
    }
  }
`;

const MapContent = styled.div`
  && {
    width: calc(100% - 80px);
    margin: 0;
    height: 100vh;
    position: fixed;
    top: 0;
    @media (max-width: 900px) {
      width: 100%;
      height: calc(100vh - 65px);
    }
  }
`;

const StyledNavigationPanel = styled.div`
  && {
    width: 80px;
    height: 100vh;
    border: none;
    z-index: 3;
    > div {
      background: #2d2e3c;
    }

    @media (max-width: 900px) {
      height: 65px;
      width: 100%;
      position: fixed;
      bottom: 0;
    }
  }
`;

const Styledmain = styled.main`
  width: calc(100% - 80px);
  z-index: 2;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

class App extends Component {
  static propTypes = {
    // children: PropTypes.oneOfType([
    //   PropTypes.arrayOf(PropTypes.node),
    //   PropTypes.node,
    // ]).isRequired,
  };

  constructor(props) {
    super(props);

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.setActivePark = this.setActivePark.bind(this);
    this.handleSearchChange = debounce(this.handleSearchChange, 600);

    if (
      !props.list ||
      props.list.length === 0 ||
      !props.list.length ||
      props.role !== props.listRole
    ) {
      props.getAllGreenSpaces();
    }
    this.roleData = props.menus[props.role];
    props.setDateSearch(getCurrentDate(), true);
  }

  handleSearchChange(e, value) {
    let element = [e.target.name];

    this.props.setSearch({
      element: element,
      value: e.target.value,
    });
  }

  setActivePark(park, type) {
    /// issue with EV element select
    if (type === "element") {
      this.props.setActivePark(
        this.props.numero,
        this.props.local,
        this.props.freguesia,
        park.properties.id || park.properties.origin,
        false,
        false,
        true
      );
    } else {
      this.props.setActivePark(
        park.properties.numero || this.props.numero,
        park.properties.local || this.props.local,
        park.properties.freguesia || this.props.freguesia,
        null
      );
    }
  }

  getGeoJson() {
    const { role, geoJson, admin } = this.props;

    if (
      this.props.location.pathname === "/tasks" ||
      this.props.location.pathname === "/dashboard" ||
      (admin && this.props.location.pathname === "/")
    ) {
      return getTaskGeoJsonEV(geoJson.geoJson);
    } else {
      return this.props.geoJson;
    }
  }

  render() {
    const {
      role,
      admin,
      dashboard,
      userType,
      inspections,
      maintenances,
      totalParks,
    } = this.props;

    let hideMap = false;

    if (admin) {
      hideMap =
        this.props.location.pathname !== "/dashboard" &&
        this.props.location.pathname !== "/reports" &&
        this.props.location.pathname !== "/calendar" &&
        this.props.location.pathname !== "/georeference" &&
        this.props.location.pathname !== "/users";
    } else {
      hideMap =
        this.props.location.pathname !== "/reports" &&
        this.props.location.pathname !== "/reports/month" &&
        this.props.location.pathname !== "/calendar" &&
        this.props.location.pathname !== "/georeference" &&
        this.props.location.pathname !== "/users";
    }
    //special dashboard
    if (this.props.dashboard && this.props.location.pathname === "/dashboard") {
      hideMap = false;
    }

    return (
      <FullContainer>
        <LoadingBar style={{ backgroundColor: "#4da1ff", zIndex: 999 }} />
        <StyledNavigationPanel>
          <NavigationPanel
            roleMenu={this.roleData}
            inspections={inspections}
            maintenances={maintenances}
            tasks={totalParks}
            admin={this.props.admin}
            dashboard={this.props.dashboard}
            type={this.props.type}
            location={this.props.history.location.pathname}
          />
        </StyledNavigationPanel>
        <Styledmain>
          <Header />
          {this.props.children}
          <Switch>
            {(admin || dashboard) && <Route path="/tasks" component={Tasks} />}
            <Route
              path="/inspections/:id?/:callback?"
              component={Inspections}
            />
            <Route
              path="/interventions/:id?/:callback?"
              component={Maintenances}
            />
            <Route path="/georeference/:id?" component={Georeference} />
            {/* <Route
              path="/georeference/:id?"
              component={userIsAdmin(Georeference)}
            /> */}
            <Route path="/reports/month" component={ViewReportManager} />
            <Route path="/reports/:id?" component={userIsAdmin(Reports)} />
            <Route path="/calendar/:id?" component={Calendar} />
            <Route path="/users/:id?" component={userIsAdmin(Users)} />

            <Route
              path="/dashboard"
              component={this.props.admin || dashboard ? Dashboard : Tasks}
            />
            {/* <Route path="/" component={() => {}} /> */}
          </Switch>

          {hideMap && (
            <FiltersContent>
              <ParcelsFilterMap handleSearchChange={this.handleSearchChange} />
            </FiltersContent>
          )}
          {hideMap && (
            <MapContent>
              <MapComponent
                geoJson={this.getGeoJson()}
                elements={this.props.elementsGeoJson}
                onClickMarker={this.setActivePark}
              />
            </MapContent>
          )}
        </Styledmain>

        {this.props.addInspection && (
          <AddNewInspection closeModal={this.props.toggleAddInspection} />
        )}
        {this.props.addIntervention && (
          <AddNewMaintenance closeModal={this.props.toggleAddInterventions} />
        )}
        {this.props.viewDayReport && (
          <ViewReport onClose={this.props.toggleViewReport} />
        )}
      </FullContainer>
    );
  }
}
export default connect(
  (state) => {
    return {
      elementTypeSelected: state.app.search.elementTypeSelected,
      element: state.app.search.element,
      local: state.app.search.local,
      freguesia: state.app.search.freguesia,
      selected: state.app.search.selected,
      status: state.app.search.status,
      userType: state.user.data && state.user.data.type,
      maintenances: state.maintenances.list && state.maintenances.list.length,
      inspections: state.inspections.list && state.inspections.list.length,
      totalParks: state.tasks.totalParks,
      geoJson: getGeoJson(state),
      elementsGeoJson: getGeoElementJson(state),
      elmentDisplay: state.app.elmentDisplay,
      elementsGeoJson: state.app.elementsGeoJson,
      role: state.user.data.role,
      admin: state.user.data.admin,
      dashboard: state.user.data.dashboard,
      type: state.user.data.type,
      list: state.parklist.list,
      listRole: state.parklist.role,
      menus: state.app.menus,
      addInspection: state.app.addInspection,
      addIntervention: state.app.addIntervention,
      viewDayReport: state.app.viewDayReport,
      viewDayReportManager: state.app.viewDayReportManager,
    };
  },
  {
    getAllGreenSpaces,
    getGeoElementJson,
    AddNewMaintenance,
    AddNewInspection,
    toggleAddInspection,
    toggleAddInterventions,
    toggleViewReport,
    setSearch,
    setActivePark,
    setDateSearch,
  }
)(App);
