import * as constants from "./constants";
import axios from "axios";
import { store } from "../../store/configure";
import { toastr } from "react-redux-toastr";

export function setDetails(details) {
  return {
    type: constants.SET_DETAILS,
    payload: details,
  };
}

export function centereToUser() {
  return {
    type: constants.CENTER,
  };
}

export function centereddOnUser() {
  return {
    type: constants.CENTERED,
  };
}

export function userLocation() {
  return (dispatch) => {
    navigator.geolocation.watchPosition(
      (position) => {
        const trackUserPosition = store.getState().app.trackUserPosition;
        if (position && trackUserPosition) {
          dispatch({
            type: constants.USER_POSITION,
            payload: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          });
        }
      },
      (e) => {
        console.log(e);
      },
      {
        enableHighAccuracy: true,
        maximumAge: 30000,
        timeout: 27000,
      }
    );
  };
}

export function login() {
  return (dispatch) => {
    dispatch({
      type: constants.USER_LOGGING_IN,
    });
    const data = store.getState().form.login.values;
    axios
      .post(`/api/v1/auth/login`, {
        email: data.email,
        password: data.password,
      })
      .then(function(response) {
        if (response.data.id) {
          dispatch({
            type: constants.USER_LOGGED_IN,
            payload: response.data,
          });
          // toastr.success('Login', 'Fez login com sucesso');
        }
      })
      .catch(function(response) {
        toastr.error("Verifique os seus dados");

        dispatch({
          type: constants.USER_LOGGED_IN_ERROR,
        });
        console.log(response);
      });
  };
}

export function refreshToken(dispatch, getState) {
  console.log("need to build login Modal for now redirecting");
  window.location = "/logout";
}

export function logout() {
  return {
    type: constants.USER_LOGGED_OUT,
  };
}

export function resetUser() {
  return {
    type: constants.RESETUSER,
  };
}

export function setUserInitData(id) {
  return {
    type: constants.SET_USER_INIT_DATA,
    payload: id,
  };
}
export function setTeamInitData(id) {
  return {
    type: constants.SET_TEAM_INIT_DATA,
    payload: id,
  };
}

export function getTeams() {
  return (dispatch) => {
    dispatch({
      type: constants.GETTING_TEAMS,
    });

    axios
      .get(`/api/v1/users/teams`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.RECEIVED_TEAMS,
            payload: response.data.msg,
          });
        }
      })
      .catch(function(response) {
        toastr.error("Ocorreu um erro a obter as equias");
      });
  };
}

export function getUsers() {
  return (dispatch) => {
    dispatch({
      type: constants.GETTING,
    });

    axios
      .get(`/api/v1/users/`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.RECEIVED,
            payload: response.data.msg,
          });
        }
      })
      .catch(function(response) {
        console.log(response);
        toastr.error("Ocorreu um erro a obter os utilizadores");
      });
  };
}

export function getUser(id) {
  return (dispatch) => {
    dispatch({
      type: constants.GETTING,
    });

    axios
      .get(`/api/v1/users/${id}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.RECEIVED_USER,
            payload: response.data.msg,
          });
        }
      })
      .catch(function(response) {
        toastr.error("Ocorreu um erro a obter os utilizador");
      })
      .finally(() =>
        dispatch({
          type: constants.DONE,
        })
      );
  };
}
export function toggleUserStatus() {
  return (dispatch) => {
    dispatch({
      type: constants.SAVING,
    });
    const userData = store.getState().user.editData;
    const id = store.getState().user.id;
    axios
      .post(`/api/v1/users/status/${id}`, {
        status: userData.status === 1 ? 0 : 1,
      })
      .then(function(response) {
        if (response.data) {
          dispatch({
            type: constants.SAVED,
            data: response.data.msg,
          });
          toastr.success("Utilizador", "Salvo com sucesso");
        }
      })
      .catch(function(response) {
        toastr.error("Ocorreu um erro a salvar o utilizador");
      });
  };
}
export function toggleTeamStatus() {
  return (dispatch) => {
    dispatch({
      type: constants.SAVING,
    });
    const userData = store.getState().user.editData;
    const id = store.getState().user.id;
    axios
      .post(`/api/v1/users/teams/status/${id}`, {
        status: userData.status === 1 ? 0 : 1,
      })
      .then(function(response) {
        if (response.data) {
          dispatch({
            type: constants.SAVED,
            data: response.data.msg,
          });
          toastr.success("Equipa", "Salvo com sucesso");
        }
      })
      .catch(function(response) {
        toastr.error("Ocorreu um erro a salvar o utilizador");
      });
  };
}

export function setTeam() {
  return (dispatch) => {
    dispatch({
      type: constants.SAVING,
    });

    const editData = store.getState().user.editData;
    const id = store.getState().user.id;

    axios
      .post(`/api/v1/users/teams/${id}`, {
        label: editData.label,
        access: editData.parks,
        details: editData.details,
        external: editData.type === "external",
      })
      .then(function(response) {
        if (response.data) {
          dispatch({
            type: constants.SAVED,
            data: response.data.msg,
          });
          toastr.success("Equipas", "Salvo com sucesso");
        }
      })
      .catch(function(response) {
        toastr.error("Ocorreu um erro a salvar o utilizador");
      });
  };
}

export function setUser() {
  return (dispatch) => {
    dispatch({
      type: constants.SAVING,
    });

    const editData = store.getState().user.editData;
    const id = store.getState().user.id;
    console.log(editData);
    axios
      .post(`/api/v1/users/${id}`, {
        name: editData.name,
        email: editData.email,
        external: editData.external,
        password: editData.password,
        admin: editData.admin,
        team: editData.Team,
        teams: editData.teams,
        attr: editData.attr,
        type: editData.type,
        write: editData.write,
        parks: editData.parks,
        permissions: editData.permissions,
      })
      .then(function(response) {
        if (response.data) {
          dispatch({
            type: constants.SAVED,
            data: response.data.msg,
          });
          toastr.success("Utilizador", "Salvo com sucesso");
        }
      })
      .catch(function(response) {
        toastr.error("Ocorreu um erro a salvar o utilizador");
      });
  };
}
