//axios setup
import axios from "axios";
import { apiAddress } from "../config/general";
import { store } from "../store/configure";
import { hideLoading, showLoading } from "react-redux-loading-bar";

export const configAxios = () => {
  let numberOfAjaxCAllPending = 0;

  axios.defaults.baseURL = apiAddress;
  axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";
  axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";
  axios.defaults.withCredentials = false; // for session keeping

  /**
   * Loading indicator Start
   */
  axios.interceptors.request.use(
    function (config) {
      numberOfAjaxCAllPending++;
      if (store && numberOfAjaxCAllPending === 1) {
        store.dispatch(showLoading());
      }
      // show loader
      return config;
    },
    function (error) {
      store.dispatch(hideLoading());
      return Promise.reject(error);
    }
  );

  /**
   * Loading indicator END
   */
  axios.interceptors.response.use(
    function (response) {
      numberOfAjaxCAllPending--;
      if (store && numberOfAjaxCAllPending === 0) {
        store.dispatch(hideLoading());
        // console.log("------------  Ajax pending", numberOfAjaxCAllPending);
      }

      return response;
    },
    function (error) {
      numberOfAjaxCAllPending--;
      store.dispatch(hideLoading());
      //hide loader
      return Promise.reject(error);
    }
  );

  try {
    if (
      typeof localStorage === "undefined" &&
      !localStorage.getItem("userData")
    ) {
      throw new Error("no user data");
    }

    const userData = JSON.parse(localStorage.getItem("userData") || null);
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + userData.data.token;
  } catch (error) {
    axios.defaults.headers.common["Authorization"] = "";
  }
};
