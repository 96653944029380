import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import FullScreenModal from "../../components/Layout/FullScreenModal";
import PageTitle from "../../components/Text/PageTitle";

import DetailsSpecific from "./steps/DetailsSpecific";
import ThankYou from "./steps/ThankYou";

import { setActiveStep, getInspections, addNewToggle } from "./actions";
import { setHistory, setDetails } from "./actions";

const Section = styled.div`
  && {
    margin: 2em 0;
  }
`;

const Close = styled.span`
  && {
    font-size: 2.5em;
    margin: 0 0.5em 0 0;
    text-align: center;
    color: #989898;
    vertical-align: middle;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 11;
    cursor: pointer;
    :hover {
      color: #4da1ff;
      transform: rotate(90deg);
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
  }
`;

class AddNew extends Component {
  constructor(props) {
    super(props);
    this.close = this.close.bind(this);
  }

  close() {
    this.props.closeModal();
    if (this.props.status === "saved") {
      this.props.getInspections();
    }
    this.props.setDetails({});
  }

  uniq(a) {
    return Array.from(new Set(a || []));
  }

  render() {
    const {
      list,
      closeModal,
      status,
      element,
      selected,
      local,
      role,
      elementsGeoJson,
    } = this.props;

    // const pidFormHistory =
    //   !pid && history && history.length > 0 ? history[0].pid : pid;

    // if (selectedPid) this.props.addNewToggle(selectedPid, false);

    const selectedParks = list.filter(
      (park) => selected.includes(park.numero) || local === park.numero
    );

    //override for tasks
    // if (selectedPid) this.props.addNewToggle(selectedPid, false);
    let parkName = "";
    let locationData = "";

    if (selected.length > 1) {
      parkName = `${this.uniq(selectedParks.map((p) => p.local)).join(
        ", "
      )}, ${this.uniq(selectedParks.map((p) => p.nome)).join(", ")}`;

      locationData = `${selected}`;
    } else if (selected.length > 0) {
      parkName = `${`${selectedParks[0].nome} ,` || ""}`;
      locationData = `${`${selectedParks[0].freguesia} ,` ||
        ""}  ${selectedParks[0].numero || ""}  ${
        element ? ", " + element : ""
      }`;
    }

    // const thisElementType = elementsGeoJson.find(eleGJ => {
    //   if (eleGJ.properties.id) {
    //     return eleGJ.properties.id === element;
    //   } else if (eleGJ.properties.origin) {
    //     return eleGJ.properties.origin === element;
    //   }
    // });

    const thisElementType = elementsGeoJson.find((eleGJ) => {
      return (
        eleGJ.properties.id === element ||
        (eleGJ.properties.origin && eleGJ.properties.origin === element)
      );
    });

    return (
      <FullScreenModal>
        <Close onClick={() => this.close()} className={`icon-close icons`} />
        <Section>
          <PageTitle>
            <i className={`icon-camera icons`} /> {parkName}
            <span>
              {locationData},
              {thisElementType &&
                thisElementType.properties.label &&
                `${
                  thisElementType.properties.category
                    ? thisElementType.properties.category + ","
                    : ""
                } ${thisElementType.properties.label}`}
            </span>
          </PageTitle>

          {status !== "saved" && (
            <DetailsSpecific
              // selectedParkId={selectedPark.pid}
              isRega={this.props.user.rega}
              selectedElement={thisElementType}
            />
          )}
          {status === "saved" && <ThankYou close={this.close} />}

          {/* <Steps
            title={`Inspeção ${type}`}
            active={this.props.activeStep}
            thankyou={this.getThankyou}
            selectedParkId={pidFormHistory}
            getSteps={this.getSteps}
            getStepContent={this.getStepContent}
          /> */}
        </Section>
      </FullScreenModal>
    );
  }
}

export default connect(
  (state) => {
    return {
      type: state.inspections.type,
      activeStep: state.inspections.activeStep,
      history: state.inspections.reportHistory,
      status: state.inspections.status,
      user: state.user.data,
      list: state.parklist.list,
      local: state.app.search.local,
      role: state.user.data.role,
      selected: state.app.search.selected,
      element: state.app.search.element,
      elementsGeoJson: state.app.elementsGeoJson,
    };
  },
  { setActiveStep, getInspections, setHistory, addNewToggle, setDetails }
)(AddNew);
