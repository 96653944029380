import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { toggleMobileView } from './actions';
import ButtonGroup from '../../components/Buttons/ButtonGroup';
import GroupedButton from '../../components/Buttons/GroupedButton';

const MenuIcon = styled.span`
  && {
    font-size: 1.3em;
    margin: 0 0.5em 0 0;
    text-align: center;
    color: #989898;
    vertical-align: middle;
  }
`;
const ToggleWrapper = styled(ButtonGroup)`
  && {
    display: none;
    bottom: 60px;
    position: fixed;
    left: 0;
    z-index: 10;
    width: 100%;
    background: #ecf5ff;
    @media (max-width: 768px) {
      display: block;
    }
    @media print {
      display: none;
    }
  }
`;
const StyledButton = styled(GroupedButton)`
  && {
    min-width: auto;
    span {
      font-size: 1.2em;
    }
  }
`;

class MobileLayoutToggle extends Component {
  render() {
    return (
      <ToggleWrapper>
        <StyledButton
          width={'50%'}
          active={this.props.activeMobileView === 'map'}
          onClick={() => this.props.toggleMobileView('map')}
        >
          <MenuIcon className={`icon-screen-tablet icons`} />
        </StyledButton>
        {/* <StyledButton
          width={'33%'}
          active={this.props.activeMobileView === 'list'}
          onClick={() => this.props.toggleMobileView('list')}
        >
          <MenuIcon className={`icon-grid icons`} />
        </StyledButton> */}
        <StyledButton
          width={'50%'}
          active={this.props.activeMobileView === 'filter'}
          onClick={() => this.props.toggleMobileView('filter')}
        >
          <MenuIcon className={' icon-list icons'} />
        </StyledButton>
      </ToggleWrapper>
    );
  }
}

export default connect(
  state => {
    return {
      activeMobileView: state.app.activeMobileView,
    };
  },
  { toggleMobileView }
)(MobileLayoutToggle);
