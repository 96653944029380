import * as constants from "./constants";
import axios from "axios";

let initialState = {
  data: null,
  editData: {
    permissions: "",
    name: "",
    email: "",
    Team: {},
    teams: [],
    admin: false,
    type: "all",
    write: "all",
    external: false,
    parks: [],
    password: "",
  },
  id: "",
  position: { lat: "", lng: "" },
  centerToUser: false,
  list: [],
  teams: [],
  status: "",
  search: {},
  isLoading: false,
  error: null,
};

const status = {
  set: "set",
  fetched: "fetched",
  edit: "edit",
  saved: "saved",
  error: "error",
  saving: "saving",
  edited: "edited",
  getting: "getting",
};

const isLocalStorage = typeof localStorage !== "undefined";

//lets load user data for reload
if (isLocalStorage && localStorage.userData) {
  initialState = JSON.parse(localStorage.userData);
}

let newState = {};
export default function user(state = initialState, { type, payload }) {
  switch (type) {
    case constants.USER_LOGGING_IN:
      return { ...initialState };
    case constants.CENTER:
      newState = {
        ...state,
        centerToUser: true,
      };
      if (isLocalStorage) localStorage.userData = JSON.stringify(newState);
      return newState;
    case constants.CENTERED:
      newState = {
        ...state,
        centerToUser: false,
      };
      if (isLocalStorage) localStorage.userData = JSON.stringify(newState);
      return newState;

    case constants.USER_POSITION:
      newState = {
        ...state,
        position: {
          lat: payload.lat || "",
          lng: payload.lng || "",
        },
      };
      if (isLocalStorage) localStorage.userData = JSON.stringify(newState);
      return newState;

    case constants.USER_LOGGED_IN:
      if (isLocalStorage) {
        localStorage.userData = JSON.stringify({ ...state, data: payload });
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + payload.token;
      }

      return { ...state, data: payload, isLoading: false };
    case constants.TOKEN_UPDATED:
      if (isLocalStorage) {
        localStorage.userData = JSON.stringify({
          ...state,
          data: {
            ...state.data,
            token: payload,
          },
        });
        axios.defaults.headers.common["Authorization"] = "Bearer " + payload;
      }
      return {
        ...state,
        data: {
          ...state.data,
          token: payload,
        },
        isLoading: false,
      };
    case constants.GETTING:
      return { ...state, status: status.getting };
    case constants.RECEIVED:
      newState = {
        ...state,
        status: status.fetched,
        list: payload,
      };
      return newState;

    case constants.RECEIVED_TEAMS:
      newState = {
        ...state,
        teams: payload,
      };
      return newState;
    case constants.SET_USER_INIT_DATA:
      const userFound = state.list.find((user) => payload === user.id);
      newState = {
        ...state,
        status: status.fetched,
        id: payload || "",
        editData: userFound || {
          permissions: "",
          name: "",
          email: "",
          type: "all",
          write: "all",
          Team: {},
          parks: [],
          teams: [],
          admin: false,
          external: false,
          password: "",
        },
      };
      return newState;
    case constants.SET_TEAM_INIT_DATA:
      const teamFound = state.teams.find((team) => payload === team.id);
      newState = {
        ...state,
        status: status.fetched,
        id: payload || "",
        editData: teamFound || {
          label: "",
          type: false,
        },
      };
      return newState;
    case constants.RESETUSER:
      newState = {
        ...state,
        status: status.fetched,
        id: "",
        editData: {
          permissions: "",
          name: "",
          email: "",
          type: "all",
          write: "all",
          Team: {},
          parks: [],
          teams: [],
          admin: false,
          external: false,
          password: "",
        },
      };
      return newState;

    case constants.SET_DETAILS:
      newState = {
        ...state,
        editData: {
          ...state.editData,
          [payload.element]: payload.value,
        },
      };
      return newState;
    case constants.RECEIVED_USER:
      newState = {
        ...state,
        status: status.fetched,
        editData: {
          permissions: payload.role,
          name: payload.name,
          email: payload.email,
          Team: payload.Team || {},
          teams: payload.attr ? payload.attr.teams : [],
          type: payload.type || "all",
          external: payload.Team ? payload.Team.type === "external" : false, //for external types
          admin: payload.admin,
          write: payload.write,
          status: payload.status,
          parks: payload.access || [],
          password: "",
          // external: payload.external,
        },
      };
      return newState;
    case constants.SAVED:
      return { ...state, status: status.saved };
    case constants.USER_LOGGED_IN_ERROR:
      return { ...state, error: true };
    case constants.DONE:
      return { ...state, status: status.fetched };
    case constants.USER_LOGGED_OUT:
      // localStorage.removeItem('userData');
      // localStorage.removeItem('parklist');
      // localStorage.removeItem('calendar');
      localStorage.clear();
      window.location = "/";
      return {
        data: null,
        isLoading: false,
        error: null,
        editData: null,
      };
    default:
      return state;
  }
}
