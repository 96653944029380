import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Rating from "react-rating";
import ContentWrap from "../../../components/Layout/ContentWrap";
import SectionTitle from "../../../components/Text/SectionTitle";
import TextField from "../../../components/form-wrappers/TextField";
import MainButton from "../../../components/Buttons/MainButton";
import LineButton from "../../../components/Buttons/LineButton";

const NextButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    width: 100%;
  }
`;
const HalfRate = styled.div`
  && {
    display: inline-block;
    width: 30%;
    margin-right: 2%;
    @media (max-width: 900px) {
      width: 45%;
    }
    @media (max-width: 425px) {
      width: 100%;
    }
  }
`;
const RatingWrap = styled.div`
  && {
    margin: 2em 0 0;
    text-align: left;
    label {
      display: block;
      color: rgba(0, 0, 0, 0.6);
      font-size: 0.8em;
      margin-bottom: 0.5em;
    }
    /* > span {
      zoom: 0.8;
    } */
  }
`;

const OptionWrap = styled(ContentWrap)`
  && {
    padding: 2em;
    margin-bottom: 1em;
    text-align: center;
    .optionsTitle {
      cursor: pointer;
      display: flex;
      width: 100%;
      font-weight: 600;
      text-align: left;
      font-size: 1.2em;
      color: #4da1ff;
      justify-content: space-between;
    }
    .options {
      display: none;
    }
    .optionLine {
      display: flex;
      text-align: left;
      padding: 0.8em 0;
      align-items: center;
      margin: 0em 0;
      border-bottom: 1px solid #4da1ff40;
      flex-wrap: wrap;
      &:last-child {
        border-bottom: none;
      }
      justify-content: space-between;
    }
    .optionName {
      padding: 0.8em 0;
      width: 30%;
    }
    .optionValues {
      display: flex;
      min-width: 70%;
      align-items: center;
      justify-content: flex-end;
      .option {
        margin: 0 0.5em 0 0;
        font-size: 0.8em;
      }
      label,
      input {
        font-size: 1em;
      }
    }
  }
`;

const inspectionCFMaterialRaw = [
  {
    name: "GOTA-A-GOTA",
    options: [
      {
        name: "Tubo gota-a-gota",

        options: ["DIÂMETRO (mm)", "COMPRIMENTO (m)"],
      },
      {
        name: "Tubo cego",

        options: ["DIÂMETRO (mm)", "COMPRIMENTO (m)"],
      },
      {
        name: "Fim de ciclo",

        options: ["UNIDADES"],
      },
      {
        name: "Braçadeira",

        options: ["UNIDADES"],
      },
      {
        name: "Estaca de fixação",

        options: ["UNIDADES"],
      },
    ],
  },
  {
    name: "UNIÕES",
    options: [
      { name: "Joelho", options: ["DIÂMETRO (mm)", "UNIDADES"] },
      { name: "Tê", options: ["DIÂMETRO (mm)", "UNIDADES"] },
      { name: "Racord", options: ["DIÂMETRO (mm)", "UNIDADES"] },
      { name: "Uniões", options: ["DIÂMETRO (mm)", "UNIDADES"] },
      { name: "Gromet", options: ["DIÂMETRO (mm)", "UNIDADES"] },
      { name: "Cachimbo", options: ["DIÂMETRO (mm)", "UNIDADES"] },
    ],
  },
  {
    name: "REGA POR ASPERSÃO",
    options: [
      { name: "Pulverizador fixo", options: ["UNIDADES"] },
      {
        name: "Pulverizador rotativo",
        options: ["UNIDADES"],
      },
      { name: "Bicos", options: ["UNIDADES"] },
      { name: "Cápsulas", options: ["UNIDADES"] },
      { name: "Aspersor 3500", options: ["UNIDADES"] },
      { name: "Aspersor 5000", options: ["UNIDADES"] },
    ],
  },
  {
    name: "CONTADORES",
    options: [
      {
        name: "Torneira",
        options: ["DIÂMETRO (mm)", "UNIDADES"],
      },
      { name: "O ring", options: ["DIÂMETRO (mm)", "UNIDADES"] },
    ],
  },
  {
    name: "VÁLVULAS",
    options: [
      {
        name: "Torneira de segurança",
        options: ["DIÂMETRO (mm)", "UNIDADES"],
      },
      {
        name: "Tomada de carga",
        options: ["DIÂMETRO (mm)", "UNIDADES"],
      },
      {
        name: "Filtro de água",
        options: ["DIÂMETRO (mm)", "UNIDADES"],
      },
      {
        name: "Eletroválvulas",
        options: ["MODELO", "Nº DE ESTAÇÕES", "DIÂMETRO (mm)", "UNIDADES"],
      },
      {
        name: "Válvula solenoide",
        options: ["MODELO", "Nº DE ESTAÇÕES", "DIÂMETRO (mm)", "UNIDADES"],
      },
    ],
  },
  {
    name: "PROGRAMADORES",
    options: [
      {
        name: "Programador novo",
        options: ["MODELO", "N. DE ESTAÇÕES", "UNIDADES"],
      },
      {
        name: "Programador reutilizado",
        options: ["MODELO", "N. DE ESTAÇÕES", "UNIDADES"],
      },
      {
        name: "Pilhas",
        options: ["UNIDADES"],
      },
      {
        name: "Programadores roubados (se aplicável)",
        options: ["UNIDADES"],
      },
    ],
  },
  {
    name: "GERAL",
    options: [
      {
        name: "Caixas",
        options: ["MODELO", "UNIDADES"],
      },
      {
        name: "Mangueira",
        options: ["DIÂMETRO (mm)", "COMPRIMENTO (m)"],
      },
      {
        name: "Sure Quick",
        options: ["DIÂMETRO (mm)", "UNIDADES"],
      },

      {
        name: "Torneira de jardim",
        options: ["DIÂMETRO (mm)", "UNIDADES"],
      },
      {
        name: "Tampões",
        options: ["DIÂMETRO (mm)", "UNIDADES"],
      },
      {
        name: "Casquilhos",
        options: ["DIÂMETRO (mm)", "UNIDADES"],
      },
      {
        name: "Tubo",
        options: ["DIÂMETRO (mm)", "COMPRIMENTO (m)"],
      },
    ],
  },
];

const SistemaDeRegaCF = (props) => {
  const [opened, setOpened] = useState([]);
  const [regaType, setRegaType] = useState("Inspeção");
  const [inspectionCFMaterial, setInspectionCFMaterial] = useState(
    inspectionCFMaterialRaw || []
  );

  useEffect(() => {
    const newOpened = [];
    //lets set the opened array for every custom field with values
    Object.keys(props?.attr?.customFields || {})?.forEach((cf) => {
      const valueHir = cf.split("->");
      const mainDif = valueHir[0];
      //get the index of the main custom field
      const mainIndex = inspectionCFMaterial.findIndex(
        (m) => m.name === mainDif
      );
      newOpened.push(mainIndex);
      //let set the array state
    });
    setOpened([...new Set(newOpened)]);

    return () => {};
  }, []);

  const duplicateIteminMaterial = (index, option) => {
    try {
      let newInspectionCFMaterial = [...inspectionCFMaterial];

      //get the options for this index
      let selectOptionToCopy = {
        ...newInspectionCFMaterial[index].options[option],
      };

      selectOptionToCopy.key = Math.floor(Math.random() * 100);
      const newArray = [];
      //add the selected option to the options array at correct index
      newInspectionCFMaterial[index].options.forEach((o, i) => {
        newArray.push(o);
        if (i === option) {
          newArray.push(selectOptionToCopy);
        }
      });

      newInspectionCFMaterial[index].options = newArray;

      setInspectionCFMaterial(newInspectionCFMaterial);
    } catch (error) {
      console.log(error);
    }
  };

  const rempveIteminMaterial = (index, option) => {
    try {
      let newInspectionCFMaterial = [...inspectionCFMaterial];

      //get the options for this index
      let selectOptionToCopy = newInspectionCFMaterial[index];

      //remove item at selected index
      newInspectionCFMaterial[index].options.splice(option, 1);

      setInspectionCFMaterial(newInspectionCFMaterial);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {/* <SectionTitle>Tipo de Intervenção</SectionTitle> */}
      <div>
        <LineButton
          onClick={() =>
            !props.readonly &&
            props.handleAttrCustomFieldsRating("Instalação", "regaType")
          }
          active={props?.attr?.customFields.regaType === "Instalação"}
        >
          <i
            className="icons icon-camera"
            style={{
              color:
                props?.attr?.customFields.regaType === "Instalação"
                  ? "#fff"
                  : "",
            }}
          />{" "}
          Instalação
        </LineButton>
        <LineButton
          onClick={() =>
            !props.readonly &&
            props.handleAttrCustomFieldsRating("Reparação", "regaType")
          }
          active={props?.attr?.customFields.regaType === "Reparação"}
        >
          <i
            className="icons icon-wrench"
            style={{
              color:
                !props.readonly &&
                props?.attr?.customFields.regaType === "Reparação"
                  ? "#fff"
                  : "",
            }}
          />{" "}
          Reparação
        </LineButton>
        <LineButton
          onClick={() =>
            props.handleAttrCustomFieldsRating("Rutura", "regaType")
          }
          active={props?.attr?.customFields.regaType === "Rutura"}
        >
          <i
            className="icons icon-social-stumbleupon"
            style={{
              color:
                props?.attr?.customFields.regaType === "Rutura" ? "#fff" : "",
            }}
          />{" "}
          Rutura
        </LineButton>
        <LineButton
          onClick={() =>
            props.handleAttrCustomFieldsRating("Em Stock", "regaType")
          }
          active={props?.attr?.customFields.regaType === "Em Stock"}
        >
          <i
            className="icons social-dropbox"
            style={{
              color:
                props?.attr?.customFields.regaType === "Em Stock" ? "#fff" : "",
            }}
          />{" "}
          Em stock
        </LineButton>
      </div>

      <SectionTitle>Material</SectionTitle>
      {inspectionCFMaterial.map((cf, index) => {
        const isOpen = opened.includes(index);
        return (
          <OptionWrap key={index}>
            <label
              className="optionsTitle"
              onClick={() =>
                isOpen
                  ? setOpened(opened.filter((o) => o !== index))
                  : setOpened([...opened, index])
              }
            >
              {cf.name}{" "}
              <i
                className={`icons ${
                  isOpen ? "icon-arrow-up" : "icon-arrow-down"
                }`}
              />
            </label>
            <div
              className="options"
              style={{ display: isOpen ? "block" : "none" }}
            >
              {cf.options.map((row, b) => {
                return (
                  <div key={b} className="optionLine">
                    <div
                      className="optionName"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        !props.readonly && duplicateIteminMaterial(index, b)
                      }
                    >
                      {!props.readonly && (
                        <i
                          className={`icons icon-plus`}
                          style={{
                            fontSize: ".8em",
                            color: "#4da1ff",
                            marginTop: "0.2em",
                          }}
                        />
                      )}{" "}
                      {row.name}
                    </div>
                    <div className="optionValues">
                      {row.options.map((option, c) => {
                        let name = `${cf.name}->${row.name}->${option}`;
                        if (row.key) {
                          name = `${name}->${row.key}`;
                        }

                        return (
                          <div key={name} className="option">
                            <TextField
                              label={option}
                              onChange={(e) => {
                                props.handleAttrCustomFieldsRating(
                                  e.target.value,
                                  name
                                );
                              }}
                              disabled={props.readonly}
                              name={name}
                              input={{}}
                              style={{
                                color:
                                  props?.attr?.customFields &&
                                  props?.attr?.customFields[name]
                                    ? "#4da1ff"
                                    : "black",
                              }}
                              value={
                                props?.attr?.customFields
                                  ? props?.attr?.customFields[name]
                                  : null
                              }
                              type="text"
                              meta={{ invalid: false }}
                            />
                            {/* <label>{option}</label> */}
                          </div>
                        );
                      })}
                      {!props.readonly && row.key && (
                        <i
                          onClick={() =>
                            !props.readonly && rempveIteminMaterial(index, b)
                          }
                          className={`icons icon-minus`}
                          style={{
                            fontSize: "1em",
                            color: "red",
                            marginTop: "0.2em",
                          }}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </OptionWrap>
        );
      })}
    </div>
  );
};

export default SistemaDeRegaCF;
