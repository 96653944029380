import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { debounce } from "lodash";
import SectionTitle from "../../components/Text/SectionTitle";
import TextField from "../../components/form-wrappers/TextField";

const Section = styled.div`
  && {
    margin: 0.5em 0;
  }
`;

const SectionTitleStyles = styled(SectionTitle)`
  && {
    @media (max-width: 425px) {
      display: none;
    }
  }
`;

const Half = styled.div`
  && {
    display: flex;
    > div {
      width: 48%;
      overflow: hidden;
    }
  }
`;

const Input = styled(TextField)`
  && {
    font-size: 0.9em;
    padding: 0;
    margin: 0 2% 0 0;
    display: flex;
    text-align: left;
    textarea {
      height: auto;
    }
    label {
      width: 100%;
    }
    div {
      width: 100%;
    }
  }
`;
const Calendar = styled(Input)`
  && {
  }
`;

class DateRangeFilter extends React.Component {
  constructor(props) {
    super(props);
    this.handleSearchChange = debounce(this.handleSearchChange.bind(this), 600);
  }

  handleSearchChange(e) {
    console.log(e.target);
    this.props.handleSearchChange(e);
  }

  render() {
    const { endDate, startDate, noTitle } = this.props;

    return (
      <Section>
        {!noTitle && <SectionTitleStyles>Data</SectionTitleStyles>}
        <Half>
          <Calendar
            id="startDate"
            name="startDate"
            label=""
            input={{}}
            meta={{ invalid: false }}
            type="date"
            onChange={(e) =>
              this.handleSearchChange({
                target: { name: "startDate", value: e.target.value },
              })
            }
            value={startDate}
            // defaultValue={startDate}
          />

          <Calendar
            id="endDate"
            name="endDate"
            label=""
            input={{}}
            meta={{ invalid: false }}
            type="date"
            value={endDate}
            onChange={(e) => {
              this.handleSearchChange({
                target: { name: "endDate", value: e.target.value },
              });
            }}
            // defaultValue={endDate}
          />
        </Half>
      </Section>
    );
  }
}

export default connect((state) => {
  return {
    list: state.parklist.list,
  };
}, {})(DateRangeFilter);
