import React from 'react';
import ScrollArea from 'react-scrollbar';
import { connect } from 'react-redux';
import styled from 'styled-components';

const DivScroll = styled.div`
  && {
    overflow-y: auto;
    height: ${props => props.height || 'auto'};
    @media (max-width: 425px) {
      height: 100%;
    }
  }
`;

class ScrollBar extends React.Component {
  render() {
    return !this.props.isMobile ? (
      <ScrollArea {...this.props}>{this.props.children}</ScrollArea>
    ) : (
      <DivScroll height={this.props.height}>{this.props.children}</DivScroll>
    );
  }
}

export default connect(
  state => {
    return {
      isMobile: state.app.isMobile,
    };
  },
  {}
)(ScrollBar);
