import React from "react";
import styled from "styled-components";
import { MenuItem, Select } from "@material-ui/core";
import { connect } from "react-redux";
import LineButton from "../../components/Buttons/LineButton";
import { recenterMap } from "../../containers/App/actions";

import SectionTitle from "../../components/Text/SectionTitle";

const Section = styled.div`
  && {
    margin: 0.5em 0;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    text-transform: uppercase;
  }
`;

const StyledLineButton = styled(LineButton)`
  && {
    display: block;
    font-size: 0.8em;
    width: 100%;
    text-align: center;
    margin: 0.8em 0;
    line-height: 1;
  }
`;

const Reset = styled.span`
  && {
    font-size: 1.5em;
    margin: -2em 0 0 0;
    text-align: center;
    color: #989898;
    vertical-align: middle;
    cursor: pointer;
    display: block;
    float: right;
    :hover {
      color: #4da1ff;
      transform: rotate(90deg);
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
  }
`;

class ParcelsFilter extends React.Component {
  constructor(props) {
    super(props);
    this.resetSearch = this.resetSearch.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  resetSearch() {
    this.props.handleSearchChange({
      target: {
        name: "freguesia",
        value: "",
      },
    });
  }

  handleSearchChange(e) {
    const { list, freguesia, local } = this.props;
    if (e.target.name === "selected" && e.target.value.includes("all")) {
      const selectedParks = list.sort().reduce((acc, park, index) => {
        if (park.freguesia === freguesia && local === park.local) {
          acc.push(park.numero);
        }
        return acc;
      }, []);
      e.target.value = selectedParks;
    }
    this.props.handleSearchChange(e);
    this.props.recenterMap();
  }

  sortAlphaNum(a, b) {
    var reA = /[^a-zA-Z]/g;
    var reN = /[^0-9]/g;
    var aA = a.replace(reA, "");
    var bA = b.replace(reA, "");
    if (aA === bA) {
      var aN = parseInt(a.replace(reN, ""), 10);
      var bN = parseInt(b.replace(reN, ""), 10);
      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1;
    }
  }

  renderParcels() {
    const { list, freguesia, local, selected } = this.props;
    const optionsList = [];

    list.reduce((acc, park, index) => {
      if (park.freguesia === freguesia && local === park.local) {
        optionsList[park.numero] = (
          <StyledMenuItem
            key={index}
            element={park.numero === selected}
            value={park.numero}
          >
            {park.numero}
          </StyledMenuItem>
        );
      }
      return acc;
    }, []);
    return optionsList.sort();
  }

  render() {
    const { location, list, handleSearchChange, notitle } = this.props;

    const elementTypesList = [];

    const {
      elementsGeoJson,
      elmentDisplay,
      local,
      freguesia,
      selected,
      elementType,
    } = this.props.override || this.props;

    return (
      <Section>
        {!notitle && <SectionTitle>Parcelas</SectionTitle>}
        <Reset
          onClick={() => this.resetSearch()}
          className={"icon-close icons reset"}
        />
        <Select
          fullWidth
          name={"freguesia"}
          value={freguesia}
          onChange={this.handleSearchChange}
          inputProps={{
            name: "freguesia",
            id: "freguesia",
          }}
        >
          {/* <MenuItem key={-1} value={''} /> */}
          {Object.keys(location)
            .sort()
            .map((freguesia, index) => (
              <StyledMenuItem key={index} value={freguesia}>
                {freguesia}
              </StyledMenuItem>
            ))}
        </Select>
        {freguesia && freguesia !== "" && (
          <Select
            fullWidth
            name={"local"}
            value={local}
            onChange={this.handleSearchChange}
            inputProps={{
              name: "local",
              id: "local",
            }}
          >
            <StyledMenuItem key={-1} value={""}>
              Todos
            </StyledMenuItem>
            {location[freguesia] &&
              Object.keys(location[freguesia])
                .sort(this.sortAlphaNum)
                .map((local, index) => (
                  <StyledMenuItem
                    key={index}
                    selected={local === freguesia}
                    value={local}
                  >
                    {location[freguesia][local].local}
                    {", "}
                    {location[freguesia][local].nome}
                  </StyledMenuItem>
                ))}
          </Select>
        )}
        {local && local !== "" && (
          <Select
            fullWidth
            multiple
            name={"selected"}
            value={selected || []}
            onChange={this.handleSearchChange}
            inputProps={{
              name: "selected",
              id: "selected",
            }}
          >
            <StyledMenuItem key={-2} value={""} />
            <StyledMenuItem key={-1} value={"all"}>
              Todos
            </StyledMenuItem>
            {this.renderParcels()}
          </Select>
        )}

        {selected &&
          elmentDisplay &&
          selected !== "" &&
          elementsGeoJson &&
          elementsGeoJson.length > 0 && (
            <Select
              fullWidth
              multiple
              name={"elementType"}
              value={elementType || []}
              onChange={this.handleSearchChange}
              inputProps={{
                name: "elementType",
                id: "elementType",
              }}
            >
              <StyledMenuItem key={-1} value={"all"}>
                Todos
              </StyledMenuItem>
              {elementsGeoJson.reduce((acc, element, index) => {
                const elementTypeSelected =
                  element.properties.category || element.properties.label;
                if (
                  elementTypeSelected &&
                  !elementTypesList.includes(elementTypeSelected) // lets make sure their unique
                ) {
                  elementTypesList.push(elementTypeSelected);
                  acc.push(
                    <StyledMenuItem
                      key={index}
                      selected={
                        elementType && elementType.includes(elementTypeSelected)
                      }
                      value={elementTypeSelected}
                    >
                      {elementTypeSelected}
                    </StyledMenuItem>
                  );
                }
                return acc;
              }, [])}
            </Select>
          )}
      </Section>
    );
  }
}

export default connect(
  (state) => {
    return {
      list: state.parklist.list,
      elementsGeoJson: state.app.elementsGeoJson,
      elmentDisplay: state.app.elmentDisplay,
      local: state.app.search.local,
      freguesia: state.app.search.freguesia,
      selected: state.app.search.selected,
      elementType: state.app.search.elementTypeSelected,
      location: state.parklist.location,
    };
  },
  { recenterMap }
)(ParcelsFilter);
