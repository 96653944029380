import React from "react";
import styled from "styled-components";
import Rating from "react-rating";
import ContentWrap from "../../../components/Layout/ContentWrap";
import SectionTitle from "../../../components/Text/SectionTitle";
import { TextField } from "@material-ui/core";

const HalfRate = styled.div`
  && {
    /* display: inline-block; */
    display: flex;
    align-items: center;
    width: 30%;
    margin-right: 2%;
    margin-bottom: 1em;
    @media (max-width: 900px) {
      width: 45%;
    }
    @media (max-width: 425px) {
      width: 100%;
    }
  }
`;
const RatingWrap = styled.div`
  && {
    /* margin: 2em 0 0; */
    text-align: left;
    align-items: center;
    justify-content: space-between;
    margin-right: 2%;
    label {
      text-align: left;
      display: block;
      color: rgba(0, 0, 0, 0.6);
      font-size: 1em;
      margin-bottom: 0.5em;
    }
    /* > span {
      zoom: 0.8;
    } */
  }
`;

const OptionWrap = styled(ContentWrap)`
  && {
    padding: 2em;
    margin-bottom: 2em;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
  }
`;

export const inspectionOldCF = [
  { name: "Geral", comment: "" },
  { name: "Limpeza", comment: "" },
  { name: "Relvado/Prado", comment: "" },
  { name: "Herbáceas", comment: "" },
  { name: "Arbustos", comment: "" },
  { name: "Árvores", comment: "" },
];

export const inspectionNewCF = [
  { name: "Geral", comment: "" },
  { name: "Limpeza", comment: "" },
  { name: "Relvado/Prado", comment: "" },
  { name: "Herbáceas", comment: "" },
  { name: "Arbustos", comment: "" },
  { name: "Árvores", comment: "" },
  { name: "Canteiro", comment: "" },
];

export const inspectionUpdatedCF = [
  { name: "Abate de árvores", comment: "" },
  { name: "Poda de árvores", comment: "" },
  { name: "Plantação árvores", comment: "" },
  { name: "Plantação arbustos", comment: "" },
  { name: "Instalação de Mobiliário Urbano", comment: "" },

  // { name: "Contenção prado", comment: "" },
  // { name: "Sementeira Relvado/Prado", comment: "" },
  // { name: "Escarificação Relvados", comment: "" },
  // { name: "Retancha de Arbustos", comment: "" },
  // { name: "Poda de Arbustos", comment: "" },
  // { name: "Corte de Sebes", comment: "" },
  // { name: "Monda de Canteiro", comment: "" },
  // { name: "Requalificação Canteiros", comment: "" },
  // { name: "Plantação Árvores", comment: "" },
  // { name: "Caldeiras de árvores", comment: "" },
  // { name: "Limpeza de Papeleiras", comment: "" },
  // { name: "Limpeza geral", comment: "" },
];

export const necessidadesCF = [
  { name: "Necessita Poda de Árvores", comment: "" },
  { name: "Necessita Plantação de Árvores", comment: "" },
  { name: "Necessita Tutoragem de Árvores (Fita/Tutores)", comment: "" },
  { name: "Necessita Plantação de Arbustos", comment: "" },
  { name: "Requalificação Canteiros Danificado", comment: "" },
  { name: "Sementeira Relvado/Prado Danificado", comment: "" },
  { name: "Sistema de Rega Danificado", comment: "" },
  { name: "Papeleira Danificada", comment: "" },
  { name: "Bebedouro Danificado", comment: "" },
  { name: "Mobiliário Urbano Danificado", comment: "" },
  { name: "Pavimento Danificado", comment: "" },
  { name: "Vandalismo", comment: "" },
];

const DefaultCF = (props) => {
  let inspectionCF = inspectionUpdatedCF;
  //lets check if its old or new
  // if (props?.attr?.customFields) {
  //   const found = Object.keys(props.attr.customFields).find(
  //     (cf) =>
  //       cf === "Geral" ||
  //       cf === "Limpeza" ||
  //       cf === "Relvado/Prado" ||
  //       cf === "Herbáceas" ||
  //       cf === "Arbustos" ||
  //       cf === "Árvores" ||
  //       cf === "Mobiliário Urbano" ||
  //       cf === "Pavimento" ||
  //       cf === "Sistema de Rega"
  //   );
  //   if (found) {
  //     inspectionCF = inspectionOldCF;
  //   }
  // }

  return (
    <div>
      <SectionTitle>Necessidades</SectionTitle>
      <OptionWrap>
        {necessidadesCF.map((cf, index) => (
          <HalfRate key={index}>
            <RatingWrap>
              <Rating
                onClick={(rate) => {
                  if (
                    rate &&
                    props.attr.customFields &&
                    props.attr.customFields[cf.name]
                  ) {
                    props.handleAttrCustomFieldsRating(0, cf.name);
                  }
                }}
                onChange={(rate) => {
                  props.handleAttrCustomFieldsRating(rate, cf.name);
                }}
                initialRating={
                  props.attr.customFields ? props.attr.customFields[cf.name] : 0
                }
                readonly={props.readonly}
                stop={1}
                // fullSymbol={["Não", "Sim"].map((n) => (
                //   <span
                //     className="icon-text"
                //     style={{
                //       fontSize: "10px",
                //       display: "inline-block",
                //       padding: "13px 10px",
                //       color:''
                //     }}
                //   >
                //     {n}
                //   </span>
                // ))}
                fractions={1}
              />
              <label className={"comment"}>{cf.comment}</label>
            </RatingWrap>
            <label>{cf.name}</label>
          </HalfRate>
        ))}
      </OptionWrap>
      <SectionTitle>Intervenções</SectionTitle>
      <OptionWrap>
        {inspectionCF.map((cf, index) => (
          <HalfRate key={index}>
            <RatingWrap>
              <input
                onChange={(e) =>
                  props.handleAttrCustomFieldsRating(e.target.value, cf.name)
                }
                disabled={props.readonly}
                style={{ width: "40px", padding: "10px", borderRadius: "5px" }}
                type="number"
                defaultValue={
                  props.attr.customFields ? props.attr.customFields[cf.name] : 0
                }
              />

              {/* <Rating
                onClick={(rate) => {
                  if (
                    rate &&
                    props.attr.customFields &&
                    props.attr.customFields[cf.name]
                  ) {
                    props.handleAttrCustomFieldsRating(0, cf.name);
                  }
                }}
                onChange={(rate) => {
                  props.handleAttrCustomFieldsRating(rate, cf.name);
                }}
                initialRating={
                  props.attr.customFields ? props.attr.customFields[cf.name] : 0
                }
                readonly={props.readonly}
                stop={1}
                // fullSymbol={["Não", "Sim"].map((n) => (
                //   <span
                //     className="icon-text"
                //     style={{
                //       fontSize: "10px",
                //       display: "inline-block",
                //       padding: "13px 10px",
                //       color:''
                //     }}
                //   >
                //     {n}
                //   </span>
                // ))}
                fractions={1}
              /> */}
              <label className={"comment"}>{cf.comment}</label>
            </RatingWrap>
            <label>{cf.name}</label>
          </HalfRate>
        ))}
      </OptionWrap>
    </div>
  );
};

export default DefaultCF;
