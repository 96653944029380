import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Row, Col } from "react-flexbox-grid";

import FullScreenModal from "../../components/Layout/FullScreenModal";
import PageTitle from "../../components/Text/PageTitle";
import ContentWrap from "../../components/Layout/ContentWrap";
import { default as InspectionsEdit } from "../Inspections/Edit";
import { default as MaintenanceEdit } from "../Maintenances/Edit";

import { getParkDayReport } from "./actions";

const OptionWrap = styled(ContentWrap)`
  && {
    padding: 2em;
    text-align: center;
  }
`;

const StyledPageTitle = styled(PageTitle)`
  && {
    margin-left: 120px;
  }
`;
const Section = styled.div`
  && {
    margin: 2em auto;
    max-width: 1400px;
  }
`;
const Bar = styled.div`
  && {
    height: 4px;
    width: 52px;
    background: ${(props) => props.color || "#4da1ff"};
    border-radius: 2px;
    margin: 0.4em 0 1em 0;
  }
`;
const DayPageTitle = styled.span`
  && {
    span {
      font-size: 0.8em;
      color: #4da1ff !important;
    }
    @media print {
      page-break-after: always;
    }
  }
`;
const Icon = styled.div`
  && {
    margin-right: 1.5%;
    box-shadow: 1px 2px 17px 0 rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    float: left;
    padding: 0;
    background: #fff;
    color: #4da1ff;
    span {
      border-bottom: 1px solid #fff;
      display: block;
      text-align: center;
      color: #4da1ff;
      font-weight: bold;
      font-size: 1.3em;
      padding: 0em 1.3em;
      &:first-child {
        margin-top: 0.5em;
      }
      &:last-child {
        color: #fff;
        font-size: 0.8em;
        margin-top: 0.5em;
        padding: 0.3em 1em;
        border-bottom: none;
        background: #4da1ff;
      }
    }
  }
`;
const Close = styled.span`
  && {
    font-size: 2.5em;
    margin: 0 0.5em 0 0;
    text-align: center;
    color: #989898;
    vertical-align: middle;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 11;
    cursor: pointer;
    :hover {
      color: #4da1ff;
      transform: rotate(90deg);
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
    @media print {
      display: none;
    }
  }
`;
const PrintButton = styled.span`
  && {
    font-size: 1.3em;
    margin: 0 0.5em 0 0;
    text-align: center;
    color: #989898;
    vertical-align: middle;
    position: absolute;
    right: 77px;
    top: 20px;
    z-index: 11;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid;
    padding: 0.4em;
    :hover {
      color: #4da1ff;
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
    @media print {
      display: none;
    }
  }
`;

const LocationTitle = styled.span`
  && {
    font-size: 0.8em;
    display: block;
  }
`;
class ViewReport extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      args: props.args,
      group: props.group,
    };
    this.generatePDF = this.generatePDF.bind(this);
  }

  componentDidMount() {
    if (this.state.args) {
      this.props.getParkDayReport(
        this.state.args.numero,
        this.state.args.date && this.state.args.date.split("T")[0]
      );
    } else {
      this.props.getParkDayReport();
    }
  }

  closeModal() {
    if (typeof this.props.onClose === "function") return this.props.onClose();
  }

  generatePDF() {
    window && window.print();
  }

  render() {
    const data = this.props.historyData;
    let selectedPID = "";
    // eslint-disable-next-line
    let isApproved = false;

    if (data && data.length > 0) {
      selectedPID = data[0].Inspection ? data[0].Inspection.pid : data[0].pid;
      isApproved = data[0].status === 1;
    }

    const selectedPark = this.props.parks.find(
      (park) => park.numero === selectedPID
    );

    let currentDay = "";
    return (
      <FullScreenModal>
        <Close
          onClick={() => this.closeModal()}
          className={`icon-close icons`}
        />
        <PrintButton
          onClick={() => this.generatePDF()}
          className={`icon-printer icons`}
        />
        <Section>
          {data && (
            <div>
              {data
                .sort((a, b) => {
                  return new Date(b.date) - new Date(a.date);
                })
                .map((visit, index) => {
                  let dateTitle = "";
                  let visitDate = new Date(visit.date);
                  const newDate = visit.date.split("T")[0];
                  if (currentDay !== newDate) {
                    dateTitle = (
                      <div>
                        <Icon>
                          <span>{visitDate.getDate()}</span>
                          <span>{visitDate.getMonth() + 1}</span>
                          <span>{visitDate.getFullYear()}</span>
                        </Icon>
                        <StyledPageTitle>
                          <DayPageTitle>
                            <span>Relatorio de actividade </span>
                          </DayPageTitle>
                          {selectedPark && selectedPark.nome}

                          <LocationTitle>
                            {selectedPark && selectedPark.freguesia},{" "}
                            {selectedPark && selectedPark.local}
                          </LocationTitle>
                          {/* <Bar color={this.props.color} /> */}
                        </StyledPageTitle>
                      </div>
                    );

                    currentDay = newDate;
                  }
                  if (visit.Intervention) {
                    return (
                      <div key={index}>
                        {dateTitle}
                        <MaintenanceEdit data={visit} />
                      </div>
                    );
                  } else {
                    return (
                      <div key={index}>
                        {dateTitle}
                        <InspectionsEdit data={visit} />
                      </div>
                    );
                  }
                })}
            </div>
          )}
          {data.length < 1 && (
            <div>
              <PageTitle>
                Relatorio de actividade
                <Bar color={this.props.color} />
              </PageTitle>
              <Row>
                <Col xs={12} md={9}>
                  <OptionWrap>
                    Não conseguimos encontrar dados para esta data
                  </OptionWrap>
                </Col>
              </Row>
            </div>
          )}
        </Section>
      </FullScreenModal>
    );
  }
}

export default connect(
  (state) => {
    return {
      historyData: state.reports.reportData,
      status: state.reports.status,
      parks: state.parklist.list,
      role: state.user.data.role,
      isAdmin: state.user.data.admin,
    };
  },
  {
    getParkDayReport,
  }
)(ViewReport);
