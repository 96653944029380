import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
// import ChipInput from 'material-ui-chip-input';
import { Row, Col } from "react-flexbox-grid";
import DropzoneComponent from "react-dropzone-component";
import { MenuItem, Select } from "@material-ui/core";

import MainButton from "../../../components/Buttons/MainButton";
import ContentWrap from "../../../components/Layout/ContentWrap";
import TextField from "../../../components/form-wrappers/TextField";
import SectionTitle from "../../../components/Text/SectionTitle";
import MenuIcon from "../../../components/Buttons/MenuIcon";
import AutoComplete from "../../../components/form-wrappers/AutoComplete";
import { apiAddress } from "../../../config/general";

import { setDetails, setElement, resetElement } from "../actions";
import { getElements } from "../../App/actions";

const NextButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    width: 100%;
  }
`;

const OptionWrap = styled(ContentWrap)`
  && {
    padding: 2em;
    text-align: center;
  }
`;

const SelectStyled = styled(Select)`
  && {
    font-size: 1.2em;
    text-align: left;
  }
`;

const AutoCompleteWrapper = styled.div`
  && {
    margin: 0.5em;
    input {
      font-size: 1.2em;
    }
  }
`;

const Input = styled(TextField)`
  && {
    width: 100%;
    padding: 10px 0;
    margin: 0;
    text-align: left;
    textarea {
      height: auto;
    }
  }
`;

const Calendar = styled(Input)`
  && {
    margin: 0;
  }
`;
const Files = styled(DropzoneComponent)`
  && {
    background-color: rgba(74, 144, 226, 0.1);
    border: 2px dashed rgba(74, 144, 226, 1);
    color: #4990e2;
    padding: 1em 1em 2em 1em;
    margin: 1em 0 1.5em;
    .dz-message {
      margin: 0;
    }
    .dz-preview.dz-image-preview {
      background: transparent;
    }
    .filepicker-file-icon {
      color: #4990e2;
      &::after {
        background-color: #4990e2;
      }
      &::before {
        border: solid 2px #4990e2;
      }
    }
  }
`;

class AddElementDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      details: "",
      elementType: "",
      showCatChoice: null,
      elementSubType: "",
      elementVegNorm: "",
      elementVegCien: "",
      elementVegFam: "",
      elementVegCat: "",
      selectedVeg: "",
      elementsTypeCategories: null,
      implementationDate: this.getCurrentDate(),
      files: props.files || [],
      attr: {
        contact: {
          email: "",
          phone: "",
          nome: "",
          id: "",
        },
      },
    };
    // For a full list of possible configurations,
    // please consult http://www.dropzonejs.com/#configuration
    this.djsConfig = {
      acceptedFiles: "image/*, application/*",
      addRemoveLinks: true,
      resizeWidth: 1100,
      resizeHeight: 1100,
      resizeQuality: 0.6,
      headers: { Authorization: "Bearer " + props.token },
      params: {
        fid: props.selectedPark, //should be the park id
      },
    };

    this.componentConfig = {
      iconFiletypes: ["docs", "images"],
      showFiletypeIcon: true,
      postUrl: `${apiAddress}api/v1/files`,
    };

    this.callback = this.callback.bind(this);
    this.removedfile = this.removedfile.bind(this);
    this.handleChangeId = this.handleChangeId.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeElementVegCat = this.handleChangeElementVegCat.bind(this);

    this.handleChangeElementVegCien =
      this.handleChangeElementVegCien.bind(this);
    this.handleChangeElementVegNormal =
      this.handleChangeElementVegNormal.bind(this);
    this.handleChangeElementFamiCat =
      this.handleChangeElementFamiCat.bind(this);

    this.handleChangeElementType = this.handleChangeElementType.bind(this);
    this.handleChangeElementSubType =
      this.handleChangeElementSubType.bind(this);

    this.handleSaveElement = this.handleSaveElement.bind(this);
    this.handleChangeContact = this.handleChangeContact.bind(this);
  }

  componentDidUpdate() {
    if (!this.state.elementsTypeCategories && this.props.elementTypesList) {
      this.setState({ elementsTypeCategories: this.createCategoryHirarchy() });
    }
  }

  getCurrentDate() {
    const now = new Date();
    const addZero = (i) => (i < 10 ? "0" + i : i);
    return `${now.getFullYear()}-${addZero(now.getMonth() + 1)}-${addZero(
      now.getDate()
    )}T${addZero(now.getHours())}:${addZero(now.getMinutes())}`;
  }

  createCategoryHirarchy() {
    // we nee to create the hirarchy
    return this.props.elementTypesList.reduce((prev, curr) => {
      let thisCategory = "generic";
      if (!prev["generic"]) prev["generic"] = [];

      if (curr.category) {
        if (!prev["generic"].find((cu) => cu.label === curr.category)) {
          prev["generic"].push({
            id: curr.id,
            label: curr.category,
            category: curr.category,
          });
        }
        thisCategory = curr.category;
      }

      prev[thisCategory] = [...(prev[thisCategory] || []), curr];
      return prev;
    }, {});
  }

  // Simple callbacks work too, of course
  callback(file) {
    const fileData = JSON.parse(file.xhr.response);
    const files = [
      ...this.state.files,
      {
        path: fileData.msg.details.path,
        name: fileData.msg.details.originalname,
        size: fileData.msg.details.size,
        id: fileData.msg.id,
      },
    ];
    this.setState({ files: files });
  }

  // Simple callbacks work too, of course
  removedfile(file) {
    //need to delete images
    console.log(file);
    // this.setState({ photos: [...this.state.photos, file.upload] });
  }

  handleChange(e, value) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleChangeId(e, value) {
    this.setState({
      attr: {
        ...this.state.attr,
        identifier: e.target.value,
      },
    });
  }

  handleChangeElementType(value) {
    this.setState({ elementType: value, elementSubType: "" });
  }

  handleChangeElementSubType(value) {
    this.setState({ elementSubType: value });
  }
  handleChangeElementVegNormal(value) {
    this.setState({ elementVegNorm: value });
  }

  handleChangeElementVegCien(value) {
    this.setState({ elementVegCien: value });
  }
  handleChangeElementVegCat(value) {
    this.setState({ elementVegCat: value });
  }
  handleChangeElementFamiCat(value) {
    this.setState({ elementVegFam: value });
  }

  handleChangeContact(e, value) {
    this.setState({
      attr: {
        ...this.state.attr,
        contact: {
          ...this.state.attr.contact,
          [e.target.name]: e.target.value,
        },
      },
    });
  }
  getElementTypesList() {
    return this.props.elementTypesList || [];
  }

  parcelElement() {
    return (
      <div>
        <SectionTitle>Contactos</SectionTitle>
        <OptionWrap>
          <Input
            label="Nome"
            onChange={(e) => this.handleChangeContact(e)}
            defaultValue={
              this.state.attr &&
              this.state.attr.contact &&
              this.state.attr.contact.name
            }
            name="name"
            input={{}}
            type="text"
            meta={{ invalid: false }}
            rows="8"
            disabled={this.props.view}
          />
          <Input
            label="Email"
            onChange={(e) => this.handleChangeContact(e)}
            defaultValue={
              this.state.attr &&
              this.state.attr.contact &&
              this.state.attr.contact.email
            }
            name="email"
            input={{}}
            type="text"
            meta={{ invalid: false }}
            rows="8"
            disabled={this.props.view}
          />
          <Input
            label="Telefone"
            onChange={(e) => this.handleChangeContact(e)}
            defaultValue={
              this.state.attr &&
              this.state.attr.contact &&
              this.state.attr.contact.phone
            }
            name="phone"
            input={{}}
            type="text"
            meta={{ invalid: false }}
            rows="8"
            disabled={this.props.view}
          />
          <Input
            label="ID PHC"
            onChange={(e) => this.handleChangeContact(e)}
            defaultValue={
              this.state.attr &&
              this.state.attr.contact &&
              this.state.attr.contact.id
            }
            name="id"
            input={{}}
            type="text"
            meta={{ invalid: false }}
            rows="8"
            disabled={this.props.view}
          />
        </OptionWrap>
      </div>
    );
  }

  renderSelect(options, name) {
    return (
      <SelectStyled
        fullWidth
        label={name}
        name={name}
        value={this.state[name]}
        onChange={this.handleChange}
        inputProps={{
          name: name,
          id: name,
        }}
      >
        <MenuItem key={-1} value={""} />
        {options.map((option, index) => {
          const optionVal =
            name === "elementVegCien"
              ? option.cientificName
              : option.normalName;
          return (
            <MenuItem key={index} value={option.id}>
              {optionVal}
            </MenuItem>
          );
        })}
      </SelectStyled>
    );
  }
  renderTreeSelect() {
    return (
      <div>
        <AutoCompleteWrapper>
          <AutoComplete
            updateState={this.handleChangeElementVegCat}
            options={
              this.props.vegetationTypes.reduce((prev, cur) => {
                if (!prev.find((ele) => ele.label === cur.category)) {
                  prev.push({ label: cur.category });
                }
                return prev;
              }, []) || []
            }
            placeholder={`Pesquisar por tipo`}
          />
        </AutoCompleteWrapper>

        {/* {this.state.elementVegCat &&
          this.state.elementVegCat.suggestion && (
            <AutoCompleteWrapper>
              <AutoComplete
                updateState={this.handleChangeElementFamiCat}
                options={
                  this.props.vegetationTypes.reduce((prev, cur) => {
                    if (
                      !prev.find(ele => ele.category === cur.category) &&
                      this.state.elementVegCat.suggestion.label === cur.category
                    ) {
                      prev.push({ label: cur.family });
                    }
                    return prev;
                  }, []) || []
                }
                placeholder={`Pesquisar por familia`}
              />
            </AutoCompleteWrapper>
          )} */}

        {this.state.elementVegCat && this.state.elementVegCat.suggestion && (
          <div>
            <br />
            <AutoCompleteWrapper className={"clearfix"}>
              {this.state.elementVegNorm === "" &&
                this.renderSelect(
                  this.props.vegetationTypes.filter((veg) => {
                    return (
                      veg.category === this.state.elementVegCat.suggestion.label
                    );
                  }) || [],
                  "elementVegCien"
                )}

              {/* {!this.state.elementVegNorm.suggestion && (
                  <AutoComplete
                    updateState={this.handleChangeElementVegCien}
                    options={
                      this.props.vegetationTypes.reduce((prev, cur) => {
                        if (
                          this.state.elementVegCat.suggestion.label ===
                          cur.category
                        ) {
                          return [
                            ...(prev || []),
                            { ...cur, label: cur.cientificName },
                          ];
                        }
                      }, []) || []
                    }
                    placeholder={`Pesquisar por nome cientifico`}
                  />
                )} */}
              {this.state.elementVegCien === "" &&
                this.renderSelect(
                  this.props.vegetationTypes.filter((veg) => {
                    return (
                      veg.category === this.state.elementVegCat.suggestion.label
                    );
                  }) || [],
                  "elementVegNorm"
                )}
            </AutoCompleteWrapper>
            <br clear={"both"} />
          </div>
        )}
      </div>
    );
  }

  renderSubcategory() {
    if (
      this.state.elementType.suggestion &&
      this.state.elementType.suggestion.label.trim() === "Vegetação"
    ) {
      return this.renderTreeSelect();
    } else if (this.state.elementType.suggestion) {
      return (
        <AutoCompleteWrapper>
          <AutoComplete
            updateState={this.handleChangeElementSubType}
            options={
              this.state.elementsTypeCategories[
                this.state.elementType.suggestion.label
              ] || []
            }
            placeholder={`Pode pesquisar ou adicionar um sub elemento ${
              this.state.elementType.suggestion.category || ""
            } `}
          />
        </AutoCompleteWrapper>
      );
    }
  }

  handleSaveElement() {
    this.props.setDetails({
      details: this.state.details,
      elementType: this.state.elementType,
      vegType:
        this.state.elementVegNorm !== ""
          ? this.state.elementVegNorm
          : this.state.elementVegCien,
      elementSubType: this.state.elementSubType,
      implementationDate: this.state.implementationDate,
      files: this.state.files,
      attr: this.state.attr,
    });
    this.props.setElement(this.props.selected); //this should not be necessary remove when using the correct table
    setTimeout(this.props.getElements("selected", this.props.selected), 2000);
    this.props.resetElement();

    this.setState({
      details: "",
      attr: {},
      files: [],
    });
  }

  render() {
    const config = this.componentConfig;
    const djsConfig = this.djsConfig;

    // For a list of all possible events (there are many), see README.md!
    const eventHandlers = {
      removedfile: this.removedfile,
      success: this.callback,
    };

    const parcelElement =
      this.state.elementType.suggestion &&
      this.state.elementType.suggestion.label &&
      this.state.elementType.suggestion.label.trim() === "Parcela";

    return (
      <div>
        <Row>
          <Col xs={12} md={9}>
            <SectionTitle>Informação sobre elemento</SectionTitle>
            <OptionWrap>
              {this.state.elementsTypeCategories && (
                <AutoCompleteWrapper>
                  <AutoComplete
                    updateState={this.handleChangeElementType}
                    options={this.state.elementsTypeCategories.generic}
                    placeholder={"Pesquise ou adicione um novo elemento"}
                  />
                </AutoCompleteWrapper>
              )}
              <br />
              {this.renderSubcategory()}
            </OptionWrap>
            {parcelElement && this.parcelElement()}

            <SectionTitle>Descrição</SectionTitle>
            <OptionWrap>
              <Input
                label="identificador"
                onChange={(e) => this.handleChangeId(e)}
                defaultValue={this.state.attr.identifier}
                name="identifier"
                input={{}}
                type="text"
                meta={{ invalid: false }}
                rows="8"
              />
              <Input
                multiline
                label="Descrição"
                onChange={(e) => this.handleChange(e)}
                name="details"
                input={{}}
                type="multiline"
                meta={{ invalid: false }}
                rows="8"
              />
              <Calendar
                id="implementationDate"
                name="implementationDate"
                input={{}}
                label="Data de implementação"
                meta={{ invalid: false }}
                type="datetime-local"
                onChange={(e) => this.handleChange(e)}
                defaultValue={this.state.implementationDate}
              />
            </OptionWrap>
          </Col>

          <Col xs={12} md={3}>
            <SectionTitle>Opções</SectionTitle>
            <OptionWrap>
              <Files
                config={config}
                eventHandlers={eventHandlers}
                djsConfig={djsConfig}
              />
              {this.props.status !== "saving" && (
                <NextButton
                  color="primary"
                  onClick={() => this.handleSaveElement()}
                >
                  Submeter
                  <MenuIcon className={`icon-check icons`} />
                </NextButton>
              )}
            </OptionWrap>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      poligonType: state.georeference.type,
      elementType: state.georeference.newItem.type,
      elementSubType: state.georeference.newItem.subType,
      selected: state.app.search.selected,
      photos: state.maintenances.photos,
      token: state.user.data.token,
      isAdmin: state.user.data.admin,
      elementTypesList: state.georeference.elementTypes,
      vegetationTypes: state.georeference.vegetationTypes,
      status: state.georeference.status,
    };
  },
  { resetElement, setDetails, setElement, getElements }
)(AddElementDetails);
