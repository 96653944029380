import React, { Component } from "react";
import Leaflet from "leaflet";
import { LeafletConsumer } from "react-leaflet";
import { OpenStreetMapProvider, GeoSearchControl } from "leaflet-geosearch";

export default class SearchLayer extends Component {
  constructor(props, context) {
    super(props);

    this.state = {
      open: false,
    };
  }

  componentDidMount() {}

  customProvider(props) {
    const provider = new OpenStreetMapProvider({
      params: {
        countrycodes: "pt",
        city: "cascais",
      },
    });
    const search = async (props) => {
      const results = await provider.search({ query: props.query });
      return results;
    };
    return { search };
    // return provider;
  }

  addOverlay(map) {
    if (map && !this.state.open) {
      const searchControl = new GeoSearchControl({
        ref: "searchControl",
        provider: this.customProvider(),
        autoClose: true,
        autoComplete: true, // optional: true|false  - default true
        autoCompleteDelay: 250,
      });
      map.addControl(searchControl.setPosition("topright"));
      this.setState({ open: true });
    }
  }

  render() {
    return (
      <LeafletConsumer>
        {(context) => this.addOverlay(context.map)}
      </LeafletConsumer>
    );
  }
}
