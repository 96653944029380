export const menus = {
  PI: [
    {
      order: 0,
      name: "Inicio",
      icon: "layers",
      path: "/dashboard",
    },
    {
      order: 1,
      name: "Tarefas",
      icon: "direction",
      path: "/tasks",
      permission: "admin",
    },
    { order: 1, name: "Inspecções", icon: "camera", path: "/inspections" },
    {
      order: 2,
      name: "Manutenção",
      icon: "wrench",
      path: "/interventions",
    },
    // { order: 3, name: 'Parques', icon: 'map', path: '/parques' },
    {
      order: 4,
      name: "Elementos",
      icon: "globe-alt",
      path: "/georeference",
      // permission: 'admin',
    },
    {
      order: 5,
      name: "Relatorios",
      icon: "graph",
      path: "/reports",
      permission: "admin",
    },
    { order: 6, name: "Calendário", icon: "clock", path: "/calendar" },
    {
      order: 7,
      name: "Equipas",
      icon: "user",
      path: "/users",
      permission: "admin",
    },
  ],
  EV: [
    {
      order: 0,
      name: "Inicio",
      icon: "layers",
      path: "/dashboard",
    },
    {
      order: 1,
      name: "Rotas",
      icon: "direction",
      path: "/tasks",
      permission: "dashboard",
    },
    {
      order: 1,
      name: "Visitas",
      icon: "check",
      path: "/interventions",
    },
    { order: 2, name: "Fiscalização", icon: "camera", path: "/inspections" },
    // { order: 3, name: 'Parques', icon: 'map', path: '/parques' },
    {
      order: 4,
      name: "Elementos",
      icon: "globe-alt",
      path: "/georeference",
      // permission: 'admin',
    },
    {
      order: 5,
      name: "Relatorios",
      icon: "graph",
      path: "/reports",
      permission: "admin",
    },
    {
      order: 6,
      name: "Relatorios",
      icon: "graph",
      path: "/reports/month",
      permission: "user",
    },
    { order: 7, name: "Calendário", icon: "clock", path: "/calendar" },
    {
      order: 8,
      name: "Equipas",
      icon: "user",
      path: "/users",
      permission: "admin",
    },
  ],
  HC: [
    {
      order: 0,
      name: "Inicio",
      icon: "layers",
      path: "/dashboard",
    },
    {
      order: 1,
      name: "Tarefas",
      icon: "direction",
      path: "/tasks",
      permission: "admin",
    },
    { order: 1, name: "Fiscalização", icon: "camera", path: "/inspections" },
    {
      order: 2,
      name: "Intervenções",
      icon: "wrench",
      path: "/interventions",
    },
    // { order: 3, name: 'Parques', icon: 'map', path: '/parques' },
    {
      order: 4,
      name: "Elementos",
      icon: "globe-alt",
      path: "/georeference",
      // permission: 'admin',
    },
    {
      order: 5,
      name: "Relatorios",
      icon: "graph",
      path: "/reports",
      permission: "admin",
    },
    { order: 6, name: "Calendário", icon: "clock", path: "/calendar" },
    {
      order: 7,
      name: "Equipas",
      icon: "user",
      path: "/users",
      permission: "admin",
    },
  ],
  TEST: [
    {
      order: 0,
      name: "Inicio",
      icon: "layers",
      path: "/dashboard",
    },
    {
      order: 1,
      name: "Tarefas",
      icon: "direction",
      path: "/tasks",
      permission: "admin",
    },
    { order: 1, name: "Inspecções", icon: "camera", path: "/inspections" },
    {
      order: 2,
      name: "Intervenções",
      icon: "wrench",
      path: "/interventions",
    },
    // { order: 3, name: 'Parques', icon: 'map', path: '/parques' },
    {
      order: 4,
      name: "Elementos",
      icon: "globe-alt",
      path: "/georeference",
      // permission: 'admin',
    },
    {
      order: 5,
      name: "Relatorios",
      icon: "graph",
      path: "/reports",
      permission: "admin",
    },
    { order: 6, name: "Calendário", icon: "clock", path: "/calendar" },
    {
      order: 7,
      name: "Equipas",
      icon: "user",
      path: "/users",
      permission: "admin",
    },
  ],
  global: [
    {
      order: 0,
      name: "Inicio",
      icon: "layers",
      path: "/dashboard",
    },
    {
      order: 1,
      name: "Tarefas",
      icon: "direction",
      path: "/tasks",
      permission: "admin",
    },
    { order: 1, name: "Inspecções", icon: "camera", path: "/inspections" },
    {
      order: 2,
      name: "Intervenções",
      icon: "wrench",
      path: "/interventions",
    },
    // { order: 3, name: 'Parques', icon: 'map', path: '/parques' },
    {
      order: 4,
      name: "Elementos",
      icon: "globe-alt",
      path: "/georeference",
      // permission: 'admin',
    },
    {
      order: 5,
      name: "Relatorios",
      icon: "graph",
      path: "/reports",
      permission: "admin",
    },
    { order: 6, name: "Calendário", icon: "clock", path: "/calendar" },
    {
      order: 7,
      name: "Equipas",
      icon: "user",
      path: "/users",
      permission: "admin",
    },
  ],
};
