import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Tooltip } from "@material-ui/core";

import AddNew from "./modals/AddNew";
import EditElement from "./modals/EditElement";
import EditPark from "./modals/EditPark";
import { debounce } from "lodash";
import MapComponent from "../../components/Map/Map";

import LocationFilterMap from "../../components/Filters/LocationFilterMap";
import ParcelsFilterMap from "../../components/Filters/ParcelsFilterMap";
import {
  addNewToggle,
  editToggle,
  updateGeojsonElement,
  viewToggle,
  receivedElements
} from "./actions";

import { setSearch, setUniqueActivePark, getElements } from "../App/actions";
import { getGeoJson, getGeoElementJson } from "../ParkList/Selector";

const StyledWrap = styled.div`
  display: flex;
  height: 100%;
  /* height: calc(100vh - 49px); */
`;

const ActionsContent = styled.div`
  && {
    position: fixed;
    right: 1em;
    bottom: 4.5em;
    z-index: 9;

    @media (max-width: 900px) {
      bottom: 7.5em;
      right: 0.5em;
      z-index: 1;
    }
    @media (max-width: 455px) {
      bottom: 4.5em;
    }
  }
`;
const FiltersContent = styled.div`
  && {
    position: fixed;
    right: 1em;
    bottom: 1em;
    z-index: 9;
    @media (max-width: 900px) {
      bottom: 4.5em;
      right: 0.5em;
    }
    @media (max-width: 455px) {
      bottom: 4.5em;
      left: 0.5em;
      z-index: 1;
      width: 80%;
    }
  }
`;

const ActionWrapper = styled.div`
  && {
    margin: 0.3em 0.2em;
    background: #4da1ff;
    padding: 0.7em;
    display: block;
    border-radius: 5px;
    max-width: 100px;
    box-shadow: 6px 0 9px 0 rgba(130, 158, 171, 0.4);
    color: #fff;
    cursor: pointer;
    > div {
      font-size: 0.8em;
      ::before {
        border-bottom: none;
      }
    }
    &:hover {
      color: #4da1ff;
      background: #fff;
    }
    &.active {
      color: #4da1ff;
      background: #fff;
    }
  }
`;

const MapContent = styled.div`
  && {
    width: 100%;
    margin: 0;
    height: 100vh;
    position: fixed;
    top: 0;
  }
`;

class Georeference extends Component {
  constructor(props) {
    super(props);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.setActivePark = this.setActivePark.bind(this);
    this.addElement = this.addElement.bind(this);
    this.closeAddModal = this.closeAddModal.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
    this.closeViewModal = this.closeViewModal.bind(this);
    this.handleEditMarker = this.handleEditMarker.bind(this);
    this.handleViewMarker = this.handleViewMarker.bind(this);
    this.getElements = this.getElements.bind(this);
    this.getElementsDelay = debounce(this.getElements, 2000);
    this.state = {
      type: "view",
      reRender: "",
      activeData: {},
      creatingElement: false
    };
    this.updateGeojsonElement = this.updateGeojsonElement.bind(this);
  }

  componentDidUpdate() {
    if (this.props.status === "getElements") {
      this.getElementsDelay();
    }

    if (this.props.status === "resetElements" && this.state.creatingElement) {
      this.setState({ creatingElement: false });
    }

    // console.log(this.props.status, this.props.appStatus);
    // if (
    //   this.props.status === 'getElements' &&
    //   this.props.appStatus === 'elements_received'
    // ) {
    //   this.props.receivedElements();
    // } else if (
    //   this.props.status === 'getElements' &&
    //   this.props.appStatus === ''
    // ) {
    //   this.getElements();
    // }
  }

  addElement(type, geoJson) {
    this.setState({ creatingElement: true });
    this.props.addNewToggle(type, geoJson);
  }

  closeAddModal() {
    this.props.addNewToggle();
  }

  handleSearchChange(e, value) {
    this.props.setSearch({
      element: [e.target.name],
      value: e.target.value
    });
  }

  updateGeojsonElement(id, geometry) {
    if (this.props.status !== "saving")
      this.props.updateGeojsonElement(id, geometry);
  }

  setActivePark(park) {
    let numero = park.properties.numero;
    let local = park.properties.local;
    let freguesia = park.properties.freguesia;
    let id = null;

    if (park.properties.typeId) {
      freguesia = this.props.freguesia;
      numero = park.properties.numero || this.props.selected;
      local = park.properties.local || this.props.local;
      id = park.properties.id;
    }
    this.props.setUniqueActivePark(numero, local, freguesia, id);
    this.setState({ activeData: park });

    if (this.state.type === "view") {
      this.props.viewToggle(park);
    } else if (this.state.type === "editinfo") {
      this.props.editToggle(park);
    }
  }

  getElements() {
    if (this.props.selected) {
      this.props.getElements("selected", this.props.selected);
    } else if (this.props.local) {
      this.props.getElements("local", this.props.local);
    }
  }

  handleViewMarker(park) {
    this.setActivePark(park);
    // if (this.state.type === 'add' || park.properties.type === 'park') return;
    // if (this.state.type === 'editinfo') {
    //   this.props.editToggle(park);
    // } else {
    //   this.props.viewToggle(park);
    // }
  }

  closeViewModal() {
    if (this.state.type === "editinfo") {
      this.props.editToggle();
    } else {
      this.props.viewToggle();
    }
  }

  handleEditMarker(park) {
    this.setActivePark(park);
    if (this.state.type === "editinfo") {
      this.props.editToggle(park);
    }
  }

  closeEditModal() {
    this.props.editToggle();
  }

  renderEditElement() {
    if (
      this.state.activeData &&
      this.state.activeData.properties &&
      this.state.activeData.properties.type !== "park"
    ) {
      return (
        <EditElement
          closeModal={this.closeEditModal}
          getElements={this.getElements}
        />
      );
    } else {
      return (
        <EditPark
          closeModal={this.closeEditModal}
          getElements={this.getElements}
        />
      );
    }
  }
  renderViewElement() {
    if (
      this.state.activeData &&
      this.state.activeData.properties &&
      this.state.activeData.properties.type !== "park"
    ) {
      return (
        <EditElement
          closeModal={this.closeViewModal}
          getElements={this.getElements}
          view
        />
      );
    } else {
      return (
        <EditPark
          closeModal={this.closeViewModal}
          getElements={this.getElements}
          view
        />
      );
    }
  }

  render() {
    const {
      menuData,
      role,
      selected,
      addNew,
      editElement,
      viewElement,
      map
    } = this.props;
    const type = this.state.type;
    return (
      <StyledWrap>
        <ActionsContent>
          <Tooltip title={`Ver Informação`}>
            <ActionWrapper
              onClick={() => {
                this.setState({ type: "view" });
              }}
              className={`icon-eye icons ${this.state.type === "view" &&
                "active"}`}
            />
          </Tooltip>

          <Tooltip title={`Editar Informação`}>
            <ActionWrapper
              onClick={() => {
                this.setState({ type: "editinfo" });
              }}
              className={`icon-note icons ${this.state.type === "editinfo" &&
                "active"}`}
            />
          </Tooltip>

          <Tooltip title={`Editar Georeferencia`}>
            <ActionWrapper
              onClick={() => {
                this.setState({ type: "edit" });
              }}
              className={`icon-pin icons ${this.state.type === "edit" &&
                "active"}`}
            />
          </Tooltip>

          <Tooltip title={`Adicionar Elementos`}>
            <ActionWrapper
              onClick={() => this.setState({ type: "add" })}
              className={`icon-tag icons ${this.state.type === "add" &&
                "active"}`}
            />
          </Tooltip>
        </ActionsContent>
        <FiltersContent>
          <ParcelsFilterMap handleSearchChange={this.handleSearchChange} />
        </FiltersContent>
        <MapContent>
          <MapComponent
            geoJson={this.props.geoJson}
            onClickMarker={this.setActivePark}
            elements={this.props.elementsGeoJson}
            elementActionType={this.state.type}
            editElements={this.state.type === "edit"}
            getElements={this.getElements}
            onClickEditMarker={this.handleEditMarker}
            // onClickMarker={this.handleViewMarker}
            updateGeojsonElement={this.updateGeojsonElement}
            onCreated={this.addElement}
            creatingElement={this.state.creatingElement}
            elementsGeoJson={this.props.elementsGeoJson}
          />
        </MapContent>
        {addNew && (
          <AddNew
            closeModal={this.closeAddModal}
            selectedParkId={selected}
            getElements={this.getElements}
          />
        )}
        {editElement && this.renderEditElement()}
        {viewElement && this.renderViewElement()}
      </StyledWrap>
    );
  }
}

export default connect(
  state => {
    return {
      element: state.app.search.element,
      local: state.app.search.local,
      freguesia: state.app.search.freguesia,
      selected: state.app.search.selected,
      elmentDisplay: state.app.elmentDisplay,
      elementType: state.app.search.elementTypeSelected,

      geoJson: getGeoJson(state),
      elementsGeoJson: getGeoElementJson(state),

      addNew: state.georeference.addNew,
      editElement: state.georeference.editElement,
      viewElement: state.georeference.viewElement,
      map: state.app.map,
      startDate: state.app.search.startDate,
      status: state.georeference.status,
      appStatus: state.app.status,
      isAdmin: state.user.data.admin,
      role: state.user.data.role,

      menuData:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          menu => menu.path === `/georeference`
        )
    };
  },
  {
    setSearch,
    setUniqueActivePark,
    addNewToggle,
    editToggle,
    viewToggle,
    updateGeojsonElement,
    getElements,
    getGeoElementJson,
    receivedElements
  }
)(Georeference);
