import moment from "moment";
import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import { Col, Grid, Row } from "react-flexbox-grid";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LineButton from "../../components/Buttons/LineButton";
import MainButton from "../../components/Buttons/MainButton";
import PageTitle from "../../components/Text/PageTitle";
import SectionTitle from "../../components/Text/SectionTitle";
import { getStats } from "./actions";
import {
  necessidadesCF,
  inspectionUpdatedCF,
  inspectionNewCF,
} from "../Inspections/steps/DefaultCF";

import { setActivePark, toggleAddInspection } from "../App/actions";
import { getTeams } from "../User/actions";

import {
  Hortas,
  InputYear,
  StyledContentWrapFullHeight,
  StyledContentWrapFullHeightBar,
  StyledWrap,
  TotalParcelPerWeek,
} from "./styled";
class DashboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parklist: false,
      freguesia: null,
      selectedFreguesia: "all",
      period: "weekly",
      year: new Date().getFullYear(),
      users: null,
      selectedUser: "all",
      selectedTeam: "all",
      internalUsers: null,
      externalUsers: null,
      hasFullAccess: null,
      users: null,
      horta: null,
      contactListUser: null,
      waitingListUser: null,
      maintenanceListUser: null,
      inspectionListDays: null,
      parcelBadRatingHistory: null,
      colors: [
        "#0ff1ce",
        "#bada55",
        "#420420",
        "#065535",
        "#5ac18e",
        "#ffa500",
        "#fa8072",
        "#468499",
        "#c39797",
        "#808080",
        "#ff8c00",
        "#808000",
        "#bdb76b",
        "#cd5c5c",
        "#f08080",
      ],
    };

    this.setSelectedFreguesia = this.setSelectedFreguesia.bind(this);
    this.setSelectedUser = this.setSelectedUser.bind(this);
    this.setSelectedTeam = this.setSelectedTeam.bind(this);
    this.setPeriod = this.setPeriod.bind(this);
  }

  componentDidUpdate() {
    if (this.state.parklist === false && this.props.geoJson) {
      const parkListObject = this.props.geoJson.reduce((prev, curr) => {
        prev[curr.properties.numero] = curr.properties.local;
        return prev;
      }, {});
      this.setState({ parklist: parkListObject });
    }

    if (
      this.props.user &&
      this.state.selectedUser !== this.props.user.name &&
      (!this.props.user.admin ||
        !this.props.user.parks ||
        this.props.user.parks.length > 0)
    ) {
      this.setState({
        selectedUser: this.props.user.name,
      });
    }

    if (this.props.user && this.state.hasFullAccess == null) {
      const hasFullAccess =
        this.props.user.admin &&
        (!this.props.user.parks || this.props.user.parks.length < 1);
      this.setState({
        hasFullAccess: hasFullAccess,
      });
    }

    if (
      this.props.data &&
      this.props.data.usersData &&
      this.props.data.teamData &&
      !this.state.internalUsers &&
      !this.state.externalUsers
    ) {
      this.getInternalExternalUsers();
    }
  }
  componentDidMount() {
    this.props.getStats(
      this.props.role === "EV" ? this.props.role : "",
      this.state.year
    );
    this.props.getTeams();
  }

  setSelectedFreguesia(freguesia) {
    this.setState({ selectedFreguesia: freguesia });
  }

  setSelectedTeam(team) {
    this.setState({ selectedTeam: team });
  }
  setSelectedUser(user) {
    this.setState({ selectedUser: user });
  }
  setPeriod(type) {
    this.setState({ period: type });
  }

  getInternalExternalUsers() {
    let externalTeamID = "";
    let internalTeamID = "";
    //lets get the internal and external team ID
    this.props.data.teamData.map((team) => {
      if (team.label === "EI") {
        internalTeamID = team.id;
      } else if (team.label === "EE") {
        externalTeamID = team.id;
      }
    });
    let externalTeamUsers = [];
    let internalTeamUsers = [];
    //lets get the users for eachteam
    this.props.data.usersData.map((user) => {
      if (!user.attr || !user.attr.teams) return null;
      if (user.attr.teams.includes(externalTeamID))
        externalTeamUsers.push(user);

      if (user.attr.teams.includes(internalTeamID))
        internalTeamUsers.push(user);
    });

    this.setState({
      internalUsers: internalTeamUsers,
      externalUsers: externalTeamUsers,
    });
  }

  generateGraphData() {
    const { data, menuInspections, menuInterventions } = this.props;

    const today = new Date();
    // const totalWeeks =
    //   this.state.period === "weekly"
    //     ? moment().isoWeek()
    //     : moment().format("M");
    const totalWeeks = this.state.period === "weekly" ? 52 : 12;
    const inspectionsFreguesia = {};
    const inspectionsUsers = {};
    const inspectionsEVPerDay = [...Array(Number(totalWeeks))].map((x, i) => 0);
    let yearWeeks = [...Array(Number(totalWeeks))].map((x, i) => i + 1);

    data.inspectionsPerWeek &&
      data.inspectionsPerWeek.map((inspection) => {
        let inspWeek =
          moment(inspection.week, "YYYY-MM-DD 00:00:00+00").isoWeek() - 1;

        if (this.state.period === "monthly") {
          inspWeek =
            moment(inspection.week, "YYYY-MM-DD 00:00:00+00").format("M") - 1;
        }
        //set default
        if (!inspectionsFreguesia[inspection.freguesia]) {
          inspectionsFreguesia[inspection.freguesia] = 0;
        }
        if (!inspectionsUsers[inspection.name]) {
          inspectionsUsers[inspection.name] = 0;
        }
        //check if we have a selection
        if (
          (this.state.selectedFreguesia === "all" ||
            this.state.selectedFreguesia === inspection.freguesia) &&
          (this.state.selectedUser === "all" ||
            this.state.selectedUser === inspection.name) &&
          (this.state.selectedTeam === "all" ||
            (this.state.selectedTeam.access &&
              this.state.selectedTeam.access.includes(inspection.location)))
        )
          inspectionsEVPerDay[inspWeek] =
            (inspectionsEVPerDay[inspWeek] || 0) + 1;

        return null;
      });
    //update state with toggles
    if (
      !this.state.freguesias &&
      Object.keys(inspectionsFreguesia).length > 0
    ) {
      this.setState({ freguesias: Object.keys(inspectionsFreguesia) });
    }
    if (!this.state.users && Object.keys(inspectionsUsers).length > 0) {
      this.setState({ users: Object.keys(inspectionsUsers) });
    }
    //let set the line for expected results
    let selectedParksTotal = 0;
    let processedFolhas = [];
    if (this.props.geoJson) {
      this.props.geoJson.map((park) => {
        if (
          this.state.selectedFreguesia !== "all" &&
          this.state.selectedFreguesia !== park.properties.freguesia
        )
          return null;
        if (
          !processedFolhas.includes(park.properties.local) &&
          !park.properties.local.match(/ESC|Z/)
        ) {
          processedFolhas.push(park.properties.local);
          selectedParksTotal++;
        }
      });
    }
    //if we have a user selected we have to sort by its access filters

    if (
      // this.state.selectedFreguesia === "all" &&
      this.state.selectedUser !== "all" &&
      this.props.data.usersData
    ) {
      const thisUserData = this.props.data.usersData.find(
        (user) => user.name === this.state.selectedUser
      );
      let totalParks = [];
      //user tema
      if (thisUserData.TeamId) {
        const thisUserTeam = this.props.teams.find(
          (team) => team.id === thisUserData.TeamId
        );
        totalParks = [...thisUserTeam.access, ...totalParks];
      }

      if (thisUserData.attr && thisUserData.attr.teams) {
        //extra temas
        thisUserData.attr.teams.map((t) => {
          const thisTeam = this.props.teams.find((team) => team.id === t);
          if (thisTeam)
            totalParks = [...(thisTeam.access || []), ...totalParks];
        });
      }
      if (this.state.selectedFreguesia === "all") {
        totalParks = totalParks.filter((park) => {
          if (!park.match(/ESC|Z/)) return true;
        });
      } else {
        totalParks = totalParks.filter((park) => {
          if (park.match(/ESC|Z/)) return false;
          const thisPark = this.props.geoJson.find(
            (p) => p.properties.local === park
          );

          return (
            thisPark &&
            thisPark.properties &&
            thisPark.properties.freguesia &&
            this.state.selectedFreguesia &&
            thisPark.properties.freguesia.toLowerCase() ===
              this.state.selectedFreguesia.toLowerCase()
          );
        });
      }

      selectedParksTotal = totalParks.length;
    }

    const inspectionsTarget = [...Array(Number(totalWeeks))].map((x, i) =>
      this.state.period === "monthly"
        ? selectedParksTotal
        : selectedParksTotal / 4
    );

    const lastWeekNumber = inspectionsEVPerDay.filter((v) => Number(v) > 0)
      .length;
    yearWeeks = [...Array(Number(lastWeekNumber))].map((x, i) => i + 1);

    return {
      labels: yearWeeks,
      datasets: [
        {
          type: "line",
          label: "Objetivo",
          borderColor: "rgb(54, 162, 235)",
          borderWidth: 2,
          fill: false,
          data: inspectionsTarget,
        },
        {
          type: "bar",
          label: `Avaliações ${
            this.state.selectedFreguesia !== "all"
              ? this.state.selectedFreguesia
              : ""
          } ${
            this.state.selectedUser !== "all" ? this.state.selectedUser : ""
          }`,
          backgroundColor: "rgba(30, 92, 161,1)",
          borderColor: "rgba(30,92,161,1)",
          data: inspectionsEVPerDay,
        },
      ],
    };
  }

  generateAverageData() {
    const { data, menuInspections, menuInterventions } = this.props;
    let outputWeeks = [];

    const parcelRatingHistory = this.props.data.inspectionsPerWeek;

    // const totalWeeks =
    //   this.state.period === "weekly"
    //     ? moment().isoWeek()
    //     : moment().format("M");
    const totalWeeks = this.state.period === "weekly" ? 52 : 12;
    const inspectionsFreguesia = {};
    const inspectionsUsers = {};
    const inspectionsEVPerDay = [...Array(Number(totalWeeks))].map((x, i) => 0);
    let yearWeeks = [...Array(Number(totalWeeks))].map((x, i) => i + 1);

    if (!this.props.data.inspectionsPerWeek) {
      return {
        labels: yearWeeks,
        datasets: [],
      };
    }

    parcelRatingHistory.map((th) => {
      let thisWeek = moment(th.week).isoWeek() - 1;

      if (this.state.period === "monthly") {
        thisWeek = moment(th.week).format("M") - 1;
      }

      if (!outputWeeks[thisWeek]) {
        outputWeeks[thisWeek] = { ratings: 0, inspections: 0 };
      } else if (
        (this.state.selectedFreguesia === "all" ||
          this.state.selectedFreguesia === th.freguesia) &&
        (this.state.selectedUser === "all" ||
          this.state.selectedUser === th.name) &&
        (this.state.selectedTeam === "all" ||
          (this.state.selectedTeam.access &&
            this.state.selectedTeam.access.includes(th.location))) &&
        th.rating !== null
      ) {
        outputWeeks[thisWeek] = {
          ratings: outputWeeks[thisWeek].ratings + Number(th.rating),
          inspections:
            outputWeeks[thisWeek].inspections + Number(th.inspections),
        };
      }
    });

    const inspectionsTarget = [...Array(Number(totalWeeks))].map((x, i) => 2.5);
    const inspectionsBadTarget = [...Array(Number(totalWeeks))].map(
      (x, i) => 1.5
    );

    const lastWeekNumber = outputWeeks.length;
    yearWeeks = [...Array(Number(lastWeekNumber))].map((x, i) => i + 1);

    return {
      labels: yearWeeks,
      datasets: [
        {
          type: "line",
          label: "Objetivo",
          borderColor: "#74b34d",
          borderWidth: 2,
          fill: false,
          data: inspectionsTarget,
        },
        {
          type: "line",
          label: "Minimo",
          borderColor: "#a92222",
          borderWidth: 2,
          fill: false,
          data: inspectionsBadTarget,
        },
        {
          type: "bar",
          label: `Avaliações ${
            this.state.selectedFreguesia !== "all"
              ? this.state.selectedFreguesia
              : ""
          } ${
            this.state.selectedUser !== "all" ? this.state.selectedUser : ""
          }`,
          backgroundColor: "rgb(54, 162, 235)",
          borderColor: "rgb(54, 162, 235)",
          data: outputWeeks.map((week) =>
            week ? week.ratings / week.inspections : 0
          ),
        },
      ],
    };
  }

  generateNecessidadesGraphData() {
    const { data, menuInspections, menuInterventions } = this.props;
    let outputWeeks = [];
    const necessidadesCFArray = necessidadesCF.map((n) => n.name);

    const parcelRatingHistory = this.props.data.queryParamsFolhas;

    // const totalWeeks =
    //   this.state.period === "weekly"
    //     ? moment().isoWeek()
    //     : moment().format("M");
    //get current week year
    // const currentWeek = moment().isoWeek();
    // //get current month year
    // const currentMonth = moment().format("M");

    const totalWeeks = this.state.period === "weekly" ? 52 : 12;
    let yearWeeks = [...Array(Number(totalWeeks))].map((x, i) => i + 1);
    const totalParams = [];
    if (!this.props.data.inspectionsPerWeek) {
      return {
        labels: yearWeeks,
        datasets: [],
      };
    }

    parcelRatingHistory.map((th) => {
      let thisWeek = moment(th.week).isoWeek() - 1;
      if (this.state.period === "monthly") {
        thisWeek = moment(th.week).format("M") - 1;
      }

      if (!outputWeeks[thisWeek]) {
        outputWeeks[thisWeek] = { ratings: 0, inspections: 0 };
      } else if (
        (this.state.selectedFreguesia === "all" ||
          this.state.selectedFreguesia === th.freguesia) &&
        (this.state.selectedUser === "all" ||
          this.state.selectedUser === th.name) &&
        (this.state.selectedTeam === "all" ||
          (this.state.selectedTeam.access &&
            this.state.selectedTeam.access.includes(th.location))) &&
        th.rating !== null
      ) {
        Object.keys(th.params).map((param) => {
          //only check for params present in inspectionNewCF
          if (!necessidadesCFArray.includes(param)) return null;

          if (!totalParams.includes(param)) {
            totalParams.push(param);
          }
          //lets add each param to the output it should be and average
          if (!outputWeeks[thisWeek][param]) {
            outputWeeks[thisWeek][param] = { ratings: 0, inspections: 0 };
          }
          outputWeeks[thisWeek][param] = {
            ratings:
              outputWeeks[thisWeek][param].ratings + Number(th.params[param]),
            inspections: outputWeeks[thisWeek][param].inspections + 1,
          };
        });
        // outputWeeks[thisWeek] = {
        //   ratings: outputWeeks[thisWeek].ratings + Number(th.rating),
        //   inspections:
        //     outputWeeks[thisWeek].inspections + Number(th.inspections),
        // };
      }
    });
    // lets find the last highst index for outputWeeks and update the yearWeeks
    const lastWeekNumber = outputWeeks.length;
    yearWeeks = [...Array(Number(lastWeekNumber))].map((x, i) => i + 1);

    return {
      labels: yearWeeks,
      datasets: totalParams.map((param) => {
        //get rand colro from this.colors
        /*        "#" +
          Math.random()
            .toString(16)
            .substr(2, 6);*/

        const randColor = this.state.colors[totalParams.indexOf(param)];

        return {
          label: param,
          backgroundColor: randColor,
          data: outputWeeks.map((week) => (week ? week[param]?.ratings : 0)),
        };
      }),
    };
  }

  generateIntervencoesGraphData() {
    const { data, menuInspections, menuInterventions } = this.props;
    let outputWeeks = [];
    const necessidadesCFArray = inspectionUpdatedCF.map((n) => n.name);

    const parcelRatingHistory = this.props.data.queryParamsFolhas;

    // const totalWeeks =
    //   this.state.period === "weekly"
    //     ? moment().isoWeek()
    //     : moment().format("M");
    //get current week year
    // const currentWeek = moment().isoWeek();
    // //get current month year
    // const currentMonth = moment().format("M");

    const totalWeeks = this.state.period === "weekly" ? 52 : 12;
    let yearWeeks = [...Array(Number(totalWeeks))].map((x, i) => i + 1);
    const totalParams = [];
    if (!this.props.data.inspectionsPerWeek) {
      return {
        labels: yearWeeks,
        datasets: [],
      };
    }

    parcelRatingHistory.map((th) => {
      let thisWeek = moment(th.week).isoWeek() - 1;
      if (this.state.period === "monthly") {
        thisWeek = moment(th.week).format("M") - 1;
      }

      if (!outputWeeks[thisWeek]) {
        outputWeeks[thisWeek] = { ratings: 0, inspections: 0 };
      } else if (
        (this.state.selectedFreguesia === "all" ||
          this.state.selectedFreguesia === th.freguesia) &&
        (this.state.selectedUser === "all" ||
          this.state.selectedUser === th.name) &&
        (this.state.selectedTeam === "all" ||
          (this.state.selectedTeam.access &&
            this.state.selectedTeam.access.includes(th.location))) &&
        th.rating !== null
      ) {
        Object.keys(th.params).map((param) => {
          //only check for params present in inspectionNewCF
          if (!necessidadesCFArray.includes(param)) return null;

          if (!totalParams.includes(param)) {
            totalParams.push(param);
          }
          //lets add each param to the output it should be and average
          if (!outputWeeks[thisWeek][param]) {
            outputWeeks[thisWeek][param] = { ratings: 0, inspections: 0 };
          }
          outputWeeks[thisWeek][param] = {
            ratings:
              outputWeeks[thisWeek][param].ratings + Number(th.params[param]),
            inspections: outputWeeks[thisWeek][param].inspections + 1,
          };
        });
        // outputWeeks[thisWeek] = {
        //   ratings: outputWeeks[thisWeek].ratings + Number(th.rating),
        //   inspections:
        //     outputWeeks[thisWeek].inspections + Number(th.inspections),
        // };
      }
    });
    // lets find the last highst index for outputWeeks and update the yearWeeks
    const lastWeekNumber = outputWeeks.length;
    yearWeeks = [...Array(Number(lastWeekNumber))].map((x, i) => i + 1);

    return {
      labels: yearWeeks,
      datasets: totalParams.map((param) => {
        //get rand colro from this.colors
        /*        "#" +
          Math.random()
            .toString(16)
            .substr(2, 6);*/

        const randColor = this.state.colors[totalParams.indexOf(param)];

        return {
          label: param,
          backgroundColor: randColor,
          data: outputWeeks.map((week) => (week ? week[param]?.ratings : 0)),
        };
      }),
    };
  }

  generateParamsGraphData() {
    const { data, menuInspections, menuInterventions } = this.props;
    let outputWeeks = [];
    const necessidadesCFArray = inspectionNewCF.map((n) => n.name);

    const parcelRatingHistory = this.props.data.queryParamsFolhas;

    // const totalWeeks =
    //   this.state.period === "weekly"
    //     ? moment().isoWeek()
    //     : moment().format("M");
    //get current week year
    // const currentWeek = moment().isoWeek();
    // //get current month year
    // const currentMonth = moment().format("M");

    const totalWeeks = this.state.period === "weekly" ? 52 : 12;
    let yearWeeks = [...Array(Number(totalWeeks))].map((x, i) => i + 1);
    const totalParams = [];
    if (!this.props.data.inspectionsPerWeek) {
      return {
        labels: yearWeeks,
        datasets: [],
      };
    }

    parcelRatingHistory.map((th) => {
      let thisWeek = moment(th.week).isoWeek() - 1;
      if (this.state.period === "monthly") {
        thisWeek = moment(th.week).format("M") - 1;
      }

      if (!outputWeeks[thisWeek]) {
        outputWeeks[thisWeek] = { ratings: 0, inspections: 0 };
      } else if (
        (this.state.selectedFreguesia === "all" ||
          this.state.selectedFreguesia === th.freguesia) &&
        (this.state.selectedUser === "all" ||
          this.state.selectedUser === th.name) &&
        (this.state.selectedTeam === "all" ||
          (this.state.selectedTeam.access &&
            this.state.selectedTeam.access.includes(th.location))) &&
        th.rating !== null
      ) {
        Object.keys(th.params).map((param) => {
          //only check for params present in inspectionNewCF
          if (!necessidadesCFArray.includes(param)) return null;

          if (!totalParams.includes(param)) {
            totalParams.push(param);
          }
          //lets add each param to the output it should be and average
          if (!outputWeeks[thisWeek][param]) {
            outputWeeks[thisWeek][param] = { ratings: 0, inspections: 0 };
          }
          outputWeeks[thisWeek][param] = {
            ratings:
              outputWeeks[thisWeek][param].ratings + Number(th.params[param]),
            inspections: outputWeeks[thisWeek][param].inspections + 1,
          };
        });
        // outputWeeks[thisWeek] = {
        //   ratings: outputWeeks[thisWeek].ratings + Number(th.rating),
        //   inspections:
        //     outputWeeks[thisWeek].inspections + Number(th.inspections),
        // };
      }
    });
    // lets find the last highst index for outputWeeks and update the yearWeeks
    const lastWeekNumber = outputWeeks.length;
    yearWeeks = [...Array(Number(lastWeekNumber))].map((x, i) => i + 1);

    return {
      labels: yearWeeks,
      datasets: totalParams.map((param) => {
        // const randColor =
        //   "#" +
        //   Math.random()
        //     .toString(16)
        //     .substr(2, 6);
        const randColor = this.state.colors[totalParams.indexOf(param)];
        return {
          label: param,
          backgroundColor: randColor,
          data: outputWeeks.map((week) =>
            week
              ? (week[param]?.ratings / week[param]?.inspections).toFixed(2) ||
                0
              : 0
          ),
        };
      }),
    };
  }

  // generatePecentageDone() {
  //   const { data, menuInspections, menuInterventions } = this.props;

  //   const today = new Date();

  //   const totalWeeks = moment().format("M");
  //   const inspectionsFreguesia = {};
  //   const inspectionsUsers = {};
  //   const inspectionsEVPerDay = [];
  //   const yearWeeks = [...Array(12)].map((x, i) => i + 1);
  //   data.inspectionsPerWeek &&
  //     data.inspectionsPerWeek.map((inspection) => {
  //       let inspWeek =
  //         moment(inspection.week, "YYYY-MM-DD 00:00:00+00").format("M") - 1;

  //       //set default
  //       if (!inspectionsFreguesia[inspection.freguesia]) {
  //         inspectionsFreguesia[inspection.freguesia] = 0;
  //       }
  //       if (!inspectionsUsers[inspection.name]) {
  //         inspectionsUsers[inspection.name] = [];
  //       }
  //       inspectionsUsers[inspection.name][inspWeek] =
  //         (inspectionsUsers[inspection.name][inspWeek] || 0) + 1;

  //       inspectionsEVPerDay[inspWeek] =
  //         (inspectionsEVPerDay[inspWeek] || 0) + 1;
  //     });

  //   if (this.props.data.usersData) {
  //     //if we have a user selected we have to sort by its access filters
  //     this.props.data.usersData.map((user) => {
  //       if (user.access) {
  //         const thisUserParks = user.access.length;

  //         if (inspectionsUsers[user.name])
  //           inspectionsUsers[user.name].map(
  //             (week) => (week * 100) / thisUserParks
  //           );
  //       }
  //     });
  //   }

  //   return {
  //     labels: yearWeeks,
  //     datasets: Object.keys(inspectionsUsers)
  //       .filter((name) => {
  //         if (this.state.hasFullAccess) {
  //           return true;
  //         } else if (name !== this.state.selectedUser) {
  //           return false;
  //         } else {
  //           return true;
  //         }
  //       })
  //       .map((name, k) => {
  //         const randColor =
  //           "#" +
  //           Math.random()
  //             .toString(16)
  //             .substr(2, 6);
  //         return {
  //           type: "bar",
  //           label: name,
  //           backgroundColor: this.state.colors[k] || randColor,
  //           borderColor: this.state.colors[k] || randColor,
  //           data: inspectionsUsers[name],
  //         };
  //       }),
  //   };
  // }

  inpectionsAverageTimeLess() {
    let outputWeeks = [];
    const parcelRatingHistory = this.props.data.inspectionsPerWeek;
    if (!this.props.data.inspectionsPerWeek) return null;
    //lets now add it to the output

    parcelRatingHistory.map((th) => {
      let thisWeek = moment(th.week).isoWeek() - 1;

      if (this.state.period === "monthly") {
        thisWeek = moment(th.week).format("M") - 1;
      }
      if (!outputWeeks[thisWeek]) {
        outputWeeks[thisWeek] = { ratings: 0, inspections: 0 };
      } else if (
        (this.state.selectedFreguesia === "all" ||
          this.state.selectedFreguesia === th.freguesia) &&
        (this.state.selectedUser === "all" ||
          this.state.selectedUser === th.name) &&
        th.rating !== null
      ) {
        outputWeeks[thisWeek] = {
          ratings: outputWeeks[thisWeek].ratings + Number(th.rating),
          inspections:
            outputWeeks[thisWeek].inspections + Number(th.inspections),
        };
      }
    });
    return outputWeeks.reverse().map((val, week) => {
      const thisVal = val ? val.ratings / val.inspections : 0;
      return (
        <TotalParcelPerWeek
          warning={thisVal <= 1.5}
          sucess={thisVal >= 2.5}
          key={week}
        >
          <span className={"week"}>{outputWeeks.length - week}</span>
          <span className={"val"}>{(thisVal || 0).toFixed(2)}</span>
        </TotalParcelPerWeek>
      );
    });

    // outputWeeks.map();
  }

  render() {
    const d = new Date();
    const time = d.getHours();
    const greet =
      time < 12
        ? `bom dia ${this.props.user.name}`
        : `boa tarde ${this.props.user.name}`;
    const { data, menuInspections, menuInterventions } = this.props;
    // let totalFreguesias = 0;
    return (
      <StyledWrap dashboard>
        <Grid fluid>
          <Row>
            <Col xs={12} sm={7} md={7} lg={7}>
              <PageTitle>Olá, {greet} </PageTitle>
            </Col>
            <Col xs={12} sm={5} md={5} lg={5}>
              <div
                style={{
                  fontSize: ".8em",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <LineButton
                  active={this.state.period === "weekly"}
                  onClick={() => this.setPeriod("weekly")}
                >
                  Semanal
                </LineButton>
                <LineButton
                  active={this.state.period === "monthly"}
                  onClick={() => this.setPeriod("monthly")}
                >
                  Mensal
                </LineButton>
                <InputYear
                  type="number"
                  min="1900"
                  max="2099"
                  step="1"
                  value={this.state.year}
                  onChange={(e) => {
                    this.setState({ year: e.target.value });

                    this.props.getStats(
                      this.props.role === "EV" ? this.props.role : "",
                      e.target.value
                    );
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={4} md={4} lg={4}>
              <Row>
                <Col xs className={"m-b-15"}>
                  <SectionTitle>{"Freguesias"}</SectionTitle>
                  <div style={{ fontSize: ".8em" }}>
                    {this.state.freguesias &&
                      this.state.freguesias.map((freguesia) => {
                        if (freguesia === "null") return null;
                        if (freguesia === this.state.selectedFreguesia) {
                          return (
                            <MainButton
                              onClick={() => this.setSelectedFreguesia("all")}
                            >
                              {freguesia}
                            </MainButton>
                          );
                        } else {
                          return (
                            <LineButton
                              onClick={() =>
                                this.setSelectedFreguesia(freguesia)
                              }
                            >
                              {freguesia}
                            </LineButton>
                          );
                        }
                      })}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={4} md={4} lg={4}>
              {this.state.hasFullAccess && (
                <div>
                  <Row>
                    <Col xs className={"m-b-15"}>
                      <SectionTitle>{"Utilizador"}</SectionTitle>
                      <div style={{ fontSize: ".8em" }}>
                        {this.state.users &&
                          this.state.users.map((user) => {
                            if (user === this.state.selectedUser) {
                              return (
                                <MainButton
                                  onClick={() => this.setSelectedUser("all")}
                                >
                                  {user}
                                </MainButton>
                              );
                            } else {
                              return (
                                <LineButton
                                  onClick={() => this.setSelectedUser(user)}
                                >
                                  {user}
                                </LineButton>
                              );
                            }
                          })}
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </Col>{" "}
            <Col xs={12} sm={4} md={4} lg={4}>
              <div>
                <Row>
                  <Col xs className={"m-b-15"}>
                    <SectionTitle>{"Prestador"}</SectionTitle>
                    <div style={{ fontSize: ".8em" }}>
                      {this.props.data &&
                        this.props.data.teamData &&
                        this.props.data.teamData
                          .filter(
                            (t) =>
                              (t.type === "external" ||
                                t?.details?.dashboard) &&
                              t.status === 1
                          )
                          .map((team) => {
                            if (
                              team.id ===
                              (this.state.selectedTeam &&
                                this.state.selectedTeam.id)
                            ) {
                              return (
                                <MainButton
                                  onClick={() => this.setSelectedTeam("all")}
                                >
                                  {team.label}
                                </MainButton>
                              );
                            } else {
                              return (
                                <LineButton
                                  onClick={() =>
                                    this.setSelectedTeam({
                                      id: team.id,
                                      access: team.access,
                                    })
                                  }
                                >
                                  {team.label}
                                </LineButton>
                              );
                            }
                          })}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col xs={12} sm={6} md={6} lg={6}>
              <SectionTitle>
                <i className={`icon-${menuInspections.icon} icons`} /> Nº Folhas
                Avaliadas
              </SectionTitle>
              <StyledContentWrapFullHeightBar>
                {data && (
                  <Bar
                    data={this.generateGraphData()}
                    options={{
                      maintainAspectRatio: false,
                    }}
                    width={600}
                    height={500}
                  />
                )}
              </StyledContentWrapFullHeightBar>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              <Row>
                <Col xs className={"m-b-15"}>
                  <SectionTitle>
                    <i className={`icon-${menuInspections.icon} icons`} />{" "}
                    {"Avaliações médias gerais "}
                  </SectionTitle>
                  <StyledContentWrapFullHeightBar>
                    {data && (
                      <Bar
                        data={this.generateAverageData()}
                        options={{
                          maintainAspectRatio: false,
                        }}
                        width={600}
                        height={500}
                      />
                    )}
                  </StyledContentWrapFullHeightBar>
                  {/* <StyledContentWrap>
                    <TotalParcelPerWeekWrap>
                      {this.inpectionsAverageTimeLess()}
                    </TotalParcelPerWeekWrap>
                  </StyledContentWrap> */}
                </Col>
              </Row>
            </Col>
          </Row>
          {/* {this.state.hasFullAccess && (
            <div>
              <br />
              <br />
              <Row>
                <Col xs={12} sm={6} md={6} lg={6} className={"m-b-15"}>
                  <SectionTitle>{menuInspections.name} Internas</SectionTitle>
                  <StyledContentWrapFullHeight>
                    {data && (
                      <Bar
                        data={this.generateInternalGraphData()}
                        options={{
                          maintainAspectRatio: false,
                        }}
                        width={600}
                        height={500}
                      />
                    )}
                  </StyledContentWrapFullHeight>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} className={"m-b-15"}>
                  <SectionTitle>{menuInspections.name} Externas</SectionTitle>
                  <StyledContentWrapFullHeight>
                    {data && (
                      <Bar
                        data={this.generateExternalGraphData()}
                        options={{
                          maintainAspectRatio: false,
                        }}
                        width={600}
                        height={500}
                      />
                    )}
                  </StyledContentWrapFullHeight>
                </Col>
              </Row>
            </div>
          )} */}
          <br />
          <br />
          <Row>
            <Col xs={12} className={"m-b-15"}>
              <SectionTitle>Parâmetros Avaliados</SectionTitle>
              <StyledContentWrapFullHeight>
                {data && (
                  <Bar
                    data={this.generateParamsGraphData()}
                    options={{
                      maintainAspectRatio: false,
                      legend: {
                        position: "bottom",
                      },
                      scales: {
                        xAxes: [
                          {
                            stacked: true,
                          },
                        ],
                        yAxes: [
                          {
                            stacked: true,
                          },
                        ],
                      },
                    }}
                    width={600}
                    height={500}
                  />
                )}
              </StyledContentWrapFullHeight>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col xs={12} sm={6} md={6} lg={6} className={"m-b-15"}>
              <SectionTitle>Necessidades dos Espaços</SectionTitle>
              <StyledContentWrapFullHeight>
                {data && (
                  <Bar
                    data={this.generateNecessidadesGraphData()}
                    options={{
                      maintainAspectRatio: false,
                      legend: {
                        position: "bottom",
                      },
                      scales: {
                        xAxes: [
                          {
                            stacked: true,
                          },
                        ],
                        yAxes: [
                          {
                            stacked: true,
                          },
                        ],
                      },
                    }}
                    width={600}
                    height={500}
                  />
                )}
              </StyledContentWrapFullHeight>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} className={"m-b-15"}>
              <SectionTitle>Intervenções</SectionTitle>
              <StyledContentWrapFullHeight>
                {data && (
                  <Bar
                    data={this.generateIntervencoesGraphData()}
                    options={{
                      maintainAspectRatio: false,
                      legend: {
                        position: "bottom",
                      },
                      scales: {
                        xAxes: [
                          {
                            stacked: true,
                          },
                        ],
                        yAxes: [
                          {
                            stacked: true,
                          },
                        ],
                      },
                    }}
                    width={600}
                    height={500}
                  />
                )}
              </StyledContentWrapFullHeight>
            </Col>
            {/* <Col xs={12} sm={4} md={4} lg={4} className={"m-b-15"}>
              <SectionTitle>
                Top 50 {menuInspections.name} mais antiga
              </SectionTitle>
              <StyledContentWrapFullHeight>
                <Hortas>
                  {data.visitedFolhas &&
                    data.visitedFolhas
                      .filter((park) => {
                        if (!park.rating || /ESC|Z/.test(park.location))
                          return null;
                        if (
                          this.props.user &&
                          !this.state.hasFullAccess &&
                          this.props.user.parks &&
                          this.props.user.parks.length > 0 &&
                          !this.props.user.parks.includes(park.location)
                        ) {
                          return false;
                        }
                        return true;
                      })
                      .reverse()
                      .slice(0, 50)
                      .map((park) => {
                        const today = moment();
                        return (
                          <Link
                            style={{
                              display: "inline-block",
                              textDecoration: "none",
                              width: "40%",
                            }}
                            to="/inspections"
                            onClick={() => {
                              // this.props.setActivePark(
                              //   raw.properties.numero,
                              //   raw.properties.local,
                              //   raw.properties.freguesia,
                              //   null,
                              //   true,
                              //   true
                              // );
                              if (this.props.geoJson) {
                                const thispark = this.props.geoJson.find(
                                  (p) => p.properties.local === park.location
                                );
                                if (thispark) {
                                  setTimeout(
                                    () =>
                                      this.props.setActivePark(
                                        thispark.properties.numero,
                                        thispark.properties.local,
                                        thispark.properties.freguesia,
                                        null,
                                        true,
                                        true
                                      ),
                                    1500
                                  );
                                }
                              }
                            }}
                          >
                            {" "}
                            <li
                              style={{
                                width: "100%",
                              }}
                            >
                              <div className={"icon"}>
                                {today.diff(moment(park.date), "days")}
                                <div style={{ fontSize: ".7em" }}>dias</div>
                              </div>
                              <div
                                className={"content"}
                                style={{
                                  fontSize: ".9em",
                                  textDecoration: "none",
                                }}
                              >
                                {park.location}
                                <br />
                                <span
                                  style={{
                                    fontSize: ".7em",
                                    textDecoration: "none",
                                  }}
                                >
                                  {park.name}
                                </span>
                                <div
                                  style={{
                                    fontSize: ".7em",
                                    textDecoration: "none",
                                  }}
                                >
                                  {park.rating || 0}pts
                                </div>
                              </div>
                            </li>
                          </Link>
                        );
                      })}
                </Hortas>
              </StyledContentWrapFullHeight>
            </Col> */}
            {/* <Col xs={12} sm={8} md={8} lg={8} className={"m-b-15"}>
              <SectionTitle>
                {"Percentagem de conclusão por Tecnico"}
              </SectionTitle>
              <StyledContentWrapFullHeight>
                {data && (
                  <Bar
                    data={this.generatePecentageDone()}
                    options={{
                      maintainAspectRatio: false,
                    }}
                    width={600}
                    height={500}
                  />
                )}
              </StyledContentWrapFullHeight>
            </Col> */}
          </Row>
          <br />
          <br />

          <br />
          <br />
        </Grid>
      </StyledWrap>
    );
  }
}

export default connect(
  (state) => {
    return {
      user: state.user.data,
      geoJson: state.parklist.geoJson,
      role: state.user.data.role,
      teams: state.user.teams,
      data: state.dashboard.data,
      menuInspections:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/inspections`
        ),
      menuInterventions:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/interventions`
        ),
    };
  },
  {
    getStats,
    getTeams,
    setActivePark,
    toggleAddInspection,
  }
)(DashboardPage);
