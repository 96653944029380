import {
  ADD_NEW_TOGGLE,
  SET_PHOTOS,
  SET_DETAILS,
  SET_TYPE,
  SET_ACTIVE_STEP,
  ERROR,
  SAVING,
  SAVED,
  DELETED,
  RESET,
  GETTING,
  RECEIVED,
  HISTORY_RECEIVED,
  UPDATED,
  HISTORY_GETTING,
  UPDATED_HISTORY,
  DAMAGES_GETTING,
  APPROVED_INSPECTION_GROUP,
  DAMAGES_RECEIVED,
  GROUP_ERROR,
  GROUP_GETTING,
  GROUP_RECEIVED,
} from "./constants";

import { getCurrentDate } from "../../services/utils";

const status = {
  edit: "edit",
  error: "error",
  saving: "saving",
  edited: "edited",
  getting: "getting",
};

let initialState = {
  addNew: false,
  activeStep: 0,
  photos: [],
  type: "",
  details: {},
  status: "edit",
  savedData: {},
  elementsGeoJson: [],
  reportHistory: [],
  historyGroup: [],
  damages: [],
  list: [],
  pid: "",
  search: {
    active: "general",
    edit: null,
    local: "",
    freguesia: "",
    selected: "",
    element: {},
    startDate: getCurrentDate(true),
    endDate: getCurrentDate(),
    type: "all",
    status: 0,
  },
};

const isLocalStorage = typeof localStorage !== "undefined";
let newState = {};
//lets load user data for reload
if (isLocalStorage && localStorage.inspections) {
  initialState = JSON.parse(localStorage.inspections);
  initialState.search = {
    ...initialState.search,
    startDate: getCurrentDate(true),
    endDate: getCurrentDate(),
  };
}

function presistData(type, data) {
  try {
    // if (isLocalStorage) localStorage[type] = JSON.stringify(data);
  } catch (error) {
    console.log(error);
  }
  return data;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_NEW_TOGGLE:
      newState = {
        ...state,
        pid: action.data.id,
        addNew: action.data.state || !state.addNew,
        details: {},
      };
      return presistData("inspections", newState);
    case SET_ACTIVE_STEP:
      newState = {
        ...state,
        activeStep: action.data,
      };
      return presistData("inspections", newState);
    case SET_PHOTOS:
      newState = {
        ...state,
        photos: action.data,
      };
      return presistData("inspections", newState);
    case SET_DETAILS:
      newState = {
        ...state,
        details: action.data,
      };
      // if (isLocalStorage) localStorage.inspections = JSON.stringify(newState);
      return { ...state, details: action.data };
    case SET_TYPE:
      newState = {
        ...state,
        type: action.data,
      };
      return presistData("inspections", newState);
    case ERROR:
      return { ...state, status: status.error };
    case SAVING:
      return { ...state, status: status.saving };
    case UPDATED:
      return { ...state, status: status.edited };
    case UPDATED_HISTORY:
      newState = {
        ...state,
        activeStep: 0,
        photos: [],
        type: "",
        details: {},
        status: "edit",
        savedData: {},
        // history: action.data, ///adding it to the history
      };
      return presistData("inspections", newState);
    case APPROVED_INSPECTION_GROUP:
      return { ...state, status: "saved" };
    case SAVED:
      // const newHistory =
      //   action.data.type === 'Final' ? [] : [...state.history, action.data.id];

      newState = {
        ...state,
        status: "saved",
        savedData: action.data,
        // history: newHistory, ///adding it to the history
      };
      return presistData("inspections", newState);
    case DELETED:
      newState = {
        ...state,
        status: "deleted",
      };
      return presistData("inspections", newState);
    case GETTING:
      return { ...state, status: status.getting };
    case RECEIVED:
      newState = {
        ...state,
        status: "set",
        list: action.data,
      };
      return presistData("inspections", newState);
    case HISTORY_GETTING:
      return {
        ...state,
        reportHistory: [],
      };
    case HISTORY_RECEIVED:
      newState = {
        ...state,
        reportHistory: action.data,
      };
      return presistData("inspections", newState);
    case GROUP_GETTING:
      return {
        ...state,
        historyGroup: [],
      };
    case GROUP_RECEIVED:
      newState = {
        ...state,
        historyGroup: action.data,
      };
      return presistData("inspections", newState);
    case RESET:
      newState = {
        ...state,
        activeStep: 0,
        photos: [],
        type: "",
        details: {},
        status: "edit",
        savedData: {},
      };
      return presistData("inspections", newState);
    case DAMAGES_GETTING:
      newState = {
        ...state,
        damages: [],
      };
      return presistData("inspections", newState);
    case DAMAGES_RECEIVED:
      newState = {
        ...state,
        damages: action.data,
      };
      return presistData("inspections", newState);

    default:
      return state;
  }
};
