import React from "react";
import styled from "styled-components";

const ContentWrap = styled.div`
  && {
    background: #f8fcfe;
    @media print {
      background: #fff;
    }
    width: calc(100% - 80px);
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 80px;
    z-index: 10;

    @media (max-width: 900px) {
      left: 0;
      width: 100%;
      padding-bottom: 160px;
    }
  }
`;
const Padder = styled.div`
  && {
    padding: ${(props) => (props.nopadding ? "0" : "2% 5% 0")};
  }
`;
class FullScreenModal extends React.Component {
  render() {
    return (
      <ContentWrap {...this.props} className={"animated fadeIn"}>
        <Padder nopadding={this.props.nopadding}>{this.props.children}</Padder>
      </ContentWrap>
    );
  }
}

export default FullScreenModal;
