import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
// import ChipInput from 'material-ui-chip-input';
import { Row, Col } from "react-flexbox-grid";
import DropzoneComponent from "react-dropzone-component";
import Rating from "react-rating";
import { Switch, FormControlLabel } from "@material-ui/core";

import DefaultCF from "./DefaultCF";
import SistemaDeRegaCF from "./SistemaDeRegaCF";

import MainButton from "../../../components/Buttons/MainButton";
import ContentWrap from "../../../components/Layout/ContentWrap";
import TextField from "../../../components/form-wrappers/TextField";
import SectionTitle from "../../../components/Text/SectionTitle";
import MenuIcon from "../../../components/Buttons/MenuIcon";
import AutoComplete from "../../../components/form-wrappers/AutoComplete";
import { apiAddress } from "../../../config/general";

import {
  setDetails,
  setMaintenance,
  setMaintenanceEV,
  getInterventions,
  setPhotos,
} from "../actions";

const Toggle = styled(Switch)`
  && {
    .MuiSwitch-bar-192,
    .jss192 {
      color: #4da1ff;
      background: #4da1ff;
    }
    .MuiSwitch-iconChecked-194,
    .jss194 {
      color: #4da1ff;
    }
  }
`;

const HalfRate = styled.div`
  && {
    display: inline-block;
    width: 30%;
    margin-right: 2%;
    @media (max-width: 900px) {
      width: 45%;
    }
    @media (max-width: 425px) {
      width: 100%;
    }
  }
`;
const RatingWrap = styled.div`
  && {
    margin: 2em 0 0;
    text-align: left;
    label {
      display: block;
      color: rgba(0, 0, 0, 0.6);
      font-size: 0.8em;
      margin-bottom: 0.5em;
    }
    /* > span {
      zoom: 0.8;
    } */
  }
`;

const NextButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    width: 100%;
  }
`;
const OptionWrap = styled(ContentWrap)`
  && {
    padding: 2em;
    text-align: center;
  }
`;

const Input = styled(TextField)`
  && {
    width: 100%;
    padding: 1em 0;
    margin: 0;
    text-align: left;
    textarea {
      height: auto;
    }
  }
`;

const Calendar = styled(Input)`
  && {
    margin: 0;
  }
`;

const Files = styled(DropzoneComponent)`
  && {
    background-color: rgba(74, 144, 226, 0.1);
    border: 2px dashed rgba(74, 144, 226, 1);
    color: #4990e2;
    padding: 1em 1em 2em 1em;
    margin: 1em 0 1.5em;
    .dz-message {
      margin: 0;
    }
    .dz-preview.dz-image-preview {
      background: transparent;
    }
    .filepicker-file-icon {
      color: #4990e2;
      &::after {
        background-color: #4990e2;
      }
      &::before {
        border: solid 2px #4990e2;
      }
    }
    .dz-image {
      img {
        width: 120px;
        height: 120px;
      }
    }
  }
`;

class DetailsSpecific extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: this.getCurrentDate(),
      endDate: this.getCurrentDate(),
      details: "",
      attr: {
        type: props.interventionType,
        isHelp: props.addHelp,
      },
      interventions: [],
      files: [],
    };

    this.inspectionCF = {
      EV: [
        { name: "Geral", comment: "" },
        { name: "Limpeza", comment: "" },
        { name: "Relvado/Prado", comment: "" },
        { name: "Herbáceas", comment: "" },
        { name: "Arbustos", comment: "" },
        { name: "Árvores", comment: "" },
        { name: "Mobiliário Urbano", comment: "" },
        { name: "Pavimento", comment: "" },
        { name: "Sistema de Rega", comment: "" },
      ],
    };
    // if (
    //   props.pid !== props.details.pid &&
    //   props.photos &&
    //   props.photos.length > 0
    // )
    //   toastr.error(
    //     'Informação incorrecta',
    //     'Atenção as fotos guardadas não corresponde a este parque, carregue em reset para começar de novo'
    //   );

    // For a full list of possible configurations,
    // please consult http://www.dropzonejs.com/#configuration
    this.djsConfig = {
      acceptedFiles: "image/*, application/*",
      addRemoveLinks: true,
      resizeWidth: 1100,
      resizeHeight: 1100,
      resizeQuality: 0.6,
      headers: { Authorization: "Bearer " + props.token },
      params: {
        fid: props.selectedParkId, //should be the park id
      },
    };

    this.componentConfig = {
      iconFiletypes: ["docs", "images"],
      showFiletypeIcon: true,
      postUrl: `${apiAddress}api/v1/files`,
    };

    this.callback = this.callback.bind(this);
    this.removedfile = this.removedfile.bind(this);
    this.handleRegaChange = this.handleRegaChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeIntervention = this.handleChangeIntervention.bind(this);
    this.handleSaveInspection = this.handleSaveInspection.bind(this);

    this.handleAttrCustomFieldsRating = this.handleAttrCustomFieldsRating.bind(
      this
    );

    props.getInterventions();
  }

  // Simple callbacks work too, of course
  callback(file) {
    const fileData = JSON.parse(file.xhr.response);
    const files = [
      ...this.state.files,
      {
        path: fileData.msg.details.path,
        name: fileData.msg.details.originalname,
        size: fileData.msg.details.size,
        id: fileData.msg.id,
      },
    ];
    this.props.setPhotos(files);
    this.setState({ files: files });
    this.props.setDetails(this.state);
  }

  handleAttrChange(e, value) {
    this.setState({
      attr: { ...this.state.attr, [e.target.name]: e.target.value },
    });
    this.props.setDetails(this.state);
  }

  handleAttrCustomFieldsRating(value, index) {
    this.setState({
      attr: {
        ...this.state.attr,
        customFields: {
          ...this.state.attr.customFields,
          [index]: value,
        },
      },
    });
  }

  // Simple callbacks work too, of course
  removedfile(file) {
    //need to delete images
    const updatedFiles = this.state.files.filter(
      (photo) => photo.name !== file.upload.filename
    );

    this.setState({
      files: updatedFiles,
    });
    this.props.setPhotos(updatedFiles);
  }

  getCurrentDate() {
    const now = new Date();
    const addZero = (i) => (i < 10 ? "0" + i : i);
    return `${now.getFullYear()}-${addZero(now.getMonth() + 1)}-${addZero(
      now.getDate()
    )}T${addZero(now.getHours())}:${addZero(now.getMinutes())}`;
  }

  getInterventionsList() {
    return this.props.interventionsList || [];
  }

  handleRegaChange(e) {
    this.setState({
      attr: { ...this.state.attr, team: e.target.checked ? "rega" : "" },
    });
    this.props.setDetails(this.state);
  }

  handleChange(e, value) {
    this.setState({ [e.target.name]: e.target.value });
    this.props.setDetails(this.state);
  }

  handleChangeIntervention(value) {
    this.setState({ interventions: value });
  }

  handleSaveInspection() {
    this.props.setDetails(this.state);
    this.props.setMaintenanceEV(
      this.props.selectedOtherPark,
      this.props.interventionType
    );
  }

  preloadImages(dropzone) {
    (this.state.files || []).forEach(function(i) {
      var name = "";
      var file = { url: i.path, name: name };
      var imageUrl = `${apiAddress}${i.path}`;

      dropzone.createThumbnailFromUrl(file, imageUrl);
      dropzone.emit("addedfile", file);
      dropzone.emit("thumbnail", file, imageUrl);
      dropzone.emit("complete", file);
    });
  }

  render() {
    const config = this.componentConfig;
    const djsConfig = this.djsConfig;

    const self = this;

    /// not bound to this is moved out of render
    const eventHandlers = {
      init: (dropzone) => {
        dropzone.autoDiscover = false;
        self.preloadImages(dropzone);
      },
      removedfile: self.removedfile,
      error: (e) => {
        console.log(e);
      },
      success: self.callback,
    };
    console.log(this.props.interventionType);

    return (
      <div>
        <Row>
          <Col xs={12} md={9}>
            {(this.props.interventionType === "" ||
              this.props.interventionType === "visita") && (
              <DefaultCF
                handleAttrCustomFieldsRating={this.handleAttrCustomFieldsRating}
                interventionType={this.props.interventionType}
                attr={this.state.attr}
              />
            )}

            {this.props.interventionType === "rega" && (
              <SistemaDeRegaCF
                handleAttrCustomFieldsRating={this.handleAttrCustomFieldsRating}
                interventionType={this.props.interventionType}
                attr={this.state.attr}
              />
            )}
            {this.props.interventionType !== "schedule" && (
              <div>
                <SectionTitle>Imagem Inicial e Final</SectionTitle>
                <Files
                  config={config}
                  eventHandlers={eventHandlers}
                  djsConfig={djsConfig}
                />
              </div>
            )}
            <SectionTitle>Descrição</SectionTitle>
            <OptionWrap>
              {/* <AutoComplete
                updateState={this.handleChangeIntervention}
                options={this.getInterventionsList()}
                placeholder={"Pesquise ou adicione uma nova intervenção"}
              /> */}

              <Input
                multiline
                label="Descrição"
                onChange={(e) => this.handleChange(e)}
                name="details"
                input={{}}
                type="multiline"
                meta={{ invalid: false }}
                rows="8"
              />
            </OptionWrap>
          </Col>

          <Col xs={12} md={3}>
            <SectionTitle>Opções</SectionTitle>

            <OptionWrap>
              {this.props.interventionType === "schedule" && (
                <FormControlLabel
                  control={
                    <Toggle
                      onChange={this.handleRegaChange}
                      value="true"
                      checked={this.state?.attr?.team === "rega"}
                      label="Admin"
                      // disabled={!data.edit}
                      name="admin"
                      color="primary"
                      meta={{ invalid: false }}
                    />
                  }
                  label="Equipa de Rega"
                />
              )}
              <Calendar
                id="date"
                name="date"
                input={{}}
                label=""
                meta={{ invalid: false }}
                type="datetime-local"
                onChange={(e) => this.handleChange(e)}
                defaultValue={this.state.date}
              />
              <Calendar
                id="endDate"
                name="endDate"
                input={{}}
                label=""
                meta={{ invalid: false }}
                type="datetime-local"
                onChange={(e) => this.handleChange(e)}
                defaultValue={this.state.endDate}
              />

              {this.props.status !== "saving" && (
                <NextButton
                  color="primary"
                  onClick={() => this.handleSaveInspection()}
                >
                  Salvar
                  <MenuIcon className={`icon-check icons`} />
                </NextButton>
              )}
            </OptionWrap>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      type: state.maintenances.type,
      status: state.maintenances.status,
      photos: state.maintenances.photos,
      selected: state.app.search.selected,
      details: state.maintenances.details,
      interventionsList: state.maintenances.interventions,
      token: state.user.data.token,
      addHelp: state.app.addHelp,
      role: state.user.data.role,
    };
  },
  {
    setDetails,
    setMaintenance,
    setMaintenanceEV,
    getInterventions,
    setPhotos,
  }
)(DetailsSpecific);
