import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
// import ChipInput from 'material-ui-chip-input';
import { Row, Col } from "react-flexbox-grid";
import DropzoneComponent from "react-dropzone-component";
import { MenuItem, Select } from "@material-ui/core";
import Lightbox from "react-image-lightbox";
import { toastr } from "react-redux-toastr";

import MainButton from "../../../components/Buttons/MainButton";
import ContentWrap from "../../../components/Layout/ContentWrap";
import TextField from "../../../components/form-wrappers/TextField";
import SectionTitle from "../../../components/Text/SectionTitle";
import MenuIcon from "../../../components/Buttons/MenuIcon";
import AutoComplete from "../../../components/form-wrappers/AutoComplete";
import { apiAddress } from "../../../config/general";

import { setDetails, setElement, updateElement, delElement } from "../actions";

const NextButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    width: 100%;
  }
`;

const OptionWrap = styled(ContentWrap)`
  && {
    padding: 2em;
    text-align: center;
  }
`;

const SelectStyled = styled(Select)`
  && {
    font-size: 1.2em;
    text-align: left;
    input:disabled,
    select:disabled,
    textarea:disabled {
      color: #4da1ff;
    }
  }
`;

const AutoCompleteWrapper = styled.div`
  && {
    margin: 0.5em;
    input {
      font-size: 1.2em;
    }
    input:disabled,
    select:disabled,
    textarea:disabled {
      color: #4da1ff;
    }
  }
`;

const Input = styled(TextField)`
  && {
    width: 100%;
    padding: 10px 0;
    text-align: left;
    textarea {
      height: auto;
    }
    input:disabled,
    select:disabled,
    textarea:disabled {
      color: #4da1ff;
    }
  }
`;

const Calendar = styled(Input)`
  && {
    margin: 0;
  }
`;

const ImageContainer = styled.div`
  && {
    text-align: left;
  }
`;

const Image = styled.div`
  && {
    width: 200px;
    height: 130px;
    margin: 0 0.5em 0.5em 0;
    display: inline-block;
    border-radius: 0.5em;
    background-size: cover;
    cursor: pointer;
  }
`;

const Files = styled(DropzoneComponent)`
  && {
    background-color: rgba(74, 144, 226, 0.1);
    border: 2px dashed rgba(74, 144, 226, 1);
    color: #4990e2;
    padding: 1em 1em 2em 1em;
    margin: 1em 0 1.5em;
    .dz-message {
      margin: 0;
    }
    .dz-preview.dz-image-preview {
      background: transparent;
    }
    .filepicker-file-icon {
      color: #4990e2;
      &::after {
        background-color: #4990e2;
      }
      &::before {
        border: solid 2px #4990e2;
      }
    }
  }
`;

const DisableButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    margin-top: 1em;
    background: #df5757;
    width: 100%;
    border-color: #f44336;
    &:hover {
      color: #df5757;
      .icons {
        color: #df5757;
      }
    }
  }
`;

class EditElementDetails extends Component {
  constructor(props) {
    super(props);

    this.state = this.setEditDataInState(props);

    this.serverURL = apiAddress;
    // For a full list of possible configurations,
    // please consult http://www.dropzonejs.com/#configuration
    this.djsConfig = {
      acceptedFiles: "image/*, application/*",
      addRemoveLinks: true,
      resizeWidth: 1100,
      resizeHeight: 1100,
      resizeQuality: 0.6,
      headers: { Authorization: "Bearer " + props.token },
      params: {
        fid: props.selectedPark, //should be the park id
      },
    };

    this.componentConfig = {
      iconFiletypes: ["docs", "images"],
      showFiletypeIcon: true,
      postUrl: `${apiAddress}api/v1/files`,
    };

    this.callback = this.callback.bind(this);
    this.delete = this.delete.bind(this);
    this.handleChangeId = this.handleChangeId.bind(this);
    this.removedfile = this.removedfile.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeElementVegCat = this.handleChangeElementVegCat.bind(this);
    this.handleChangeContact = this.handleChangeContact.bind(this);

    this.handleChangeElementFamiCat =
      this.handleChangeElementFamiCat.bind(this);

    this.handleChangeElementType = this.handleChangeElementType.bind(this);
    this.handleChangeElementSubType =
      this.handleChangeElementSubType.bind(this);

    this.handleSaveElement = this.handleSaveElement.bind(this);
  }

  setEditDataInState(props) {
    if (props.element && props.element.properties) {
      const element = props.element;
      const thisType = props.elementTypesList.find((type) => {
        return type.id === props.element.properties.typeId;
      });

      return {
        details: element.properties.observations || "",
        local: element.properties.local || "",
        numero: element.properties.numero || "",
        vegetation: element.vegetation || "",
        attr: element.properties.attr || {},
        elementVegCat: element.vegetation && {
          suggestion: { label: element.vegetation.category },
        },
        elementVegFam: element.vegetation && {
          suggestion: { label: element.vegetation.family },
        },
        elementType: {
          suggestion: thisType,
        },
        showCatChoice: element.vegetation && element.vegetation.category,
        elementSubType: element.vegetation && {
          suggestion: { label: element.vegetation.family },
        },
        elementVegNorm: element.vegetation && element.vegetation.id,
        elementVegCien: element.vegetation && element.vegetation.id,
        isOpen: null,
        elementId: element.properties.id,
        photoIndex: 0,
        elementsTypeCategories: props.elementsTypeCategories,
        implementationDate: this.getDate(element.properties.implementationDate),
        files: props.files || element.properties.files,
      };
    } else {
      return {
        details: "",
        vegetation: "",
        elementType: "",
        elementSubType: "",
        elementVegNorm: "",
        elementVegCien: "",
        elementVegFam: "",
        elementVegCat: "",
        attr: {
          contact: {
            email: "",
            phone: "",
            nome: "",
            id: "",
          },
        },
        isOpen: null,
        elementId: null,
        photoIndex: 0,
        elementsTypeCategories: props.elementsTypeCategories,
        implementationDate: this.getDate(),
        files: props.files,
      };
    }
  }

  getDate(date) {
    const now = date ? new Date(date) : new Date();
    const addZero = (i) => (i < 10 ? "0" + i : i);
    return `${now.getFullYear()}-${addZero(now.getMonth() + 1)}-${addZero(
      now.getDate()
    )}T${addZero(now.getHours())}:${addZero(now.getMinutes())}`;
  }

  // Simple callbacks work too, of course
  callback(file) {
    const fileData = JSON.parse(file.xhr.response);
    const files = [
      ...this.state.files,
      {
        path: fileData.msg.details.path,
        name: fileData.msg.details.originalname,
        size: fileData.msg.details.size,
        id: fileData.msg.id,
      },
    ];
    this.setState({ files: files });
  }

  // Simple callbacks work too, of course
  removedfile(file) {
    //need to delete images
    console.log(file);
    // this.setState({ photos: [...this.state.photos, file.upload] });
  }

  handleChange(e, value) {
    this.setState({ elementVegNorm: null, elementVegCien: null });
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeElementType(value) {
    this.setState({
      elementType: value,
      elementSubType: "",
      elementVegNorm: "",
      elementVegCien: "",
      elementVegCat: "",
      elementVegFam: "",
    });
  }

  handleChangeId(e, value) {
    this.setState({
      attr: {
        ...this.state.attr,
        identifier: e.target.value,
      },
    });
  }
  handleChangeContact(e, value) {
    this.setState({
      attr: {
        ...this.state.attr,
        contact: {
          ...this.state.attr.contact,
          [e.target.name]: e.target.value,
        },
      },
    });
  }

  handleChangeElementSubType(value) {
    this.setState({ elementSubType: value });
  }

  handleChangeElementVegCat(value) {
    this.setState({ elementVegCat: value });
  }
  handleChangeElementFamiCat(value) {
    this.setState({ elementVegFam: value });
  }

  getElementTypesList() {
    return this.props.elementTypesList || [];
  }

  renderSelect(options, name) {
    return (
      <SelectStyled
        fullWidth
        label={name}
        name={name}
        value={this.state[name]}
        disabled={this.props.view}
        onChange={this.handleChange}
        inputProps={{
          name: name,
          id: name,
        }}
      >
        <MenuItem key={-1} value={""} />
        {options.map((option, index) => {
          const optionVal =
            name === "elementVegCien"
              ? option.cientificName
              : option.normalName;
          return (
            <MenuItem key={index} value={option.id}>
              {optionVal}
            </MenuItem>
          );
        })}
      </SelectStyled>
    );
  }
  renderTreeSelect() {
    return (
      <div>
        <AutoCompleteWrapper>
          <AutoComplete
            updateState={this.handleChangeElementVegCat}
            disabled={this.props.view}
            defaultValue={
              this.state.elementVegCat && this.state.elementVegCat.suggestion
            }
            options={
              this.props.vegetationTypes.reduce((prev, cur) => {
                if (!prev.find((ele) => ele.label === cur.category)) {
                  prev.push({ label: cur.category });
                }
                return prev;
              }, []) || []
            }
            placeholder={`Pesquisar por tipo`}
          />
        </AutoCompleteWrapper>

        {/* {this.state.elementVegCat &&
          this.state.elementVegCat.suggestion && (
            <AutoCompleteWrapper>
              <AutoComplete
                updateState={this.handleChangeElementFamiCat}
                disabled={this.props.view}
                defaultValue={
                  this.state.elementVegFam &&
                  this.state.elementVegFam.suggestion
                }
                options={
                  this.props.vegetationTypes.reduce((prev, cur) => {
                    if (
                      !prev.find(ele => ele.category === cur.category) &&
                      this.state.elementVegCat.suggestion.label === cur.category
                    ) {
                      prev.push({ label: cur.family });
                    }
                    return prev;
                  }, []) || []
                }
                placeholder={`Pesquisar por familia`}
              />
            </AutoCompleteWrapper>
          )} */}

        {this.state.elementVegCat && this.state.elementVegCat.suggestion && (
          <div>
            <br />
            <AutoCompleteWrapper className={"clearfix"}>
              {this.renderSelect(
                this.props.vegetationTypes.filter((veg) => {
                  return (
                    veg.category === this.state.elementVegCat.suggestion.label
                  );
                }) || [],
                "elementVegCien"
              )}

              {/* {!this.state.elementVegNorm.suggestion && (
                  <AutoComplete
                    updateState={this.handleChangeElementVegCien}
                    options={
                      this.props.vegetationTypes.reduce((prev, cur) => {
                        if (
                          this.state.elementVegCat.suggestion.label ===
                          cur.category
                        ) {
                          return [
                            ...(prev || []),
                            { ...cur, label: cur.cientificName },
                          ];
                        }
                      }, []) || []
                    }
                    placeholder={`Pesquisar por nome cientifico`}
                  />
                )} */}
              {this.state.elementVegCien === "" &&
                this.renderSelect(
                  this.props.vegetationTypes.filter((veg) => {
                    return (
                      veg.category === this.state.elementVegCat.suggestion.label
                    );
                  }) || [],
                  "elementVegNorm"
                )}
            </AutoCompleteWrapper>
            <br clear={"both"} />
          </div>
        )}
      </div>
    );
  }

  renderSubcategory() {
    if (
      this.state.elementType.suggestion &&
      this.state.elementType.suggestion.label &&
      this.state.elementType.suggestion.label.trim() === "Vegetação"
    ) {
      return this.renderTreeSelect();
    } else if (this.state.elementType.suggestion) {
      return (
        <AutoCompleteWrapper>
          <AutoComplete
            updateState={this.handleChangeElementSubType}
            options={
              this.props.elementsTypeCategories[
                this.state.elementType.suggestion.label
              ] || []
            }
            placeholder={`Pode pesquisar ou adicionar um sub elemento ${
              this.state.elementType.suggestion.category || ""
            } `}
          />
        </AutoCompleteWrapper>
      );
    }
  }

  handleSaveElement() {
    const details = {
      pid: this.state.pid,
      local: this.state.local,
      details: this.state.details,
      elementType: this.state.elementType,
      vegType: this.state.elementVegNorm
        ? this.state.elementVegNorm
        : this.state.elementVegCien,
      elementSubType: this.state.elementSubType,
      implementationDate: this.state.implementationDate,
      files: this.state.files,
      attr: this.state.attr,
    };

    this.props.setDetails(details);

    this.props.updateElement();
  }

  renderPhotos(photos) {
    return (
      <div>
        <SectionTitle>Fotos</SectionTitle>
        <ImageContainer>
          {photos &&
            photos.map((photo, index) => (
              <Image
                onClick={() =>
                  this.setState({
                    isOpen: true,
                    photoIndex: index,
                  })
                }
                key={index}
                style={{
                  backgroundImage: `url('${this.serverURL}${photo.path}')`,
                }}
              />
            ))}
        </ImageContainer>
      </div>
    );
  }
  parcelElement() {
    return (
      <div>
        <SectionTitle>Contactos</SectionTitle>
        <OptionWrap>
          <Input
            label="Nome"
            onChange={(e) => this.handleChangeContact(e)}
            defaultValue={this.props.selectedParcelHorta.Contacto}
            name="name"
            input={{}}
            type="text"
            meta={{ invalid: false }}
            rows="8"
            disabled={true}
          />
          <Input
            label="Email"
            onChange={(e) => this.handleChangeContact(e)}
            defaultValue={this.props.selectedParcelHorta.Email}
            name="email"
            input={{}}
            type="text"
            meta={{ invalid: false }}
            rows="8"
            disabled={true}
          />
          <Input
            label="Telefone"
            onChange={(e) => this.handleChangeContact(e)}
            defaultValue={this.props.selectedParcelHorta.Telefone}
            name="phone"
            input={{}}
            type="text"
            meta={{ invalid: false }}
            rows="8"
            disabled={true}
          />
          <Input
            label="Morada"
            onChange={(e) => this.handleChangeContact(e)}
            defaultValue={this.props.selectedParcelHorta.Morada}
            name="address"
            input={{}}
            type="text"
            meta={{ invalid: false }}
            rows="8"
            disabled={true}
          />
          <Input
            label="ID PHC"
            onChange={(e) => this.handleChangeContact(e)}
            defaultValue={this.props.selectedParcelHorta.Horta}
            name="id"
            input={{}}
            type="text"
            meta={{ invalid: false }}
            rows="8"
            disabled={true}
          />
        </OptionWrap>
      </div>
    );
  }

  delete() {
    const toastrConfirmOptions = {
      onOk: () => this.props.delElement(),
      onCancel: () => console.log("CANCEL: clicked"),
    };
    toastr.confirm(
      "Tem a certeza? Esta acção não pode ser revertida",
      toastrConfirmOptions
    );
  }

  render() {
    const config = this.componentConfig;
    const djsConfig = this.djsConfig;
    const { photoIndex, isOpen } = this.state;
    const images = this.state.files;

    // For a list of all possible events (there are many), see README.md!
    const eventHandlers = {
      removedfile: this.removedfile,
      success: this.callback,
    };

    const parcelElement =
      this.state.elementType.suggestion &&
      this.state.elementType.suggestion.category &&
      this.state.elementType.suggestion.category.trim() === "Parcela";

    return (
      <div>
        <Row>
          <Col xs={12} md={9}>
            <SectionTitle>Informação sobre elemento</SectionTitle>
            <OptionWrap>
              {this.props.elementsTypeCategories && this.state.elementType && (
                <AutoCompleteWrapper>
                  <AutoComplete
                    updateState={this.handleChangeElementType}
                    defaultValue={this.state.elementType.suggestion}
                    options={this.props.elementsTypeCategories.generic || []}
                    placeholder={"Pesquise ou adicione um novo elemento"}
                    disabled={this.props.view}
                  />
                </AutoCompleteWrapper>
              )}
              <br />
              {this.renderSubcategory()}
            </OptionWrap>

            {parcelElement && this.parcelElement()}
            <SectionTitle>Descrição</SectionTitle>
            <OptionWrap>
              <Input
                label="identificador"
                onChange={(e) => this.handleChangeId(e)}
                defaultValue={this.state.attr.identifier}
                name="identifier"
                input={{}}
                type="text"
                meta={{ invalid: false }}
                rows="8"
                disabled={this.props.view}
              />
              <Input
                multiline
                label="Descrição"
                onChange={(e) => this.handleChange(e)}
                defaultValue={this.state.details}
                name="details"
                input={{}}
                type="multiline"
                meta={{ invalid: false }}
                rows="8"
                disabled={this.props.view}
              />
              <Calendar
                id="implementationDate"
                name="implementationDate"
                input={{}}
                label="Data de implementação"
                meta={{ invalid: false }}
                type="datetime-local"
                onChange={(e) => this.handleChange(e)}
                defaultValue={this.state.implementationDate}
                disabled={this.props.view}
              />
            </OptionWrap>
          </Col>
          <Col xs={12} md={3}>
            <SectionTitle>Localização</SectionTitle>
            <OptionWrap>
              <Input
                label="Local"
                onChange={(e) => this.handleChange(e)}
                defaultValue={this.state.local}
                name="local"
                input={{}}
                type="text"
                meta={{ invalid: false }}
                rows="8"
                disabled={this.props.view}
              />
              <Input
                label="Parcela / Parque"
                onChange={(e) => this.handleChange(e)}
                defaultValue={this.state.numero}
                name="pid"
                input={{}}
                type="text"
                meta={{ invalid: false }}
                rows="8"
                disabled={this.props.view}
              />
            </OptionWrap>
            {!this.props.view && (
              <div>
                <SectionTitle>Opções</SectionTitle>
                <OptionWrap>
                  <Files
                    config={config}
                    eventHandlers={eventHandlers}
                    djsConfig={djsConfig}
                  />

                  {this.props.status !== "getting" &&
                    this.props.status !== "saving" && (
                      <NextButton
                        color="primary"
                        onClick={() => this.handleSaveElement()}
                      >
                        Submeter
                        <MenuIcon className={`icon-check icons`} />
                      </NextButton>
                    )}
                  {this.props.isAdmin && this.state.elementId && (
                    <DisableButton onClick={() => this.delete()}>
                      <MenuIcon className={`icon-close icons`} /> Apagar
                    </DisableButton>
                  )}
                </OptionWrap>
              </div>
            )}
          </Col>

          {images && images.length > 0 && (
            <Col xs={12} md={3}>
              {this.renderPhotos(images)}
            </Col>
          )}
        </Row>
        {isOpen && (
          <Lightbox
            mainSrc={`${this.serverURL}${images[photoIndex].path}`}
            nextSrc={`${this.serverURL}${
              images[(photoIndex + 1) % images.length].path
            }`}
            prevSrc={`${this.serverURL}${
              images[(photoIndex + images.length - 1) % images.length].path
            }`}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      poligonType: state.georeference.type,
      elementType: state.georeference.newItem.type,
      elementSubType: state.georeference.newItem.subType,
      selected: state.app.search.selected,
      token: state.user.data.token,
      isAdmin: state.user.data.admin,
    };
  },
  { setDetails, setElement, updateElement, delElement }
)(EditElementDetails);
