import {
  UPDATE_SEARCH,
  GETTING,
  RECEIVED,
  TOGGLE_PARK,
  ROUTE_UPDATE,
  ROUTE_SAVED,
  ROUTE_EDIT,
} from "./constants";
import moment from "moment";
import { store } from "../../store/configure";

const date = new Date(),
  y = date.getFullYear(),
  m = date.getMonth();
const firstDay = new Date(y, m, 1);
const lastDay = new Date(y, m + 1, 0);

let initialState = {
  maintenancesList: [],
  inpectionsList: [],
  routesList: [],
  list: null,
  status: "",
  route: {
    id: null,
    open: false,
    list: [],
    startDate: moment(firstDay).format("YYYY-MM-DD"),
    endDate: moment(lastDay).format("YYYY-MM-DD"),
    team: "",
    frequency: "",
  },
  search: {
    local: "",
    freguesia: "",
    selected: "",
    team: "",
    startDate: moment(firstDay).format("YYYY-MM-DD"),
    endDate: moment(lastDay).format("YYYY-MM-DD"),
  },
};

const status = {
  edit: "edit",
  error: "error",
  saving: "saving",
  edited: "edited",
  saved: "saved",
  getting: "getting",
};

const isLocalStorage = typeof localStorage !== "undefined";
let newState = {};

//lets load user data for reload
if (isLocalStorage && localStorage.calendar) {
  initialState = JSON.parse(localStorage.calendar);
  initialState = {
    ...initialState,
    list: null,
    search: {
      ...initialState.search,
      startDate: moment(firstDay).format("YYYY-MM-DD"),
      endDate: moment(lastDay).format("YYYY-MM-DD"),
    },
  };
}
function presistData(type, data) {
  try {
    // if (isLocalStorage) localStorage[type] = JSON.stringify(data);
  } catch (error) {
    console.log(error);
  }
  return data;
}

function selectedParcelsString(route) {
  //TODO
  const role = store.getState().user.data.role;
  const geoJson = store.getState().parklist.geoJson;

  let thisParks = geoJson.reduce((prev, curr) => {
    // lets check if we already added folha
    if (!prev.find((p) => p.properties.local === curr.properties.local)) {
      //lets find and push this park
      const thisPark = route.find((r) => r === curr.properties.numero);
      if (thisPark) {
        prev.push(curr);
      }
    }

    return prev;
  }, []);
  return thisParks.map((park) => park.properties.local).join(", ");
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ROUTE_UPDATE:
      newState = {
        ...state,
        status: status.saving,
        route: {
          ...state.route,
          [action.data.element]: action.data.value,
        },
      };
      return presistData("calendar", newState);

    case ROUTE_SAVED:
      newState = {
        ...state,
        status: status.saved,
        route: {
          id: null,
          open: false,
          list: [],
          frequency: "",
          startDate: moment(firstDay).format("YYYY-MM-DD"),
          endDate: moment(lastDay).format("YYYY-MM-DD"),
          team: "",
        },
      };
      return presistData("calendar", newState);

    case ROUTE_EDIT:
      const selectedRoute = state.routesList.find(
        (route) => route.id === action.data
      );

      //no route found;
      if (!selectedRoute) {
        newState = {
          ...state,
          route: {
            id: null,
            open: state.route ? !state.route.open : true,
            list: [],
            frequency: "",
            startDate: moment(firstDay).format("YYYY-MM-DD"),
            endDate: moment(lastDay).format("YYYY-MM-DD"),
            team: "",
          },
        };
      } else {
        newState = {
          ...state,
          route: {
            id: selectedRoute.id,
            open: state.route ? !state.route.open : true,
            list: selectedRoute.list,
            frequency: selectedRoute.frequency,
            startDate: moment(selectedRoute.dateStart).format("YYYY-MM-DD"),
            endDate: moment(selectedRoute.dateEnd).format("YYYY-MM-DD"),
            team: selectedRoute.TeamId,
          },
        };
      }

      return presistData("calendar", newState);

    case UPDATE_SEARCH:
      newState = {
        ...state,
        search: {
          ...state.search,
          [action.data.element]:
            state.search[action.data.element] === action.data.value
              ? ""
              : action.data.value, //this should toggle
        },
      };

      //reset search
      if (action.data.element[0] === "freguesia") {
        newState = {
          ...state,
          search: {
            ...state.search,
            freguesia: action.data.value,
            selected: "",
            local: "",
          },
        };
        //reset search
      }
      return presistData("calendar", newState);

    case TOGGLE_PARK:
      let newPlaceList = [...state.route.list];
      let foundPark = [];
      if (typeof action.data === "object") {
        // check if we have new items
        foundPark = newPlaceList.filter(
          (value) => -1 !== action.data.indexOf(value)
        );

        // if we have new items
        if (foundPark.length > 0) {
          // // remove elements
          newPlaceList = newPlaceList.filter((value) => {
            return -1 === action.data.indexOf(value);
          });

          // console.log(newPlaceList);
        } else {
          newPlaceList = [...newPlaceList, ...action.data];
        }
      } else {
        // for individual elements
        foundPark = newPlaceList.indexOf(action.data);

        if (foundPark !== -1) {
          // remove elements
          newPlaceList.splice(foundPark, 1);
        } else {
          // add elements
          newPlaceList = [...newPlaceList, action.data];
        }
      }

      newState = {
        ...state,
        route: { ...state.route, list: newPlaceList },
      };
      if (isLocalStorage) localStorage.calendar = JSON.stringify(newState);
      return { ...newState };

    case GETTING:
      return { ...state, status: status.getting };
    case RECEIVED:
      let list = [];

      if (action.data.maintenancesList)
        action.data.maintenancesList.map((maintenance) => {
          let text = maintenance.details;

          if (maintenance.type === "preventiva") {
            if (maintenance.Intervention) {
              text = maintenance.Intervention.label;
            } else if (maintenance.Inspection) {
              text = maintenance.Inspection.type;
            }
          }

          list.push({
            id: maintenance.id,
            colorIndex: 1,
            content: text,
            range: moment.range(
              new Date(maintenance.date),
              new Date(maintenance.endDate)
            ),
            type: "maintenances",
          });
          return maintenance;
        });

      if (action.data.inspectionList)
        action.data.inspectionList.map((inspection) => {
          list.push({
            id: inspection.id,
            content: inspection.type,
            colorIndex: 2,
            range: moment.range(
              new Date(inspection.date),
              new Date(inspection.date)
            ),
            type: "inspection",
          });
          return inspection;
        });

      if (action.data.routesList)
        action.data.routesList.map((route) => {
          const parcelList = selectedParcelsString(route.list);
          if (parcelList.length > 0)
            list.push({
              id: route.id,
              content: `${
                route["Team.label"]
                  ? `${route["Team.label"]}, ${parcelList}`
                  : `${parcelList}`
              } `,
              colorIndex: 3,
              range: moment.range(
                new Date(route.eventDateStart || route.dateStart),
                new Date(route.eventDateEnd || route.dateEnd)
              ),
              type: "rota",
            });
          return route;
        });

      newState = {
        ...state,
        status: status.set,
        maintenancesList: action.data.maintenancesList,
        inpectionsList: action.data.inspectionList,
        routesList: action.data.routesList,
        list,
      };
      return presistData("calendar", newState);

    default:
      return state;
  }
};
