import * as constants from "./constants";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { store } from "../../store/configure";
import leafletPip from "@mapbox/leaflet-pip";
import Leaflet from "leaflet";
import moment from "moment";

export function findParkFromPosition() {
  return (dispatch) => {
    const geoJson = store.getState().parklist.geoJson;
    const position = store.getState().user.position;

    if (position) {
      const gjLayer = Leaflet.geoJson(geoJson);
      const results = leafletPip.pointInLayer(
        [position.lng, position.lat],
        gjLayer,
        true
      );

      dispatch({
        type: constants.CENTER,
      });

      if (results.length > 0) {
        toastr.success("Foi encontrado uma parcela na sua localização");
        return results[0].feature;
      }
    }
    toastr.error(
      "Não conseguimos encontrar nenhum elemento na sua localização"
    );

    return null;
  };
}

export function getGeoJson() {
  const geoJson = store.getState().parklist.geoJson;
  const { freguesia, selected, local, element } = store.getState().app.search;
  const role = store.getState().user.data.role;

  if (!geoJson)
    return {
      geoJson: [],
      freguesia: [],
      local: [],
      selected: [],
    };

  const freguesiaList = [];
  const localList = [];
  const selectedList = [];

  const list = geoJson.reduce((acc, park, index) => {
    const parkInSelection = selected.includes(park.properties.numero);
    if (element && element === park.properties.eid) {
      acc.push({
        ...park,
        properties: { ...park.properties, selected: true, color: "#fff" },
      });
      selectedList.push(park);
    } else if (selected && parkInSelection) {
      acc.push({
        ...park,
        properties: { ...park.properties, selected: true, color: "#01f4c1" },
      });
      selectedList.push(park);
    } else if (local && park.properties.local === local) {
      acc.push({
        ...park,
        properties: { ...park.properties, selected: true, color: "#4da1ff" },
      });
      localList.push(park);
    } else {
      if (
        !parkInSelection &&
        ((local && park.properties.local === local) ||
          (freguesia && park.properties.freguesia === freguesia))
      ) {
        acc.push({
          ...park,
          properties: { ...park.properties, area: true },
        });
      } else if (
        !local &&
        !parkInSelection &&
        freguesia &&
        park.properties.freguesia === freguesia
      ) {
        acc.push({
          ...park,
          properties: { ...park.properties, area: true, color: "#8400ff" },
        });
        freguesiaList.push(park);
      }
    }

    return acc;
  }, []);

  return {
    geoJson: list,
    freguesia: freguesiaList,
    local: localList,
    selected: selectedList,
  };
}

/**
 * we need this for the new EV team
 */
export function getTaskGeoJsonEV(geoJson) {
  const inpections = store.getState().tasks.inspectionList;
  const maintenances = store.getState().tasks.maintenancesList;
  const routesList = store.getState().tasks.routesList;
  const { selected, local, startDate } = store.getState().app.search;

  if (!geoJson || !routesList) return [];

  //lets find and push this park
  const parkinRoutes = routesList.reduce((prev, curr) => {
    return [...prev, ...curr.list];
  }, []);

  const list = geoJson.reduce((acc, park, index) => {
    const parkInSelection = selected.includes(park.properties.numero);
    if (
      selected &&
      selected.length > 0 &&
      parkInSelection &&
      local &&
      local === park.properties.local
    ) {
      acc.push({
        ...park,
        properties: { ...park.properties, color: "#01f4c1" },
      });
    } else if (local && local === park.properties.local) {
      acc.push({ ...park });
    } else if (
      !local &&
      selected && selected.length < 1 &&
      routesList.find((rout) => rout.list.includes(park.properties.numero))
    ) {
      let wasVisited = inpections.filter(
        (inpection) =>
          parkinRoutes.find((pid) => pid === inpection.pid) &&
          moment(startDate).diff(inpection.date, "hours") < 16
      );
      // lets check if we have maintenances
      if (wasVisited && maintenances && wasVisited.length === 0) {
        wasVisited = maintenances.filter(
          (maintenance) =>
            parkinRoutes.find((pid) => pid === maintenance.pid) &&
            moment(startDate).diff(maintenance.date, "hours") < 16
        );
      }

      acc.push({
        ...park,
        properties: wasVisited.find(
          (visited) => visited.location === park.properties.local
        )
          ? { ...park.properties, color: "#01f4c1" }
          : park.properties,
      });
    }
    return acc;
  }, []);

  return {
    geoJson: list,
    freguesia: list,
    local: list,
    selected: list,
  };
}

/**
 * This renders the park for normal teams
 */
export function getTaskGeoJson(geoJson) {
  const inpections = store.getState().tasks.inspectionList;
  const maintenances = store.getState().tasks.maintenancesList;
  const routesList = store.getState().tasks.routesList;
  const { selected, startDate } = store.getState().app.search;

  if (!geoJson || !routesList) return [];

  const list = geoJson.reduce((acc, park, index) => {
    const parkInSelection = selected.includes(park.properties.numero);
    if (selected.length > 0 && parkInSelection) {
      acc.push({
        ...park,
        properties: { ...park.properties, color: "#01f4c1" },
      });
    } else if (
      selected.length < 1 &&
      routesList.find((rout) => rout.list.includes(park.properties.numero))
    ) {
      let wasVisited =
        inpections.find(
          (inpection) =>
            inpection.pid === park.properties.numero &&
            moment(startDate).diff(inpection.date, "hours") < 16
        ) || [];
      // lets check if we have maintenances
      if (wasVisited && maintenances && wasVisited.length === 0) {
        wasVisited = maintenances.filter(
          (maintenance) =>
            maintenance.pid === park.properties.numero &&
            moment(startDate).diff(maintenance.date, "hours") < 16
        );
      }
      acc.push({
        ...park,
        properties: wasVisited
          ? { ...park.properties, color: "#01f4c1" }
          : park.properties,
      });
    }
    return acc;
  }, []);

  return {
    geoJson: list,
    freguesia: list,
    local: list,
    selected: list,
  };
}

export function getGeoElementJson(elementsGeoJson) {
  return (dispatch) => {
    const geoJson = elementsGeoJson || store.getState().app.elementsGeoJson;
    const elmentDisplay = store.getState().app.elmentDisplay;
    const role = store.getState().user.data.role;

    if (!elmentDisplay) return [];

    const {
      freguesia,
      selected,
      local,
      elementTypeSelected,
      element,
    } = store.getState().app.search;
    // const { freguesia, selected, local, role } = this.props;
    if (!geoJson) return [];
    return geoJson.reduce((acc, park, index) => {
      const parkInSelection = selected.includes(park.properties.numero);
      let elementSelection = false;

      if (elementTypeSelected.length === 0) {
        elementSelection = true;
      }

      if (
        elementTypeSelected.length > 0 &&
        park.properties.category &&
        elementTypeSelected.includes(park.properties.category)
      ) {
        elementSelection = true;
      }

      if (
        elementTypeSelected.length > 0 &&
        park.properties.label &&
        elementTypeSelected.includes(park.properties.label)
      ) {
        elementSelection = true;
      }

      const thisId = park.properties.id || park.properties.origin;
      //set element properties

      const addElement = {
        ...park,
        properties:
          thisId === element || thisId === elementTypeSelected
            ? { ...park.properties, selected: true, color: "#ff3c3c" }
            : park.properties,
      };

      acc.push({
        ...addElement,
      });
      if (
        // !parkInSelection &&
        local &&
        park.properties.local === local &&
        elementSelection
      ) {
        acc.push({ ...addElement });
      } else if (
        // !local &&
        // !parkInSelection &&
        freguesia &&
        elementSelection &&
        park.properties.freguesia === freguesia
      ) {
        acc.push({ ...addElement });
      }

      return acc;
    }, []);
  };
}

export function getAllGreenSpaces() {
  return (dispatch) => {
    dispatch({
      type: constants.GETTING,
    });
    toastr.info("A receber lista parcelas");
    axios
      .get(`/api/v1/espacosverdes/`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.RECEIVED,
            data: response.data.msg[0],
          });
          toastr.success("Lista de parcelas recebida");
        }
      })
      .catch(function(response) {
        // toastr.error('Ocorreu um erro a obter as inspecções');

        dispatch({
          type: constants.ERROR,
        });
        console.log(response);
      });
  };
}
